export const COLORS = {
  green: "#00FF00",
  red: "#FF0000",
};

export const INFO_POPOVER_CONTENT = {
  salesLift: <p>Estimated sales lift</p>,
};

export const determineScoreColor = (score1: number, score2: number) => {
  if (score1 > score2) return COLORS.green;
  if (score1 < score2) return COLORS.red;
  return "inherit";
};

export const getTextColor = (value: number) => {
  return value >= 0 ? COLORS.green : COLORS.red;
};

export const determineDifference = (
  productScore: number,
  competitorScore: number,
) => {
  if (productScore > competitorScore) return "higher";
  if (productScore < competitorScore) return "lower";
  return "equal";
};

export const getDifferenceColorClass = (
  productScore: number,
  competitorScore: number,
  styles: Record<string, string>,
) => {
  if (productScore > competitorScore) return styles.differenceGreen;
  if (productScore < competitorScore) return styles.differenceRed;
  return styles.differenceEqual;
};

export const getHighlightColorClass = (
  productScore: number,
  competitorScore: number,
  styles: Record<string, string>,
) => {
  if (productScore > competitorScore) return styles.highlightGreen;
  if (productScore < competitorScore) return styles.highlightRed;
  return styles.highlightEqual;
};
