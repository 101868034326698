import React, { useCallback } from "react";
import "./index.scss";
import {
  Area,
  CartesianGrid,
  ComposedChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { formatThousands } from "@pages/AdsAnalitics/components/Campaigns/components/CampaignChart/utils";
import { CustomTooltip } from "@pages/Dashboard/features/SalesPerformance/Chart/CustomTooltip";
import { Checks } from "@pages/Dashboard/features/SalesPerformance/types";

interface ChartProps {
  checks: Checks;
  metricsColors: { [key: string]: string };
  data: any;
}

export const Chart: React.FC<ChartProps> = ({ metricsColors, data }) => {
  const updatedMetricsColors = {
    ...metricsColors,
    adSpend: "#80C67A",
  };

  return (
    <div className="chart-container">
      <h2 className="chart-title">Subscription Revenue Reporting</h2>
      <div className="chart-legend">
        <div className="legend-item">
          <span
            className="legend-color"
            style={{ backgroundColor: updatedMetricsColors["adSpend"] }}
          ></span>
          <span>Subscription Cart Revenue</span>
        </div>
        <div className="legend-item">
          <span
            className="legend-color"
            style={{ backgroundColor: updatedMetricsColors["adImpressions"] }}
          ></span>
          <span>Subscription Cart Revenue Forecasted</span>
        </div>
      </div>
      <div className="chart-wrapper">
        <ResponsiveContainer width="100%" height={300}>
          <ComposedChart
            width={500}
            height={300}
            data={data}
            margin={{
              top: 20,
              right: 30,
              left: 20,
              bottom: 0,
            }}
          >
            <defs>
              <linearGradient
                id="adImpressionsGradient"
                x1="0"
                y1="0"
                x2="0"
                y2="1"
              >
                <stop
                  offset="0%"
                  stopColor={updatedMetricsColors["adImpressions"]}
                  stopOpacity={0.3}
                />
                <stop
                  offset="100%"
                  stopColor={updatedMetricsColors["adImpressions"]}
                  stopOpacity={0}
                />
              </linearGradient>
              <linearGradient id="adSpendGradient" x1="0" y1="0" x2="0" y2="1">
                <stop
                  offset="0%"
                  stopColor={updatedMetricsColors["adSpend"]}
                  stopOpacity={0.3}
                />
                <stop
                  offset="100%"
                  stopColor={updatedMetricsColors["adSpend"]}
                  stopOpacity={0}
                />
              </linearGradient>
            </defs>
            <CartesianGrid vertical={false} stroke="#333" opacity={0.1} />
            <XAxis
              dataKey="date"
              interval="preserveStartEnd"
              stroke="#7C7C78"
              padding={{ left: 20, right: 20 }}
            />
            <Tooltip
              content={({ active, payload, label }) => (
                <CustomTooltip
                  active={active}
                  payload={payload}
                  colorMetric={updatedMetricsColors}
                  label={label}
                />
              )}
              cursor={{
                fill: "transparent",
                stroke: "white",
                strokeDasharray: "4 4",
              }}
            />
            <YAxis
              yAxisId="left"
              orientation="left"
              stroke="#7C7C78"
              tickFormatter={(tick) => formatThousands(tick)}
              axisLine={false}
              tickLine={false}
              tickCount={6}
              domain={[0, (dataMax: number) => Math.ceil(dataMax / 10) * 10]}
              tick={{ fill: "#7C7C78" }}
            />
            <Area
              key="adImpressions"
              yAxisId="left"
              type="monotone"
              dataKey="adImpressions"
              stroke={updatedMetricsColors["adImpressions"]}
              fill="url(#adImpressionsGradient)"
              strokeDasharray="4 4"
              strokeWidth={2}
              dot={false}
            />
            <Area
              key="adSpend"
              yAxisId="left"
              type="monotone"
              dataKey="adSpend"
              stroke={updatedMetricsColors["adSpend"]}
              fill="url(#adSpendGradient)"
              strokeWidth={2}
              dot={false}
            />
          </ComposedChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};
