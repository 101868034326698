import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { Spin } from "antd";
import { mockWidgets } from "./constants";
import DiscountCards from "../DiscountCards";
import { useAdsAnaliticsStore } from "src/store/ads-analitics.store";
import classNames from "classnames";
export interface SubWidget {
  id: string;
  title: string;
  value: string;
  change?: string;
  isPositive: boolean;
}

export interface Widget {
  id: string;
  items: SubWidget[];
}

interface WidgetsSubscriptionProps {
  widgets: Widget[];
}

const WidgetsSubscription: React.FC<WidgetsSubscriptionProps> = () => {
  const { isWhatIfEnabled } = useAdsAnaliticsStore((state) => ({
    isWhatIfEnabled: state.isWhatIfEnabled,
  }));

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => setIsLoading(false), 1000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <div className={styles.widgetsList}>
      <div className={styles.flexBox}>
        <DiscountCards isWhatIfEnabled={isWhatIfEnabled} />
        <div className={styles.metricsBlock}>
          <div className={styles.metricsHeader}>
            <h2 className={styles.metricsTitle}>Metrics</h2>
          </div>
          {isLoading ? (
            <div className={styles.loader}>
              <Spin size="large" />
            </div>
          ) : isWhatIfEnabled ? (
            <div className={styles.widgetContainer}>
              {mockWidgets
                .flatMap((widget) => widget.items)
                .map((item) => (
                  <div key={item.id} className={styles.widgetItem}>
                    <div className={styles.widgetTitle}>{item.title}</div>
                    <div className={styles.blockBetween}>
                      <p className={styles.valueAmount}>{item.value}</p>
                      {item.change && (
                        <p
                          className={classNames(styles.change, {
                            [styles.positive]: item.isPositive,
                            [styles.negative]: !item.isPositive,
                          })}
                        >
                          {item.change}
                        </p>
                      )}
                    </div>
                  </div>
                ))}
            </div>
          ) : (
            <div className={styles.widgetContainer}>
              {mockWidgets.slice(0, 4).map((widget) => (
                <div key={widget.id} className={styles.widgetItem}>
                  {widget.items.slice(0, 1).map((item) => (
                    <div key={item.id} className={styles.subWidget}>
                      <div className={styles.widgetTitle}>{item.title}</div>
                      <div className={styles.between}>
                        <div className={styles.valueAmount}>{item.value}</div>
                      </div>
                    </div>
                  ))}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default React.memo(WidgetsSubscription);
