import React from "react";
import ReactECharts from "echarts-for-react";
import { data } from "./mock";
import { tooltipFormatter } from "./utils";

export const HeatMap: React.FC = () => {
  const option = {
    tooltip: {
      trigger: "item",
      formatter: tooltipFormatter,
      backgroundColor: "#ffffff",
      borderColor: "#ffffff",
      borderRadius: 16,
      boxShadow: "0px 6px 50px 0px rgba(0, 0, 0, 0.25)",
      padding: 16,
      position: function (point, params, dom, rect, size) {
        const x = point[0] + 45;
        const y = point[1] - size.contentSize[1] / 2;
        return [x, y];
      },
    },
    xAxis: {
      name: "ACOS",
      nameLocation: "center",
      nameTextStyle: {
        color: "#ffffff",
        fontSize: 14,
      },
      nameGap: 52,
      type: "value",
      min: 0,
      max: 100,
      interval: 5,
      axisLabel: {
        formatter: (value: number) => `${value}%`,
        color: "#B5B5B5",
        align: "center",
        fontFamily: "Roboto",
        fontSize: 12,
        fontStyle: "normal",
        fontWeight: 300,
        lineHeight: 17,
        padding: [10, 0, 0, 0],
      },
      splitLine: { show: false },
      axisTick: { show: false },
      axisLine: { show: false },
    },
    yAxis: {
      name: "Ad Sales",
      nameLocation: "center",
      nameTextStyle: { color: "#ffffff", fontSize: 14 },
      nameGap: 50,
      nameRotate: 90,
      type: "value",
      axisLabel: {
        formatter: (value: number) => `$${value}`,
        color: "#B5B5B5",
        align: "center",
        fontFamily: "Roboto",
        fontSize: 12,
        fontStyle: "normal",
        fontWeight: 300,
        lineHeight: 17,
        padding: [0, 40, 0, 0],
      },
      splitLine: { show: false },
      axisTick: { show: false },
      axisLine: { show: false },
    },
    series: [
      {
        type: "scatter",
        data: data.map((point) => ({
          value: [point.x, point.y],
          symbolSize: point.size,
          itemStyle: {
            color: point.color,
          },
          name: point.name,
          adSpend: point.adSpend,
          searchVolume: point.searchVolume,
          relevanceScore: point.relevanceScore,
        })),
      },
    ],
  };

  return (
    <ReactECharts option={option} style={{ height: 654, width: "100%" }} />
  );
};
