import { Product } from "@services/amazon/products/types";
import { MockDataType } from "../mock";

export const getAsin = (
  selectedProduct: string | { asin?: string } | null,
): string | undefined => {
  return typeof selectedProduct === "string"
    ? selectedProduct
    : selectedProduct?.asin;
};

export const getStarReviews = (reviews: Record<string, number>) => {
  return [
    { label: "5 STAR", value: reviews["5_star"] },
    { label: "4 STAR", value: reviews["4_star"] },
    { label: "3 STAR", value: reviews["3_star"] },
    { label: "2 STAR", value: reviews["2_star"] },
    { label: "1 STAR", value: reviews["1_star"] },
  ];
};

export const getDateRange = () => {
  const today = new Date();
  const date_end = today.toISOString().split("T")[0];

  const oneMonthAgo = new Date(today);
  oneMonthAgo.setMonth(today.getMonth() - 8);
  const date_start = oneMonthAgo.toISOString().split("T")[0];

  return { date_start, date_end };
};

export const getRat = (
  product: Product | null,
  mockData: MockDataType,
): number => {
  return product?.rating ?? mockData.rating;
};

export const getReviews = (mockData: MockDataType): Record<string, number> => {
  return mockData.reviews;
};

export const getProductsToRender = (
  product: Product | null,
  mockData: MockDataType,
): any[] => {
  if (Array.isArray(product)) {
    return product.flatMap((item) => item.spiderDots || [item]);
  } else if (product?.spiderDots && Array.isArray(product.spiderDots)) {
    return product.spiderDots;
  } else {
    return [product || mockData];
  }
};

export const getUniqueProductsToRender = (productsToRender: any[]): any[] => {
  return productsToRender.filter(
    (item, index, self) =>
      index === self.findIndex((t) => t.asin === item.asin),
  );
};
