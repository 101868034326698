import { Suspense, useEffect } from "react";
import "./App.css";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";

import { features } from "@lib/features";
import { ErrorBoundary } from "@components/ErrorBoundary";
import { FeatureToggles } from "@paralleldrive/react-feature-toggles";
import { NyleConfig, NyleConfigProvider } from "@components/NyleConfig";
import { ResponsiveProvider } from "./contexts/ResponsiveContext";
import { useLocation, useRoutes } from "react-router-dom";
import { routes } from "./router";
import { useCheckSession } from "@hooks/useCheckSession";
import { Spinner } from "@components/ui-kit/Spinner";
import { Chat } from "@components/ChatBot";
import { RouteTracker } from "@components/Analytics/RouteTracker";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
    },
  },
});

function App() {
  const router = useRoutes(routes);
  const loading = useCheckSession();
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <ErrorBoundary>
      <ResponsiveProvider>
        <NyleConfigProvider>
          <div className="app">
            <NyleConfig />
            <FeatureToggles features={features}>
              <QueryClientProvider client={queryClient}>
                <ReactQueryDevtools
                  initialIsOpen={false}
                  position="bottom-right"
                />
                <Suspense fallback="">
                  {loading ? (
                    <Spinner size="xxl" className="app__spinner" />
                  ) : (
                    <>
                      <RouteTracker />
                      {router}
                      <Chat />
                    </>
                  )}
                </Suspense>
              </QueryClientProvider>
            </FeatureToggles>
          </div>
        </NyleConfigProvider>
      </ResponsiveProvider>
    </ErrorBoundary>
  );
}

export default App;
