import "./styles.module.scss";
import { Tooltip } from "../../../../components/ExpandebleTable/Tooltip";
import TooltipIcon from "@assets/icons/tooltip";
import styles from "./styles.module.scss";
import classNames from "classnames";
import Minus from "@assets/icons/minus";
import Star from "@assets/icons/StarKeyword";
import { KeyTooltip } from "@components/ExpandebleTable/KeyTooltip";
import { Keyword } from "src/store/keyword.state";

export const formatData = ({
  data,
  openTooltip,
  openedTooltip,
  keyTooltip,
  setKeyTooltip,
  keyword,
  setKeywords,
  withKeywords,
  parentAsin,
  whatIfMode,
}: {
  data: any[];
  openTooltip?: (title: string) => void;
  openedTooltip?: string;
  keyTooltip?: string;
  setKeyTooltip?: (title: string) => void;
  keyword?: Keyword;
  setKeywords?: (keywords: Keyword) => void;
  withKeywords?: boolean;
  parentAsin?: string;
  whatIfMode?: boolean;
}) => {
  if (!data) return [];
  return data.map((item) => {
    const newItem = { ...item };
    let color: string;

    if (newItem.performance && whatIfMode) {
      switch (newItem.performance) {
        case "Good":
          color = "green";
          break;
        case "Mid":
          color = "yellow";
          break;
        case "Poor":
          color = "darkRed";
          break;
        case "No Sales":
          color = "red";
          break;
      }
    }

    // Process percentage and trend formatting as before
    Object.keys(newItem).forEach((key) => {
      if (color) {
        newItem[key] = (
          <div className={classNames(styles.cell, color)}>{newItem[key]}</div>
        );
      }

      if (typeof newItem[key] === "number") {
        switch (key) {
          case "spend":
          case "sales":
          case "shareSpend":
          case "cpc":
            newItem[key] =
              `$${(Math.floor(newItem[key] * 100) / 100).toLocaleString("en-US")}`;
            break;
          case "acos":
          case "roi":
          case "tacos":
            newItem[key] = `${(newItem[key] * 100).toFixed(2)}%`;
            break;
        }
      }

      if (
        typeof newItem[key] === "object" &&
        newItem[key].value &&
        newItem[key].percentage
      ) {
        const { value, percentage } = newItem[key];

        let trend;
        if (percentage.startsWith("+")) {
          trend = "up";
        } else if (percentage.startsWith("-")) {
          trend = "down";
        } else {
          trend = "neutral";
        }

        newItem[key] = (
          <div className={classNames(styles.cell, color)}>
            <span>{value}</span>
            <span className={classNames(styles.percentage, styles[trend])}>
              {trend === "neutral" && (
                <>
                  <Minus />
                  {percentage.replace(/[+-]/g, "")}
                </>
              )}
            </span>
          </div>
        );
      }
    });

    // Determine the current ASIN and conditions
    const isAsin = newItem.typeAd?.includes("asin");
    const isNyl = newItem.campaign?.includes("Nyl");
    const isCampaign = newItem.campaign?.includes("Campaign Name");

    if (isAsin) {
      newItem.typeAd = withTooltip(
        openTooltip,
        openedTooltip,
        newItem.typeAd,
        true,
      );
    }

    if (isNyl && withKeywords) {
      newItem.action = withCustomKey(
        setKeyTooltip,
        keyTooltip,
        newItem.campaign,
        keyword,
        setKeywords,
      );
    }

    if (isNyl || isCampaign) {
      newItem.campaign = withTooltip(
        openTooltip,
        openedTooltip,
        newItem.campaign,
        false,
        isCampaign && parentAsin,
      );
    }

    // Recursively process children, passing down the current ASIN
    if (newItem.children) {
      const asin = newItem.campaign;
      newItem.children = formatData({
        data: newItem.children,
        openTooltip,
        openedTooltip,
        keyTooltip,
        setKeyTooltip,
        keyword,
        setKeywords,
        withKeywords,
        parentAsin: asin,
      });
    }

    return newItem;
  });
};

const withCustomKey = (
  openTooltip: (title: string) => void,
  openedTooltip: string,
  title: string,
  keyword: Keyword,
  setKeywords: (keywords: Keyword) => void,
) => {
  const closeTooltip = () => {
    openTooltip("");
  };

  return (
    <>
      <button
        onClick={(e) => {
          e.stopPropagation();
          if (openedTooltip === title) {
            openTooltip("");
          } else {
            openTooltip(title);
          }
        }}
        className={styles.star}
      >
        <Star
          fill={keyword && keyword.name.includes(title) ? "#fff" : "#7C7C78"}
        />
      </button>
      {openedTooltip === title && (
        <KeyTooltip
          close={closeTooltip}
          keywordName={title}
          keyword={keyword}
          setKeywords={setKeywords}
        />
      )}
    </>
  );
};

const withTooltip = (
  openTooltip: (title: string) => void,
  openedTooltip: string,
  title: string,
  withKeys?: boolean,
  value?: string,
) => {
  const closeTooltip = () => {
    openTooltip("");
  };
  return (
    <div className={styles.cell__tooltip}>
      <span>{title}</span>
      <button
        onMouseEnter={() => {
          openTooltip(title);
        }}
        onMouseLeave={closeTooltip}
      >
        <TooltipIcon />
      </button>
      {openedTooltip === title && (
        <Tooltip title={value ? value : title} withKeys={withKeys} />
      )}
    </div>
  );
};

export const performanceData = [
  {
    key: "1",
    typeAd: "Good",
    spend: "$7,798",
    shareSpend: "25%",
    sales: "$29,911",
    shareSales: "75%",
    cpc: "$1.11",
    acos: "10%",
    roi: "131%",
    tacos: "10%",
    children: [
      {
        key: "1-1",
        typeAd: "Sponsored Brands",
        spend: "$348",
        shareSpend: "4.4%",
        sales: "$29,911",
        shareSales: "75%",
        cpc: "$1.11",
        acos: "10%",
        roi: "131%",
        tacos: "10%",
        children: [
          {
            key: "1-1-1",
            typeAd: "Nyle - SB PC",
            spend: "$300",
            shareSpend: "86%",
            sales: "$29,911",
            shareSales: "75%",
            cpc: "$1.11",
            acos: "10%",
            roi: "131%",
            tacos: "10%",
            children: [
              {
                key: "1-1-1-1",
                typeAd: 'asin="B00100LLVS"',
                spend: "$300",
                shareSpend: "86%",
                sales: "$29,911",
                shareSales: "75%",
                cpc: "$1.11",
                acos: "10%",
                roi: "131%",
                tacos: "10%",
              },
              {
                key: "1-1-1-2",
                typeAd: 'asin="B00AHAWWOO"',
                spend: "$300",
                shareSpend: "86%",
                sales: "$29,911",
                shareSales: "75%",
                cpc: "$1.11",
                acos: "10%",
                roi: "131%",
                tacos: "10%",
              },
              {
                key: "1-1-1-3",
                typeAd: 'asin="B07QC8LFQP"',
                spend: "$300",
                shareSpend: "86%",
                sales: "$29,911",
                shareSales: "75%",
                cpc: "$1.11",
                acos: "10%",
                roi: "131%",
                tacos: "10%",
              },
              {
                key: "1-1-1-4",
                typeAd: 'asin="B07XJZ1MSF"',
                spend: "$300",
                shareSpend: "86%",
                sales: "$29,911",
                shareSales: "75%",
                cpc: "$1.11",
                acos: "10%",
                roi: "131%",
                tacos: "10%",
              },
              {
                key: "1-1-1-5",
                typeAd: 'asin="B082TPDTM2"',
                spend: "$300",
                shareSpend: "86%",
                sales: "$29,911",
                shareSales: "75%",
                cpc: "$1.11",
                acos: "10%",
                roi: "131%",
                tacos: "10%",
              },
              {
                key: "1-1-1-6",
                typeAd: 'asin="BOCRVN4NLR"',
                spend: "$300",
                shareSpend: "86%",
                sales: "$29,911",
                shareSales: "75%",
                cpc: "$1.11",
                acos: "10%",
                roi: "131%",
                tacos: "10%",
              },
              {
                key: "1-1-1-7",
                typeAd: 'asin="B0D2QK4SHR"',
                spend: "$300",
                shareSpend: "86%",
                sales: "$29,911",
                shareSales: "75%",
                cpc: "$1.11",
                acos: "10%",
                roi: "131%",
                tacos: "10%",
              },
            ],
          },
          {
            key: "1-1-2",
            typeAd: "Nyle - SB PC",
            spend: "$300",
            shareSpend: "86%",
            sales: "$29,911",
            shareSales: "75%",
            cpc: "$1.11",
            acos: "10%",
            roi: "131%",
            tacos: "10%",
            children: [
              {
                key: "1-1-2-1",
                typeAd: 'asin="B00100LLVS"',
                spend: "$300",
                shareSpend: "86%",
                sales: "$29,911",
                shareSales: "75%",
                cpc: "$1.11",
                acos: "10%",
                roi: "131%",
                tacos: "10%",
              },
              {
                key: "1-1-2-2",
                typeAd: 'asin="B00AHAWWOO"',
                spend: "$300",
                shareSpend: "86%",
                sales: "$29,911",
                shareSales: "75%",
                cpc: "$1.11",
                acos: "10%",
                roi: "131%",
                tacos: "10%",
              },
              {
                key: "1-1-2-3",
                typeAd: 'asin="B07QC8LFQP"',
                spend: "$300",
                shareSpend: "86%",
                sales: "$29,911",
                shareSales: "75%",
                cpc: "$1.11",
                acos: "10%",
                roi: "131%",
                tacos: "10%",
              },
              {
                key: "1-1-2-4",
                typeAd: 'asin="B07XJZ1MSF"',
                spend: "$300",
                shareSpend: "86%",
                sales: "$29,911",
                shareSales: "75%",
                cpc: "$1.11",
                acos: "10%",
                roi: "131%",
                tacos: "10%",
              },
              {
                key: "1-1-2-5",
                typeAd: 'asin="B082TPDTM2"',
                spend: "$300",
                shareSpend: "86%",
                sales: "$29,911",
                shareSales: "75%",
                cpc: "$1.11",
                acos: "10%",
                roi: "131%",
                tacos: "10%",
              },
              {
                key: "1-1-2-6",
                typeAd: 'asin="BOCRVN4NLR"',
                spend: "$300",
                shareSpend: "86%",
                sales: "$29,911",
                shareSales: "75%",
                cpc: "$1.11",
                acos: "10%",
                roi: "131%",
                tacos: "10%",
              },
              {
                key: "1-1-2-7",
                typeAd: 'asin="B0D2QK4SHR"',
                spend: "$300",
                shareSpend: "86%",
                sales: "$29,911",
                shareSales: "75%",
                cpc: "$1.11",
                acos: "10%",
                roi: "131%",
                tacos: "10%",
              },
            ],
          },
          {
            key: "1-1-3",
            typeAd: "Nyle - SBV PC",
            spend: "$300",
            shareSpend: "86%",
            sales: "$29,911",
            shareSales: "75%",
            cpc: "$1.11",
            acos: "10%",
            roi: "131%",
            tacos: "10%",
            children: [
              {
                key: "1-1-3-1",
                typeAd: 'asin="B00100LLVS"',
                spend: "$300",
                shareSpend: "86%",
                sales: "$29,911",
                shareSales: "75%",
                cpc: "$1.11",
                acos: "10%",
                roi: "131%",
                tacos: "10%",
              },
              {
                key: "1-1-3-2",
                typeAd: 'asin="B00AHAWWOO"',
                spend: "$300",
                shareSpend: "86%",
                sales: "$29,911",
                shareSales: "75%",
                cpc: "$1.11",
                acos: "10%",
                roi: "131%",
                tacos: "10%",
              },
              {
                key: "1-1-3-3",
                typeAd: 'asin="B07QC8LFQP"',
                spend: "$300",
                shareSpend: "86%",
                sales: "$29,911",
                shareSales: "75%",
                cpc: "$1.11",
                acos: "10%",
                roi: "131%",
                tacos: "10%",
              },
              {
                key: "1-1-3-4",
                typeAd: 'asin="B07XJZ1MSF"',
                spend: "$300",
                shareSpend: "86%",
                sales: "$29,911",
                shareSales: "75%",
                cpc: "$1.11",
                acos: "10%",
                roi: "131%",
                tacos: "10%",
              },
              {
                key: "1-1-3-5",
                typeAd: 'asin="B082TPDTM2"',

                spend: "$300",
                shareSpend: "86%",
                sales: "$29,911",
                shareSales: "75%",
                cpc: "$1.11",
                acos: "10%",
                roi: "131%",
                tacos: "10%",
              },
              {
                key: "1-1-3-6",
                typeAd: 'asin="BOCRVN4NLR"',

                spend: "$300",
                shareSpend: "86%",
                sales: "$29,911",
                shareSales: "75%",
                cpc: "$1.11",
                acos: "10%",
                roi: "131%",
                tacos: "10%",
              },
              {
                key: "1-1-3-7",
                typeAd: 'asin="B0D2QK4SHR"',

                spend: "$300",
                shareSpend: "86%",
                sales: "$29,911",
                shareSales: "75%",
                cpc: "$1.11",
                acos: "10%",
                roi: "131%",
                tacos: "10%",
              },
            ],
          },
        ],
      },
      {
        key: "1-2",
        typeAd: "Sponsored Display",
        spend: "$19",
        shareSpend: "0.2%",
        sales: "$29,911",
        shareSales: "75%",
        cpc: "$1.11",
        acos: "10%",
        roi: "131%",
        tacos: "10%",
      },
      {
        key: "1-3",
        typeAd: "Sponsored Products",
        spend: "$7,431",
        shareSpend: "95.4%",
        sales: "$29,911",
        shareSales: "75%",
        cpc: "$1.11",
        acos: "10%",
        roi: "131%",
        tacos: "10%",
      },
    ],
  },
  {
    key: "2",
    typeAd: "Mid",
    spend: "$8,504",
    shareSpend: "27.7%",
    sales: "$6,819",
    shareSales: "21%",
    cpc: "$1.32",
    acos: "38%",
    roi: "78%",
    tacos: "13%",
    children: [
      {
        key: "2-1",
        typeAd: "Sponsored Brands",
        spend: "$348",
        shareSpend: "4.4%",
        sales: "$29,911",
        shareSales: "75%",
        cpc: "$1.11",
        acos: "10%",
        roi: "131%",
        tacos: "10%",
        children: [
          {
            key: "2-1-1",
            typeAd: "Nyle - SB PC",
            spend: "$300",
            shareSpend: "86%",
            sales: "$29,911",
            shareSales: "75%",
            cpc: "$1.11",
            acos: "10%",
            roi: "131%",
            tacos: "10%",
            children: [
              {
                key: "2-1-1-1",
                typeAd: 'asin="B00100LLVS"',
                spend: "$300",
                shareSpend: "86%",
                sales: "$29,911",
                shareSales: "75%",
                cpc: "$1.11",
                acos: "10%",
                roi: "131%",
                tacos: "10%",
              },
              {
                key: "2-1-1-2",
                typeAd: 'asin="B00AHAWWOO"',
                spend: "$300",
                shareSpend: "86%",
                sales: "$29,911",
                shareSales: "75%",
                cpc: "$1.11",
                acos: "10%",
                roi: "131%",
                tacos: "10%",
              },
              {
                key: "2-1-1-3",
                typeAd: 'asin="B07QC8LFQP"',
                spend: "$300",
                shareSpend: "86%",
                sales: "$29,911",
                shareSales: "75%",
                cpc: "$1.11",
                acos: "10%",
                roi: "131%",
                tacos: "10%",
              },
              {
                key: "2-1-1-4",
                typeAd: 'asin="B07XJZ1MSF"',
                spend: "$300",
                shareSpend: "86%",
                sales: "$29,911",
                shareSales: "75%",
                cpc: "$1.11",
                acos: "10%",
                roi: "131%",
                tacos: "10%",
              },
              {
                key: "2-1-1-5",
                typeAd: 'asin="B082TPDTM2"',
                spend: "$300",
                shareSpend: "86%",
                sales: "$29,911",
                shareSales: "75%",
                cpc: "$1.11",
                acos: "10%",
                roi: "131%",
                tacos: "10%",
              },
              {
                key: "2-1-1-6",
                typeAd: 'asin="BOCRVN4NLR"',
                spend: "$300",
                shareSpend: "86%",
                sales: "$29,911",
                shareSales: "75%",
                cpc: "$1.11",
                acos: "10%",
                roi: "131%",
                tacos: "10%",
              },
              {
                key: "2-1-1-7",
                typeAd: 'asin="B0D2QK4SHR"',
                spend: "$300",
                shareSpend: "86%",
                sales: "$29,911",
                shareSales: "75%",
                cpc: "$1.11",
                acos: "10%",
                roi: "131%",
                tacos: "10%",
              },
            ],
          },
          {
            key: "2-1-2",
            typeAd: "Nyle - SB PC",
            spend: "$300",
            shareSpend: "86%",
            sales: "$29,911",
            shareSales: "75%",
            cpc: "$1.11",
            acos: "10%",
            roi: "131%",
            tacos: "10%",
            children: [
              {
                key: "2-1-2-1",
                typeAd: 'asin="B00100LLVS"',
                spend: "$300",
                shareSpend: "86%",
                sales: "$29,911",
                shareSales: "75%",
                cpc: "$1.11",
                acos: "10%",
                roi: "131%",
                tacos: "10%",
              },
              {
                key: "2-1-2-2",
                typeAd: 'asin="B00AHAWWOO"',
                spend: "$300",
                shareSpend: "86%",
                sales: "$29,911",
                shareSales: "75%",
                cpc: "$1.11",
                acos: "10%",
                roi: "131%",
                tacos: "10%",
              },
              {
                key: "2-1-2-3",
                typeAd: 'asin="B07QC8LFQP"',
                spend: "$300",
                shareSpend: "86%",
                sales: "$29,911",
                shareSales: "75%",
                cpc: "$1.11",
                acos: "10%",
                roi: "131%",
                tacos: "10%",
              },
              {
                key: "2-1-2-4",
                typeAd: 'asin="B07XJZ1MSF"',
                spend: "$300",
                shareSpend: "86%",
                sales: "$29,911",
                shareSales: "75%",
                cpc: "$1.11",
                acos: "10%",
                roi: "131%",
                tacos: "10%",
              },
              {
                key: "2-1-2-5",
                typeAd: 'asin="B082TPDTM2"',
                spend: "$300",
                shareSpend: "86%",
                sales: "$29,911",
                shareSales: "75%",
                cpc: "$1.11",
                acos: "10%",
                roi: "131%",
                tacos: "10%",
              },
              {
                key: "2-1-2-6",
                typeAd: 'asin="BOCRVN4NLR"',
                spend: "$300",
                shareSpend: "86%",
                sales: "$29,911",
                shareSales: "75%",
                cpc: "$1.11",
                acos: "10%",
                roi: "131%",
                tacos: "10%",
              },
              {
                key: "2-1-2-7",
                typeAd: 'asin="B0D2QK4SHR"',
                spend: "$300",
                shareSpend: "86%",
                sales: "$29,911",
                shareSales: "75%",
                cpc: "$1.11",
                acos: "10%",
                roi: "131%",
                tacos: "10%",
              },
            ],
          },
          {
            key: "2-1-3",
            typeAd: "Nyle - SBV PC",
            spend: "$300",
            shareSpend: "86%",
            sales: "$29,911",
            shareSales: "75%",
            cpc: "$1.11",
            acos: "10%",
            roi: "131%",
            tacos: "10%",
            children: [
              {
                key: "2-1-3-1",
                typeAd: 'asin="B00100LLVS"',
                spend: "$300",
                shareSpend: "86%",
                sales: "$29,911",
                shareSales: "75%",
                cpc: "$1.11",
                acos: "10%",
                roi: "131%",
                tacos: "10%",
              },
              {
                key: "2-1-3-2",
                typeAd: 'asin="B00AHAWWOO"',
                spend: "$300",
                shareSpend: "86%",
                sales: "$29,911",
                shareSales: "75%",
                cpc: "$1.11",
                acos: "10%",
                roi: "131%",
                tacos: "10%",
              },
              {
                key: "2-1-3-3",
                typeAd: 'asin="B07QC8LFQP"',
                spend: "$300",
                shareSpend: "86%",
                sales: "$29,911",
                shareSales: "75%",
                cpc: "$1.11",
                acos: "10%",
                roi: "131%",
                tacos: "10%",
              },
              {
                key: "2-1-3-4",
                typeAd: 'asin="B07XJZ1MSF"',
                spend: "$300",
                shareSpend: "86%",
                sales: "$29,911",
                shareSales: "75%",
                cpc: "$1.11",
                acos: "10%",
                roi: "131%",
                tacos: "10%",
              },
              {
                key: "2-1-3-5",
                typeAd: 'asin="B082TPDTM2"',

                spend: "$300",
                shareSpend: "86%",
                sales: "$29,911",
                shareSales: "75%",
                cpc: "$1.11",
                acos: "10%",
                roi: "131%",
                tacos: "10%",
              },
              {
                key: "2-1-3-6",
                typeAd: 'asin="BOCRVN4NLR"',

                spend: "$300",
                shareSpend: "86%",
                sales: "$29,911",
                shareSales: "75%",
                cpc: "$1.11",
                acos: "10%",
                roi: "131%",
                tacos: "10%",
              },
              {
                key: "2-1-3-7",
                typeAd: 'asin="B0D2QK4SHR"',

                spend: "$300",
                shareSpend: "86%",
                sales: "$29,911",
                shareSales: "75%",
                cpc: "$1.11",
                acos: "10%",
                roi: "131%",
                tacos: "10%",
              },
            ],
          },
        ],
      },
      {
        key: "2-2",
        typeAd: "Sponsored Display",
        spend: "$19",
        shareSpend: "0.2%",
        sales: "$29,911",
        shareSales: "75%",
        cpc: "$1.11",
        acos: "10%",
        roi: "131%",
        tacos: "10%",
      },
      {
        key: "2-3",
        typeAd: "Sponsored Products",
        spend: "$7,431",
        shareSpend: "95.4%",
        sales: "$29,911",
        shareSales: "75%",
        cpc: "$1.11",
        acos: "10%",
        roi: "131%",
        tacos: "10%",
      },
    ],
  },
  {
    key: "3",
    typeAd: "Poor",
    spend: "$10,751",
    shareSpend: "34.8%",
    sales: "$571",
    shareSales: "4%",
    cpc: "$2.22",
    acos: "52%",
    roi: "13%",
    tacos: "11%",
    children: [
      {
        key: "3-1",
        typeAd: "Sponsored Brands",
        spend: "$348",
        shareSpend: "4.4%",
        sales: "$29,911",
        shareSales: "75%",
        cpc: "$1.11",
        acos: "10%",
        roi: "131%",
        tacos: "10%",
        children: [
          {
            key: "3-1-1",
            typeAd: "Nyle - SB PC",
            spend: "$300",
            shareSpend: "86%",
            sales: "$29,911",
            shareSales: "75%",
            cpc: "$1.11",
            acos: "10%",
            roi: "131%",
            tacos: "10%",
            children: [
              {
                key: "3-1-1-1",
                typeAd: 'asin="B00100LLVS"',
                spend: "$300",
                shareSpend: "86%",
                sales: "$29,911",
                shareSales: "75%",
                cpc: "$1.11",
                acos: "10%",
                roi: "131%",
                tacos: "10%",
              },
              {
                key: "3-1-1-2",
                typeAd: 'asin="B00AHAWWOO"',
                spend: "$300",
                shareSpend: "86%",
                sales: "$29,911",
                shareSales: "75%",
                cpc: "$1.11",
                acos: "10%",
                roi: "131%",
                tacos: "10%",
              },
              {
                key: "3-1-1-3",
                typeAd: 'asin="B07QC8LFQP"',
                spend: "$300",
                shareSpend: "86%",
                sales: "$29,911",
                shareSales: "75%",
                cpc: "$1.11",
                acos: "10%",
                roi: "131%",
                tacos: "10%",
              },
              {
                key: "3-1-1-4",
                typeAd: 'asin="B07XJZ1MSF"',
                spend: "$300",
                shareSpend: "86%",
                sales: "$29,911",
                shareSales: "75%",
                cpc: "$1.11",
                acos: "10%",
                roi: "131%",
                tacos: "10%",
              },
              {
                key: "3-1-1-5",
                typeAd: 'asin="B082TPDTM2"',
                spend: "$300",
                shareSpend: "86%",
                sales: "$29,911",
                shareSales: "75%",
                cpc: "$1.11",
                acos: "10%",
                roi: "131%",
                tacos: "10%",
              },
              {
                key: "3-1-1-6",
                typeAd: 'asin="BOCRVN4NLR"',
                spend: "$300",
                shareSpend: "86%",
                sales: "$29,911",
                shareSales: "75%",
                cpc: "$1.11",
                acos: "10%",
                roi: "131%",
                tacos: "10%",
              },
              {
                key: "3-1-1-7",
                typeAd: 'asin="B0D2QK4SHR"',
                spend: "$300",
                shareSpend: "86%",
                sales: "$29,911",
                shareSales: "75%",
                cpc: "$1.11",
                acos: "10%",
                roi: "131%",
                tacos: "10%",
              },
            ],
          },
          {
            key: "3-1-2",
            typeAd: "Nyle - SB PC",
            spend: "$300",
            shareSpend: "86%",
            sales: "$29,911",
            shareSales: "75%",
            cpc: "$1.11",
            acos: "10%",
            roi: "131%",
            tacos: "10%",
            children: [
              {
                key: "3-1-2-1",
                typeAd: 'asin="B00100LLVS"',
                spend: "$300",
                shareSpend: "86%",
                sales: "$29,911",
                shareSales: "75%",
                cpc: "$1.11",
                acos: "10%",
                roi: "131%",
                tacos: "10%",
              },
              {
                key: "3-1-2-2",
                typeAd: 'asin="B00AHAWWOO"',
                spend: "$300",
                shareSpend: "86%",
                sales: "$29,911",
                shareSales: "75%",
                cpc: "$1.11",
                acos: "10%",
                roi: "131%",
                tacos: "10%",
              },
              {
                key: "3-1-2-3",
                typeAd: 'asin="B07QC8LFQP"',
                spend: "$300",
                shareSpend: "86%",
                sales: "$29,911",
                shareSales: "75%",
                cpc: "$1.11",
                acos: "10%",
                roi: "131%",
                tacos: "10%",
              },
              {
                key: "3-1-2-4",
                typeAd: 'asin="B07XJZ1MSF"',
                spend: "$300",
                shareSpend: "86%",
                sales: "$29,911",
                shareSales: "75%",
                cpc: "$1.11",
                acos: "10%",
                roi: "131%",
                tacos: "10%",
              },
              {
                key: "3-1-2-5",
                typeAd: 'asin="B082TPDTM2"',
                spend: "$300",
                shareSpend: "86%",
                sales: "$29,911",
                shareSales: "75%",
                cpc: "$1.11",
                acos: "10%",
                roi: "131%",
                tacos: "10%",
              },
              {
                key: "3-1-2-6",
                typeAd: 'asin="BOCRVN4NLR"',
                spend: "$300",
                shareSpend: "86%",
                sales: "$29,911",
                shareSales: "75%",
                cpc: "$1.11",
                acos: "10%",
                roi: "131%",
                tacos: "10%",
              },
              {
                key: "3-1-2-7",
                typeAd: 'asin="B0D2QK4SHR"',
                spend: "$300",
                shareSpend: "86%",
                sales: "$29,911",
                shareSales: "75%",
                cpc: "$1.11",
                acos: "10%",
                roi: "131%",
                tacos: "10%",
              },
            ],
          },
          {
            key: "3-1-3",
            typeAd: "Nyle - SBV PC",
            spend: "$300",
            shareSpend: "86%",
            sales: "$29,911",
            shareSales: "75%",
            cpc: "$1.11",
            acos: "10%",
            roi: "131%",
            tacos: "10%",
            children: [
              {
                key: "3-1-3-1",
                typeAd: 'asin="B00100LLVS"',
                spend: "$300",
                shareSpend: "86%",
                sales: "$29,911",
                shareSales: "75%",
                cpc: "$1.11",
                acos: "10%",
                roi: "131%",
                tacos: "10%",
              },
              {
                key: "3-1-3-2",
                typeAd: 'asin="B00AHAWWOO"',
                spend: "$300",
                shareSpend: "86%",
                sales: "$29,911",
                shareSales: "75%",
                cpc: "$1.11",
                acos: "10%",
                roi: "131%",
                tacos: "10%",
              },
              {
                key: "3-1-3-3",
                typeAd: 'asin="B07QC8LFQP"',
                spend: "$300",
                shareSpend: "86%",
                sales: "$29,911",
                shareSales: "75%",
                cpc: "$1.11",
                acos: "10%",
                roi: "131%",
                tacos: "10%",
              },
              {
                key: "3-1-3-4",
                typeAd: 'asin="B07XJZ1MSF"',
                spend: "$300",
                shareSpend: "86%",
                sales: "$29,911",
                shareSales: "75%",
                cpc: "$1.11",
                acos: "10%",
                roi: "131%",
                tacos: "10%",
              },
              {
                key: "3-1-3-5",
                typeAd: 'asin="B082TPDTM2"',

                spend: "$300",
                shareSpend: "86%",
                sales: "$29,911",
                shareSales: "75%",
                cpc: "$1.11",
                acos: "10%",
                roi: "131%",
                tacos: "10%",
              },
              {
                key: "3-1-3-6",
                typeAd: 'asin="BOCRVN4NLR"',

                spend: "$300",
                shareSpend: "86%",
                sales: "$29,911",
                shareSales: "75%",
                cpc: "$1.11",
                acos: "10%",
                roi: "131%",
                tacos: "10%",
              },
              {
                key: "3-1-3-7",
                typeAd: 'asin="B0D2QK4SHR"',

                spend: "$300",
                shareSpend: "86%",
                sales: "$29,911",
                shareSales: "75%",
                cpc: "$1.11",
                acos: "10%",
                roi: "131%",
                tacos: "10%",
              },
            ],
          },
        ],
      },
      {
        key: "3-2",
        typeAd: "Sponsored Display",
        spend: "$19",
        shareSpend: "0.2%",
        sales: "$29,911",
        shareSales: "75%",
        cpc: "$1.11",
        acos: "10%",
        roi: "131%",
        tacos: "10%",
      },
      {
        key: "3-3",
        typeAd: "Sponsored Products",
        spend: "$7,431",
        shareSpend: "95.4%",
        sales: "$29,911",
        shareSales: "75%",
        cpc: "$1.11",
        acos: "10%",
        roi: "131%",
        tacos: "10%",
      },
    ],
  },
  {
    key: "4",
    typeAd: "No sales",
    spend: "$3,854",
    shareSpend: "12.5%",
    sales: "$571",
    shareSales: "4%",
    cpc: "$2.22",
    acos: "52%",
    roi: "13%",
    tacos: "11%",
    children: [
      {
        key: "4-1",
        typeAd: "Sponsored Brands",
        spend: "$348",
        shareSpend: "4.4%",
        sales: "$29,911",
        shareSales: "75%",
        cpc: "$1.11",
        acos: "10%",
        roi: "131%",
        tacos: "10%",
        children: [
          {
            key: "4-1-1",
            typeAd: "Nyle - SB PC",
            spend: "$300",
            shareSpend: "86%",
            sales: "$29,911",
            shareSales: "75%",
            cpc: "$1.11",
            acos: "10%",
            roi: "131%",
            tacos: "10%",
            children: [
              {
                key: "4-1-1-1",
                typeAd: 'asin="B00100LLVS"',
                spend: "$300",
                shareSpend: "86%",
                sales: "$29,911",
                shareSales: "75%",
                cpc: "$1.11",
                acos: "10%",
                roi: "131%",
                tacos: "10%",
              },
              {
                key: "4-1-1-2",
                typeAd: 'asin="B00AHAWWOO"',
                spend: "$300",
                shareSpend: "86%",
                sales: "$29,911",
                shareSales: "75%",
                cpc: "$1.11",
                acos: "10%",
                roi: "131%",
                tacos: "10%",
              },
              {
                key: "4-1-1-3",
                typeAd: 'asin="B07QC8LFQP"',
                spend: "$300",
                shareSpend: "86%",
                sales: "$29,911",
                shareSales: "75%",
                cpc: "$1.11",
                acos: "10%",
                roi: "131%",
                tacos: "10%",
              },
              {
                key: "4-1-1-4",
                typeAd: 'asin="B07XJZ1MSF"',
                spend: "$300",
                shareSpend: "86%",
                sales: "$29,911",
                shareSales: "75%",
                cpc: "$1.11",
                acos: "10%",
                roi: "131%",
                tacos: "10%",
              },
              {
                key: "4-1-1-5",
                typeAd: 'asin="B082TPDTM2"',
                spend: "$300",
                shareSpend: "86%",
                sales: "$29,911",
                shareSales: "75%",
                cpc: "$1.11",
                acos: "10%",
                roi: "131%",
                tacos: "10%",
              },
              {
                key: "4-1-1-6",
                typeAd: 'asin="BOCRVN4NLR"',
                spend: "$300",
                shareSpend: "86%",
                sales: "$29,911",
                shareSales: "75%",
                cpc: "$1.11",
                acos: "10%",
                roi: "131%",
                tacos: "10%",
              },
              {
                key: "4-1-1-7",
                typeAd: 'asin="B0D2QK4SHR"',
                spend: "$300",
                shareSpend: "86%",
                sales: "$29,911",
                shareSales: "75%",
                cpc: "$1.11",
                acos: "10%",
                roi: "131%",
                tacos: "10%",
              },
            ],
          },
          {
            key: "4-1-2",
            typeAd: "Nyle - SB PC",
            spend: "$300",
            shareSpend: "86%",
            sales: "$29,911",
            shareSales: "75%",
            cpc: "$1.11",
            acos: "10%",
            roi: "131%",
            tacos: "10%",
            children: [
              {
                key: "4-1-2-1",
                typeAd: 'asin="B00100LLVS"',
                spend: "$300",
                shareSpend: "86%",
                sales: "$29,911",
                shareSales: "75%",
                cpc: "$1.11",
                acos: "10%",
                roi: "131%",
                tacos: "10%",
              },
              {
                key: "4-1-2-2",
                typeAd: 'asin="B00AHAWWOO"',
                spend: "$300",
                shareSpend: "86%",
                sales: "$29,911",
                shareSales: "75%",
                cpc: "$1.11",
                acos: "10%",
                roi: "131%",
                tacos: "10%",
              },
              {
                key: "4-1-2-3",
                typeAd: 'asin="B07QC8LFQP"',
                spend: "$300",
                shareSpend: "86%",
                sales: "$29,911",
                shareSales: "75%",
                cpc: "$1.11",
                acos: "10%",
                roi: "131%",
                tacos: "10%",
              },
              {
                key: "4-1-2-4",
                typeAd: 'asin="B07XJZ1MSF"',
                spend: "$300",
                shareSpend: "86%",
                sales: "$29,911",
                shareSales: "75%",
                cpc: "$1.11",
                acos: "10%",
                roi: "131%",
                tacos: "10%",
              },
              {
                key: "4-1-2-5",
                typeAd: 'asin="B082TPDTM2"',
                spend: "$300",
                shareSpend: "86%",
                sales: "$29,911",
                shareSales: "75%",
                cpc: "$1.11",
                acos: "10%",
                roi: "131%",
                tacos: "10%",
              },
              {
                key: "4-1-2-6",
                typeAd: 'asin="BOCRVN4NLR"',
                spend: "$300",
                shareSpend: "86%",
                sales: "$29,911",
                shareSales: "75%",
                cpc: "$1.11",
                acos: "10%",
                roi: "131%",
                tacos: "10%",
              },
              {
                key: "4-1-2-7",
                typeAd: 'asin="B0D2QK4SHR"',
                spend: "$300",
                shareSpend: "86%",
                sales: "$29,911",
                shareSales: "75%",
                cpc: "$1.11",
                acos: "10%",
                roi: "131%",
                tacos: "10%",
              },
            ],
          },
          {
            key: "4-1-3",
            typeAd: "Nyle - SBV PC",
            spend: "$300",
            shareSpend: "86%",
            sales: "$29,911",
            shareSales: "75%",
            cpc: "$1.11",
            acos: "10%",
            roi: "131%",
            tacos: "10%",
            children: [
              {
                key: "4-1-3-1",
                typeAd: 'asin="B00100LLVS"',
                spend: "$300",
                shareSpend: "86%",
                sales: "$29,911",
                shareSales: "75%",
                cpc: "$1.11",
                acos: "10%",
                roi: "131%",
                tacos: "10%",
              },
              {
                key: "4-1-3-2",
                typeAd: 'asin="B00AHAWWOO"',
                spend: "$300",
                shareSpend: "86%",
                sales: "$29,911",
                shareSales: "75%",
                cpc: "$1.11",
                acos: "10%",
                roi: "131%",
                tacos: "10%",
              },
              {
                key: "4-1-3-3",
                typeAd: 'asin="B07QC8LFQP"',
                spend: "$300",
                shareSpend: "86%",
                sales: "$29,911",
                shareSales: "75%",
                cpc: "$1.11",
                acos: "10%",
                roi: "131%",
                tacos: "10%",
              },
              {
                key: "4-1-3-4",
                typeAd: 'asin="B07XJZ1MSF"',
                spend: "$300",
                shareSpend: "86%",
                sales: "$29,911",
                shareSales: "75%",
                cpc: "$1.11",
                acos: "10%",
                roi: "131%",
                tacos: "10%",
              },
              {
                key: "4-1-3-5",
                typeAd: 'asin="B082TPDTM2"',

                spend: "$300",
                shareSpend: "86%",
                sales: "$29,911",
                shareSales: "75%",
                cpc: "$1.11",
                acos: "10%",
                roi: "131%",
                tacos: "10%",
              },
              {
                key: "4-1-3-6",
                typeAd: 'asin="BOCRVN4NLR"',

                spend: "$300",
                shareSpend: "86%",
                sales: "$29,911",
                shareSales: "75%",
                cpc: "$1.11",
                acos: "10%",
                roi: "131%",
                tacos: "10%",
              },
              {
                key: "4-1-3-7",
                typeAd: 'asin="B0D2QK4SHR"',

                spend: "$300",
                shareSpend: "86%",
                sales: "$29,911",
                shareSales: "75%",
                cpc: "$1.11",
                acos: "10%",
                roi: "131%",
                tacos: "10%",
              },
            ],
          },
        ],
      },
      {
        key: "4-2",
        typeAd: "Sponsored Display",
        spend: "$19",
        shareSpend: "0.2%",
        sales: "$29,911",
        shareSales: "75%",
        cpc: "$1.11",
        acos: "10%",
        roi: "131%",
        tacos: "10%",
      },
      {
        key: "4-3",
        typeAd: "Sponsored Products",
        spend: "$7,431",
        shareSpend: "95.4%",
        sales: "$29,911",
        shareSales: "75%",
        cpc: "$1.11",
        acos: "10%",
        roi: "131%",
        tacos: "10%",
      },
    ],
  },
  {
    key: "5",
    typeAd: "Grand Total",
    spend: "$30,907",
    shareSpend: "100%",
    sales: "$37,301",
    shareSales: "100%",
    cpc: "$1.67",
    acos: "50%",
    roi: "50%",
    tacos: "50%",
  },
];

export const performanceColumns = [
  {
    title: "Type Ad",
    dataIndex: "typeAd",
    key: "typeAd",
    fixed: "left" as const,
    width: 256,
  },
  {
    title: "Spend",
    dataIndex: "spend",
    key: "spend",
    width: 110,
  },
  {
    title: "Share Spend",
    dataIndex: "shareSpend",
    key: "shareSpend",
    width: 110,
  },
  {
    title: "Sales",
    dataIndex: "sales",
    key: "sales",
    width: 110,
  },
  {
    title: "Share Sales",
    dataIndex: "shareSales",
    key: "shareSales",
    width: 110,
  },
  {
    title: "CPC",
    dataIndex: "cpc",
    key: "cpc",
    width: 110,
  },
  {
    title: "ACOS",
    dataIndex: "acos",
    key: "acos",
    width: 110,
  },
  {
    title: "ROI",
    dataIndex: "roi",
    key: "roi",
    width: 110,
  },
  {
    title: "TACOS",
    dataIndex: "tacos",
    key: "tacos",
    width: 110,
  },
];

export const targetingColumns = [
  {
    title: "Targeting",
    dataIndex: "campaign",
    key: "campaign",
    fixed: "left" as const,
    width: 256,
  },
  {
    title: "Ad Impressions",
    dataIndex: "impressions",
    key: "impressions",
    width: 140,
  },
  {
    title: "Top-of-searh IS",
    dataIndex: "topOfSearchIs",
    key: "topOfSearchIs",
    width: 140,
  },
  {
    title: "Ad Clicks",
    dataIndex: "clicks",
    key: "clicks",
    width: 140,
  },
  {
    title: "Ad CTR",
    dataIndex: "ctr",
    key: "ctr",
    width: 140,
  },
  {
    title: "Ad Spend",
    dataIndex: "spend",
    key: "spend",
    width: 140,
  },
  {
    title: "CPC",
    dataIndex: "cpc",
    key: "cpc",
    width: 140,
  },
  {
    title: "Ad Orders",
    dataIndex: "orders",
    key: "orders",
    width: 140,
  },
];

export const targetTypeColumns = [
  {
    title: "Target Type",
    dataIndex: "campaign",
    key: "campaign",
    fixed: "left" as const,
    width: 256,
  },
  {
    title: "Ad Impressions",
    dataIndex: "impressions",
    key: "impressions",
    width: 140,
  },
  {
    title: "Top-of-searh IS",
    dataIndex: "topOfSearchIs",
    key: "topOfSearchIs",
    width: 140,
  },
  {
    title: "Ad Clicks",
    dataIndex: "clicks",
    key: "clicks",
    width: 140,
  },
  {
    title: "Ad CTR",
    dataIndex: "ctr",
    key: "ctr",
    width: 140,
  },
  {
    title: "Ad Spend",
    dataIndex: "spend",
    key: "spend",
    width: 140,
  },
  {
    title: "CPC",
    dataIndex: "cpc",
    key: "cpc",
    width: 140,
  },
  {
    title: "Ad Orders",
    dataIndex: "orders",
    key: "orders",
    width: 140,
  },
];

export const productsColumns = [
  {
    title: "Campaign",
    dataIndex: "campaign",
    key: "campaign",
    fixed: "left" as const,
    width: 278,
  },
  {
    title: "",
    dataIndex: "action",
    key: "action",
    width: 48,
  },
  {
    title: "Ad Impressions",
    dataIndex: "impressions",
    key: "impressions",
    width: 140,
  },
  {
    title: "Top-of-searh IS",
    dataIndex: "topOfSearchIs",
    key: "topOfSearchIs",
    width: 140,
  },
  {
    title: "Ad Clicks",
    dataIndex: "clicks",
    key: "clicks",
    width: 140,
  },
  {
    title: "Ad CTR",
    dataIndex: "ctr",
    key: "ctr",
    width: 140,
  },
  {
    title: "Ad Spend",
    dataIndex: "spend",
    key: "spend",
    width: 140,
  },
  {
    title: "CPC",
    dataIndex: "cpc",
    key: "cpc",
    width: 140,
  },
  {
    title: "Ad Orders",
    dataIndex: "orders",
    key: "orders",
    width: 140,
  },
];

export const productsData = [
  {
    key: "1",
    campaign: "Nyl - SP - B0B5HN65QQ",
    impressions: {
      value: "456,505",
      percentage: "-4.7%",
    },
    topOfSearchIs: {
      value: "<5%",
      percentage: "0.0%",
    },
    clicks: {
      value: "373",
      percentage: "+3.4%",
    },
    ctr: {
      value: "0.08%",
      percentage: "-+0.8%",
    },
    spend: {
      value: "$5,911.57",
      percentage: "+3.2%",
    },
    cpc: {
      value: "$1.11",
      percentage: "+3.2%",
    },
    orders: {
      value: "1,270",
      percentage: "+1.6%",
    },

    children: [
      {
        key: "1-1",
        campaign: "KEYWORD #001",
        impressions: {
          value: "154,697",
          percentage: "-1.4%",
        },
        topOfSearchIs: {
          value: "<5%",
          percentage: "0.0%",
        },
        clicks: {
          value: "555",
          percentage: "-+0.8%",
        },
        ctr: {
          value: "0.70%",
          percentage: "+2.8%",
        },
        spend: {
          value: "$2,547.11",
          percentage: "+0.6%",
        },
        cpc: {
          value: "$1.14",
          percentage: "+7.9%",
        },
        orders: {
          value: "456",
          percentage: "-1.4%",
        },
      },
      {
        key: "1-2",
        campaign: "KEYWORD #002",
        impressions: {
          value: "101,222",
          percentage: "+0.2%",
        },
        topOfSearchIs: {
          value: "<5%",
          percentage: "0.0%",
        },
        clicks: {
          value: "1711",
          percentage: "+2.7%",
        },
        ctr: {
          value: "0.80%",
          percentage: "+3.0%",
        },
        spend: {
          value: "$1,565.16",
          percentage: "+3.0%",
        },
        cpc: {
          value: "$1.08",
          percentage: "+10.8%",
        },
        orders: {
          value: "211",
          percentage: "+0.4%",
        },
      },
      {
        key: "1-3",
        campaign: "KEYWORD #003",
        impressions: {
          value: "118,991",
          percentage: "-4.2%",
        },
        topOfSearchIs: {
          value: "<5%",
          percentage: "0.0%",
        },
        clicks: {
          value: "1215",
          percentage: "+2.0%",
        },
        ctr: {
          value: "0.79%",
          percentage: "-3.6%",
        },
        spend: {
          value: "$1,800.16",
          percentage: "+1.0%",
        },
        cpc: {
          value: "$1.11",
          percentage: "-2.5%",
        },
        orders: {
          value: "603",
          percentage: "-1.4%",
        },
      },
    ],
  },
  {
    key: "2",
    campaign: "Nyl - SP - B0B2HN33KQ",
    impressions: {
      value: "374,910",
      percentage: "-2.2%",
    },
    topOfSearchIs: {
      value: "<5%",
      percentage: "0.0%",
    },
    clicks: {
      value: "3,481",
      percentage: "+1.6%",
    },
    ctr: {
      value: "0.77%",
      percentage: "+3.2%",
    },
    spend: {
      value: "$5,911.57",
      percentage: "+18%",
    },
    cpc: {
      value: "$1.11",
      percentage: "+8.2%",
    },
    orders: {
      value: "1,270",
      percentage: "+8%",
    },

    children: [
      {
        key: "2-1",
        campaign: "KEYWORD #001",
        impressions: {
          value: "154,697",
          percentage: "-1.4%",
        },
        topOfSearchIs: {
          value: "<5%",
          percentage: "0.0%",
        },
        clicks: {
          value: "555",
          percentage: "-+0.8%",
        },
        ctr: {
          value: "0.70%",
          percentage: "+2.8%",
        },
        spend: {
          value: "$2,547.11",
          percentage: "+0.6%",
        },
        cpc: {
          value: "$1.14",
          percentage: "+7.9%",
        },
        orders: {
          value: "456",
          percentage: "-1.4%",
        },
      },
      {
        key: "2-2",
        campaign: "KEYWORD #002",
        impressions: {
          value: "101,222",
          percentage: "+0.2%",
        },
        topOfSearchIs: {
          value: "<5%",
          percentage: "0.0%",
        },
        clicks: {
          value: "1711",
          percentage: "+2.7%",
        },
        ctr: {
          value: "0.80%",
          percentage: "+3.0%",
        },
        spend: {
          value: "$1,565.16",
          percentage: "+11.0%",
        },
        cpc: {
          value: "$1.08",
          percentage: "+10.8%",
        },
        orders: {
          value: "211",
          percentage: "+0.4%",
        },
      },
      {
        key: "2-3",
        campaign: "KEYWORD #003",
        impressions: {
          value: "118,991",
          percentage: "-4.2%",
        },
        topOfSearchIs: {
          value: "<5%",
          percentage: "0.0%",
        },
        clicks: {
          value: "1215",
          percentage: "+2.0%",
        },
        ctr: {
          value: "0.79%",
          percentage: "-3.6%",
        },
        spend: {
          value: "$1,800.16",
          percentage: "+1.0%",
        },
        cpc: {
          value: "$1.11",
          percentage: "-2.5%",
        },
        orders: {
          value: "603",
          percentage: "-1.4%",
        },
      },
    ],
  },
  {
    key: "3",
    campaign: "Nyl - SP - B0B5HN65KO",
    impressions: {
      value: "266,363",
      percentage: "+7.1%",
    },
    topOfSearchIs: {
      value: "<5%",
      percentage: "0.0%",
    },
    clicks: {
      value: "859",
      percentage: "-+0.8%",
    },
    ctr: {
      value: "0.08%",
      percentage: "-7.7%",
    },
    spend: {
      value: "$5,911.57",
      percentage: "-7.1%",
    },
    cpc: {
      value: "$1.11",
      percentage: "+3.2%",
    },
    orders: {
      value: "1,270",
      percentage: "+9.9%",
    },

    children: [
      {
        key: "3-1",
        campaign: "KEYWORD #001",
        impressions: {
          value: "154,697",
          percentage: "-1.4%",
        },
        topOfSearchIs: {
          value: "<5%",
          percentage: "0.0%",
        },
        clicks: {
          value: "555",
          percentage: "-+0.8%",
        },
        ctr: {
          value: "0.70%",
          percentage: "+2.8%",
        },
        spend: {
          value: "$2,547.11",
          percentage: "+0.6%",
        },
        cpc: {
          value: "$1.14",
          percentage: "+7.9%",
        },
        orders: {
          value: "456",
          percentage: "-1.4%",
        },
      },
      {
        key: "3-2",
        campaign: "KEYWORD #002",
        impressions: {
          value: "101,222",
          percentage: "+0.2%",
        },
        topOfSearchIs: {
          value: "<5%",
          percentage: "0.0%",
        },
        clicks: {
          value: "1711",
          percentage: "+2.7%",
        },
        ctr: {
          value: "0.80%",
          percentage: "+3.0%",
        },
        spend: {
          value: "$1,565.16",
          percentage: "+2.0%",
        },
        cpc: {
          value: "$1.08",
          percentage: "+10.8%",
        },
        orders: {
          value: "211",
          percentage: "+0.4%",
        },
      },
      {
        key: "3-3",
        campaign: "KEYWORD #003",
        impressions: {
          value: "118,991",
          percentage: "-4.2%",
        },
        topOfSearchIs: {
          value: "<5%",
          percentage: "0.0%",
        },
        clicks: {
          value: "1215",
          percentage: "+2.0%",
        },
        ctr: {
          value: "0.79%",
          percentage: "-3.6%",
        },
        spend: {
          value: "$1,800.16",
          percentage: "+1.0%",
        },
        cpc: {
          value: "$1.11",
          percentage: "-2.5%",
        },
        orders: {
          value: "603",
          percentage: "-1.4%",
        },
      },
    ],
  },
  {
    key: "4",
    campaign: "Nyl - SP - B0B5IP83AA",
    impressions: {
      value: "163,763",
      percentage: "+3.3%",
    },
    topOfSearchIs: {
      value: "<5%",
      percentage: "0.0%",
    },
    clicks: {
      value: "371",
      percentage: "+9.9%",
    },
    ctr: {
      value: "3.23%",
      percentage: "+3.2%",
    },
    spend: {
      value: "$5,911.57",
      percentage: "-8.2%",
    },
    cpc: {
      value: "$1.11",
      percentage: "-+0.8%",
    },
    orders: {
      value: "1,270",
      percentage: "-2.2%",
    },

    children: [
      {
        key: "4-1",
        campaign: "KEYWORD #001",
        impressions: {
          value: "154,697",
          percentage: "-1.4%",
        },
        topOfSearchIs: {
          value: "<5%",
          percentage: "0.0%",
        },
        clicks: {
          value: "555",
          percentage: "-+0.8%",
        },
        ctr: {
          value: "0.70%",
          percentage: "+2.8%",
        },
        spend: {
          value: "$2,547.11",
          percentage: "+0.6%",
        },
        cpc: {
          value: "$1.14",
          percentage: "+7.9%",
        },
        orders: {
          value: "456",
          percentage: "-1.4%",
        },
      },
      {
        key: "4-2",
        campaign: "KEYWORD #002",
        impressions: {
          value: "101,222",
          percentage: "+0.2%",
        },
        topOfSearchIs: {
          value: "<5%",
          percentage: "0.0%",
        },
        clicks: {
          value: "1711",
          percentage: "+2.7%",
        },
        ctr: {
          value: "0.80%",
          percentage: "+3.0%",
        },
        spend: {
          value: "$1,565.16",
          percentage: "+2.0%",
        },
        cpc: {
          value: "$1.08",
          percentage: "+10.8%",
        },
        orders: {
          value: "211",
          percentage: "+0.4%",
        },
      },
      {
        key: "4-3",
        campaign: "KEYWORD #003",
        impressions: {
          value: "118,991",
          percentage: "-4.2%",
        },
        topOfSearchIs: {
          value: "<5%",
          percentage: "0.0%",
        },
        clicks: {
          value: "1215",
          percentage: "+2.0%",
        },
        ctr: {
          value: "0.79%",
          percentage: "-3.6%",
        },
        spend: {
          value: "$1,800.16",
          percentage: "+1.0%",
        },
        cpc: {
          value: "$1.11",
          percentage: "-2.5%",
        },
        orders: {
          value: "603",
          percentage: "-1.4%",
        },
      },
    ],
  },
  {
    key: "5",
    campaign: "Nyl - SP - B0B5ER88KP",
    impressions: {
      value: "91,256",
      percentage: "+8.2%",
    },
    topOfSearchIs: {
      value: "<5%",
      percentage: "0.0%",
    },
    clicks: {
      value: "1,889",
      percentage: "-5.1%",
    },
    ctr: {
      value: "0.08%",
      percentage: "+9.9%",
    },
    spend: {
      value: "$5,911.57",
      percentage: "-3.2%",
    },
    cpc: {
      value: "$1.11",
      percentage: "+9.9%",
    },
    orders: {
      value: "1,270",
      percentage: "+3.2%",
    },

    children: [
      {
        key: "5-1",
        campaign: "KEYWORD #001",
        impressions: {
          value: "154,697",
          percentage: "-1.4%",
        },
        topOfSearchIs: {
          value: "<5%",
          percentage: "0.0%",
        },
        clicks: {
          value: "555",
          percentage: "-+0.8%",
        },
        ctr: {
          value: "0.70%",
          percentage: "+2.8%",
        },
        spend: {
          value: "$2,547.11",
          percentage: "+0.6%",
        },
        cpc: {
          value: "$1.14",
          percentage: "+7.9%",
        },
        orders: {
          value: "456",
          percentage: "-1.4%",
        },
      },
      {
        key: "5-2",
        campaign: "KEYWORD #002",
        impressions: {
          value: "101,222",
          percentage: "+0.2%",
        },
        topOfSearchIs: {
          value: "<5%",
          percentage: "0.0%",
        },
        clicks: {
          value: "1711",
          percentage: "+2.7%",
        },
        ctr: {
          value: "0.80%",
          percentage: "+3.0%",
        },
        spend: {
          value: "$1,565.16",
          percentage: "+2.0%",
        },
        cpc: {
          value: "$1.08",
          percentage: "+10.8%",
        },
        orders: {
          value: "211",
          percentage: "+0.4%",
        },
      },
      {
        key: "5-3",
        campaign: "KEYWORD #003",
        impressions: {
          value: "118,991",
          percentage: "-4.2%",
        },
        topOfSearchIs: {
          value: "<5%",
          percentage: "0.0%",
        },
        clicks: {
          value: "1215",
          percentage: "+2.0%",
        },
        ctr: {
          value: "0.79%",
          percentage: "-3.6%",
        },
        spend: {
          value: "$1,800.16",
          percentage: "+1.0%",
        },
        cpc: {
          value: "$1.11",
          percentage: "-2.5%",
        },
        orders: {
          value: "603",
          percentage: "-1.4%",
        },
      },
    ],
  },
  {
    key: "6",
    campaign: "Nyl - SP - B0B5JK13QP",
    impressions: {
      value: "17,154",
      percentage: "-5.1%",
    },
    topOfSearchIs: {
      value: "<5%",
      percentage: "0.0%",
    },
    clicks: {
      value: "7,928",
      percentage: "+8.2%",
    },
    ctr: {
      value: "0.77%",
      percentage: "-7.7%",
    },
    spend: {
      value: "$5,911.57",
      percentage: "+0.8%",
    },
    cpc: {
      value: "$1.11",
      percentage: "+7.1%",
    },
    orders: {
      value: "1,270",
      percentage: "+3.2%",
    },

    children: [
      {
        key: "6-1",
        campaign: "KEYWORD #001",
        impressions: {
          value: "154,697",
          percentage: "-1.4%",
        },
        topOfSearchIs: {
          value: "<5%",
          percentage: "0.0%",
        },
        clicks: {
          value: "555",
          percentage: "-+0.8%",
        },
        ctr: {
          value: "0.70%",
          percentage: "+2.8%",
        },
        spend: {
          value: "$2,547.11",
          percentage: "+0.6%",
        },
        cpc: {
          value: "$1.14",
          percentage: "+7.9%",
        },
        orders: {
          value: "456",
          percentage: "-1.4%",
        },
      },
      {
        key: "6-2",
        campaign: "KEYWORD #002",
        impressions: {
          value: "101,222",
          percentage: "+0.2%",
        },
        topOfSearchIs: {
          value: "<5%",
          percentage: "0.0%",
        },
        clicks: {
          value: "1711",
          percentage: "+2.7%",
        },
        ctr: {
          value: "0.80%",
          percentage: "+3.0%",
        },
        spend: {
          value: "$1,565.16",
          percentage: "+2.0%",
        },
        cpc: {
          value: "$1.08",
          percentage: "+10.8%",
        },
        orders: {
          value: "211",
          percentage: "+0.4%",
        },
      },
      {
        key: "6-3",
        campaign: "KEYWORD #003",
        impressions: {
          value: "118,991",
          percentage: "-4.2%",
        },
        topOfSearchIs: {
          value: "<5%",
          percentage: "0.0%",
        },
        clicks: {
          value: "1215",
          percentage: "+2.0%",
        },
        ctr: {
          value: "0.79%",
          percentage: "-3.6%",
        },
        spend: {
          value: "$1,800.16",
          percentage: "+1.0%",
        },
        cpc: {
          value: "$1.11",
          percentage: "-2.5%",
        },
        orders: {
          value: "603",
          percentage: "-1.4%",
        },
      },
    ],
  },
  {
    key: "7",
    campaign: "Nyl - SP - B0B5II010Q",
    impressions: {
      value: "1,847",
      percentage: "+9.9%",
    },
    topOfSearchIs: {
      value: "<5%",
      percentage: "0.0%",
    },
    clicks: {
      value: "1,111",
      percentage: "+4.1%",
    },
    ctr: {
      value: "0.77%",
      percentage: "-+0.8%",
    },
    spend: {
      value: "$5,911.57",
      percentage: "-3.2%",
    },
    cpc: {
      value: "$1.11",
      percentage: "+3.2%",
    },
    orders: {
      value: "1,270",
      percentage: "+3.2%",
    },

    children: [
      {
        key: "7-1",
        campaign: "KEYWORD #001",
        impressions: {
          value: "154,697",
          percentage: "-1.4%",
        },
        topOfSearchIs: {
          value: "<5%",
          percentage: "0.0%",
        },
        clicks: {
          value: "555",
          percentage: "-+0.8%",
        },
        ctr: {
          value: "0.70%",
          percentage: "+2.8%",
        },
        spend: {
          value: "$2,547.11",
          percentage: "+0.6%",
        },
        cpc: {
          value: "$1.14",
          percentage: "+7.9%",
        },
        orders: {
          value: "456",
          percentage: "-1.4%",
        },
      },
      {
        key: "7-2",
        campaign: "KEYWORD #002",
        impressions: {
          value: "101,222",
          percentage: "+0.2%",
        },
        topOfSearchIs: {
          value: "<5%",
          percentage: "0.0%",
        },
        clicks: {
          value: "1711",
          percentage: "+2.7%",
        },
        ctr: {
          value: "0.80%",
          percentage: "+3.0%",
        },
        spend: {
          value: "$1,565.16",
          percentage: "+2.0%",
        },
        cpc: {
          value: "$1.08",
          percentage: "+10.8%",
        },
        orders: {
          value: "211",
          percentage: "+0.4%",
        },
      },
      {
        key: "7-3",
        campaign: "KEYWORD #003",
        impressions: {
          value: "118,991",
          percentage: "-4.2%",
        },
        topOfSearchIs: {
          value: "<5%",
          percentage: "0.0%",
        },
        clicks: {
          value: "1215",
          percentage: "+2.0%",
        },
        ctr: {
          value: "0.79%",
          percentage: "-3.6%",
        },
        spend: {
          value: "$1,800.16",
          percentage: "+1.0%",
        },
        cpc: {
          value: "$1.11",
          percentage: "-2.5%",
        },
        orders: {
          value: "603",
          percentage: "-1.4%",
        },
      },
    ],
  },
  {
    key: "8",
    campaign: "Total 7 Products",
    impressions: {
      value: "1,371,797",
      percentage: "+3.7%",
    },
    topOfSearchIs: {
      value: "<5%",
      percentage: "0.0%",
    },
    clicks: {
      value: "16,012",
      percentage: "+5.3%",
    },
    ctr: {
      value: "0.82%",
      percentage: "-1.2%",
    },
    spend: {
      value: "$41,381,80",
      percentage: "-8.2%",
    },
    cpc: {
      value: "$1.11",
      percentage: "-+0.8%",
    },
    orders: {
      value: "8,891",
      percentage: "-3.1%",
    },
  },
];

export const adTypeColumns = [
  {
    title: "Ad Type",
    dataIndex: "campaign",
    key: "campaign",
    fixed: "left" as const,
    width: 278,
  },
  {
    title: "Ad Impressions",
    dataIndex: "impressions",
    key: "impressions",
    width: 140,
  },
  {
    title: "Top-of-searh IS",
    dataIndex: "topOfSearchIs",
    key: "topOfSearchIs",
    width: 140,
  },
  {
    title: "Ad Clicks",
    dataIndex: "clicks",
    key: "clicks",
    width: 140,
  },
  {
    title: "Ad CTR",
    dataIndex: "ctr",
    key: "ctr",
    width: 140,
  },
  {
    title: "Ad Spend",
    dataIndex: "spend",
    key: "spend",
    width: 140,
  },
  {
    title: "CPC",
    dataIndex: "cpc",
    key: "cpc",
    width: 140,
  },
  {
    title: "Ad Orders",
    dataIndex: "orders",
    key: "orders",
    width: 140,
  },
];

export const funnelColumns = [
  {
    title: "Funnel",
    dataIndex: "campaign",
    key: "campaign",
    fixed: "left" as const,
    width: 278,
  },
  {
    title: "Ad Impressions",
    dataIndex: "impressions",
    key: "impressions",
    width: 140,
  },
  {
    title: "Top-of-searh IS",
    dataIndex: "topOfSearchIs",
    key: "topOfSearchIs",
    width: 140,
  },
  {
    title: "Ad Clicks",
    dataIndex: "clicks",
    key: "clicks",
    width: 140,
  },
  {
    title: "Ad CTR",
    dataIndex: "ctr",
    key: "ctr",
    width: 140,
  },
  {
    title: "Ad Spend",
    dataIndex: "spend",
    key: "spend",
    width: 140,
  },
  {
    title: "CPC",
    dataIndex: "cpc",
    key: "cpc",
    width: 140,
  },
  {
    title: "Ad Orders",
    dataIndex: "orders",
    key: "orders",
    width: 140,
  },
];

export const adtypeData = [
  {
    key: "0-1",
    campaign: "Sponsored Brands",
    impressions: {
      value: "456,505",
      percentage: "-4.7%",
    },
    topOfSearchIs: {
      value: "<5%",
      percentage: "0.0%",
    },
    clicks: {
      value: "373",
      percentage: "+3.4%",
    },
    ctr: {
      value: "0.08%",
      percentage: "-+0.8%",
    },
    spend: {
      value: "$5,911.57",
      percentage: "+3.2%",
    },
    cpc: {
      value: "$1.11",
      percentage: "+3.2%",
    },
    orders: {
      value: "1,270",
      percentage: "+1.6%",
    },

    children: [
      {
        key: "1",
        campaign: "Nyl - SP - B0B5HN65QQ",
        impressions: {
          value: "456,505",
          percentage: "-4.7%",
        },
        topOfSearchIs: {
          value: "<5%",
          percentage: "0.0%",
        },
        clicks: {
          value: "373",
          percentage: "+3.4%",
        },
        ctr: {
          value: "0.08%",
          percentage: "-+0.8%",
        },
        spend: {
          value: "$5,911.57",
          percentage: "+3.2%",
        },
        cpc: {
          value: "$1.11",
          percentage: "+3.2%",
        },
        orders: {
          value: "1,270",
          percentage: "+1.6%",
        },

        children: [
          {
            key: "1-1",
            campaign: "KEYWORD #001",
            impressions: {
              value: "154,697",
              percentage: "-1.4%",
            },
            topOfSearchIs: {
              value: "<5%",
              percentage: "0.0%",
            },
            clicks: {
              value: "555",
              percentage: "-+0.8%",
            },
            ctr: {
              value: "0.70%",
              percentage: "+2.8%",
            },
            spend: {
              value: "$2,547.11",
              percentage: "+0.6%",
            },
            cpc: {
              value: "$1.14",
              percentage: "+7.9%",
            },
            orders: {
              value: "456",
              percentage: "-1.4%",
            },
          },
          {
            key: "1-2",
            campaign: "KEYWORD #002",
            impressions: {
              value: "101,222",
              percentage: "+0.2%",
            },
            topOfSearchIs: {
              value: "<5%",
              percentage: "0.0%",
            },
            clicks: {
              value: "1711",
              percentage: "+2.7%",
            },
            ctr: {
              value: "0.80%",
              percentage: "+3.0%",
            },
            spend: {
              value: "$1,565.16",
              percentage: "+2.0%",
            },
            cpc: {
              value: "$1.08",
              percentage: "+10.8%",
            },
            orders: {
              value: "211",
              percentage: "+0.4%",
            },
          },
          {
            key: "1-3",
            campaign: "KEYWORD #003",
            impressions: {
              value: "118,991",
              percentage: "-4.2%",
            },
            topOfSearchIs: {
              value: "<5%",
              percentage: "0.0%",
            },
            clicks: {
              value: "1215",
              percentage: "+2.0%",
            },
            ctr: {
              value: "0.79%",
              percentage: "-3.6%",
            },
            spend: {
              value: "$1,800.16",
              percentage: "+1.0%",
            },
            cpc: {
              value: "$1.11",
              percentage: "-2.5%",
            },
            orders: {
              value: "603",
              percentage: "-1.4%",
            },
          },
        ],
      },
      {
        key: "2",
        campaign: "Nyl - SP - B0B2HN33KQ",
        impressions: {
          value: "374,910",
          percentage: "-2.2%",
        },
        topOfSearchIs: {
          value: "<5%",
          percentage: "0.0%",
        },
        clicks: {
          value: "3,481",
          percentage: "+1.6%",
        },
        ctr: {
          value: "0.77%",
          percentage: "+3.2%",
        },
        spend: {
          value: "$5,911.57",
          percentage: "++0.8%",
        },
        cpc: {
          value: "$1.11",
          percentage: "+8.2%",
        },
        orders: {
          value: "1,270",
          percentage: "++0.8%",
        },

        children: [
          {
            key: "2-1",
            campaign: "KEYWORD #001",
            impressions: {
              value: "154,697",
              percentage: "-1.4%",
            },
            topOfSearchIs: {
              value: "<5%",
              percentage: "0.0%",
            },
            clicks: {
              value: "555",
              percentage: "-+0.8%",
            },
            ctr: {
              value: "0.70%",
              percentage: "+2.8%",
            },
            spend: {
              value: "$2,547.11",
              percentage: "+0.6%",
            },
            cpc: {
              value: "$1.14",
              percentage: "+7.9%",
            },
            orders: {
              value: "456",
              percentage: "-1.4%",
            },
          },
          {
            key: "2-2",
            campaign: "KEYWORD #002",
            impressions: {
              value: "101,222",
              percentage: "+0.2%",
            },
            topOfSearchIs: {
              value: "<5%",
              percentage: "0.0%",
            },
            clicks: {
              value: "1711",
              percentage: "+2.7%",
            },
            ctr: {
              value: "0.80%",
              percentage: "+3.0%",
            },
            spend: {
              value: "$1,565.16",
              percentage: "+2.0%",
            },
            cpc: {
              value: "$1.08",
              percentage: "+10.8%",
            },
            orders: {
              value: "211",
              percentage: "+0.4%",
            },
          },
          {
            key: "2-3",
            campaign: "KEYWORD #003",
            impressions: {
              value: "118,991",
              percentage: "-4.2%",
            },
            topOfSearchIs: {
              value: "<5%",
              percentage: "0.0%",
            },
            clicks: {
              value: "1215",
              percentage: "+2.0%",
            },
            ctr: {
              value: "0.79%",
              percentage: "-3.6%",
            },
            spend: {
              value: "$1,800.16",
              percentage: "+1.0%",
            },
            cpc: {
              value: "$1.11",
              percentage: "-2.5%",
            },
            orders: {
              value: "603",
              percentage: "-1.4%",
            },
          },
        ],
      },
      {
        key: "3",
        campaign: "Nyl - SP - B0B5HN65KO",
        impressions: {
          value: "266,363",
          percentage: "+7.1%",
        },
        topOfSearchIs: {
          value: "<5%",
          percentage: "0.0%",
        },
        clicks: {
          value: "859",
          percentage: "-+0.8%",
        },
        ctr: {
          value: "0.08%",
          percentage: "-7.7%",
        },
        spend: {
          value: "$5,911.57",
          percentage: "-7.1%",
        },
        cpc: {
          value: "$1.11",
          percentage: "+3.2%",
        },
        orders: {
          value: "1,270",
          percentage: "+9.9%",
        },

        children: [
          {
            key: "3-1",
            campaign: "KEYWORD #001",
            impressions: {
              value: "154,697",
              percentage: "-1.4%",
            },
            topOfSearchIs: {
              value: "<5%",
              percentage: "0.0%",
            },
            clicks: {
              value: "555",
              percentage: "-+0.8%",
            },
            ctr: {
              value: "0.70%",
              percentage: "+2.8%",
            },
            spend: {
              value: "$2,547.11",
              percentage: "+0.6%",
            },
            cpc: {
              value: "$1.14",
              percentage: "+7.9%",
            },
            orders: {
              value: "456",
              percentage: "-1.4%",
            },
          },
          {
            key: "3-2",
            campaign: "KEYWORD #002",
            impressions: {
              value: "101,222",
              percentage: "+0.2%",
            },
            topOfSearchIs: {
              value: "<5%",
              percentage: "0.0%",
            },
            clicks: {
              value: "1711",
              percentage: "+2.7%",
            },
            ctr: {
              value: "0.80%",
              percentage: "+3.0%",
            },
            spend: {
              value: "$1,565.16",
              percentage: "+2.0%",
            },
            cpc: {
              value: "$1.08",
              percentage: "+10.8%",
            },
            orders: {
              value: "211",
              percentage: "+0.4%",
            },
          },
          {
            key: "3-3",
            campaign: "KEYWORD #003",
            impressions: {
              value: "118,991",
              percentage: "-4.2%",
            },
            topOfSearchIs: {
              value: "<5%",
              percentage: "0.0%",
            },
            clicks: {
              value: "1215",
              percentage: "+2.0%",
            },
            ctr: {
              value: "0.79%",
              percentage: "-3.6%",
            },
            spend: {
              value: "$1,800.16",
              percentage: "+1.0%",
            },
            cpc: {
              value: "$1.11",
              percentage: "-2.5%",
            },
            orders: {
              value: "603",
              percentage: "-1.4%",
            },
          },
        ],
      },
    ],
  },
  {
    key: "0-2",
    campaign: "Sponsored Display",
    impressions: {
      value: "374,910",
      percentage: "-2.2%",
    },
    topOfSearchIs: {
      value: "<5%",
      percentage: "0.0%",
    },
    clicks: {
      value: "3,481",
      percentage: "+1.6%",
    },
    ctr: {
      value: "0.77%",
      percentage: "+3.2%",
    },
    spend: {
      value: "$5,911.57",
      percentage: "++0.8%",
    },
    cpc: {
      value: "$1.11",
      percentage: "+8.2%",
    },
    orders: {
      value: "1,270",
      percentage: "++0.8%",
    },

    children: [
      {
        key: "1",
        campaign: "Nyl - SP - B0B5HN65QQ",
        impressions: {
          value: "456,505",
          percentage: "-4.7%",
        },
        topOfSearchIs: {
          value: "<5%",
          percentage: "0.0%",
        },
        clicks: {
          value: "373",
          percentage: "+3.4%",
        },
        ctr: {
          value: "0.08%",
          percentage: "-+0.8%",
        },
        spend: {
          value: "$5,911.57",
          percentage: "+3.2%",
        },
        cpc: {
          value: "$1.11",
          percentage: "+3.2%",
        },
        orders: {
          value: "1,270",
          percentage: "+1.6%",
        },

        children: [
          {
            key: "1-1",
            campaign: "KEYWORD #001",
            impressions: {
              value: "154,697",
              percentage: "-1.4%",
            },
            topOfSearchIs: {
              value: "<5%",
              percentage: "0.0%",
            },
            clicks: {
              value: "555",
              percentage: "-+0.8%",
            },
            ctr: {
              value: "0.70%",
              percentage: "+2.8%",
            },
            spend: {
              value: "$2,547.11",
              percentage: "+0.6%",
            },
            cpc: {
              value: "$1.14",
              percentage: "+7.9%",
            },
            orders: {
              value: "456",
              percentage: "-1.4%",
            },
          },
          {
            key: "1-2",
            campaign: "KEYWORD #002",
            impressions: {
              value: "101,222",
              percentage: "+0.2%",
            },
            topOfSearchIs: {
              value: "<5%",
              percentage: "0.0%",
            },
            clicks: {
              value: "1711",
              percentage: "+2.7%",
            },
            ctr: {
              value: "0.80%",
              percentage: "+3.0%",
            },
            spend: {
              value: "$1,565.16",
              percentage: "+2.0%",
            },
            cpc: {
              value: "$1.08",
              percentage: "+10.8%",
            },
            orders: {
              value: "211",
              percentage: "+0.4%",
            },
          },
          {
            key: "1-3",
            campaign: "KEYWORD #003",
            impressions: {
              value: "118,991",
              percentage: "-4.2%",
            },
            topOfSearchIs: {
              value: "<5%",
              percentage: "0.0%",
            },
            clicks: {
              value: "1215",
              percentage: "+2.0%",
            },
            ctr: {
              value: "0.79%",
              percentage: "-3.6%",
            },
            spend: {
              value: "$1,800.16",
              percentage: "+1.0%",
            },
            cpc: {
              value: "$1.11",
              percentage: "-2.5%",
            },
            orders: {
              value: "603",
              percentage: "-1.4%",
            },
          },
        ],
      },
      {
        key: "2",
        campaign: "Nyl - SP - B0B2HN33KQ",
        impressions: {
          value: "374,910",
          percentage: "-2.2%",
        },
        topOfSearchIs: {
          value: "<5%",
          percentage: "0.0%",
        },
        clicks: {
          value: "3,481",
          percentage: "+1.6%",
        },
        ctr: {
          value: "0.77%",
          percentage: "+3.2%",
        },
        spend: {
          value: "$5,911.57",
          percentage: "++0.8%",
        },
        cpc: {
          value: "$1.11",
          percentage: "+8.2%",
        },
        orders: {
          value: "1,270",
          percentage: "++0.8%",
        },

        children: [
          {
            key: "2-1",
            campaign: "KEYWORD #001",
            impressions: {
              value: "154,697",
              percentage: "-1.4%",
            },
            topOfSearchIs: {
              value: "<5%",
              percentage: "0.0%",
            },
            clicks: {
              value: "555",
              percentage: "-+0.8%",
            },
            ctr: {
              value: "0.70%",
              percentage: "+2.8%",
            },
            spend: {
              value: "$2,547.11",
              percentage: "+0.6%",
            },
            cpc: {
              value: "$1.14",
              percentage: "+7.9%",
            },
            orders: {
              value: "456",
              percentage: "-1.4%",
            },
          },
          {
            key: "2-2",
            campaign: "KEYWORD #002",
            impressions: {
              value: "101,222",
              percentage: "+0.2%",
            },
            topOfSearchIs: {
              value: "<5%",
              percentage: "0.0%",
            },
            clicks: {
              value: "1711",
              percentage: "+2.7%",
            },
            ctr: {
              value: "0.80%",
              percentage: "+3.0%",
            },
            spend: {
              value: "$1,565.16",
              percentage: "+2.0%",
            },
            cpc: {
              value: "$1.08",
              percentage: "+10.8%",
            },
            orders: {
              value: "211",
              percentage: "+0.4%",
            },
          },
          {
            key: "2-3",
            campaign: "KEYWORD #003",
            impressions: {
              value: "118,991",
              percentage: "-4.2%",
            },
            topOfSearchIs: {
              value: "<5%",
              percentage: "0.0%",
            },
            clicks: {
              value: "1215",
              percentage: "+2.0%",
            },
            ctr: {
              value: "0.79%",
              percentage: "-3.6%",
            },
            spend: {
              value: "$1,800.16",
              percentage: "+1.0%",
            },
            cpc: {
              value: "$1.11",
              percentage: "-2.5%",
            },
            orders: {
              value: "603",
              percentage: "-1.4%",
            },
          },
        ],
      },
      {
        key: "3",
        campaign: "Nyl - SP - B0B5HN65KO",
        impressions: {
          value: "266,363",
          percentage: "+7.1%",
        },
        topOfSearchIs: {
          value: "<5%",
          percentage: "0.0%",
        },
        clicks: {
          value: "859",
          percentage: "-+0.8%",
        },
        ctr: {
          value: "0.08%",
          percentage: "-7.7%",
        },
        spend: {
          value: "$5,911.57",
          percentage: "-7.1%",
        },
        cpc: {
          value: "$1.11",
          percentage: "+3.2%",
        },
        orders: {
          value: "1,270",
          percentage: "+9.9%",
        },

        children: [
          {
            key: "3-1",
            campaign: "KEYWORD #001",
            impressions: {
              value: "154,697",
              percentage: "-1.4%",
            },
            topOfSearchIs: {
              value: "<5%",
              percentage: "0.0%",
            },
            clicks: {
              value: "555",
              percentage: "-+0.8%",
            },
            ctr: {
              value: "0.70%",
              percentage: "+2.8%",
            },
            spend: {
              value: "$2,547.11",
              percentage: "+0.6%",
            },
            cpc: {
              value: "$1.14",
              percentage: "+7.9%",
            },
            orders: {
              value: "456",
              percentage: "-1.4%",
            },
          },
          {
            key: "3-2",
            campaign: "KEYWORD #002",
            impressions: {
              value: "101,222",
              percentage: "+0.2%",
            },
            topOfSearchIs: {
              value: "<5%",
              percentage: "0.0%",
            },
            clicks: {
              value: "1711",
              percentage: "+2.7%",
            },
            ctr: {
              value: "0.80%",
              percentage: "+3.0%",
            },
            spend: {
              value: "$1,565.16",
              percentage: "+2.0%",
            },
            cpc: {
              value: "$1.08",
              percentage: "+10.8%",
            },
            orders: {
              value: "211",
              percentage: "+0.4%",
            },
          },
          {
            key: "3-3",
            campaign: "KEYWORD #003",
            impressions: {
              value: "118,991",
              percentage: "-4.2%",
            },
            topOfSearchIs: {
              value: "<5%",
              percentage: "0.0%",
            },
            clicks: {
              value: "1215",
              percentage: "+2.0%",
            },
            ctr: {
              value: "0.79%",
              percentage: "-3.6%",
            },
            spend: {
              value: "$1,800.16",
              percentage: "+1.0%",
            },
            cpc: {
              value: "$1.11",
              percentage: "-2.5%",
            },
            orders: {
              value: "603",
              percentage: "-1.4%",
            },
          },
        ],
      },
    ],
  },
  {
    key: "0-3",
    campaign: "Sponsored Products",
    impressions: {
      value: "266,363",
      percentage: "+7.1%",
    },
    topOfSearchIs: {
      value: "<5%",
      percentage: "0.0%",
    },
    clicks: {
      value: "859",
      percentage: "-+0.8%",
    },
    ctr: {
      value: "0.08%",
      percentage: "-7.7%",
    },
    spend: {
      value: "$5,911.57",
      percentage: "-7.1%",
    },
    cpc: {
      value: "$1.11",
      percentage: "+3.2%",
    },
    orders: {
      value: "1,270",
      percentage: "+9.9%",
    },

    children: [
      {
        key: "1",
        campaign: "Nyl - SP - B0B5HN65QQ",
        impressions: {
          value: "456,505",
          percentage: "-4.7%",
        },
        topOfSearchIs: {
          value: "<5%",
          percentage: "0.0%",
        },
        clicks: {
          value: "373",
          percentage: "+3.4%",
        },
        ctr: {
          value: "0.08%",
          percentage: "-+0.8%",
        },
        spend: {
          value: "$5,911.57",
          percentage: "+3.2%",
        },
        cpc: {
          value: "$1.11",
          percentage: "+3.2%",
        },
        orders: {
          value: "1,270",
          percentage: "+1.6%",
        },

        children: [
          {
            key: "1-1",
            campaign: "KEYWORD #001",
            impressions: {
              value: "154,697",
              percentage: "-1.4%",
            },
            topOfSearchIs: {
              value: "<5%",
              percentage: "0.0%",
            },
            clicks: {
              value: "555",
              percentage: "-+0.8%",
            },
            ctr: {
              value: "0.70%",
              percentage: "+2.8%",
            },
            spend: {
              value: "$2,547.11",
              percentage: "+0.6%",
            },
            cpc: {
              value: "$1.14",
              percentage: "+7.9%",
            },
            orders: {
              value: "456",
              percentage: "-1.4%",
            },
          },
          {
            key: "1-2",
            campaign: "KEYWORD #002",
            impressions: {
              value: "101,222",
              percentage: "+0.2%",
            },
            topOfSearchIs: {
              value: "<5%",
              percentage: "0.0%",
            },
            clicks: {
              value: "1711",
              percentage: "+2.7%",
            },
            ctr: {
              value: "0.80%",
              percentage: "+3.0%",
            },
            spend: {
              value: "$1,565.16",
              percentage: "+2.0%",
            },
            cpc: {
              value: "$1.08",
              percentage: "+10.8%",
            },
            orders: {
              value: "211",
              percentage: "+0.4%",
            },
          },
          {
            key: "1-3",
            campaign: "KEYWORD #003",
            impressions: {
              value: "118,991",
              percentage: "-4.2%",
            },
            topOfSearchIs: {
              value: "<5%",
              percentage: "0.0%",
            },
            clicks: {
              value: "1215",
              percentage: "+2.0%",
            },
            ctr: {
              value: "0.79%",
              percentage: "-3.6%",
            },
            spend: {
              value: "$1,800.16",
              percentage: "+1.0%",
            },
            cpc: {
              value: "$1.11",
              percentage: "-2.5%",
            },
            orders: {
              value: "603",
              percentage: "-1.4%",
            },
          },
        ],
      },
      {
        key: "2",
        campaign: "Nyl - SP - B0B2HN33KQ",
        impressions: {
          value: "374,910",
          percentage: "-2.2%",
        },
        topOfSearchIs: {
          value: "<5%",
          percentage: "0.0%",
        },
        clicks: {
          value: "3,481",
          percentage: "+1.6%",
        },
        ctr: {
          value: "0.77%",
          percentage: "+3.2%",
        },
        spend: {
          value: "$5,911.57",
          percentage: "++0.8%",
        },
        cpc: {
          value: "$1.11",
          percentage: "+8.2%",
        },
        orders: {
          value: "1,270",
          percentage: "++0.8%",
        },

        children: [
          {
            key: "2-1",
            campaign: "KEYWORD #001",
            impressions: {
              value: "154,697",
              percentage: "-1.4%",
            },
            topOfSearchIs: {
              value: "<5%",
              percentage: "0.0%",
            },
            clicks: {
              value: "555",
              percentage: "-+0.8%",
            },
            ctr: {
              value: "0.70%",
              percentage: "+2.8%",
            },
            spend: {
              value: "$2,547.11",
              percentage: "+0.6%",
            },
            cpc: {
              value: "$1.14",
              percentage: "+7.9%",
            },
            orders: {
              value: "456",
              percentage: "-1.4%",
            },
          },
          {
            key: "2-2",
            campaign: "KEYWORD #002",
            impressions: {
              value: "101,222",
              percentage: "+0.2%",
            },
            topOfSearchIs: {
              value: "<5%",
              percentage: "0.0%",
            },
            clicks: {
              value: "1711",
              percentage: "+2.7%",
            },
            ctr: {
              value: "0.80%",
              percentage: "+3.0%",
            },
            spend: {
              value: "$1,565.16",
              percentage: "+2.0%",
            },
            cpc: {
              value: "$1.08",
              percentage: "+10.8%",
            },
            orders: {
              value: "211",
              percentage: "+0.4%",
            },
          },
          {
            key: "2-3",
            campaign: "KEYWORD #003",
            impressions: {
              value: "118,991",
              percentage: "-4.2%",
            },
            topOfSearchIs: {
              value: "<5%",
              percentage: "0.0%",
            },
            clicks: {
              value: "1215",
              percentage: "+2.0%",
            },
            ctr: {
              value: "0.79%",
              percentage: "-3.6%",
            },
            spend: {
              value: "$1,800.16",
              percentage: "+1.0%",
            },
            cpc: {
              value: "$1.11",
              percentage: "-2.5%",
            },
            orders: {
              value: "603",
              percentage: "-1.4%",
            },
          },
        ],
      },
      {
        key: "3",
        campaign: "Nyl - SP - B0B5HN65KO",
        impressions: {
          value: "266,363",
          percentage: "+7.1%",
        },
        topOfSearchIs: {
          value: "<5%",
          percentage: "0.0%",
        },
        clicks: {
          value: "859",
          percentage: "-+0.8%",
        },
        ctr: {
          value: "0.08%",
          percentage: "-7.7%",
        },
        spend: {
          value: "$5,911.57",
          percentage: "-7.1%",
        },
        cpc: {
          value: "$1.11",
          percentage: "+3.2%",
        },
        orders: {
          value: "1,270",
          percentage: "+9.9%",
        },

        children: [
          {
            key: "3-1",
            campaign: "KEYWORD #001",
            impressions: {
              value: "154,697",
              percentage: "-1.4%",
            },
            topOfSearchIs: {
              value: "<5%",
              percentage: "0.0%",
            },
            clicks: {
              value: "555",
              percentage: "-+0.8%",
            },
            ctr: {
              value: "0.70%",
              percentage: "+2.8%",
            },
            spend: {
              value: "$2,547.11",
              percentage: "+0.6%",
            },
            cpc: {
              value: "$1.14",
              percentage: "+7.9%",
            },
            orders: {
              value: "456",
              percentage: "-1.4%",
            },
          },
          {
            key: "3-2",
            campaign: "KEYWORD #002",
            impressions: {
              value: "101,222",
              percentage: "+0.2%",
            },
            topOfSearchIs: {
              value: "<5%",
              percentage: "0.0%",
            },
            clicks: {
              value: "1711",
              percentage: "+2.7%",
            },
            ctr: {
              value: "0.80%",
              percentage: "+3.0%",
            },
            spend: {
              value: "$1,565.16",
              percentage: "+0.0%",
            },
            cpc: {
              value: "$1.08",
              percentage: "+10.8%",
            },
            orders: {
              value: "211",
              percentage: "+0.4%",
            },
          },
          {
            key: "3-3",
            campaign: "KEYWORD #003",
            impressions: {
              value: "118,991",
              percentage: "-4.2%",
            },
            topOfSearchIs: {
              value: "<5%",
              percentage: "0.0%",
            },
            clicks: {
              value: "1215",
              percentage: "+2.0%",
            },
            ctr: {
              value: "0.79%",
              percentage: "-3.6%",
            },
            spend: {
              value: "$1,800.16",
              percentage: "+1.0%",
            },
            cpc: {
              value: "$1.11",
              percentage: "-2.5%",
            },
            orders: {
              value: "603",
              percentage: "-1.4%",
            },
          },
        ],
      },
    ],
  },
  {
    key: "0-4",
    campaign: "Total 3 Ad Types",
    impressions: {
      value: "1,371,797",
      percentage: "+3.7%",
    },
    topOfSearchIs: {
      value: "<5%",
      percentage: "0.0%",
    },
    clicks: {
      value: "16,012",
      percentage: "+5.3%",
    },
    ctr: {
      value: "0.82%",
      percentage: "-1.2%",
    },
    spend: {
      value: "$41,381,80",
      percentage: "-8.2%",
    },
    cpc: {
      value: "$1.11",
      percentage: "-+0.8%",
    },
    orders: {
      value: "8,891",
      percentage: "-3.1%",
    },
  },
];

export const funnelData = [
  {
    key: "0-1",
    campaign: "TOF",
    impressions: {
      value: "456,505",
      percentage: "-4.7%",
    },
    topOfSearchIs: {
      value: "<5%",
      percentage: "0.0%",
    },
    clicks: {
      value: "373",
      percentage: "+3.4%",
    },
    ctr: {
      value: "0.08%",
      percentage: "-+0.8%",
    },
    spend: {
      value: "$5,911.57",
      percentage: "+3.2%",
    },
    cpc: {
      value: "$1.11",
      percentage: "+3.2%",
    },
    orders: {
      value: "1,270",
      percentage: "+1.6%",
    },

    children: [
      {
        key: "1",
        campaign: "Nyl - SP - B0B5HN65QQ",
        impressions: {
          value: "456,505",
          percentage: "-4.7%",
        },
        topOfSearchIs: {
          value: "<5%",
          percentage: "0.0%",
        },
        clicks: {
          value: "373",
          percentage: "+3.4%",
        },
        ctr: {
          value: "0.08%",
          percentage: "-+0.8%",
        },
        spend: {
          value: "$5,911.57",
          percentage: "+3.2%",
        },
        cpc: {
          value: "$1.11",
          percentage: "+3.2%",
        },
        orders: {
          value: "1,270",
          percentage: "+1.6%",
        },

        children: [
          {
            key: "1-1",
            campaign: "KEYWORD #001",
            impressions: {
              value: "154,697",
              percentage: "-1.4%",
            },
            topOfSearchIs: {
              value: "<5%",
              percentage: "0.0%",
            },
            clicks: {
              value: "555",
              percentage: "-+0.8%",
            },
            ctr: {
              value: "0.70%",
              percentage: "+2.8%",
            },
            spend: {
              value: "$2,547.11",
              percentage: "+0.6%",
            },
            cpc: {
              value: "$1.14",
              percentage: "+7.9%",
            },
            orders: {
              value: "456",
              percentage: "-1.4%",
            },
          },
          {
            key: "1-2",
            campaign: "KEYWORD #002",
            impressions: {
              value: "101,222",
              percentage: "+0.2%",
            },
            topOfSearchIs: {
              value: "<5%",
              percentage: "0.0%",
            },
            clicks: {
              value: "1711",
              percentage: "+2.7%",
            },
            ctr: {
              value: "0.80%",
              percentage: "+3.0%",
            },
            spend: {
              value: "$1,565.16",
              percentage: "+0.0%",
            },
            cpc: {
              value: "$1.08",
              percentage: "+10.8%",
            },
            orders: {
              value: "211",
              percentage: "+0.4%",
            },
          },
          {
            key: "1-3",
            campaign: "KEYWORD #003",
            impressions: {
              value: "118,991",
              percentage: "-4.2%",
            },
            topOfSearchIs: {
              value: "<5%",
              percentage: "0.0%",
            },
            clicks: {
              value: "1215",
              percentage: "+2.0%",
            },
            ctr: {
              value: "0.79%",
              percentage: "-3.6%",
            },
            spend: {
              value: "$1,800.16",
              percentage: "+1.0%",
            },
            cpc: {
              value: "$1.11",
              percentage: "-2.5%",
            },
            orders: {
              value: "603",
              percentage: "-1.4%",
            },
          },
        ],
      },
      {
        key: "2",
        campaign: "Nyl - SP - B0B2HN33KQ",
        impressions: {
          value: "374,910",
          percentage: "-2.2%",
        },
        topOfSearchIs: {
          value: "<5%",
          percentage: "0.0%",
        },
        clicks: {
          value: "3,481",
          percentage: "+1.6%",
        },
        ctr: {
          value: "0.77%",
          percentage: "+3.2%",
        },
        spend: {
          value: "$5,911.57",
          percentage: "++0.8%",
        },
        cpc: {
          value: "$1.11",
          percentage: "+8.2%",
        },
        orders: {
          value: "1,270",
          percentage: "++0.8%",
        },

        children: [
          {
            key: "2-1",
            campaign: "KEYWORD #001",
            impressions: {
              value: "154,697",
              percentage: "-1.4%",
            },
            topOfSearchIs: {
              value: "<5%",
              percentage: "0.0%",
            },
            clicks: {
              value: "555",
              percentage: "-+0.8%",
            },
            ctr: {
              value: "0.70%",
              percentage: "+2.8%",
            },
            spend: {
              value: "$2,547.11",
              percentage: "+0.6%",
            },
            cpc: {
              value: "$1.14",
              percentage: "+7.9%",
            },
            orders: {
              value: "456",
              percentage: "-1.4%",
            },
          },
          {
            key: "2-2",
            campaign: "KEYWORD #002",
            impressions: {
              value: "101,222",
              percentage: "+0.2%",
            },
            topOfSearchIs: {
              value: "<5%",
              percentage: "0.0%",
            },
            clicks: {
              value: "1711",
              percentage: "+2.7%",
            },
            ctr: {
              value: "0.80%",
              percentage: "+3.0%",
            },
            spend: {
              value: "$1,565.16",
              percentage: "+0.0%",
            },
            cpc: {
              value: "$1.08",
              percentage: "+10.8%",
            },
            orders: {
              value: "211",
              percentage: "+0.4%",
            },
          },
          {
            key: "2-3",
            campaign: "KEYWORD #003",
            impressions: {
              value: "118,991",
              percentage: "-4.2%",
            },
            topOfSearchIs: {
              value: "<5%",
              percentage: "0.0%",
            },
            clicks: {
              value: "1215",
              percentage: "+2.0%",
            },
            ctr: {
              value: "0.79%",
              percentage: "-3.6%",
            },
            spend: {
              value: "$1,800.16",
              percentage: "+1.0%",
            },
            cpc: {
              value: "$1.11",
              percentage: "-2.5%",
            },
            orders: {
              value: "603",
              percentage: "-1.4%",
            },
          },
        ],
      },
      {
        key: "3",
        campaign: "Nyl - SP - B0B5HN65KO",
        impressions: {
          value: "266,363",
          percentage: "+7.1%",
        },
        topOfSearchIs: {
          value: "<5%",
          percentage: "0.0%",
        },
        clicks: {
          value: "859",
          percentage: "-+0.8%",
        },
        ctr: {
          value: "0.08%",
          percentage: "-7.7%",
        },
        spend: {
          value: "$5,911.57",
          percentage: "-7.1%",
        },
        cpc: {
          value: "$1.11",
          percentage: "+3.2%",
        },
        orders: {
          value: "1,270",
          percentage: "+9.9%",
        },

        children: [
          {
            key: "3-1",
            campaign: "KEYWORD #001",
            impressions: {
              value: "154,697",
              percentage: "-1.4%",
            },
            topOfSearchIs: {
              value: "<5%",
              percentage: "0.0%",
            },
            clicks: {
              value: "555",
              percentage: "-+0.8%",
            },
            ctr: {
              value: "0.70%",
              percentage: "+2.8%",
            },
            spend: {
              value: "$2,547.11",
              percentage: "+0.6%",
            },
            cpc: {
              value: "$1.14",
              percentage: "+7.9%",
            },
            orders: {
              value: "456",
              percentage: "-1.4%",
            },
          },
          {
            key: "3-2",
            campaign: "KEYWORD #002",
            impressions: {
              value: "101,222",
              percentage: "+0.2%",
            },
            topOfSearchIs: {
              value: "<5%",
              percentage: "0.0%",
            },
            clicks: {
              value: "1711",
              percentage: "+2.7%",
            },
            ctr: {
              value: "0.80%",
              percentage: "+3.0%",
            },
            spend: {
              value: "$1,565.16",
              percentage: "+0.0%",
            },
            cpc: {
              value: "$1.08",
              percentage: "+10.8%",
            },
            orders: {
              value: "211",
              percentage: "+0.4%",
            },
          },
          {
            key: "3-3",
            campaign: "KEYWORD #003",
            impressions: {
              value: "118,991",
              percentage: "-4.2%",
            },
            topOfSearchIs: {
              value: "<5%",
              percentage: "0.0%",
            },
            clicks: {
              value: "1215",
              percentage: "+2.0%",
            },
            ctr: {
              value: "0.79%",
              percentage: "-3.6%",
            },
            spend: {
              value: "$1,800.16",
              percentage: "+1.0%",
            },
            cpc: {
              value: "$1.11",
              percentage: "-2.5%",
            },
            orders: {
              value: "603",
              percentage: "-1.4%",
            },
          },
        ],
      },
    ],
  },
  {
    key: "0-2",
    campaign: "MOF",
    impressions: {
      value: "374,910",
      percentage: "-2.2%",
    },
    topOfSearchIs: {
      value: "<5%",
      percentage: "0.0%",
    },
    clicks: {
      value: "3,481",
      percentage: "+1.6%",
    },
    ctr: {
      value: "0.77%",
      percentage: "+3.2%",
    },
    spend: {
      value: "$5,911.57",
      percentage: "++0.8%",
    },
    cpc: {
      value: "$1.11",
      percentage: "+8.2%",
    },
    orders: {
      value: "1,270",
      percentage: "++0.8%",
    },

    children: [
      {
        key: "1",
        campaign: "Nyl - SP - B0B5HN65QQ",
        impressions: {
          value: "456,505",
          percentage: "-4.7%",
        },
        topOfSearchIs: {
          value: "<5%",
          percentage: "0.0%",
        },
        clicks: {
          value: "373",
          percentage: "+3.4%",
        },
        ctr: {
          value: "0.08%",
          percentage: "-+0.8%",
        },
        spend: {
          value: "$5,911.57",
          percentage: "+3.2%",
        },
        cpc: {
          value: "$1.11",
          percentage: "+3.2%",
        },
        orders: {
          value: "1,270",
          percentage: "+1.6%",
        },

        children: [
          {
            key: "1-1",
            campaign: "KEYWORD #001",
            impressions: {
              value: "154,697",
              percentage: "-1.4%",
            },
            topOfSearchIs: {
              value: "<5%",
              percentage: "0.0%",
            },
            clicks: {
              value: "555",
              percentage: "-+0.8%",
            },
            ctr: {
              value: "0.70%",
              percentage: "+2.8%",
            },
            spend: {
              value: "$2,547.11",
              percentage: "+0.6%",
            },
            cpc: {
              value: "$1.14",
              percentage: "+7.9%",
            },
            orders: {
              value: "456",
              percentage: "-1.4%",
            },
          },
          {
            key: "1-2",
            campaign: "KEYWORD #002",
            impressions: {
              value: "101,222",
              percentage: "+0.2%",
            },
            topOfSearchIs: {
              value: "<5%",
              percentage: "0.0%",
            },
            clicks: {
              value: "1711",
              percentage: "+2.7%",
            },
            ctr: {
              value: "0.80%",
              percentage: "+3.0%",
            },
            spend: {
              value: "$1,565.16",
              percentage: "+0.0%",
            },
            cpc: {
              value: "$1.08",
              percentage: "+10.8%",
            },
            orders: {
              value: "211",
              percentage: "+0.4%",
            },
          },
          {
            key: "1-3",
            campaign: "KEYWORD #003",
            impressions: {
              value: "118,991",
              percentage: "-4.2%",
            },
            topOfSearchIs: {
              value: "<5%",
              percentage: "0.0%",
            },
            clicks: {
              value: "1215",
              percentage: "+2.0%",
            },
            ctr: {
              value: "0.79%",
              percentage: "-3.6%",
            },
            spend: {
              value: "$1,800.16",
              percentage: "+1.0%",
            },
            cpc: {
              value: "$1.11",
              percentage: "-2.5%",
            },
            orders: {
              value: "603",
              percentage: "-1.4%",
            },
          },
        ],
      },
      {
        key: "2",
        campaign: "Nyl - SP - B0B2HN33KQ",
        impressions: {
          value: "374,910",
          percentage: "-2.2%",
        },
        topOfSearchIs: {
          value: "<5%",
          percentage: "0.0%",
        },
        clicks: {
          value: "3,481",
          percentage: "+1.6%",
        },
        ctr: {
          value: "0.77%",
          percentage: "+3.2%",
        },
        spend: {
          value: "$5,911.57",
          percentage: "++0.8%",
        },
        cpc: {
          value: "$1.11",
          percentage: "+8.2%",
        },
        orders: {
          value: "1,270",
          percentage: "++0.8%",
        },

        children: [
          {
            key: "2-1",
            campaign: "KEYWORD #001",
            impressions: {
              value: "154,697",
              percentage: "-1.4%",
            },
            topOfSearchIs: {
              value: "<5%",
              percentage: "0.0%",
            },
            clicks: {
              value: "555",
              percentage: "-+0.8%",
            },
            ctr: {
              value: "0.70%",
              percentage: "+2.8%",
            },
            spend: {
              value: "$2,547.11",
              percentage: "+0.6%",
            },
            cpc: {
              value: "$1.14",
              percentage: "+7.9%",
            },
            orders: {
              value: "456",
              percentage: "-1.4%",
            },
          },
          {
            key: "2-2",
            campaign: "KEYWORD #002",
            impressions: {
              value: "101,222",
              percentage: "+0.2%",
            },
            topOfSearchIs: {
              value: "<5%",
              percentage: "0.0%",
            },
            clicks: {
              value: "1711",
              percentage: "+2.7%",
            },
            ctr: {
              value: "0.80%",
              percentage: "+3.0%",
            },
            spend: {
              value: "$1,565.16",
              percentage: "+0.0%",
            },
            cpc: {
              value: "$1.08",
              percentage: "+10.8%",
            },
            orders: {
              value: "211",
              percentage: "+0.4%",
            },
          },
          {
            key: "2-3",
            campaign: "KEYWORD #003",
            impressions: {
              value: "118,991",
              percentage: "-4.2%",
            },
            topOfSearchIs: {
              value: "<5%",
              percentage: "0.0%",
            },
            clicks: {
              value: "1215",
              percentage: "+2.0%",
            },
            ctr: {
              value: "0.79%",
              percentage: "-3.6%",
            },
            spend: {
              value: "$1,800.16",
              percentage: "+1.0%",
            },
            cpc: {
              value: "$1.11",
              percentage: "-2.5%",
            },
            orders: {
              value: "603",
              percentage: "-1.4%",
            },
          },
        ],
      },
      {
        key: "3",
        campaign: "Nyl - SP - B0B5HN65KO",
        impressions: {
          value: "266,363",
          percentage: "+7.1%",
        },
        topOfSearchIs: {
          value: "<5%",
          percentage: "0.0%",
        },
        clicks: {
          value: "859",
          percentage: "-+0.8%",
        },
        ctr: {
          value: "0.08%",
          percentage: "-7.7%",
        },
        spend: {
          value: "$5,911.57",
          percentage: "-7.1%",
        },
        cpc: {
          value: "$1.11",
          percentage: "+3.2%",
        },
        orders: {
          value: "1,270",
          percentage: "+9.9%",
        },

        children: [
          {
            key: "3-1",
            campaign: "KEYWORD #001",
            impressions: {
              value: "154,697",
              percentage: "-1.4%",
            },
            topOfSearchIs: {
              value: "<5%",
              percentage: "0.0%",
            },
            clicks: {
              value: "555",
              percentage: "-+0.8%",
            },
            ctr: {
              value: "0.70%",
              percentage: "+2.8%",
            },
            spend: {
              value: "$2,547.11",
              percentage: "+0.6%",
            },
            cpc: {
              value: "$1.14",
              percentage: "+7.9%",
            },
            orders: {
              value: "456",
              percentage: "-1.4%",
            },
          },
          {
            key: "3-2",
            campaign: "KEYWORD #002",
            impressions: {
              value: "101,222",
              percentage: "+0.2%",
            },
            topOfSearchIs: {
              value: "<5%",
              percentage: "0.0%",
            },
            clicks: {
              value: "1711",
              percentage: "+2.7%",
            },
            ctr: {
              value: "0.80%",
              percentage: "+3.0%",
            },
            spend: {
              value: "$1,565.16",
              percentage: "+0.0%",
            },
            cpc: {
              value: "$1.08",
              percentage: "+10.8%",
            },
            orders: {
              value: "211",
              percentage: "+0.4%",
            },
          },
          {
            key: "3-3",
            campaign: "KEYWORD #003",
            impressions: {
              value: "118,991",
              percentage: "-4.2%",
            },
            topOfSearchIs: {
              value: "<5%",
              percentage: "0.0%",
            },
            clicks: {
              value: "1215",
              percentage: "+2.0%",
            },
            ctr: {
              value: "0.79%",
              percentage: "-3.6%",
            },
            spend: {
              value: "$1,800.16",
              percentage: "+1.0%",
            },
            cpc: {
              value: "$1.11",
              percentage: "-2.5%",
            },
            orders: {
              value: "603",
              percentage: "-1.4%",
            },
          },
        ],
      },
    ],
  },
  {
    key: "0-3",
    campaign: "BCF",
    impressions: {
      value: "266,363",
      percentage: "+7.1%",
    },
    topOfSearchIs: {
      value: "<5%",
      percentage: "0.0%",
    },
    clicks: {
      value: "859",
      percentage: "-+0.8%",
    },
    ctr: {
      value: "0.08%",
      percentage: "-7.7%",
    },
    spend: {
      value: "$5,911.57",
      percentage: "-7.1%",
    },
    cpc: {
      value: "$1.11",
      percentage: "+3.2%",
    },
    orders: {
      value: "1,270",
      percentage: "+9.9%",
    },

    children: [
      {
        key: "1",
        campaign: "Nyl - SP - B0B5HN65QQ",
        impressions: {
          value: "456,505",
          percentage: "-4.7%",
        },
        topOfSearchIs: {
          value: "<5%",
          percentage: "0.0%",
        },
        clicks: {
          value: "373",
          percentage: "+3.4%",
        },
        ctr: {
          value: "0.08%",
          percentage: "-+0.8%",
        },
        spend: {
          value: "$5,911.57",
          percentage: "+3.2%",
        },
        cpc: {
          value: "$1.11",
          percentage: "+3.2%",
        },
        orders: {
          value: "1,270",
          percentage: "+1.6%",
        },

        children: [
          {
            key: "1-1",
            campaign: "KEYWORD #001",
            impressions: {
              value: "154,697",
              percentage: "-1.4%",
            },
            topOfSearchIs: {
              value: "<5%",
              percentage: "0.0%",
            },
            clicks: {
              value: "555",
              percentage: "-+0.8%",
            },
            ctr: {
              value: "0.70%",
              percentage: "+2.8%",
            },
            spend: {
              value: "$2,547.11",
              percentage: "+0.6%",
            },
            cpc: {
              value: "$1.14",
              percentage: "+7.9%",
            },
            orders: {
              value: "456",
              percentage: "-1.4%",
            },
          },
          {
            key: "1-2",
            campaign: "KEYWORD #002",
            impressions: {
              value: "101,222",
              percentage: "+0.2%",
            },
            topOfSearchIs: {
              value: "<5%",
              percentage: "0.0%",
            },
            clicks: {
              value: "1711",
              percentage: "+2.7%",
            },
            ctr: {
              value: "0.80%",
              percentage: "+3.0%",
            },
            spend: {
              value: "$1,565.16",
              percentage: "+0.0%",
            },
            cpc: {
              value: "$1.08",
              percentage: "+10.8%",
            },
            orders: {
              value: "211",
              percentage: "+0.4%",
            },
          },
          {
            key: "1-3",
            campaign: "KEYWORD #003",
            impressions: {
              value: "118,991",
              percentage: "-4.2%",
            },
            topOfSearchIs: {
              value: "<5%",
              percentage: "0.0%",
            },
            clicks: {
              value: "1215",
              percentage: "+2.0%",
            },
            ctr: {
              value: "0.79%",
              percentage: "-3.6%",
            },
            spend: {
              value: "$1,800.16",
              percentage: "+1.0%",
            },
            cpc: {
              value: "$1.11",
              percentage: "-2.5%",
            },
            orders: {
              value: "603",
              percentage: "-1.4%",
            },
          },
        ],
      },
      {
        key: "2",
        campaign: "Nyl - SP - B0B2HN33KQ",
        impressions: {
          value: "374,910",
          percentage: "-2.2%",
        },
        topOfSearchIs: {
          value: "<5%",
          percentage: "0.0%",
        },
        clicks: {
          value: "3,481",
          percentage: "+1.6%",
        },
        ctr: {
          value: "0.77%",
          percentage: "+3.2%",
        },
        spend: {
          value: "$5,911.57",
          percentage: "++0.8%",
        },
        cpc: {
          value: "$1.11",
          percentage: "+8.2%",
        },
        orders: {
          value: "1,270",
          percentage: "++0.8%",
        },

        children: [
          {
            key: "2-1",
            campaign: "KEYWORD #001",
            impressions: {
              value: "154,697",
              percentage: "-1.4%",
            },
            topOfSearchIs: {
              value: "<5%",
              percentage: "0.0%",
            },
            clicks: {
              value: "555",
              percentage: "-+0.8%",
            },
            ctr: {
              value: "0.70%",
              percentage: "+2.8%",
            },
            spend: {
              value: "$2,547.11",
              percentage: "+0.6%",
            },
            cpc: {
              value: "$1.14",
              percentage: "+7.9%",
            },
            orders: {
              value: "456",
              percentage: "-1.4%",
            },
          },
          {
            key: "2-2",
            campaign: "KEYWORD #002",
            impressions: {
              value: "101,222",
              percentage: "+0.2%",
            },
            topOfSearchIs: {
              value: "<5%",
              percentage: "0.0%",
            },
            clicks: {
              value: "1711",
              percentage: "+2.7%",
            },
            ctr: {
              value: "0.80%",
              percentage: "+3.0%",
            },
            spend: {
              value: "$1,565.16",
              percentage: "+0.0%",
            },
            cpc: {
              value: "$1.08",
              percentage: "+10.8%",
            },
            orders: {
              value: "211",
              percentage: "+0.4%",
            },
          },
          {
            key: "2-3",
            campaign: "KEYWORD #003",
            impressions: {
              value: "118,991",
              percentage: "-4.2%",
            },
            topOfSearchIs: {
              value: "<5%",
              percentage: "0.0%",
            },
            clicks: {
              value: "1215",
              percentage: "+2.0%",
            },
            ctr: {
              value: "0.79%",
              percentage: "-3.6%",
            },
            spend: {
              value: "$1,800.16",
              percentage: "+1.0%",
            },
            cpc: {
              value: "$1.11",
              percentage: "-2.5%",
            },
            orders: {
              value: "603",
              percentage: "-1.4%",
            },
          },
        ],
      },
      {
        key: "3",
        campaign: "Nyl - SP - B0B5HN65KO",
        impressions: {
          value: "266,363",
          percentage: "+7.1%",
        },
        topOfSearchIs: {
          value: "<5%",
          percentage: "0.0%",
        },
        clicks: {
          value: "859",
          percentage: "-+0.8%",
        },
        ctr: {
          value: "0.08%",
          percentage: "-7.7%",
        },
        spend: {
          value: "$5,911.57",
          percentage: "-7.1%",
        },
        cpc: {
          value: "$1.11",
          percentage: "+3.2%",
        },
        orders: {
          value: "1,270",
          percentage: "+9.9%",
        },

        children: [
          {
            key: "3-1",
            campaign: "KEYWORD #001",
            impressions: {
              value: "154,697",
              percentage: "-1.4%",
            },
            topOfSearchIs: {
              value: "<5%",
              percentage: "0.0%",
            },
            clicks: {
              value: "555",
              percentage: "-+0.8%",
            },
            ctr: {
              value: "0.70%",
              percentage: "+2.8%",
            },
            spend: {
              value: "$2,547.11",
              percentage: "+0.6%",
            },
            cpc: {
              value: "$1.14",
              percentage: "+7.9%",
            },
            orders: {
              value: "456",
              percentage: "-1.4%",
            },
          },
          {
            key: "3-2",
            campaign: "KEYWORD #002",
            impressions: {
              value: "101,222",
              percentage: "+0.2%",
            },
            topOfSearchIs: {
              value: "<5%",
              percentage: "0.0%",
            },
            clicks: {
              value: "1711",
              percentage: "+2.7%",
            },
            ctr: {
              value: "0.80%",
              percentage: "+3.0%",
            },
            spend: {
              value: "$1,565.16",
              percentage: "+0.0%",
            },
            cpc: {
              value: "$1.08",
              percentage: "+10.8%",
            },
            orders: {
              value: "211",
              percentage: "+0.4%",
            },
          },
          {
            key: "3-3",
            campaign: "KEYWORD #003",
            impressions: {
              value: "118,991",
              percentage: "-4.2%",
            },
            topOfSearchIs: {
              value: "<5%",
              percentage: "0.0%",
            },
            clicks: {
              value: "1215",
              percentage: "+2.0%",
            },
            ctr: {
              value: "0.79%",
              percentage: "-3.6%",
            },
            spend: {
              value: "$1,800.16",
              percentage: "+1.0%",
            },
            cpc: {
              value: "$1.11",
              percentage: "-2.5%",
            },
            orders: {
              value: "603",
              percentage: "-1.4%",
            },
          },
        ],
      },
    ],
  },
  {
    key: "0-4",
    campaign: "Total 3 Funnel",
    impressions: {
      value: "1,371,797",
      percentage: "+3.7%",
    },
    topOfSearchIs: {
      value: "<5%",
      percentage: "0.0%",
    },
    clicks: {
      value: "16,012",
      percentage: "+5.3%",
    },
    ctr: {
      value: "0.82%",
      percentage: "-1.2%",
    },
    spend: {
      value: "$41,381,80",
      percentage: "-8.2%",
    },
    cpc: {
      value: "$1.11",
      percentage: "-+0.8%",
    },
    orders: {
      value: "8,891",
      percentage: "-3.1%",
    },
  },
];

export const targetData = [
  {
    key: "0-1",
    campaign: "BRAND",
    impressions: {
      value: "456,505",
      percentage: "-4.7%",
    },
    topOfSearchIs: {
      value: "<5%",
      percentage: "0.0%",
    },
    clicks: {
      value: "373",
      percentage: "+3.4%",
    },
    ctr: {
      value: "0.08%",
      percentage: "-+0.8%",
    },
    spend: {
      value: "$5,911.57",
      percentage: "+3.2%",
    },
    cpc: {
      value: "$1.11",
      percentage: "+3.2%",
    },
    orders: {
      value: "1,270",
      percentage: "+1.6%",
    },

    children: [
      {
        key: "1",
        campaign: "Nyl - SP - B0B5HN65QQ",
        impressions: {
          value: "456,505",
          percentage: "-4.7%",
        },
        topOfSearchIs: {
          value: "<5%",
          percentage: "0.0%",
        },
        clicks: {
          value: "373",
          percentage: "+3.4%",
        },
        ctr: {
          value: "0.08%",
          percentage: "-+0.8%",
        },
        spend: {
          value: "$5,911.57",
          percentage: "+3.2%",
        },
        cpc: {
          value: "$1.11",
          percentage: "+3.2%",
        },
        orders: {
          value: "1,270",
          percentage: "+1.6%",
        },

        children: [
          {
            key: "1-1",
            campaign: "KEYWORD #001",
            impressions: {
              value: "154,697",
              percentage: "-1.4%",
            },
            topOfSearchIs: {
              value: "<5%",
              percentage: "0.0%",
            },
            clicks: {
              value: "555",
              percentage: "-+0.8%",
            },
            ctr: {
              value: "0.70%",
              percentage: "+2.8%",
            },
            spend: {
              value: "$2,547.11",
              percentage: "+0.6%",
            },
            cpc: {
              value: "$1.14",
              percentage: "+7.9%",
            },
            orders: {
              value: "456",
              percentage: "-1.4%",
            },
          },
          {
            key: "1-2",
            campaign: "KEYWORD #002",
            impressions: {
              value: "101,222",
              percentage: "+0.2%",
            },
            topOfSearchIs: {
              value: "<5%",
              percentage: "0.0%",
            },
            clicks: {
              value: "1711",
              percentage: "+2.7%",
            },
            ctr: {
              value: "0.80%",
              percentage: "+3.0%",
            },
            spend: {
              value: "$1,565.16",
              percentage: "+0.0%",
            },
            cpc: {
              value: "$1.08",
              percentage: "+10.8%",
            },
            orders: {
              value: "211",
              percentage: "+0.4%",
            },
          },
          {
            key: "1-3",
            campaign: "KEYWORD #003",
            impressions: {
              value: "118,991",
              percentage: "-4.2%",
            },
            topOfSearchIs: {
              value: "<5%",
              percentage: "0.0%",
            },
            clicks: {
              value: "1215",
              percentage: "+2.0%",
            },
            ctr: {
              value: "0.79%",
              percentage: "-3.6%",
            },
            spend: {
              value: "$1,800.16",
              percentage: "+1.0%",
            },
            cpc: {
              value: "$1.11",
              percentage: "-2.5%",
            },
            orders: {
              value: "603",
              percentage: "-1.4%",
            },
          },
        ],
      },
      {
        key: "2",
        campaign: "Nyl - SP - B0B2HN33KQ",
        impressions: {
          value: "374,910",
          percentage: "-2.2%",
        },
        topOfSearchIs: {
          value: "<5%",
          percentage: "0.0%",
        },
        clicks: {
          value: "3,481",
          percentage: "+1.6%",
        },
        ctr: {
          value: "0.77%",
          percentage: "+3.2%",
        },
        spend: {
          value: "$5,911.57",
          percentage: "++0.8%",
        },
        cpc: {
          value: "$1.11",
          percentage: "+8.2%",
        },
        orders: {
          value: "1,270",
          percentage: "++0.8%",
        },

        children: [
          {
            key: "2-1",
            campaign: "KEYWORD #001",
            impressions: {
              value: "154,697",
              percentage: "-1.4%",
            },
            topOfSearchIs: {
              value: "<5%",
              percentage: "0.0%",
            },
            clicks: {
              value: "555",
              percentage: "-+0.8%",
            },
            ctr: {
              value: "0.70%",
              percentage: "+2.8%",
            },
            spend: {
              value: "$2,547.11",
              percentage: "+0.6%",
            },
            cpc: {
              value: "$1.14",
              percentage: "+7.9%",
            },
            orders: {
              value: "456",
              percentage: "-1.4%",
            },
          },
          {
            key: "2-2",
            campaign: "KEYWORD #002",
            impressions: {
              value: "101,222",
              percentage: "+0.2%",
            },
            topOfSearchIs: {
              value: "<5%",
              percentage: "0.0%",
            },
            clicks: {
              value: "1711",
              percentage: "+2.7%",
            },
            ctr: {
              value: "0.80%",
              percentage: "+3.0%",
            },
            spend: {
              value: "$1,565.16",
              percentage: "+0.0%",
            },
            cpc: {
              value: "$1.08",
              percentage: "+10.8%",
            },
            orders: {
              value: "211",
              percentage: "+0.4%",
            },
          },
          {
            key: "2-3",
            campaign: "KEYWORD #003",
            impressions: {
              value: "118,991",
              percentage: "-4.2%",
            },
            topOfSearchIs: {
              value: "<5%",
              percentage: "0.0%",
            },
            clicks: {
              value: "1215",
              percentage: "+2.0%",
            },
            ctr: {
              value: "0.79%",
              percentage: "-3.6%",
            },
            spend: {
              value: "$1,800.16",
              percentage: "+1.0%",
            },
            cpc: {
              value: "$1.11",
              percentage: "-2.5%",
            },
            orders: {
              value: "603",
              percentage: "-1.4%",
            },
          },
        ],
      },
      {
        key: "3",
        campaign: "Nyl - SP - B0B5HN65KO",
        impressions: {
          value: "266,363",
          percentage: "+7.1%",
        },
        topOfSearchIs: {
          value: "<5%",
          percentage: "0.0%",
        },
        clicks: {
          value: "859",
          percentage: "-+0.8%",
        },
        ctr: {
          value: "0.08%",
          percentage: "-7.7%",
        },
        spend: {
          value: "$5,911.57",
          percentage: "-7.1%",
        },
        cpc: {
          value: "$1.11",
          percentage: "+3.2%",
        },
        orders: {
          value: "1,270",
          percentage: "+9.9%",
        },

        children: [
          {
            key: "3-1",
            campaign: "KEYWORD #001",
            impressions: {
              value: "154,697",
              percentage: "-1.4%",
            },
            topOfSearchIs: {
              value: "<5%",
              percentage: "0.0%",
            },
            clicks: {
              value: "555",
              percentage: "-+0.8%",
            },
            ctr: {
              value: "0.70%",
              percentage: "+2.8%",
            },
            spend: {
              value: "$2,547.11",
              percentage: "+0.6%",
            },
            cpc: {
              value: "$1.14",
              percentage: "+7.9%",
            },
            orders: {
              value: "456",
              percentage: "-1.4%",
            },
          },
          {
            key: "3-2",
            campaign: "KEYWORD #002",
            impressions: {
              value: "101,222",
              percentage: "+0.2%",
            },
            topOfSearchIs: {
              value: "<5%",
              percentage: "0.0%",
            },
            clicks: {
              value: "1711",
              percentage: "+2.7%",
            },
            ctr: {
              value: "0.80%",
              percentage: "+3.0%",
            },
            spend: {
              value: "$1,565.16",
              percentage: "+0.0%",
            },
            cpc: {
              value: "$1.08",
              percentage: "+10.8%",
            },
            orders: {
              value: "211",
              percentage: "+0.4%",
            },
          },
          {
            key: "3-3",
            campaign: "KEYWORD #003",
            impressions: {
              value: "118,991",
              percentage: "-4.2%",
            },
            topOfSearchIs: {
              value: "<5%",
              percentage: "0.0%",
            },
            clicks: {
              value: "1215",
              percentage: "+2.0%",
            },
            ctr: {
              value: "0.79%",
              percentage: "-3.6%",
            },
            spend: {
              value: "$1,800.16",
              percentage: "+1.0%",
            },
            cpc: {
              value: "$1.11",
              percentage: "-2.5%",
            },
            orders: {
              value: "603",
              percentage: "-1.4%",
            },
          },
        ],
      },
    ],
  },
  {
    key: "0-2",
    campaign: "COMPETITOR",
    impressions: {
      value: "374,910",
      percentage: "-2.2%",
    },
    topOfSearchIs: {
      value: "<5%",
      percentage: "0.0%",
    },
    clicks: {
      value: "3,481",
      percentage: "+1.6%",
    },
    ctr: {
      value: "0.77%",
      percentage: "+3.2%",
    },
    spend: {
      value: "$5,911.57",
      percentage: "++0.8%",
    },
    cpc: {
      value: "$1.11",
      percentage: "+8.2%",
    },
    orders: {
      value: "1,270",
      percentage: "++0.8%",
    },

    children: [
      {
        key: "1",
        campaign: "Nyl - SP - B0B5HN65QQ",
        impressions: {
          value: "456,505",
          percentage: "-4.7%",
        },
        topOfSearchIs: {
          value: "<5%",
          percentage: "0.0%",
        },
        clicks: {
          value: "373",
          percentage: "+3.4%",
        },
        ctr: {
          value: "0.08%",
          percentage: "-+0.8%",
        },
        spend: {
          value: "$5,911.57",
          percentage: "+3.2%",
        },
        cpc: {
          value: "$1.11",
          percentage: "+3.2%",
        },
        orders: {
          value: "1,270",
          percentage: "+1.6%",
        },

        children: [
          {
            key: "1-1",
            campaign: "KEYWORD #001",
            impressions: {
              value: "154,697",
              percentage: "-1.4%",
            },
            topOfSearchIs: {
              value: "<5%",
              percentage: "0.0%",
            },
            clicks: {
              value: "555",
              percentage: "-+0.8%",
            },
            ctr: {
              value: "0.70%",
              percentage: "+2.8%",
            },
            spend: {
              value: "$2,547.11",
              percentage: "+0.6%",
            },
            cpc: {
              value: "$1.14",
              percentage: "+7.9%",
            },
            orders: {
              value: "456",
              percentage: "-1.4%",
            },
          },
          {
            key: "1-2",
            campaign: "KEYWORD #002",
            impressions: {
              value: "101,222",
              percentage: "+0.2%",
            },
            topOfSearchIs: {
              value: "<5%",
              percentage: "0.0%",
            },
            clicks: {
              value: "1711",
              percentage: "+2.7%",
            },
            ctr: {
              value: "0.80%",
              percentage: "+3.0%",
            },
            spend: {
              value: "$1,565.16",
              percentage: "+0.0%",
            },
            cpc: {
              value: "$1.08",
              percentage: "+10.8%",
            },
            orders: {
              value: "211",
              percentage: "+0.4%",
            },
          },
          {
            key: "1-3",
            campaign: "KEYWORD #003",
            impressions: {
              value: "118,991",
              percentage: "-4.2%",
            },
            topOfSearchIs: {
              value: "<5%",
              percentage: "0.0%",
            },
            clicks: {
              value: "1215",
              percentage: "+2.0%",
            },
            ctr: {
              value: "0.79%",
              percentage: "-3.6%",
            },
            spend: {
              value: "$1,800.16",
              percentage: "+1.0%",
            },
            cpc: {
              value: "$1.11",
              percentage: "-2.5%",
            },
            orders: {
              value: "603",
              percentage: "-1.4%",
            },
          },
        ],
      },
      {
        key: "2",
        campaign: "Nyl - SP - B0B2HN33KQ",
        impressions: {
          value: "374,910",
          percentage: "-2.2%",
        },
        topOfSearchIs: {
          value: "<5%",
          percentage: "0.0%",
        },
        clicks: {
          value: "3,481",
          percentage: "+1.6%",
        },
        ctr: {
          value: "0.77%",
          percentage: "+3.2%",
        },
        spend: {
          value: "$5,911.57",
          percentage: "++0.8%",
        },
        cpc: {
          value: "$1.11",
          percentage: "+8.2%",
        },
        orders: {
          value: "1,270",
          percentage: "++0.8%",
        },

        children: [
          {
            key: "2-1",
            campaign: "KEYWORD #001",
            impressions: {
              value: "154,697",
              percentage: "-1.4%",
            },
            topOfSearchIs: {
              value: "<5%",
              percentage: "0.0%",
            },
            clicks: {
              value: "555",
              percentage: "-+0.8%",
            },
            ctr: {
              value: "0.70%",
              percentage: "+2.8%",
            },
            spend: {
              value: "$2,547.11",
              percentage: "+0.6%",
            },
            cpc: {
              value: "$1.14",
              percentage: "+7.9%",
            },
            orders: {
              value: "456",
              percentage: "-1.4%",
            },
          },
          {
            key: "2-2",
            campaign: "KEYWORD #002",
            impressions: {
              value: "101,222",
              percentage: "+0.2%",
            },
            topOfSearchIs: {
              value: "<5%",
              percentage: "0.0%",
            },
            clicks: {
              value: "1711",
              percentage: "+2.7%",
            },
            ctr: {
              value: "0.80%",
              percentage: "+3.0%",
            },
            spend: {
              value: "$1,565.16",
              percentage: "+0.0%",
            },
            cpc: {
              value: "$1.08",
              percentage: "+10.8%",
            },
            orders: {
              value: "211",
              percentage: "+0.4%",
            },
          },
          {
            key: "2-3",
            campaign: "KEYWORD #003",
            impressions: {
              value: "118,991",
              percentage: "-4.2%",
            },
            topOfSearchIs: {
              value: "<5%",
              percentage: "0.0%",
            },
            clicks: {
              value: "1215",
              percentage: "+2.0%",
            },
            ctr: {
              value: "0.79%",
              percentage: "-3.6%",
            },
            spend: {
              value: "$1,800.16",
              percentage: "+1.0%",
            },
            cpc: {
              value: "$1.11",
              percentage: "-2.5%",
            },
            orders: {
              value: "603",
              percentage: "-1.4%",
            },
          },
        ],
      },
      {
        key: "3",
        campaign: "Nyl - SP - B0B5HN65KO",
        impressions: {
          value: "266,363",
          percentage: "+7.1%",
        },
        topOfSearchIs: {
          value: "<5%",
          percentage: "0.0%",
        },
        clicks: {
          value: "859",
          percentage: "-+0.8%",
        },
        ctr: {
          value: "0.08%",
          percentage: "-7.7%",
        },
        spend: {
          value: "$5,911.57",
          percentage: "-7.1%",
        },
        cpc: {
          value: "$1.11",
          percentage: "+3.2%",
        },
        orders: {
          value: "1,270",
          percentage: "+9.9%",
        },

        children: [
          {
            key: "3-1",
            campaign: "KEYWORD #001",
            impressions: {
              value: "154,697",
              percentage: "-1.4%",
            },
            topOfSearchIs: {
              value: "<5%",
              percentage: "0.0%",
            },
            clicks: {
              value: "555",
              percentage: "-+0.8%",
            },
            ctr: {
              value: "0.70%",
              percentage: "+2.8%",
            },
            spend: {
              value: "$2,547.11",
              percentage: "+0.6%",
            },
            cpc: {
              value: "$1.14",
              percentage: "+7.9%",
            },
            orders: {
              value: "456",
              percentage: "-1.4%",
            },
          },
          {
            key: "3-2",
            campaign: "KEYWORD #002",
            impressions: {
              value: "101,222",
              percentage: "+0.2%",
            },
            topOfSearchIs: {
              value: "<5%",
              percentage: "0.0%",
            },
            clicks: {
              value: "1711",
              percentage: "+2.7%",
            },
            ctr: {
              value: "0.80%",
              percentage: "+3.0%",
            },
            spend: {
              value: "$1,565.16",
              percentage: "+0.0%",
            },
            cpc: {
              value: "$1.08",
              percentage: "+10.8%",
            },
            orders: {
              value: "211",
              percentage: "+0.4%",
            },
          },
          {
            key: "3-3",
            campaign: "KEYWORD #003",
            impressions: {
              value: "118,991",
              percentage: "-4.2%",
            },
            topOfSearchIs: {
              value: "<5%",
              percentage: "0.0%",
            },
            clicks: {
              value: "1215",
              percentage: "+2.0%",
            },
            ctr: {
              value: "0.79%",
              percentage: "-3.6%",
            },
            spend: {
              value: "$1,800.16",
              percentage: "+1.0%",
            },
            cpc: {
              value: "$1.11",
              percentage: "-2.5%",
            },
            orders: {
              value: "603",
              percentage: "-1.4%",
            },
          },
        ],
      },
    ],
  },
  {
    key: "0-3",
    campaign: "GENERIC",
    impressions: {
      value: "266,363",
      percentage: "+7.1%",
    },
    topOfSearchIs: {
      value: "<5%",
      percentage: "0.0%",
    },
    clicks: {
      value: "859",
      percentage: "-+0.8%",
    },
    ctr: {
      value: "0.08%",
      percentage: "-7.7%",
    },
    spend: {
      value: "$5,911.57",
      percentage: "-7.1%",
    },
    cpc: {
      value: "$1.11",
      percentage: "+3.2%",
    },
    orders: {
      value: "1,270",
      percentage: "+9.9%",
    },

    children: [
      {
        key: "1",
        campaign: "Nyl - SP - B0B5HN65QQ",
        impressions: {
          value: "456,505",
          percentage: "-4.7%",
        },
        topOfSearchIs: {
          value: "<5%",
          percentage: "0.0%",
        },
        clicks: {
          value: "373",
          percentage: "+3.4%",
        },
        ctr: {
          value: "0.08%",
          percentage: "-+0.8%",
        },
        spend: {
          value: "$5,911.57",
          percentage: "+3.2%",
        },
        cpc: {
          value: "$1.11",
          percentage: "+3.2%",
        },
        orders: {
          value: "1,270",
          percentage: "+1.6%",
        },

        children: [
          {
            key: "1-1",
            campaign: "KEYWORD #001",
            impressions: {
              value: "154,697",
              percentage: "-1.4%",
            },
            topOfSearchIs: {
              value: "<5%",
              percentage: "0.0%",
            },
            clicks: {
              value: "555",
              percentage: "-+0.8%",
            },
            ctr: {
              value: "0.70%",
              percentage: "+2.8%",
            },
            spend: {
              value: "$2,547.11",
              percentage: "+0.6%",
            },
            cpc: {
              value: "$1.14",
              percentage: "+7.9%",
            },
            orders: {
              value: "456",
              percentage: "-1.4%",
            },
          },
          {
            key: "1-2",
            campaign: "KEYWORD #002",
            impressions: {
              value: "101,222",
              percentage: "+0.2%",
            },
            topOfSearchIs: {
              value: "<5%",
              percentage: "0.0%",
            },
            clicks: {
              value: "1711",
              percentage: "+2.7%",
            },
            ctr: {
              value: "0.80%",
              percentage: "+3.0%",
            },
            spend: {
              value: "$1,565.16",
              percentage: "+0.0%",
            },
            cpc: {
              value: "$1.08",
              percentage: "+10.8%",
            },
            orders: {
              value: "211",
              percentage: "+0.4%",
            },
          },
          {
            key: "1-3",
            campaign: "KEYWORD #003",
            impressions: {
              value: "118,991",
              percentage: "-4.2%",
            },
            topOfSearchIs: {
              value: "<5%",
              percentage: "0.0%",
            },
            clicks: {
              value: "1215",
              percentage: "+2.0%",
            },
            ctr: {
              value: "0.79%",
              percentage: "-3.6%",
            },
            spend: {
              value: "$1,800.16",
              percentage: "+1.0%",
            },
            cpc: {
              value: "$1.11",
              percentage: "-2.5%",
            },
            orders: {
              value: "603",
              percentage: "-1.4%",
            },
          },
        ],
      },
      {
        key: "2",
        campaign: "Nyl - SP - B0B2HN33KQ",
        impressions: {
          value: "374,910",
          percentage: "-2.2%",
        },
        topOfSearchIs: {
          value: "<5%",
          percentage: "0.0%",
        },
        clicks: {
          value: "3,481",
          percentage: "+1.6%",
        },
        ctr: {
          value: "0.77%",
          percentage: "+3.2%",
        },
        spend: {
          value: "$5,911.57",
          percentage: "++0.8%",
        },
        cpc: {
          value: "$1.11",
          percentage: "+8.2%",
        },
        orders: {
          value: "1,270",
          percentage: "++0.8%",
        },

        children: [
          {
            key: "2-1",
            campaign: "KEYWORD #001",
            impressions: {
              value: "154,697",
              percentage: "-1.4%",
            },
            topOfSearchIs: {
              value: "<5%",
              percentage: "0.0%",
            },
            clicks: {
              value: "555",
              percentage: "-+0.8%",
            },
            ctr: {
              value: "0.70%",
              percentage: "+2.8%",
            },
            spend: {
              value: "$2,547.11",
              percentage: "+0.6%",
            },
            cpc: {
              value: "$1.14",
              percentage: "+7.9%",
            },
            orders: {
              value: "456",
              percentage: "-1.4%",
            },
          },
          {
            key: "2-2",
            campaign: "KEYWORD #002",
            impressions: {
              value: "101,222",
              percentage: "+0.2%",
            },
            topOfSearchIs: {
              value: "<5%",
              percentage: "0.0%",
            },
            clicks: {
              value: "1711",
              percentage: "+2.7%",
            },
            ctr: {
              value: "0.80%",
              percentage: "+3.0%",
            },
            spend: {
              value: "$1,565.16",
              percentage: "+0.0%",
            },
            cpc: {
              value: "$1.08",
              percentage: "+10.8%",
            },
            orders: {
              value: "211",
              percentage: "+0.4%",
            },
          },
          {
            key: "2-3",
            campaign: "KEYWORD #003",
            impressions: {
              value: "118,991",
              percentage: "-4.2%",
            },
            topOfSearchIs: {
              value: "<5%",
              percentage: "0.0%",
            },
            clicks: {
              value: "1215",
              percentage: "+2.0%",
            },
            ctr: {
              value: "0.79%",
              percentage: "-3.6%",
            },
            spend: {
              value: "$1,800.16",
              percentage: "+1.0%",
            },
            cpc: {
              value: "$1.11",
              percentage: "-2.5%",
            },
            orders: {
              value: "603",
              percentage: "-1.4%",
            },
          },
        ],
      },
      {
        key: "3",
        campaign: "Nyl - SP - B0B5HN65KO",
        impressions: {
          value: "266,363",
          percentage: "+7.1%",
        },
        topOfSearchIs: {
          value: "<5%",
          percentage: "0.0%",
        },
        clicks: {
          value: "859",
          percentage: "-+0.8%",
        },
        ctr: {
          value: "0.08%",
          percentage: "-7.7%",
        },
        spend: {
          value: "$5,911.57",
          percentage: "-7.1%",
        },
        cpc: {
          value: "$1.11",
          percentage: "+3.2%",
        },
        orders: {
          value: "1,270",
          percentage: "+9.9%",
        },

        children: [
          {
            key: "3-1",
            campaign: "KEYWORD #001",
            impressions: {
              value: "154,697",
              percentage: "-1.4%",
            },
            topOfSearchIs: {
              value: "<5%",
              percentage: "0.0%",
            },
            clicks: {
              value: "555",
              percentage: "-+0.8%",
            },
            ctr: {
              value: "0.70%",
              percentage: "+2.8%",
            },
            spend: {
              value: "$2,547.11",
              percentage: "+0.6%",
            },
            cpc: {
              value: "$1.14",
              percentage: "+7.9%",
            },
            orders: {
              value: "456",
              percentage: "-1.4%",
            },
          },
          {
            key: "3-2",
            campaign: "KEYWORD #002",
            impressions: {
              value: "101,222",
              percentage: "+0.2%",
            },
            topOfSearchIs: {
              value: "<5%",
              percentage: "0.0%",
            },
            clicks: {
              value: "1711",
              percentage: "+2.7%",
            },
            ctr: {
              value: "0.80%",
              percentage: "+3.0%",
            },
            spend: {
              value: "$1,565.16",
              percentage: "+0.0%",
            },
            cpc: {
              value: "$1.08",
              percentage: "+10.8%",
            },
            orders: {
              value: "211",
              percentage: "+0.4%",
            },
          },
          {
            key: "3-3",
            campaign: "KEYWORD #003",
            impressions: {
              value: "118,991",
              percentage: "-4.2%",
            },
            topOfSearchIs: {
              value: "<5%",
              percentage: "0.0%",
            },
            clicks: {
              value: "1215",
              percentage: "+2.0%",
            },
            ctr: {
              value: "0.79%",
              percentage: "-3.6%",
            },
            spend: {
              value: "$1,800.16",
              percentage: "+1.0%",
            },
            cpc: {
              value: "$1.11",
              percentage: "-2.5%",
            },
            orders: {
              value: "603",
              percentage: "-1.4%",
            },
          },
        ],
      },
    ],
  },
  {
    key: "0-4",
    campaign: "Total 3 Ad Types",
    impressions: {
      value: "1,371,797",
      percentage: "+3.7%",
    },
    topOfSearchIs: {
      value: "<5%",
      percentage: "0.0%",
    },
    clicks: {
      value: "16,012",
      percentage: "+5.3%",
    },
    ctr: {
      value: "0.82%",
      percentage: "-1.2%",
    },
    spend: {
      value: "$41,381,80",
      percentage: "-8.2%",
    },
    cpc: {
      value: "$1.11",
      percentage: "-+0.8%",
    },
    orders: {
      value: "8,891",
      percentage: "-3.1%",
    },
  },
];

export const targetingData = [
  {
    key: "1",
    campaign: "asin = “B0B5HN65QQ”",
    impressions: {
      value: "456,505",
      percentage: "-4.7%",
    },
    topOfSearchIs: {
      value: "<5%",
      percentage: "0.0%",
    },
    clicks: {
      value: "373",
      percentage: "+3.4%",
    },
    ctr: {
      value: "0.08%",
      percentage: "-+0.8%",
    },
    spend: {
      value: "$5,911.57",
      percentage: "+3.2%",
    },
    cpc: {
      value: "$1.11",
      percentage: "+3.2%",
    },
    orders: {
      value: "1,270",
      percentage: "+1.6%",
    },

    children: [
      {
        key: "1-1",
        campaign: "Campaign Name 1",
        impressions: {
          value: "154,697",
          percentage: "-1.4%",
        },
        topOfSearchIs: {
          value: "<5%",
          percentage: "0.0%",
        },
        clicks: {
          value: "555",
          percentage: "-+0.8%",
        },
        ctr: {
          value: "0.70%",
          percentage: "+2.8%",
        },
        spend: {
          value: "$2,547.11",
          percentage: "+0.6%",
        },
        cpc: {
          value: "$1.14",
          percentage: "+7.9%",
        },
        orders: {
          value: "456",
          percentage: "-1.4%",
        },
      },
      {
        key: "1-2",
        campaign: "Campaign Name 2",
        impressions: {
          value: "101,222",
          percentage: "+0.2%",
        },
        topOfSearchIs: {
          value: "<5%",
          percentage: "0.0%",
        },
        clicks: {
          value: "1711",
          percentage: "+2.7%",
        },
        ctr: {
          value: "0.80%",
          percentage: "+3.0%",
        },
        spend: {
          value: "$1,565.16",
          percentage: "0.0%",
        },
        cpc: {
          value: "$1.08",
          percentage: "+10.8%",
        },
        orders: {
          value: "211",
          percentage: "+0.4%",
        },
      },
      {
        key: "1-3",
        campaign: "Campaign Name 3",
        impressions: {
          value: "118,991",
          percentage: "-4.2%",
        },
        topOfSearchIs: {
          value: "<5%",
          percentage: "0.0%",
        },
        clicks: {
          value: "1215",
          percentage: "+2.0%",
        },
        ctr: {
          value: "0.79%",
          percentage: "-3.6%",
        },
        spend: {
          value: "$1,800.16",
          percentage: "+1.0%",
        },
        cpc: {
          value: "$1.11",
          percentage: "-2.5%",
        },
        orders: {
          value: "603",
          percentage: "-1.4%",
        },
      },
    ],
  },
  {
    key: "2",
    campaign: "asin= “B0B2HN33KQ”",
    impressions: {
      value: "374,910",
      percentage: "-2.2%",
    },
    topOfSearchIs: {
      value: "<5%",
      percentage: "0.0%",
    },
    clicks: {
      value: "3,481",
      percentage: "+1.6%",
    },
    ctr: {
      value: "0.77%",
      percentage: "+3.2%",
    },
    spend: {
      value: "$5,911.57",
    },
    cpc: {
      value: "$1.11",
      percentage: "+8.2%",
    },
    orders: {
      value: "1,270",
      percentage: "++0.8%",
    },

    children: [
      {
        key: "2-1",
        campaign: "Campaign Name 1",
        impressions: {
          value: "154,697",
          percentage: "-1.4%",
        },
        topOfSearchIs: {
          value: "<5%",
          percentage: "0.0%",
        },
        clicks: {
          value: "555",
          percentage: "-+0.8%",
        },
        ctr: {
          value: "0.70%",
          percentage: "+2.8%",
        },
        spend: {
          value: "$2,547.11",
          percentage: "+0.6%",
        },
        cpc: {
          value: "$1.14",
          percentage: "+7.9%",
        },
        orders: {
          value: "456",
          percentage: "-1.4%",
        },
      },
      {
        key: "2-2",
        campaign: "Campaign Name 2",
        impressions: {
          value: "101,222",
          percentage: "+0.2%",
        },
        topOfSearchIs: {
          value: "<5%",
          percentage: "0.0%",
        },
        clicks: {
          value: "1711",
          percentage: "+2.7%",
        },
        ctr: {
          value: "0.80%",
          percentage: "+3.0%",
        },
        spend: {
          value: "$1,565.16",
          percentage: "0.0%",
        },
        cpc: {
          value: "$1.08",
          percentage: "+10.8%",
        },
        orders: {
          value: "211",
          percentage: "+0.4%",
        },
      },
      {
        key: "2-3",
        campaign: "Campaign Name 3",
        impressions: {
          value: "118,991",
          percentage: "-4.2%",
        },
        topOfSearchIs: {
          value: "<5%",
          percentage: "0.0%",
        },
        clicks: {
          value: "1215",
          percentage: "+2.0%",
        },
        ctr: {
          value: "0.79%",
          percentage: "-3.6%",
        },
        spend: {
          value: "$1,800.16",
          percentage: "+1.0%",
        },
        cpc: {
          value: "$1.11",
          percentage: "-2.5%",
        },
        orders: {
          value: "603",
          percentage: "-1.4%",
        },
      },
    ],
  },
  {
    key: "3",
    campaign: "theeth whiting strips",
    impressions: {
      value: "266,363",
      percentage: "+7.1%",
    },
    topOfSearchIs: {
      value: "<5%",
      percentage: "0.0%",
    },
    clicks: {
      value: "859",
      percentage: "-+0.8%",
    },
    ctr: {
      value: "0.08%",
      percentage: "-7.7%",
    },
    spend: {
      value: "$5,911.57",
      percentage: "-7.1%",
    },
    cpc: {
      value: "$1.11",
      percentage: "+3.2%",
    },
    orders: {
      value: "1,270",
      percentage: "+9.9%",
    },

    children: [
      {
        key: "3-1",
        campaign: "Campaign Name 1",
        impressions: {
          value: "154,697",
          percentage: "-1.4%",
        },
        topOfSearchIs: {
          value: "<5%",
          percentage: "0.0%",
        },
        clicks: {
          value: "555",
          percentage: "-+0.8%",
        },
        ctr: {
          value: "0.70%",
          percentage: "+2.8%",
        },
        spend: {
          value: "$2,547.11",
          percentage: "+0.6%",
        },
        cpc: {
          value: "$1.14",
          percentage: "+7.9%",
        },
        orders: {
          value: "456",
          percentage: "-1.4%",
        },
      },
      {
        key: "3-2",
        campaign: "Campaign Name 2",
        impressions: {
          value: "101,222",
          percentage: "+0.2%",
        },
        topOfSearchIs: {
          value: "<5%",
          percentage: "0.0%",
        },
        clicks: {
          value: "1711",
          percentage: "+2.7%",
        },
        ctr: {
          value: "0.80%",
          percentage: "+3.0%",
        },
        spend: {
          value: "$1,565.16",
          percentage: "0.0%",
        },
        cpc: {
          value: "$1.08",
          percentage: "+10.8%",
        },
        orders: {
          value: "211",
          percentage: "+0.4%",
        },
      },
      {
        key: "3-3",
        campaign: "Campaign Name 3",
        impressions: {
          value: "118,991",
          percentage: "-4.2%",
        },
        topOfSearchIs: {
          value: "<5%",
          percentage: "0.0%",
        },
        clicks: {
          value: "1215",
          percentage: "+2.0%",
        },
        ctr: {
          value: "0.79%",
          percentage: "-3.6%",
        },
        spend: {
          value: "$1,800.16",
          percentage: "+1.0%",
        },
        cpc: {
          value: "$1.11",
          percentage: "-2.5%",
        },
        orders: {
          value: "603",
          percentage: "-1.4%",
        },
      },
    ],
  },
  {
    key: "4",
    campaign: "category = ”Theeth whiting strips”",
    impressions: {
      value: "163,763",
      percentage: "+3.3%",
    },
    topOfSearchIs: {
      value: "<5%",
      percentage: "0.0%",
    },
    clicks: {
      value: "371",
      percentage: "+9.9%",
    },
    ctr: {
      value: "3.23%",
      percentage: "+3.2%",
    },
    spend: {
      value: "$5,911.57",
      percentage: "-8.2%",
    },
    cpc: {
      value: "$1.11",
      percentage: "-+0.8%",
    },
    orders: {
      value: "1,270",
      percentage: "-2.2%",
    },

    children: [
      {
        key: "4-1",
        campaign: "Campaign Name 1",
        impressions: {
          value: "154,697",
          percentage: "-1.4%",
        },
        topOfSearchIs: {
          value: "<5%",
          percentage: "0.0%",
        },
        clicks: {
          value: "555",
          percentage: "-+0.8%",
        },
        ctr: {
          value: "0.70%",
          percentage: "+2.8%",
        },
        spend: {
          value: "$2,547.11",
          percentage: "+0.6%",
        },
        cpc: {
          value: "$1.14",
          percentage: "+7.9%",
        },
        orders: {
          value: "456",
          percentage: "-1.4%",
        },
      },
      {
        key: "4-2",
        campaign: "Campaign Name 2",
        impressions: {
          value: "101,222",
          percentage: "+0.2%",
        },
        topOfSearchIs: {
          value: "<5%",
          percentage: "0.0%",
        },
        clicks: {
          value: "1711",
          percentage: "+2.7%",
        },
        ctr: {
          value: "0.80%",
          percentage: "+3.0%",
        },
        spend: {
          value: "$1,565.16",
          percentage: "0.0%",
        },
        cpc: {
          value: "$1.08",
          percentage: "+10.8%",
        },
        orders: {
          value: "211",
          percentage: "+0.4%",
        },
      },
      {
        key: "4-3",
        campaign: "Campaign Name 3",
        impressions: {
          value: "118,991",
          percentage: "-4.2%",
        },
        topOfSearchIs: {
          value: "<5%",
          percentage: "0.0%",
        },
        clicks: {
          value: "1215",
          percentage: "+2.0%",
        },
        ctr: {
          value: "0.79%",
          percentage: "-3.6%",
        },
        spend: {
          value: "$1,800.16",
          percentage: "+1.0%",
        },
        cpc: {
          value: "$1.11",
          percentage: "-2.5%",
        },
        orders: {
          value: "603",
          percentage: "-1.4%",
        },
      },
    ],
  },
  {
    key: "5",
    campaign: "Whiting Strip",
    impressions: {
      value: "91,256",
      percentage: "+8.2%",
    },
    topOfSearchIs: {
      value: "<5%",
      percentage: "0.0%",
    },
    clicks: {
      value: "1,889",
      percentage: "-5.1%",
    },
    ctr: {
      value: "0.08%",
      percentage: "+9.9%",
    },
    spend: {
      value: "$5,911.57",
      percentage: "-3.2%",
    },
    cpc: {
      value: "$1.11",
      percentage: "+9.9%",
    },
    orders: {
      value: "1,270",
      percentage: "+3.2%",
    },

    children: [
      {
        key: "5-1",
        campaign: "Campaign Name 1",
        impressions: {
          value: "154,697",
          percentage: "-1.4%",
        },
        topOfSearchIs: {
          value: "<5%",
          percentage: "0.0%",
        },
        clicks: {
          value: "555",
          percentage: "-+0.8%",
        },
        ctr: {
          value: "0.70%",
          percentage: "+2.8%",
        },
        spend: {
          value: "$2,547.11",
          percentage: "+0.6%",
        },
        cpc: {
          value: "$1.14",
          percentage: "+7.9%",
        },
        orders: {
          value: "456",
          percentage: "-1.4%",
        },
      },
      {
        key: "5-2",
        campaign: "Campaign Name 2",
        impressions: {
          value: "101,222",
          percentage: "+0.2%",
        },
        topOfSearchIs: {
          value: "<5%",
          percentage: "0.0%",
        },
        clicks: {
          value: "1711",
          percentage: "+2.7%",
        },
        ctr: {
          value: "0.80%",
          percentage: "+3.0%",
        },
        spend: {
          value: "$1,565.16",
          percentage: "0.0%",
        },
        cpc: {
          value: "$1.08",
          percentage: "+10.8%",
        },
        orders: {
          value: "211",
          percentage: "+0.4%",
        },
      },
      {
        key: "5-3",
        campaign: "Campaign Name 3",
        impressions: {
          value: "118,991",
          percentage: "-4.2%",
        },
        topOfSearchIs: {
          value: "<5%",
          percentage: "0.0%",
        },
        clicks: {
          value: "1215",
          percentage: "+2.0%",
        },
        ctr: {
          value: "0.79%",
          percentage: "-3.6%",
        },
        spend: {
          value: "$1,800.16",
          percentage: "+1.0%",
        },
        cpc: {
          value: "$1.11",
          percentage: "-2.5%",
        },
        orders: {
          value: "603",
          percentage: "-1.4%",
        },
      },
    ],
  },
  {
    key: "6",
    campaign: "asin= “B0B5II01OQ”",
    impressions: {
      value: "17,154",
      percentage: "-5.1%",
    },
    topOfSearchIs: {
      value: "<5%",
      percentage: "0.0%",
    },
    clicks: {
      value: "7,928",
      percentage: "+8.2%",
    },
    ctr: {
      value: "0.77%",
      percentage: "-7.7%",
    },
    spend: {
      value: "$5,911.57",
      percentage: "+0.8%",
    },
    cpc: {
      value: "$1.11",
      percentage: "+7.1%",
    },
    orders: {
      value: "1,270",
      percentage: "+3.2%",
    },

    children: [
      {
        key: "6-1",
        campaign: "Campaign Name 1",
        impressions: {
          value: "154,697",
          percentage: "-1.4%",
        },
        topOfSearchIs: {
          value: "<5%",
          percentage: "0.0%",
        },
        clicks: {
          value: "555",
          percentage: "-+0.8%",
        },
        ctr: {
          value: "0.70%",
          percentage: "+2.8%",
        },
        spend: {
          value: "$2,547.11",
          percentage: "+0.6%",
        },
        cpc: {
          value: "$1.14",
          percentage: "+7.9%",
        },
        orders: {
          value: "456",
          percentage: "-1.4%",
        },
      },
      {
        key: "6-2",
        campaign: "Campaign Name 2",
        impressions: {
          value: "101,222",
          percentage: "+0.2%",
        },
        topOfSearchIs: {
          value: "<5%",
          percentage: "0.0%",
        },
        clicks: {
          value: "1711",
          percentage: "+2.7%",
        },
        ctr: {
          value: "0.80%",
          percentage: "+3.0%",
        },
        spend: {
          value: "$1,565.16",
          percentage: "0.0%",
        },
        cpc: {
          value: "$1.08",
          percentage: "+10.8%",
        },
        orders: {
          value: "211",
          percentage: "+0.4%",
        },
      },
      {
        key: "6-3",
        campaign: "Campaign Name 3",
        impressions: {
          value: "118,991",
          percentage: "-4.2%",
        },
        topOfSearchIs: {
          value: "<5%",
          percentage: "0.0%",
        },
        clicks: {
          value: "1215",
          percentage: "+2.0%",
        },
        ctr: {
          value: "0.79%",
          percentage: "-3.6%",
        },
        spend: {
          value: "$1,800.16",
          percentage: "+1.0%",
        },
        cpc: {
          value: "$1.11",
          percentage: "-2.5%",
        },
        orders: {
          value: "603",
          percentage: "-1.4%",
        },
      },
    ],
  },
  {
    key: "7",
    campaign: "natural whiting strips",
    impressions: {
      value: "1,847",
      percentage: "+9.9%",
    },
    topOfSearchIs: {
      value: "<5%",
      percentage: "0.0%",
    },
    clicks: {
      value: "1,111",
      percentage: "+4.1%",
    },
    ctr: {
      value: "0.77%",
      percentage: "-+0.8%",
    },
    spend: {
      value: "$5,911.57",
      percentage: "-3.2%",
    },
    cpc: {
      value: "$1.11",
      percentage: "+3.2%",
    },
    orders: {
      value: "1,270",
      percentage: "+3.2%",
    },

    children: [
      {
        key: "7-1",
        campaign: "Campaign Name 1",
        impressions: {
          value: "154,697",
          percentage: "-1.4%",
        },
        topOfSearchIs: {
          value: "<5%",
          percentage: "0.0%",
        },
        clicks: {
          value: "555",
          percentage: "-+0.8%",
        },
        ctr: {
          value: "0.70%",
          percentage: "+2.8%",
        },
        spend: {
          value: "$2,547.11",
          percentage: "+0.6%",
        },
        cpc: {
          value: "$1.14",
          percentage: "+7.9%",
        },
        orders: {
          value: "456",
          percentage: "-1.4%",
        },
      },
      {
        key: "7-2",
        campaign: "Campaign Name 2",
        impressions: {
          value: "101,222",
          percentage: "+0.2%",
        },
        topOfSearchIs: {
          value: "<5%",
          percentage: "0.0%",
        },
        clicks: {
          value: "1711",
          percentage: "+2.7%",
        },
        ctr: {
          value: "0.80%",
          percentage: "+3.0%",
        },
        spend: {
          value: "$1,565.16",
          percentage: "0.0%",
        },
        cpc: {
          value: "$1.08",
          percentage: "+10.8%",
        },
        orders: {
          value: "211",
          percentage: "+0.4%",
        },
      },
      {
        key: "7-3",
        campaign: "Campaign Name 3",
        impressions: {
          value: "118,991",
          percentage: "-4.2%",
        },
        topOfSearchIs: {
          value: "<5%",
          percentage: "0.0%",
        },
        clicks: {
          value: "1215",
          percentage: "+2.0%",
        },
        ctr: {
          value: "0.79%",
          percentage: "-3.6%",
        },
        spend: {
          value: "$1,800.16",
          percentage: "+1.0%",
        },
        cpc: {
          value: "$1.11",
          percentage: "-2.5%",
        },
        orders: {
          value: "603",
          percentage: "-1.4%",
        },
      },
    ],
  },
  {
    key: "8",
    campaign: "Total 7 Targets",
    impressions: {
      value: "1,371,797",
      percentage: "+3.7%",
    },
    topOfSearchIs: {
      value: "<5%",
      percentage: "0.0%",
    },
    clicks: {
      value: "16,012",
      percentage: "+5.3%",
    },
    ctr: {
      value: "0.82%",
      percentage: "-1.2%",
    },
    spend: {
      value: "$41,381,80",
      percentage: "-8.2%",
    },
    cpc: {
      value: "$1.11",
      percentage: "-+0.8%",
    },
    orders: {
      value: "8,891",
      percentage: "-3.1%",
    },
  },
];
