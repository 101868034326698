export const columns = [
  {
    title: "Type",
    dataIndex: "type",
    key: "type",
    fixed: "left" as const,
    width: 264,
  },
  {
    title: "Search Volume SQ",
    dataIndex: "searchVolume",
    key: "searchVolume",
    width: 128,
  },
  {
    title: "Search Query",
    dataIndex: "searchQuery",
    key: "searchQuery",
    width: 220,
  },
  {
    title: "Total Organic Impressions",
    dataIndex: "organicImpressions",
    key: "organicImpressions",
    width: 128,
  },
  {
    title: "Total Organic Clicks",
    dataIndex: "organicClicks",
    key: "organicClicks",
    width: 128,
  },
  {
    title: "Total Organic Add to Cart",
    dataIndex: "addToCart",
    key: "addToCart",
    width: 128,
  },
  {
    title: "Total Organic Orders",
    dataIndex: "organicOrders",
    key: "organicUnitsSold",
    width: 128,
  },
  {
    title: "Total Organic Sales",
    dataIndex: "organicSales",
    key: "organicSales",
    width: 128,
  },
  {
    title: "Brand Organic Sales",
    dataIndex: "brandOrganicSales",
    key: "brandOrganicSales",
    width: 128,
  },
  {
    title: "Brand Organic Sales What If",
    dataIndex: "brandOrganicSalesWhatIf",
    key: "brandOrganicSalesWhatIf",
    width: 128,
  },
  {
    title: "Brand Ad Sales",
    dataIndex: "brandAdSales",
    key: "brandAdSales",
    width: 128,
  },
  {
    title: "Lost Sales Missing Target",
    dataIndex: "lostSalesMissingTarget",
    key: "lostSalesMissingTarget",
    width: 128,
  },
  {
    title: "Organic rank",
    dataIndex: "organicRank",
    key: "organicRank",
    width: 128,
  },
  {
    title: "Relevance score",
    dataIndex: "relevanceScore",
    key: "relevanceScore",
    width: 128,
  },
];

export const data = [
  {
    key: 1,
    type: "Missing target",
    searchVolume: "840098",
    searchQuery: "travel",
    organicImpressions: "18984173",
    organicClicks: "198444",
    addToCart: "78174",
    organicOrders: "19273",
    organicSales: "$153049",
    brandOrganicSales: "$665",
    brandOrganicSalesWhatIf: "$941",
    brandAdSales: "",
    lostSalesMissingTarget: "$854",
    organicRank: "",
    relevanceScore: "0%",
  },
  {
    key: 2,
    type: "Missing target",
    searchVolume: "467014",
    searchQuery: "computers and accessories",
    organicImpressions: "8346630",
    organicClicks: "80927",
    addToCart: "8229",
    organicOrders: "159",
    organicSales: "$11879",
    brandOrganicSales: "",
    brandOrganicSalesWhatIf: "",
    brandAdSales: "",
    lostSalesMissingTarget: "",
    organicRank: "",
    relevanceScore: "",
  },
  {
    key: 3,
    type: "Missing target",
    searchVolume: "403829",
    searchQuery: "garmin",
    organicImpressions: "7864255",
    organicClicks: "103130",
    addToCart: "8243",
    organicOrders: "1400",
    organicSales: "$267230",
    brandOrganicSales: "",
    brandOrganicSalesWhatIf: "",
    brandAdSales: "",
    lostSalesMissingTarget: "",
    organicRank: "",
    relevanceScore: "",
  },
  {
    key: 4,
    type: "Missing target",
    searchVolume: "393411",
    searchQuery: "generic",
    organicImpressions: "12596539",
    organicClicks: "41230",
    addToCart: "5200",
    organicOrders: "290",
    organicSales: "$2608",
    brandOrganicSales: "",
    brandOrganicSalesWhatIf: "",
    brandAdSales: "",
    lostSalesMissingTarget: "",
    organicRank: "",
    relevanceScore: "",
  },
  {
    key: 5,
    type: "Missing target",
    searchVolume: "345784",
    searchQuery: "google",
    organicImpressions: "5190393",
    organicClicks: "27083",
    addToCart: "3813",
    organicOrders: "2631",
    organicSales: "$0",
    brandOrganicSales: "",
    brandOrganicSalesWhatIf: "",
    brandAdSales: "",
    lostSalesMissingTarget: "",
    organicRank: "",
    relevanceScore: "",
  },
  {
    key: 6,
    type: "Missing target",
    searchVolume: "327006",
    searchQuery: "smart home",
    organicImpressions: "8167469",
    organicClicks: "65144",
    addToCart: "8655",
    organicOrders: "435",
    organicSales: "$8041",
    brandOrganicSales: "",
    brandOrganicSalesWhatIf: "",
    brandAdSales: "",
    lostSalesMissingTarget: "",
    organicRank: "",
    relevanceScore: "0%",
  },
  {
    key: 7,
    type: "Missing target",
    searchVolume: "305656",
    searchQuery: "generator",
    organicImpressions: "5933404",
    organicClicks: "89418",
    addToCart: "12915",
    organicOrders: "1557",
    organicSales: "$621227",
    brandOrganicSales: "",
    brandOrganicSalesWhatIf: "",
    brandAdSales: "",
    lostSalesMissingTarget: "",
    organicRank: "",
    relevanceScore: "",
  },
  {
    key: 8,
    type: "Missing target",
    searchVolume: "275386",
    searchQuery: "gaming",
    organicImpressions: "6950750",
    organicClicks: "58510",
    addToCart: "6849",
    organicOrders: "147",
    organicSales: "$3674",
    brandOrganicSales: "",
    brandOrganicSalesWhatIf: "",
    brandAdSales: "",
    lostSalesMissingTarget: "",
    organicRank: "",
    relevanceScore: "",
  },
  {
    key: 9,
    type: "Missing target",
    searchVolume: "258906",
    searchQuery: "flipper zero",
    organicImpressions: "4761006",
    organicClicks: "54194",
    addToCart: "9596",
    organicOrders: "1026",
    organicSales: "$22990",
    brandOrganicSales: "",
    brandOrganicSalesWhatIf: "",
    brandAdSales: "",
    lostSalesMissingTarget: "",
    organicRank: "",
    relevanceScore: "",
  },
  {
    key: 10,
    type: "Missing target",
    searchVolume: "197811",
    searchQuery: "eero",
    organicImpressions: "3973287",
    organicClicks: "94159",
    addToCart: "15384",
    organicOrders: "6912",
    organicSales: "$665211",
    brandOrganicSales: "",
    brandOrganicSalesWhatIf: "",
    brandAdSales: "",
    lostSalesMissingTarget: "",
    organicRank: "",
    relevanceScore: "16%",
  },
  {
    key: 11,
    type: "Missing target",
    searchVolume: "189833",
    searchQuery: "ryobi",
    organicImpressions: "4463620",
    organicClicks: "34132",
    addToCart: "7208",
    organicOrders: "791",
    organicSales: "$35385",
    brandOrganicSales: "",
    brandOrganicSalesWhatIf: "",
    brandAdSales: "",
    lostSalesMissingTarget: "",
    organicRank: "",
    relevanceScore: "",
  },
  {
    key: 12,
    type: "Missing target",
    searchVolume: "187052",
    searchQuery: "huawei",
    organicImpressions: "3570105",
    organicClicks: "42612",
    addToCart: "2715",
    organicOrders: "180",
    organicSales: "$35774",
    brandOrganicSales: "",
    brandOrganicSalesWhatIf: "",
    brandAdSales: "",
    lostSalesMissingTarget: "",
    organicRank: "",
    relevanceScore: "",
  },
  {
    key: 13,
    type: "Missing target",
    searchVolume: "167397",
    searchQuery: "dji osmo pocket 3",
    organicImpressions: "3284216",
    organicClicks: "72918",
    addToCart: "14937",
    organicOrders: "1388",
    organicSales: "$886932",
    brandOrganicSales: "",
    brandOrganicSalesWhatIf: "",
    brandAdSales: "",
    lostSalesMissingTarget: "",
    organicRank: "",
    relevanceScore: "",
  },
  {
    key: 14,
    type: "Missing target",
    searchVolume: "151237",
    searchQuery: "satellite phones",
    organicImpressions: "2936544",
    organicClicks: "59697",
    addToCart: "7757",
    organicOrders: "340",
    organicSales: "$102715",
    brandOrganicSales: "$285",
    brandOrganicSalesWhatIf: "$403",
    brandAdSales: "",
    lostSalesMissingTarget: "$366",
    organicRank: "",
    relevanceScore: "",
  },
  {
    key: 15,
    type: "Missing target",
    searchVolume: "139044",
    searchQuery: "starlink mini",
    organicImpressions: "2769285",
    organicClicks: "41883",
    addToCart: "7006",
    organicOrders: "1020",
    organicSales: "$50373",
    brandOrganicSales: "$120",
    brandOrganicSalesWhatIf: "$170",
    brandAdSales: "",
    lostSalesMissingTarget: "$154",
    organicRank: "",
    relevanceScore: "0%",
  },
  {
    key: 16,
    type: "Missing target",
    searchVolume: "112429",
    searchQuery: "pokemon booster box",
    organicImpressions: "2473684",
    organicClicks: "36758",
    addToCart: "8516",
    organicOrders: "1764",
    organicSales: "$73162",
    brandOrganicSales: "",
    brandOrganicSalesWhatIf: "",
    brandAdSales: "",
    lostSalesMissingTarget: "",
    organicRank: "",
    relevanceScore: "",
  },
  {
    key: 17,
    type: "Missing target",
    searchVolume: "111390",
    searchQuery: "wifi booster",
    organicImpressions: "2167350",
    organicClicks: "45093",
    addToCart: "15260",
    organicOrders: "7014",
    organicSales: "$165928",
    brandOrganicSales: "",
    brandOrganicSalesWhatIf: "",
    brandAdSales: "",
    lostSalesMissingTarget: "",
    organicRank: "",
    relevanceScore: "8%",
  },
  {
    key: 18,
    type: "Missing target",
    searchVolume: "107889",
    searchQuery: "motorola",
    organicImpressions: "2089783",
    organicClicks: "30201",
    addToCart: "2737",
    organicOrders: "395",
    organicSales: "$57355",
    brandOrganicSales: "",
    brandOrganicSalesWhatIf: "",
    brandAdSales: "",
    lostSalesMissingTarget: "",
    organicRank: "",
    relevanceScore: "",
  },
  {
    key: 19,
    type: "Missing target",
    searchVolume: "99104",
    searchQuery: "samsung galaxy",
    organicImpressions: "1901275",
    organicClicks: "27260",
    addToCart: "3222",
    organicOrders: "543",
    organicSales: "$102776",
    brandOrganicSales: "",
    brandOrganicSalesWhatIf: "",
    brandAdSales: "",
    lostSalesMissingTarget: "",
    organicRank: "",
    relevanceScore: "",
  },
  {
    key: 20,
    type: "Missing target",
    searchVolume: "95139",
    searchQuery: "wifi 7 router",
    organicImpressions: "1908333",
    organicClicks: "38940",
    addToCart: "3958",
    organicOrders: "666",
    organicSales: "$150731",
    brandOrganicSales: "",
    brandOrganicSalesWhatIf: "",
    brandAdSales: "",
    lostSalesMissingTarget: "",
    organicRank: "",
    relevanceScore: "8%",
  },
  {
    key: 21,
    type: "Missing target",
    searchVolume: "95060",
    searchQuery: "wi",
    organicImpressions: "1886285",
    organicClicks: "69",
    addToCart: "0",
    organicOrders: "0",
    organicSales: "",
    brandOrganicSales: "",
    brandOrganicSalesWhatIf: "",
    brandAdSales: "",
    lostSalesMissingTarget: "",
    organicRank: "",
    relevanceScore: "",
  },
  {
    key: 22,
    type: "Missing target",
    searchVolume: "88126",
    searchQuery: "asus router",
    organicImpressions: "1763366",
    organicClicks: "37743",
    addToCart: "5720",
    organicOrders: "2036",
    organicSales: "$243748",
    brandOrganicSales: "",
    brandOrganicSalesWhatIf: "",
    brandAdSales: "",
    lostSalesMissingTarget: "",
    organicRank: "",
    relevanceScore: "8%",
  },
  {
    key: 23,
    type: "Missing target",
    searchVolume: "86047",
    searchQuery: "garmin gps",
    organicImpressions: "1725299",
    organicClicks: "30731",
    addToCart: "4800",
    organicOrders: "1426",
    organicSales: "$217648",
    brandOrganicSales: "",
    brandOrganicSalesWhatIf: "",
    brandAdSales: "",
    lostSalesMissingTarget: "",
    organicRank: "",
    relevanceScore: "",
  },
  {
    key: 24,
    type: "Missing target",
    searchVolume: "85335",
    searchQuery: "vpn",
    organicImpressions: "2124078",
    organicClicks: "5484",
    addToCart: "881",
    organicOrders: "514",
    organicSales: "$0",
    brandOrganicSales: "",
    brandOrganicSalesWhatIf: "",
    brandAdSales: "",
    lostSalesMissingTarget: "",
    organicRank: "",
    relevanceScore: "16%",
  },
];
