import React from "react";
import styles from "../SubscriptionReport/styles.module.scss";

interface CustomTooltipProps {
  active?: boolean;
  payload?: any[];
  label?: string;
}

const CustomTooltip: React.FC<CustomTooltipProps> = ({
  active,
  payload,
  label,
}) => {
  if (active && payload && payload.length) {
    const total = payload.reduce(
      (sum: number, entry: any) => sum + entry.value,
      0,
    );
    const colors = ["#1BB08D", "#50DAB9", "#A6FAE6"];

    return (
      <div className={styles.customTooltip}>
        <p className={styles.label}>{label}</p>
        {payload.map((entry: any, index: number) => (
          <div key={`item-${index}`} className={styles.tooltipItem}>
            <span
              className={styles.colorDot}
              style={{ backgroundColor: colors[index] }}
            ></span>
            <span className={styles.name}>{entry.name}:</span>
            <span className={styles.value}>{entry.value.toLocaleString()}</span>
          </div>
        ))}
        <div className={styles.total}>
          <span>Total</span>
          <span>{total.toLocaleString()}</span>
        </div>
      </div>
    );
  }
  return null;
};

export default CustomTooltip;
