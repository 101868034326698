import { useNavigate } from "react-router-dom";
import Logo from "@assets/img/logo";
import styles from "./styles.module.scss";
import AuthButtonArrow from "@assets/icons/authButtonArrow";
import { useIsMobile } from "src/utils/isMobile";

function ConfirmedEmail() {
  const navigate = useNavigate();
  const isMobile = useIsMobile();

  localStorage.removeItem("registeredEmail");

  const handleButtonClick = () => {
    navigate("/auth/login");
  };

  return (
    <>
      <div className={styles.logo}>
        <Logo width="67" height="30" />
      </div>
      <div className={styles.messageContainer}>
        <h1>
          {isMobile ? (
            <>
              Your email <br /> address has <br /> been{" "}
              <span className={styles.confirmed}>confirmed!</span>
            </>
          ) : (
            <>
              Your email <br /> address has been <br />{" "}
              <span className={styles.confirmed}>confirmed!</span>
            </>
          )}
        </h1>
        <button className={styles.button} onClick={handleButtonClick}>
          Go to your account <AuthButtonArrow />
        </button>
      </div>
    </>
  );
}

export default ConfirmedEmail;
