export const getDynamicRatings = (
  productOneValues: number[],
  productTwoValues: number[],
  activeIndex: number,
) => {
  const productOneRating = productOneValues?.[activeIndex];
  const productTwoRating = productTwoValues?.[activeIndex];
  const difference = (productOneRating - productTwoRating).toFixed(1);
  return { productOneRating, productTwoRating, difference };
};

export const getCombinedHeaderText = (
  productOneTitles: string[],
  productTwoTitles: string[],
  activeIndex: number,
): string | null => {
  const productOneTitleText = productOneTitles?.[activeIndex]?.trim();
  const productTwoTitleText = productTwoTitles?.[activeIndex]?.trim();

  if (!productOneTitleText && !productTwoTitleText) return null;

  if (productOneTitleText === productTwoTitleText) {
    return productOneTitleText || null;
  }
  return `${productOneTitleText || ""}${productOneTitleText && productTwoTitleText ? ", " : ""}${productTwoTitleText || ""}`;
};

export const getAsin = (selectedProduct: string | { asin?: string }) => {
  return typeof selectedProduct === "string"
    ? selectedProduct
    : selectedProduct?.asin || null;
};

export const extractAllAsins = (selectedProductToCompare: any[]) => {
  if (!Array.isArray(selectedProductToCompare)) return [];
  if (Array.isArray(selectedProductToCompare[0])) {
    return selectedProductToCompare.flatMap((innerArray) =>
      Array.isArray(innerArray)
        ? innerArray.map((product) => product.asin)
        : [],
    );
  }
  return selectedProductToCompare
    .map((product) => product.asin)
    .filter(Boolean);
};
