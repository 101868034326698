import React, { useState, useMemo } from "react";
import styles from "./styles.module.scss";
import { renderAnalyzeData } from "src/utils/renderAnalyzeData";
import { Spin } from "antd";

interface UpdateRecommendationsProps {
  loadingAnalyzeData: boolean;
  loadingRecommendationData: boolean;
  analyzeData?: any;
  recommendationData?: any;
  onToggleRecommendation?: () => void;
  id?: string;
  setFetchPhoto?: (bool: boolean) => void;
  onStartGeneration?: () => void;
}

const UpdateRecommendations: React.FC<UpdateRecommendationsProps> = ({
  loadingAnalyzeData,
  loadingRecommendationData,
  analyzeData,
  recommendationData,
  onToggleRecommendation,
  id,
  setFetchPhoto,
  onStartGeneration,
}) => {
  const [showRecommendations, setShowRecommendations] = useState(false);

  const handleToggleRecommendation = () => {
    setShowRecommendations(!showRecommendations);
    if (!showRecommendations) {
      onToggleRecommendation();
      if (setFetchPhoto) {
        setFetchPhoto(true);
      }
    }
  };

  const analysis = useMemo(() => {
    if (analyzeData) {
      return typeof analyzeData.recommendations === "string"
        ? analyzeData.recommendations
        : renderAnalyzeData(analyzeData, id);
    }
    return null;
  }, [analyzeData, id]);

  const suggestions = useMemo(() => {
    if (recommendationData) {
      return typeof analyzeData.recommendations === "string"
        ? recommendationData.recommendations
        : renderAnalyzeData(recommendationData, id);
    }
    return null;
  }, [recommendationData, id]);

  return (
    <div className={styles.options}>
      <div className={styles.block}>
        <h2>Analysis</h2>
        {loadingAnalyzeData === false && analyzeData ? (
          <p>{analysis ? analysis : "No analysis available."}</p>
        ) : (
          <Spin size="large" />
        )}
        <div className={styles.row}>
          {analyzeData && (
            <button
              className={styles.button}
              onClick={handleToggleRecommendation}
            >
              {showRecommendations ? "Hide" : "See Recommendation"}
            </button>
          )}
        </div>
      </div>
      {showRecommendations && (
        <>
          <div className={styles.block}>
            <h2>Recommendations</h2>
            <div className={styles.loadingContent}>
              {loadingRecommendationData === false &&
                recommendationData &&
                (recommendationData.recommendations ? (
                  <ul className={styles.recomendation}>
                    {suggestions?.map((recommendation, index) => (
                      <li key={index}>
                        <strong>Issue:</strong> {recommendation.issue}
                        <br />
                        <strong>Suggestion:</strong> {recommendation.suggestion}
                      </li>
                    ))}
                  </ul>
                ) : (
                  <p>No recommendations available.</p>
                ))}
            </div>
            {loadingRecommendationData && <Spin size="large" />}
          </div>
          {recommendationData && (
            <div className={styles.updateControls}>
              <button
                className={styles.applyButton}
                onClick={onStartGeneration}
              >
                image generation
              </button>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default UpdateRecommendations;
