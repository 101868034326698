export interface Stats {
  total: number;
  five_stars: number;
  four_stars: number;
  three_stars: number;
  two_stars: number;
  one_star: number;
}

export const calculateRatingPercentages = (stats: Stats | undefined) => {
  if (!stats) return [];

  const total =
    stats.five_stars +
    stats.four_stars +
    stats.three_stars +
    stats.two_stars +
    stats.one_star;

  if (total === 0) return [];

  return [
    {
      star: "5 STAR",
      percentage: ((stats.five_stars / total) * 100).toFixed(1),
      width: `${(stats.five_stars / total) * 100}%`,
    },
    {
      star: "4 STAR",
      percentage: ((stats.four_stars / total) * 100).toFixed(1),
      width: `${(stats.four_stars / total) * 100}%`,
    },
    {
      star: "3 STAR",
      percentage: ((stats.three_stars / total) * 100).toFixed(1),
      width: `${(stats.three_stars / total) * 100}%`,
    },
    {
      star: "2 STAR",
      percentage: ((stats.two_stars / total) * 100).toFixed(1),
      width: `${(stats.two_stars / total) * 100}%`,
    },
    {
      star: "1 STAR",
      percentage: ((stats.one_star / total) * 100).toFixed(1),
      width: `${(stats.one_star / total) * 100}%`,
    },
  ];
};

export const calculateStars = (
  reviews: number,
): ("full" | "half" | "empty")[] => {
  return [1, 2, 3, 4, 5].map((index) => {
    if (reviews >= index - 0.24) return "full";
    else if (reviews >= index - 0.74) return "half";
    else return "empty";
  });
};
