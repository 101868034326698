import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import InfoIcon from "@assets/icons/i.svg";
import { Button, Popover } from "antd";
import { SpiderService } from "@services/spider/spider.services";
import { useProductStore } from "src/store/overviewProduct.state";
import classNames from "classnames";
import {
  INFO_POPOVER_CONTENT,
  determineScoreColor,
  getTextColor,
  determineDifference,
  getDifferenceColorClass,
  getHighlightColorClass,
} from "./constants";
import { useIsMobile } from "src/utils/isMobile";

interface DynamicComparisonBlockProps {
  productScore: number;
  competitorScore: number;
  difference: string;
  potentialSalesIncrease: string | number;
}

const DynamicComparisonBlock: React.FC<DynamicComparisonBlockProps> = ({
  productScore,
  competitorScore,
  difference,
  potentialSalesIncrease,
}) => {
  const { selectedProduct, selectedProductToCompare } = useProductStore();
  const [sp, setSp] = useState<number | null>(null);
  const isMobile = useIsMobile();

  useEffect(() => {
    const spider = new SpiderService();

    const fetchData = async () => {
      if (selectedProductToCompare) {
        const asin =
          typeof selectedProduct === "string"
            ? selectedProduct
            : selectedProduct?.asin;

        const allAsin = selectedProductToCompare
          .flatMap((innerArray) => innerArray.map((product) => product.asin))
          .filter(Boolean);

        const mainAsin = asin;

        const uniqueAsins = Array.from(new Set(allAsin)).filter(
          (asin) => asin !== mainAsin,
        );

        try {
          const differenceList = await spider.getDifferenceList(
            uniqueAsins,
            mainAsin,
          );
          setSp(Math.round(differenceList.difference_sales));
        } catch (error) {
          console.error("Error fetching difference list:", error);
        }
      }
    };

    fetchData();
  }, [selectedProductToCompare]);

  const productScoreColor = determineScoreColor(productScore, competitorScore);
  const competitorScoreColor = determineScoreColor(
    competitorScore,
    productScore,
  );
  const differenceText = determineDifference(productScore, competitorScore);
  const differenceColorClass = getDifferenceColorClass(
    productScore,
    competitorScore,
    styles,
  );
  const highlightColorClass = getHighlightColorClass(
    productScore,
    competitorScore,
    styles,
  );

  return (
    <div className={styles.comparisonBlock}>
      {isMobile && (
        <div
          className={classNames(styles.differenceMobile, differenceColorClass)}
        >
          <p>
            Your aspect score is
            <span className={classNames(styles.highlight, highlightColorClass)}>
              {difference} {differenceText}
            </span>
          </p>
        </div>
      )}
      <div className={styles.scores}>
        <div className={styles.scoreBox}>
          <div className={styles.scoreGap}>
            <p className={`${styles.scoreLabel} ${styles.firstScoreLabel}`}>
              Score
            </p>
          </div>
          <div className={styles.scoreRow}>
            <span
              className={styles.ratingValue}
              style={{ color: productScoreColor }}
            >
              {productScore}
            </span>
            <div className={styles.lockedContainer}>
              <div className={styles.lockedData}>
                <span
                  className={styles.lockedText}
                  style={{ color: getTextColor(sp || 0) }}
                >
                  {potentialSalesIncrease !== null
                    ? `$${potentialSalesIncrease}`
                    : "Loading..."}
                </span>
              </div>
              <div className={styles.lockedBlock}>
                <Popover
                  content={INFO_POPOVER_CONTENT.salesLift}
                  overlayClassName={styles.customPopover}
                  trigger="click"
                >
                  <Button
                    type="link"
                    className={styles.infoButton}
                    icon={
                      <img
                        src={InfoIcon}
                        alt="Info"
                        className={styles.infoIcon}
                        width={14}
                        height={14}
                      />
                    }
                  />
                </Popover>
              </div>
            </div>
          </div>
        </div>
        {!isMobile && (
          <div className={classNames(styles.difference, differenceColorClass)}>
            <p>
              Your aspect score is
              <span
                className={classNames(styles.highlight, highlightColorClass)}
              >
                {difference} {differenceText}
              </span>
            </p>
          </div>
        )}
        <div className={styles.scoreBox}>
          <p>Score</p>
          <span
            className={styles.ratingValue}
            style={{ color: competitorScoreColor }}
          >
            {competitorScore}
          </span>
        </div>
      </div>
    </div>
  );
};

export default DynamicComparisonBlock;
