export default () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <g opacity="0.3">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M14 5C14 4.44772 14.4477 4 15 4H17C17.5523 4 18 4.44772 18 5C18 5.55228 17.5523 6 17 6H15C14.4477 6 14 5.55228 14 5ZM14 10C14 9.44772 14.4477 9 15 9H17C17.5523 9 18 9.44772 18 10C18 10.5523 17.5523 11 17 11H15C14.4477 11 14 10.5523 14 10Z"
          fill="white"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M8 5C8 4.44772 8.44772 4 9 4H11C11.5523 4 12 4.44772 12 5C12 5.55228 11.5523 6 11 6H9C8.44772 6 8 5.55228 8 5ZM8 10C8 9.44772 8.44772 9 9 9H11C11.5523 9 12 9.44772 12 10C12 10.5523 11.5523 11 11 11H9C8.44772 11 8 10.5523 8 10Z"
          fill="white"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M2 5C2 4.44772 2.44772 4 3 4H5C5.55228 4 6 4.44772 6 5C6 5.55228 5.55228 6 5 6H3C2.44772 6 2 5.55228 2 5ZM2 10C2 9.44772 2.44772 9 3 9H5C5.55228 9 6 9.44772 6 10C6 10.5523 5.55228 11 5 11H3C2.44772 11 2 10.5523 2 10Z"
          fill="white"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M14 10C14 9.44772 14.4477 9 15 9H17C17.5523 9 18 9.44772 18 10C18 10.5523 17.5523 11 17 11H15C14.4477 11 14 10.5523 14 10ZM14 15C14 14.4477 14.4477 14 15 14H17C17.5523 14 18 14.4477 18 15C18 15.5523 17.5523 16 17 16H15C14.4477 16 14 15.5523 14 15Z"
          fill="white"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M8 10C8 9.44772 8.44772 9 9 9H11C11.5523 9 12 9.44772 12 10C12 10.5523 11.5523 11 11 11H9C8.44772 11 8 10.5523 8 10ZM8 15C8 14.4477 8.44772 14 9 14H11C11.5523 14 12 14.4477 12 15C12 15.5523 11.5523 16 11 16H9C8.44772 16 8 15.5523 8 15Z"
          fill="white"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M2 10C2 9.44772 2.44772 9 3 9H5C5.55228 9 6 9.44772 6 10C6 10.5523 5.55228 11 5 11H3C2.44772 11 2 10.5523 2 10ZM2 15C2 14.4477 2.44772 14 3 14H5C5.55228 14 6 14.4477 6 15C6 15.5523 5.55228 16 5 16H3C2.44772 16 2 15.5523 2 15Z"
          fill="white"
        />
      </g>
    </svg>
  );
};
