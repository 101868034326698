export const formatName = (name: string): string => {
  switch (name) {
    case "Sales Total":
      return "Total Sales";
    case "Ads Roas":
      return "ROAS";
    case "Ads Acos":
      return "ACOS";
    case "Ads Cvr":
      return "CVR";
    case "Ads CPC":
      return "CPC";
    case "Ads Cpc":
      return "CPC";
    case "Ads Ctr":
      return "CTR";
    default:
      return name;
  }
};

export const getValueWithLabel = (name: string, value: number): string => {
  const lowerName = name.toLowerCase();
  const procentKeys = ["acos", "cvr", "ctr"];
  const dollarKeys = ["spend", "sales", "total", "cpc"];
  const foundProcentKey = procentKeys.find((key) => lowerName.includes(key));
  const foundDollarKey = dollarKeys.find((key) => lowerName.includes(key));
  const formattedNumber = formatNumber(value, !!foundProcentKey);

  let formattedValue: string = formattedNumber;

  if (foundProcentKey) {
    formattedValue = formattedValue + " %";
  } else if (foundDollarKey) {
    formattedValue = "$ " + formattedValue;
  }

  return formattedValue;
};

export const formatNumber = (value: number, isPercentage: boolean): string => {
  const formattedValue = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: isPercentage ? 2 : 0,
    maximumFractionDigits: isPercentage ? 2 : 0,
  }).format(value);
  return formattedValue;
};

export const getProfitColor = (
  name: string,
  value: number,
  maxValue: number,
) => {
  const lowerName = name.toLowerCase();
  const procentProfit = (value / maxValue) * 100;

  if (lowerName.includes("spend") || lowerName.includes("acos")) {
    return procentProfit > 80 ? "#FE5858" : "#80C67A";
  }
  if (
    lowerName.includes("roas") ||
    lowerName.includes("ctr") ||
    lowerName.includes("cvr")
  ) {
    return procentProfit > 30 ? "#80C67A" : "#FE5858";
  }

  return procentProfit > 50 ? "#80C67A" : "#FE5858";
};
