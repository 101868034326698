import { AmazonService } from "@services/amazon/amazon.service";
import { Product } from "@services/amazon/products/types";

export const TABS = [{ tab: "allProducts", label: "Other Products" }];

export const isCompareRoute = (): boolean => {
  return window.location.pathname.includes("/compare");
};

export const fetchProducts = async (
  activeTab: string,
  params: { asin: string; limit: number },
  setSearchResults: (results: Product[]) => void,
) => {
  try {
    let response: Product[] = [];

    if (activeTab === "myProducts") {
      response = params.asin
        ? await AmazonService.products.searchOwnProducts(params)
        : await AmazonService.products.getOwnProducts(params);
    } else {
      response = params.asin
        ? await AmazonService.products.searchPublicProducts(params)
        : await AmazonService.products.getPublicProducts(params);
    }

    setSearchResults(response || []);
  } catch (error) {
    console.error("Error fetching products:", error);
    setSearchResults([]);
  }
};

export const capitalizeWords = (str: string): string => {
  return str
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

export const getPaginatedItems = <T>(
  items: T[],
  currentPage: number,
  itemsPerPage: number,
): T[] => {
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = currentPage * itemsPerPage;
  return items.slice(startIndex, endIndex);
};

export const extractAllAsins = (selectedProductToCompare: any[]) => {
  if (!Array.isArray(selectedProductToCompare)) return [];
  if (Array.isArray(selectedProductToCompare[0])) {
    return selectedProductToCompare.flatMap((innerArray) =>
      Array.isArray(innerArray)
        ? innerArray.map((product) => product.asin)
        : [],
    );
  }
  return selectedProductToCompare
    .map((product) => product.asin)
    .filter(Boolean);
};
export const getItemsToDisplay = (
  searchTerm: string,
  searchResults: Product[],
  paginatedItems: Product[],
): Product[] => {
  return searchTerm ? searchResults : paginatedItems;
};
