const MenuIcon = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 9.33333C4 8.97971 4.14048 8.64057 4.39052 8.39052C4.64057 8.14048 4.97971 8 5.33333 8H26.6667C27.0203 8 27.3594 8.14048 27.6095 8.39052C27.8595 8.64057 28 8.97971 28 9.33333C28 9.68696 27.8595 10.0261 27.6095 10.2761C27.3594 10.5262 27.0203 10.6667 26.6667 10.6667H5.33333C4.97971 10.6667 4.64057 10.5262 4.39052 10.2761C4.14048 10.0261 4 9.68696 4 9.33333ZM4 16C4 15.6464 4.14048 15.3072 4.39052 15.0572C4.64057 14.8071 4.97971 14.6667 5.33333 14.6667H26.6667C27.0203 14.6667 27.3594 14.8071 27.6095 15.0572C27.8595 15.3072 28 15.6464 28 16C28 16.3536 27.8595 16.6928 27.6095 16.9428C27.3594 17.1929 27.0203 17.3333 26.6667 17.3333H5.33333C4.97971 17.3333 4.64057 17.1929 4.39052 16.9428C4.14048 16.6928 4 16.3536 4 16ZM4 22.6667C4 22.313 4.14048 21.9739 4.39052 21.7239C4.64057 21.4738 4.97971 21.3333 5.33333 21.3333H26.6667C27.0203 21.3333 27.3594 21.4738 27.6095 21.7239C27.8595 21.9739 28 22.313 28 22.6667C28 23.0203 27.8595 23.3594 27.6095 23.6095C27.3594 23.8595 27.0203 24 26.6667 24H5.33333C4.97971 24 4.64057 23.8595 4.39052 23.6095C4.14048 23.3594 4 23.0203 4 22.6667Z"
      fill="#7C7C78"
    />
  </svg>
);

export default MenuIcon;
