import { Item } from "@pages/Overview";
import { create } from "zustand";

interface Category {
  id: string;
  name: string;
  items: Item[];
}

interface ICategoryStore {
  selectedCategory: Category | null;
  paginatedItem: any[];
  setSelectedCategory: (category: Category) => void;
  setPaginatedItem: (items: any[]) => void;
}

export const useCategoryStore = create<ICategoryStore>((set) => ({
  selectedCategory: null,
  paginatedItem: [],
  setSelectedCategory: (category) => set({ selectedCategory: category }),
  setPaginatedItem: (items) => set({ paginatedItem: items }),
}));
