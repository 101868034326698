import PageWrapper from "@components/PageWrapper/PageWrapper";
import Products from "@pages/Compare/components/Products/index";
import Header from "@pages/Compare/components/Header/index";
import ProblemComponent from "./components/Problems";
import TopPhrasesComponent from "./components/TopPhrases";
import ProductComparison from "./components/ProductComparison";
import { useInsightsStore } from "src/store/insights/insights.state";
import { OverviewType } from "@pages/Overview";

function Compare({ type = "my-product" }: { type?: OverviewType }) {
  const { productOne, productTwo } = useInsightsStore();
  return (
    <PageWrapper>
      <Header />
      <Products type={type} />
      <ProductComparison
        productOneTitles={productOne.spiderTitles}
        productTwoTitles={productTwo.spiderTitles}
        productOneValues={productOne.spiderValues}
        productTwoValues={productTwo.spiderValues}
      />
      <ProblemComponent
        problemsYour={productOne.problems}
        problemsCompare={productTwo.problems}
      />
      <TopPhrasesComponent
        phrasesYour={productOne.phrases}
        phrasesCompare={productTwo.phrases}
      />
      {/* <Hypotheses /> */}
    </PageWrapper>
  );
}

export default Compare;
