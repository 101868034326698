import { useState, useEffect } from "react";
import styles from "./styles.module.scss";
import SubscriptionReport from "../SubscriptionReport";
import SubscriptionHeatmap from "../ConortAnalysisTable";
import classNames from "classnames";

const Tabs = () => {
  const [activeTab, setActiveTab] = useState("subscription");

  useEffect(() => {
    document.documentElement.style.setProperty(
      "--tab-indicator-left",
      activeTab === "subscription" ? "0" : "50%",
    );
  }, [activeTab]);

  const renderContent = () => {
    switch (activeTab) {
      case "subscription":
        return (
          <div>
            <SubscriptionReport />
          </div>
        );
      case "cohort":
        return (
          <div>
            <SubscriptionHeatmap />
          </div>
        );
      default:
        return <h2>Subscription Count Content Placeholder</h2>;
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.tabs}>
        <button
          className={classNames(styles.tab, {
            [styles.active]: activeTab === "subscription",
          })}
          onClick={() => setActiveTab("subscription")}
        >
          SUBSCRIPTION COUNT
        </button>
        <button
          className={classNames(styles.tab, {
            [styles.active]: activeTab === "cohort",
          })}
          onClick={() => setActiveTab("cohort")}
        >
          COHORT ANALYSIS
        </button>
      </div>
      <div className={styles.content}>{renderContent()}</div>
    </div>
  );
};

export default Tabs;
