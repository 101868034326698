import { create } from "zustand";

interface Brand {
  name: string;
  asins: string[];
}

interface BrandState {
  brands: Brand[];
  setBrands: (brands: Brand[]) => void;
}

export const useBrandStore = create<BrandState>((set) => ({
  brands: [],
  setBrands: (brands) => set({ brands }),
}));
