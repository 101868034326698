import "./index.css";
import { Header } from "./Header";
import WidgetsList from "./WidgetsList";
import { useDashboardStore } from "@pages/Dashboard/store/dashboard.state";
import { useEffect, useState } from "react";
import { WidgetClass } from "@services/widget.service";
import { endOfWeek, startOfWeek, subWeeks } from "date-fns";
import { IWidget } from "@pages/Dashboard/types/dashboard.types";

export const Widgets = () => {
  const { selectedWidgets, dateRange } = useDashboardStore((state) => ({
    selectedWidgets: state.selectedWidgets,
    dateRange: state.dateRange,
  }));
  const { startDate, endDate } = dateRange;
  const [widgets, setWidgets] = useState<IWidget[]>();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchWidgets = async () => {
      setIsLoading(true);
      const widgetClass = new WidgetClass();
      const startDateString = startDate.toISOString().split("T")[0];
      const endDateString = endDate.toISOString().split("T")[0];
      const compareStartDate = startOfWeek(subWeeks(startDate, 1))
        .toISOString()
        .split("T")[0];
      const compareEndDate = endOfWeek(subWeeks(startDate, 1))
        .toISOString()
        .split("T")[0];

      const response = await widgetClass.getWidgetsData(
        selectedWidgets,
        startDateString,
        endDateString,
        compareStartDate,
        compareEndDate,
      );
      setWidgets(response);
      setIsLoading(false);
    };
    fetchWidgets();
  }, [selectedWidgets, dateRange]);

  return (
    <div className="dashboard-widgets">
      <Header />
      <WidgetsList widgets={widgets} isLoading={isLoading} />
    </div>
  );
};
