import {
  subDays,
  subWeeks,
  subMonths,
  subYears,
  differenceInCalendarDays,
  endOfWeek,
  startOfWeek,
  startOfMonth,
  endOfMonth,
  startOfYear,
  endOfYear,
} from "date-fns";

export const getDateItems = (currentDate: Date) => {
  const year = currentDate.getFullYear();
  const month = currentDate.getMonth();
  const date = currentDate.getDate();

  return [
    {
      label: "Previous period",
      dateRange: {
        startDate: new Date(year, month, date - 21),
        endDate: currentDate,
      },
    },
    {
      label: "Previous week",
      dateRange: {
        startDate: new Date(year, month, date - 7),
        endDate: currentDate,
      },
    },
    {
      label: "Previous month",
      dateRange: {
        startDate: new Date(year, month - 1, date),
        endDate: currentDate,
      },
    },
    {
      label: "Previous year",
      dateRange: {
        startDate: new Date(year - 1, month, date),
        endDate: currentDate,
      },
    },
  ];
};

export const COMPARING_TO = "Comparing to ";

export const calculateCompareRange = (
  label: string,
  currentDateRange: { startDate: Date; endDate: Date },
): { startDate: Date; endDate: Date } => {
  const { startDate } = currentDateRange;

  switch (label) {
    case "Previous period": {
      const dayDifference =
        differenceInCalendarDays(currentDateRange.endDate, startDate) + 1;
      const newStartDate = subDays(startDate, dayDifference);
      const newEndDate = subDays(currentDateRange.endDate, dayDifference);
      return { startDate: newStartDate, endDate: newEndDate };
    }
    case "Previous week": {
      const newStartDate = startOfWeek(subWeeks(startDate, 1));
      const newEndDate = endOfWeek(subWeeks(startDate, 1));
      return { startDate: newStartDate, endDate: newEndDate };
    }
    case "Previous month": {
      const newStartDate = startOfMonth(subMonths(startDate, 1));
      const newEndDate = endOfMonth(subMonths(startDate, 1));
      return { startDate: newStartDate, endDate: newEndDate };
    }
    case "Previous year": {
      const newStartDate = startOfYear(subYears(startDate, 1));
      const newEndDate = endOfYear(subYears(startDate, 1));
      return { startDate: newStartDate, endDate: newEndDate };
    }
    case "Custom":
      return { startDate, endDate: currentDateRange.endDate };
    default:
      return { startDate, endDate: currentDateRange.endDate };
  }
};

export const getLabelFromCompareWith = (
  compareWith: { startDate: Date; endDate: Date },
  dateRange: { startDate: Date; endDate: Date },
): string => {
  const { startDate, endDate } = dateRange;
  const { startDate: compareStartDate, endDate: compareEndDate } = compareWith;

  if (
    compareStartDate.getTime() ===
      startDate.getTime() - 7 * 24 * 60 * 60 * 1000 &&
    compareEndDate.getTime() === endDate.getTime() - 7 * 24 * 60 * 60 * 1000
  ) {
    return "Previous week";
  }

  if (
    compareStartDate.getTime() ===
      startDate.getTime() - 30 * 24 * 60 * 60 * 1000 &&
    compareEndDate.getTime() === endDate.getTime() - 30 * 24 * 60 * 60 * 1000
  ) {
    return "Previous month";
  }

  if (
    compareStartDate.getTime() ===
      startDate.getTime() - 365 * 24 * 60 * 60 * 1000 &&
    compareEndDate.getTime() === endDate.getTime() - 365 * 24 * 60 * 60 * 1000
  ) {
    return "Previous year";
  }

  return "Custom";
};
