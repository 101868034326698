import React from "react";
import styles from "./styles.module.scss";

type Metric = {
  name: string;
  key: string;
  color?: string;
};

type ProductMetricsProps = {
  mockData: Record<string, any>;
  metrics: Metric[];
  isMobile?: boolean;
};

export const ProductMetrics: React.FC<ProductMetricsProps> = ({
  mockData,
  metrics,
  isMobile,
}) => {
  const getCustomColor = (key: string): string => {
    switch (key) {
      case "unitsSessionPercentage":
        return "#80c67a";
      case "pageViewsTotal":
        return "#FE5858";
      case "orderedProductSales":
        return "#5295E0";
      default:
        return "inherit";
    }
  };

  const groupMetricsInPairs = (metrics: Metric[]): Metric[][] => {
    const pairs: Metric[][] = [];
    for (let i = 0; i < metrics.length; i += 2) {
      pairs.push(metrics.slice(i, i + 2));
    }
    return pairs;
  };

  if (isMobile) {
    const metricPairs = groupMetricsInPairs(metrics);

    return (
      <div className={styles.blockContainer}>
        {metricPairs.map((pair, pairIndex) => (
          <div key={pairIndex} className={styles.rowContainer}>
            {pair.map((metric, metricIndex) => {
              const value = mockData[metric.key] || "N/A";
              const customColor = getCustomColor(metric.key);

              return (
                <div key={metricIndex} className={styles.metricContainer}>
                  <div className={styles.label}>{metric.name}</div>
                  <div className={styles.value} style={{ color: customColor }}>
                    {metric.key === "orderedProductSales" ? `$${value}` : value}
                  </div>
                </div>
              );
            })}
          </div>
        ))}
      </div>
    );
  }

  return (
    <React.Fragment>
      {metrics.map((metric, index) => {
        const value = mockData[metric.key] || "N/A";
        const customColor = getCustomColor(metric.key);

        return (
          <td key={index} className={styles.dataCell}>
            <div
              style={{
                color: customColor,
              }}
            >
              {metric.key === "orderedProductSales" ? `$${value}` : value}
            </div>
          </td>
        );
      })}
    </React.Fragment>
  );
};
