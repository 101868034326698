import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { trackPageView } from "src/utils/analytics";

export const RouteTracker: React.FC = () => {
  const location = useLocation();

  useEffect(() => {
    // Get page title from document or set a default
    const title = document.title || "Nyle";

    trackPageView(location.pathname, title);
  }, [location.pathname]);

  return null;
};
