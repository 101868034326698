export const data = [
  { name: "Other", value: 2893 },
  { name: "1 month", value: 964 },
  { name: "3 month", value: 1815 },
];

export const COLORS = ["#A6FAE6", "#1BB08D", "#50DAB9"];
export const DARK_YELLOW = "#DAA520";

export const legendOrder = ["1 month", "3 month", "Other"];

export const renderInnerDots = (cx, cy, innerRadius) => {
  const dots = [];
  const dotCount = 30;
  const radius = innerRadius - 15;

  for (let i = 0; i < dotCount; i++) {
    const angle = (i / dotCount) * 2 * Math.PI;
    const x = cx + radius * Math.cos(angle);
    const y = cy + radius * Math.sin(angle);

    dots.push(<circle key={i} cx={x} cy={y} r={1} fill={DARK_YELLOW} />);
  }

  return <g>{dots}</g>;
};
