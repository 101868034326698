import { Api } from "src/utils/api";
import { AMAZON_URL } from "../amazonUrl";

export interface GraphData {
  date: string;
  average: number;
  positive: number;
  neutral: number;
  negative: number;
  total: number;
}

export class GraphsService extends Api {
  constructor() {
    super(AMAZON_URL);
  }

  public async getGraph(
    asin: string,
    date_start: string,
    date_end: string,
  ): Promise<GraphData[]> {
    return this.get("/graphs/review-score", { asin, date_start, date_end });
  }
}
