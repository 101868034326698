import ModalBox from "@components/ModalBox";
import styles from "./styles.module.scss";
import { useAdsAnaliticsStore } from "src/store/ads-analitics.store";
import { ArrowRightIcon } from "lucide-react";

interface HitsoryInteractionModalProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

export const HitsoryInteractionModal = ({
  isOpen,
  setIsOpen,
}: HitsoryInteractionModalProps) => {
  const { keys } = useAdsAnaliticsStore((state) => ({
    keys: state.keys,
  }));

  const iterations = [
    {
      title: "Iteration 1",
      date: "25 October 2024, 1:29 PM",
      targets: Object.values(keys).flat(),
      spends: [
        { label: "Ad Spend", value: "70%" },
        { label: "Bids", value: "40%" },
        { label: "Reduced Ad Spend", value: "70%" },
      ],
      results: [
        {
          label: "ACOS",
          from: "70%",
          to: "20%",
          positive: true,
        },
        {
          label: "Ad Spend",
          from: "$0",
          to: "$3,437",
          positive: false,
        },
        {
          label: "Ad Sales",
          from: "$64,090",
          to: "$119,752",
          positive: true,
        },
      ],
    },
    {
      title: "Iteration 2",
      date: "25 October 2024, 1:45 PM",
      targets: Object.values(keys).flat(),
      spends: [
        { label: "Ad Spend", value: "50%" },
        { label: "Bids", value: "35%" },
        { label: "Reduced Ad Spend", value: "60%" },
      ],
      results: [
        {
          label: "ACOS",
          from: "20%",
          to: "18%",
          positive: true,
        },
        {
          label: "Ad Spend",
          from: "$3,437",
          to: "$3,000",
          positive: true,
        },
        {
          label: "Ad Sales",
          from: "$119,752",
          to: "$99,281",
          positive: false,
        },
      ],
    },
    {
      title: "Iteration 3",
      date: "25 October 2024, 1:45 PM",
    },
  ];

  return (
    <ModalBox
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      title="Iteration History"
      className={styles.modal}
    >
      <div className={styles.container}>
        <div className={styles.roadmap}></div>
        <div className={styles.iterations}>
          {iterations.map((iteration, idx) => (
            <div key={idx} className={styles.iteration}>
              <div className={styles.header}>
                <div className={styles.text}>
                  <h3>{iteration.title}</h3>
                  <span>{iteration.date}</span>
                </div>
                <button>See details</button>
              </div>
              {iteration.targets && (
                <div className={styles.keys}>
                  <h4>Targets:</h4>
                  <p>
                    {iteration.targets.map((key, index) => (
                      <span key={index}>
                        {key}
                        {index < iteration.targets.length - 1 && ", "}
                      </span>
                    ))}
                  </p>
                </div>
              )}
              {iteration.spends && (
                <div className={styles.spends}>
                  {iteration.spends.map((spend, index) => (
                    <div key={index} className={styles.spend}>
                      <h4>{spend.label}: </h4>
                      <p>{spend.value}</p>
                    </div>
                  ))}
                </div>
              )}
              {iteration.results && (
                <div className={styles.targets}>
                  {iteration.results.map((result, index) => (
                    <div key={index} className={styles.target}>
                      <h4>{result.label}</h4>
                      <p>
                        {result.from} <ArrowRightIcon />{" "}
                        <span
                          className={
                            result.positive ? styles.positive : styles.negative
                          }
                        >
                          {result.to}
                        </span>
                      </p>
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </ModalBox>
  );
};
