import React from "react";
import { useTranslation } from "react-i18next";
import { useTitle } from "@hooks/useTitle";
import { Widgets } from "@pages/Dashboard/features/Widgets";
import { Products } from "@pages/Dashboard/features/Products";
import { SalesPerformance } from "@pages/Dashboard/features/SalesPerformance";
import { WelcomeSection } from "@components/WelcomeSection";
import PageWrapper from "@components/PageWrapper/PageWrapper";

export const Dashboard: React.FC = () => {
  const { t } = useTranslation();
  useTitle(t("window.dashboard"));

  return (
    <PageWrapper>
      <WelcomeSection />
      <Widgets />
      <SalesPerformance />
      <Products />
    </PageWrapper>
  );
};
