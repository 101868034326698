import WidgetsList from "@pages/Dashboard/features/Widgets/WidgetsList";
import styles from "./styles.module.scss";
import { ConfigModal } from "@components/config-modal";
import { useDashboardStore } from "@pages/Dashboard/store/dashboard.state";
import { useEffect, useState } from "react";
import ConfigImage from "@assets/icons/config.svg";
import classNames from "classnames";
import MetricBuilder from "@pages/CROModule/components/CROPage/components/Sections/MetricBuilder";
import { useCanvaStore } from "src/store/canva.state";
import { IWidget } from "@pages/Dashboard/types/dashboard.types";
import { WidgetClass } from "@services/widget.service";
import { endOfWeek, startOfWeek, subWeeks } from "date-fns";
import { useAdsAnaliticsStore } from "src/store/ads-analitics.store";

export const ExecutiveSummary: React.FC = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const { selectedWidgets, setSelectedWidgets, dateRange } = useDashboardStore(
    (state) => ({
      selectedWidgets: state.selectedWidgets,
      dateRange: state.dateRange,
      setSelectedWidgets: state.setSelectedWidgets,
    }),
  );
  const { compareWith } = useAdsAnaliticsStore();
  const { startDate, endDate } = dateRange;
  const [widgets, setWidgets] = useState<IWidget[]>();
  const [isLoading, setIsLoading] = useState(true);
  const { isWhatIf } = useCanvaStore();

  useEffect(() => {
    const fetchWidgets = async () => {
      setIsLoading(true);
      const widgetClass = new WidgetClass();
      const startDateString = startDate.toISOString().split("T")[0];
      const endDateString = endDate.toISOString().split("T")[0];
      const { startDate: compareStartDate, endDate: compareEndDate } =
        compareWith;
      const compareStartDateString = startOfWeek(subWeeks(compareStartDate, 1))
        .toISOString()
        .split("T")[0];
      const compareEndDateString = endOfWeek(subWeeks(compareEndDate, 1))
        .toISOString()
        .split("T")[0];

      const response = await widgetClass.getWidgetsData(
        selectedWidgets,
        startDateString,
        endDateString,
        compareStartDateString,
        compareEndDateString,
      );
      setWidgets(response);
      setIsLoading(false);
    };
    fetchWidgets();
  }, [selectedWidgets, dateRange, compareWith]);

  return (
    <>
      {isWhatIf ? (
        <div className={styles.optimisation}>
          <MetricBuilder />
        </div>
      ) : (
        <div className={styles.container}>
          {modalOpen && (
            <div className={styles.modal}>
              <ConfigModal
                buttonWidth={0}
                closeModal={() => {
                  setModalOpen(false);
                }}
                selectedWidgets={selectedWidgets}
                maxSelectedWidgets={12}
                setWidgets={setSelectedWidgets}
              />
            </div>
          )}
          <button
            className={classNames(styles.button, modalOpen && styles.active)}
            onClick={() => setModalOpen(!modalOpen)}
          >
            <img src={ConfigImage} alt="config" />
            CONFIGURE
          </button>
          <WidgetsList
            widgets={widgets}
            containerClassName={styles.widgets}
            itemClassName={styles.widget}
            isLoading={isLoading}
          />
        </div>
      )}
    </>
  );
};
