import React, { useState } from "react";
import styles from "./styles.module.scss";
import { ExpandableTable } from "@components/ExpandebleTable";
import { columns, data } from "./mock";
import { Input } from "@components/Input";
import { Select } from "@components/Select";
import Search from "@assets/icons/search";

export const OrganicKeywordTable: React.FC = () => {
  const [selections, setSelections] = useState<any[]>([]);
  const [search, setSearch] = useState<string>("");
  const [filter, setFilter] = useState<string>("");

  const rowSelections = {
    selectedRowKeys: selections,
    onChange: (selectedRowKeys: any) => {
      setSelections(selectedRowKeys);
    },
  };

  const handleFilter = (value: string) => {
    setFilter(value);
  };

  return (
    <div className={styles.container} id={styles.table}>
      <h3>Organic Keyword Performance</h3>
      <div className={styles.filters}>
        <Input
          value={search}
          placeholder="Search a campaign..."
          onChange={setSearch}
          className={styles.input}
          icon={<Search />}
        />
        <Select
          value={filter}
          options={["All", "Active", "Inactive"]}
          onChange={handleFilter}
          placeholder="Filters"
        />
        <button className={styles.export__button}>EXPORT</button>
      </div>
      <ExpandableTable
        columns={columns}
        data={data}
        withCustomScroll
        rowSelection={rowSelections}
        className={styles.table}
        scroll={{ x: 1000 }}
      />
    </div>
  );
};
