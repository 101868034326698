import { AddsSalesApi } from "@services/adds-sales/adds-sales.api";

export type WidgetData = {
  sales: number;
  spend: number;
  impressions: number;
  ctr: number;
  cvr: number;
  cpc: number;
  acos: number;
  roas: number;
  orders: number;
  unitSolds: number;
  clicks: number;
  salesCompare: number;
  spendCompare: number;
  impressionsCompare: number;
  ctrCompare: number;
  cvrCompare: number;
  cpcCompare: number;
  acosCompare: number;
  roasCompare: number;
  ordersCompare: number;
  unitSoldsCompare: number;
  clicksCompare: number;
};

export interface AdsWidget {
  title: string;
  value: string;
  increase: string;
  increaseDirection: "up" | "down";
  color: string;
}

export const calculateIncrease = (
  current: number,
  difference: number,
): { increase: string; increaseDirection: "up" | "down" } => {
  const previous = current - difference;
  const percentage = previous !== 0 ? (difference / previous) * 100 : 0;
  const isIncrease = percentage >= 0;
  return {
    increase: `${isIncrease ? "+" : ""}${percentage.toFixed(2)}%`,
    increaseDirection: isIncrease ? "up" : "down",
  };
};

export const formatNumber = (num: number): string =>
  num.toLocaleString("en-US");
export const formatCurrency = (num: number): string =>
  `$${Math.floor(num).toLocaleString("en-US")}`;
export const formatPercentage = (num: number): string =>
  `${(num * 100).toFixed(2)}%`;

export const generateWidgets = (data: WidgetData): AdsWidget[] => {
  const widgets: AdsWidget[] = [
    {
      title: "Sales",
      value: formatCurrency(data.sales),
      ...calculateIncrease(data.sales, data.salesCompare),
      color: "#80C67A",
    },
    {
      title: "Spend",
      value: formatCurrency(data.spend),
      ...calculateIncrease(data.spend, data.spendCompare),
      color: "#BF4C46",
    },
    {
      title: "Impressions",
      value: formatNumber(data.impressions),
      ...calculateIncrease(data.impressions, data.impressionsCompare),
      color: "#5C55D5",
    },
    {
      title: "CTR",
      value: formatPercentage(data.ctr),
      ...calculateIncrease(data.ctr, data.ctrCompare),
      color: "#67AC9B",
    },
    {
      title: "CVR",
      value: formatPercentage(data.cvr),
      ...calculateIncrease(data.cvr, data.cvrCompare),
      color: "#9C8437",
    },
    {
      title: "CPC",
      value: `$${data.cpc.toFixed(2)}`,
      ...calculateIncrease(data.cpc, data.cpcCompare),
      color: "#A8A03D",
    },
    {
      title: "ACOS",
      value: formatPercentage(data.acos),
      ...calculateIncrease(data.acos, data.acosCompare),
      color: "#9E44CC",
    },
    {
      title: "ROAS",
      value: data.roas.toFixed(2),
      ...calculateIncrease(data.roas, data.roasCompare),
      color: "#F19867",
    },
    {
      title: "Orders",
      value: formatNumber(data.orders),
      ...calculateIncrease(data.orders, data.ordersCompare),
      color: "#5295E0",
    },
    {
      title: "Units Sold",
      value: formatNumber(data.unitSolds),
      ...calculateIncrease(data.unitSolds, data.unitSoldsCompare),
      color: "#255FA0",
    },
  ];

  return widgets;
};

export const getWidgetData = async (
  dateRange: {
    startDate: Date;
    endDate: Date;
  },
  compareWith: {
    startDate: Date;
    endDate: Date;
  },
): Promise<WidgetData> => {
  const { startDate, endDate } = dateRange;
  const { startDate: compareStartDate, endDate: compareEndDate } = compareWith;

  const addsSalesApi = new AddsSalesApi();
  const startDateString = startDate.toISOString().split("T")[0];
  const endDateString = endDate.toISOString().split("T")[0];

  const compareStartDateString = compareStartDate.toISOString().split("T")[0];
  const compareEndDateString = compareEndDate.toISOString().split("T")[0];

  const {
    sales,
    spend,
    impressions,
    ctr,
    cvr,
    cpc,
    acos,
    roas,
    orders,
    unitSolds,
    clicks,
  } = await addsSalesApi.getWidgetDataPromise(startDateString, endDateString);

  const {
    sales: salesCompare,
    spend: spendCompare,
    impressions: impressionsCompare,
    ctr: ctrCompare,
    cvr: cvrCompare,
    cpc: cpcCompare,
    acos: acosCompare,
    roas: roasCompare,
    orders: ordersCompare,
    unitSolds: unitSoldsCompare,
    clicks: clicksCompare,
  } = await addsSalesApi.getWidgetsComparePromise(
    startDateString,
    endDateString,
    compareStartDateString,
    compareEndDateString,
  );

  return {
    sales,
    spend,
    impressions,
    ctr,
    cvr,
    cpc,
    acos,
    roas,
    orders,
    unitSolds,
    clicks,
    salesCompare,
    spendCompare,
    impressionsCompare,
    ctrCompare,
    cvrCompare,
    cpcCompare,
    acosCompare,
    roasCompare,
    ordersCompare,
    unitSoldsCompare,
    clicksCompare,
  };
};
