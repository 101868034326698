import { ChangeEventHandler, useMemo } from "react";
import styles from "./styles.module.scss";
import TemplateItem, { Template } from "./Template";

interface TemplatesProps {
  templates: Template[];
}

const Templates = ({ templates }: TemplatesProps) => {
  const [leftTemplate, rightTemplate] = useMemo(() => {
    const middle = Math.ceil(templates.length / 2);
    return [templates.slice(0, middle), templates.slice(middle)];
  }, [templates]);

  return (
    <div className={styles.accordion}>
      <div className={styles.accordion__col}>
        {leftTemplate.map((template, i) => (
          <TemplateItem key={i} template={template} />
        ))}
      </div>
      <div className={styles.accordion__col}>
        {rightTemplate.map((template, i) => (
          <TemplateItem key={i} template={template} />
        ))}
      </div>
    </div>
  );
};

export default Templates;
