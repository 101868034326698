import { mapCheckKeyToMetricKey } from "@pages/Dashboard/mocks/mockMetricsData";
import { useMemo } from "react";
import {
  NameType,
  Payload,
  ValueType,
} from "recharts/types/component/DefaultTooltipContent";

interface CustomTooltipProps {
  active?: boolean;
  payload?: Payload<ValueType, NameType>[];
  colorMetric?: { [key: string]: string };
  label: string;
}

const formatValue = (key: string, value: ValueType) => {
  const lowerKey = key.toLowerCase();
  if (
    lowerKey.includes("cvr") ||
    lowerKey.includes("ctr") ||
    lowerKey.includes("acos")
  ) {
    return `${(Number(value) * 100).toFixed(2)} %`;
  }
  if (lowerKey.includes("spend") || lowerKey.includes("sales")) {
    return `$${Math.floor(Number(value)).toLocaleString("en-US")}`;
  }
  return value;
};

export const CustomTooltip: React.FC<CustomTooltipProps> = ({
  active,
  payload,
  colorMetric,
  label,
}) => {
  if (!active || !payload || !payload.length) {
    return null;
  }

  const uniquePayload = useMemo(() => {
    const visited: { [key: string]: boolean } = {};
    const result = [];

    payload.forEach((p) => {
      if (!visited[p.name]) {
        visited[p.name] = true;
        result.push(p);
      }
    });

    return result;
  }, [payload]);

  const payloadJSX = uniquePayload.map((p, i) => {
    return (
      <div key={i} className="performance-tooltip__row">
        <div className="performance-tooltip__rowLabel body-small">
          <div
            className="sales-performance__legendCircle"
            style={{ backgroundColor: colorMetric[p.name] }}
          />
          {mapCheckKeyToMetricKey(p.name)}
        </div>
        <div className="performance-tooltip__rowValue body-small">
          {formatValue(p.name, p.value)}
        </div>
      </div>
    );
  });

  return (
    <div className="performance-tooltip">
      <div className="performance-tooltip__date">{label}</div>
      {payloadJSX}
    </div>
  );
};
