import { ProductTab } from ".";

export const getTabs = (productTwo: boolean) => {
  return productTwo
    ? [
        { tab: "myProducts", label: "Our Products" },
        { tab: "allProducts", label: "Other Products" },
      ]
    : ([{ tab: "myProducts", label: "Our Products" }] satisfies {
        tab: ProductTab;
        label: string;
      }[]);
};

export const getRouteFlags = (pathname: string) => ({
  isCompareRoute: pathname.includes("/compare"),
  isMyRoute: pathname.includes("/my-product"),
});
export const getPaginatedItems = <T>(
  items: T[],
  currentPage: number,
  itemsPerPage: number,
): T[] => {
  return items.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage,
  );
};
export const getPaginationRange = (
  currentPage: number,
  totalPages: number,
  paginationStart: number,
  maxVisiblePages: number = 5,
) => {
  const range: (number | string)[] = [];
  const visiblePagesEnd = paginationStart + maxVisiblePages - 1;

  if (paginationStart > 1) {
    range.push(1);
    range.push("...");
  }

  for (
    let i = paginationStart;
    i <= Math.min(visiblePagesEnd, totalPages);
    i++
  ) {
    range.push(i);
  }

  if (visiblePagesEnd < totalPages) {
    range.push("...");
    range.push(totalPages);
  }

  return range;
};
