import { Api } from "src/utils/api";
import { AMAZON_URL } from "../amazonUrl";
import { CategoryData } from "src/types/spider.types";

export class CategoriesService extends Api {
  constructor() {
    super(AMAZON_URL);
  }

  public async getCategories(asin = ""): Promise<CategoryData> {
    return this.get("/categories", { asin });
  }
  public async getStats(asin = ""): Promise<CategoryData> {
    return this.get("/reviews/stats", { asin });
  }
  public async getBrands(): Promise<CategoryData> {
    return this.get("/products/own/brands");
  }
}
