import { create } from "zustand";
import { IWidget } from "@pages/Dashboard/types/dashboard.types.ts";
import { initializeCalendarData } from "src/utils/initialCalendarData";
import {
  WIDGET_METRIC_KEYS,
  WidgetMetricKey,
} from "@services/math/math.service";

export interface IDashboardState {
  widgets: WidgetMetricKey[];
  availableWidgets: WidgetMetricKey[];
  modalWidgets: IWidget[];
  selectedWidgets: WidgetMetricKey[];
  dateRange: {
    startDate: Date;
    endDate: Date;
  };
  setWidgets: (widgets: WidgetMetricKey[]) => void;
  setAvailableWidgets: (widgets: WidgetMetricKey[]) => void;
  setModalWidgets: (widgets: IWidget[]) => void;
  setSelectedWidgets: (widgets: WidgetMetricKey[]) => void;
  setDateRange: (range: { startDate: Date; endDate: Date }) => void;
  updateWidgets: (
    widgets: WidgetMetricKey[],
    availableWidgets: WidgetMetricKey[],
  ) => void;
  reset: () => void;
}

const { today, monday } = initializeCalendarData();

export const useDashboardStore = create<IDashboardState>((set) => ({
  widgets: [],
  availableWidgets: [...WIDGET_METRIC_KEYS],
  modalWidgets: [],
  selectedWidgets: [
    "salesTotal",
    "adsClicks",
    "adsSales",
    "adsSpend",
    "adsAcos",
    "adsRoas",
    "adsCpc",
    "adsCvr",
    "adsCtr",
  ],
  dateRange: {
    endDate: today,
    startDate: monday,
  },
  setWidgets: (widgets: WidgetMetricKey[]) => set({ widgets }),
  setAvailableWidgets: (availableWidgets: WidgetMetricKey[]) => {
    if (!Array.isArray(availableWidgets)) {
      return;
    }
    set({ availableWidgets });
  },
  setModalWidgets: (modalWidgets: IWidget[]) => set({ modalWidgets }),
  setSelectedWidgets: (selectedWidgets: WidgetMetricKey[]) =>
    set({ selectedWidgets }),
  setDateRange: (range: { startDate: Date; endDate: Date }) =>
    set({ dateRange: range }),
  updateWidgets: (
    widgets: WidgetMetricKey[],
    availableWidgets: WidgetMetricKey[],
  ) => {
    set({ widgets, availableWidgets });
  },
  reset: () =>
    set({
      widgets: [],
      availableWidgets: [],
      modalWidgets: [],
      dateRange: { startDate: new Date(), endDate: new Date() },
    }),
}));
