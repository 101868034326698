import React, { useState } from "react";
import { NavItem, createNavItems } from "./navItems";
import { ROUTES } from "src/router/routes";
import { useOutsideClick } from "src/utils/useOutsideClick";
import { useUserStore } from "src/store/user.state";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

interface HeaderNavProps {
  menuOpens: boolean;
  setMenuOpens: (state: boolean) => void;
}

export const HeaderNav: React.FC<HeaderNavProps> = ({
  menuOpens,
  setMenuOpens,
}) => {
  const { t } = useTranslation();
  const { user } = useUserStore();
  const [menuOpen, setMenuOpen] = useState<boolean>(false);

  const popupRef = useOutsideClick(() => {
    setMenuOpen(false);
    setMenuOpens(false);
  });

  const navItems: NavItem[] = [
    {
      text: t("label.dashboard"),
      url: `${ROUTES.ROOT}`,
      onClick: () => setMenuOpens(false),
    },
    {
      text: t("Revenue"),
      onClick: () => {
        setMenuOpen(!menuOpen);
      },
    },
    {
      text: t("finance"),
      url: "/finance",
      onClick: () => setMenuOpens(false),
    },
    {
      text: t("Supply chain"),
      url: "/supply-chain",
      onClick: () => setMenuOpens(false),
    },
    {
      text: t("Market Intelligence"),
      url: "/market-intelligence",
      onClick: () => setMenuOpens(false),
    },
    {
      text: t("AUDIT"),
      url: "/canvas",
      onClick: () => setMenuOpens(false),
    },
  ];

  if (user?.is_staff) {
    navItems.push({
      text: t("Customers"),
      url: "/customers",
      onClick: () => setMenuOpens(false),
    });
  }

  return (
    <nav
      className={classNames("app-header__nav", {
        "app-header__nav--open": menuOpens,
      })}
      ref={popupRef}
    >
      {createNavItems(navItems, menuOpen, setMenuOpen, popupRef, setMenuOpens)}
    </nav>
  );
};
