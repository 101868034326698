import { v4 as uuidv4 } from "uuid";
import { Tool, Message, AgentApiResponse } from "./types";

export const PREFIX_METRIC = "ads_sales_";
export const PREFIX_PERFORMANCE = "sales_performance_";

function parseData(data: string): any {
  try {
    return JSON.parse(data);
  } catch (error) {
    console.error("Error parsing data:", error);
    return null;
  }
}

const handleToolResponse = (
  tool: Tool,
  addMessage: (message: Message) => void,
  finalResult: string,
) => {
  const { data, type } = tool;

  if (type.startsWith(PREFIX_METRIC)) {
    // Check if data is "0" or empty, and final message indicates an error
    const isDataInvalid =
      data === "0" || !data || data.toLowerCase().includes("error");

    const isErrorMessage =
      finalResult.startsWith("I'm sorry") ||
      finalResult.includes("unable to fetch") ||
      finalResult.includes("there was an error fetching the data");

    if (isDataInvalid && isErrorMessage) {
      addMessage({
        id: uuidv4(),
        text: finalResult,
        sender: "assistant",
        type: "text",
      });
    } else {
      // Display both the text message and the metric value
      const value = parseFloat(data);
      addMessage({
        id: uuidv4(),
        text: "",
        sender: "assistant",
        type: "metric_value",
        data: value,
      });
      addMessage({
        id: uuidv4(),
        text: finalResult,
        sender: "assistant",
        type: "text",
      });
    }
  } else if (type.startsWith(PREFIX_PERFORMANCE)) {
    const chartData = parseData(data);
    if (chartData && chartData.length > 0) {
      addMessage({
        id: uuidv4(),
        text: "",
        sender: "assistant",
        type: "performance_chart",
        data: chartData,
      });
    }
    addMessage({
      id: uuidv4(),
      text: finalResult,
      sender: "assistant",
      type: "text",
    });
  } else if (type === "get_product_spider") {
    const spiderData = parseData(data);
    if (spiderData) {
      addMessage({
        id: uuidv4(),
        text: "",
        sender: "assistant",
        type: "get_product_spider",
        data: spiderData,
      });
    }
    addMessage({
      id: uuidv4(),
      text: finalResult,
      sender: "assistant",
      type: "text",
    });
  } else {
    // Default handling
    addMessage({
      id: uuidv4(),
      text: finalResult,
      sender: "assistant",
      type: "text",
    });
  }
};

export async function getFinancialAgent({
  responseData,
  addMessage,
}: {
  responseData: AgentApiResponse;
  addMessage: (message: Message) => void;
}) {
  const tool = responseData.tools[0];

  if (tool) {
    handleToolResponse(tool, addMessage, responseData.finalMessage);
  } else {
    addMessage({
      id: uuidv4(),
      text: responseData.finalMessage,
      sender: "assistant",
      type: "text",
    });
  }
}
