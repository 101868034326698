import { useEffect, useState } from "react";
import { ApiResponse } from "src/types/apiResponce";
import { useProductStore } from "src/store/overviewProduct.state";
import { OptimizationService } from "@services/optimization/optimization.services";
import styles from "./styles.module.scss";
import WidgetIcon from "@assets/icons/widgetsDNDBtn";
import { AnalyzeProductPhoto } from "@services/amazon/products/types";
import { useLocation } from "react-router-dom";
import UpdateRecommendations from "@pages/CROModule/components/Product/Optimisation/components/UpdateRecommendations";

interface UpdatePhotoWidgetProps {
  onStartGeneration?: () => void;
}

const UpdatePhotoWidget = ({ onStartGeneration }: UpdatePhotoWidgetProps) => {
  const [analyzeData, setAnalyzeData] =
    useState<ApiResponse<AnalyzeProductPhoto> | null>(null);
  const [recommendationData, setRecommendationData] = useState<string | null>(
    null,
  );
  const { selectedProduct } = useProductStore();
  const optimizationService = new OptimizationService();
  const [loadingAnalyzeData, setLoadingAnalyzeData] = useState(false);
  const [loadingRecommendationData, setLoadingRecommendationData] =
    useState(false);

  const location = useLocation();

  const { variant } = location.state || {};

  useEffect(() => {
    if (!selectedProduct?.asin) return;

    const fetchPhotoAnalysis = async () => {
      setLoadingAnalyzeData(true);
      try {
        const res = await optimizationService.getAnalyzePhotoByAsin(
          selectedProduct.asin,
        );

        setAnalyzeData(res as any);
      } catch (error) {
        console.error("Error fetching product analysis:", error);
      } finally {
        setLoadingAnalyzeData(false);
      }
    };

    fetchPhotoAnalysis();
  }, [selectedProduct]);

  const fetchAnalyzeSuggestions = async () => {
    setLoadingRecommendationData(true);
    try {
      if (!selectedProduct?.asin) return;

      const optimizationService = new OptimizationService();
      const res = await optimizationService.getAnalyzePhotoSuggestionsByAsin(
        selectedProduct.asin,
        100,
      );

      setRecommendationData(res as any);
    } catch (error) {
      console.error("Error fetching product analysis:", error);
    } finally {
      setLoadingRecommendationData(false);
    }
  };

  return (
    <div className={styles.editor}>
      <WidgetIcon />
      <div className={styles.row}>
        <UpdateRecommendations
          loadingAnalyzeData={loadingAnalyzeData}
          loadingRecommendationData={loadingRecommendationData}
          analyzeData={analyzeData}
          recommendationData={recommendationData}
          onToggleRecommendation={fetchAnalyzeSuggestions}
          id={variant}
          onStartGeneration={onStartGeneration}
        />
      </div>
    </div>
  );
};

export default UpdatePhotoWidget;
