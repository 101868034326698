import { ROUTES } from "src/router/routes";

export const optimizationData = [
  {
    id: 1,
    label: "Text Optimization",
    percentage: 45,
    color: "#80C67A",
    route: ROUTES.CRO_OPTIMISATIONDETAINS,
  },
  {
    id: 2,
    label: "Image Optimizations",
    percentage: 70,
    color: "#186C58",
    route: ROUTES.CRO_PHOTOOPTIMISATION,
  },
  {
    id: 3,
    label: "Video Optimizations",
    percentage: 15,
    color: "#342460",
    route: ROUTES.CRO_VIDEOOPTIMISATION,
  },
  {
    id: 4,
    label: "A+ Сontent Optimizations",
    percentage: 0,
    color: "#FFFFFF",
    text: "Not optimized",
    route: "/a-plus-content-optimization",
  },
];
