import { create } from "zustand";

interface Metric {
  id: string;
  name: string;
  checked: boolean;
}

interface IMetrictState {
  selectedMetric: Metric[];
  setSelectedMetric: (metrics: Metric[]) => void;
  availableWidgets: Metric[];
  setAvailableWidgets: (widgets: Metric[]) => void;
}

export const useMetricStore = create<IMetrictState>((set) => ({
  availableWidgets: [],
  selectedMetric: [],
  setSelectedMetric: (metrics) => set({ selectedMetric: metrics }),
  setAvailableWidgets: (widgets) => set({ availableWidgets: widgets }),
}));
