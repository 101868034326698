import styles from "./styles.module.scss";
import { Input } from "@components/Input";
import Search from "@assets/icons/search";

interface SearchWidgetProps {
  label: string;
  initioalValue: string;
  onChange: (value: string) => void;
}

export default function SearchWidget({
  label,
  initioalValue,
  onChange,
}: SearchWidgetProps) {
  return (
    <div className={styles.search}>
      <label htmlFor="search">{label}</label>
      <Input
        value={initioalValue}
        placeholder="Search elements..."
        onChange={onChange}
        className={styles.input}
        icon={<Search />}
      />
    </div>
  );
}
