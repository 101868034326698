import { CheckWidget } from "@components/CheckWidget";
import { AdsWidget } from "../../utils";

type Checks = {
  sales: boolean;
  spend: boolean;
  impressions: boolean;
  ctr: boolean;
  cvr: boolean;
  cpc: boolean;
  acos: boolean;
  roas: boolean;
  orders: boolean;
  unitSolds: boolean;
};

interface WidgetsProps {
  checks: Checks;
  setChecks: (checks: string) => void;
  widgetsData: AdsWidget[];
}

export const Widgets = ({ checks, setChecks, widgetsData }: WidgetsProps) => {
  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "repeat(5, 1fr)",
        gap: "10px",
      }}
    >
      {widgetsData.map((card) => (
        <CheckWidget
          key={card.title}
          title={card.title}
          value={card.value.toLocaleString()}
          increase={card.increase.toLocaleString()}
          increaseDirection={card.increaseDirection as "up" | "down"}
          isChecked={
            checks[
              card.title === "Units Sold"
                ? "unitSolds"
                : card.title.toLowerCase().replace(" ", "")
            ]
          }
          onChange={setChecks}
          color={card.color}
        />
      ))}
    </div>
  );
};
