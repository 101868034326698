import classNames from "classnames";
import styles from "./styles.module.scss";
import SectionContainer from "../components/SectionContainer";
import { formatName, getValueWithLabel } from "./utils";
import { MetricCard } from "./MetricCard";
import React, { useEffect, useState } from "react";
import { ChevronDown } from "lucide-react";
import { useDashboardStore } from "@pages/Dashboard/store/dashboard.state";
import { subWeeks, endOfWeek, startOfWeek } from "date-fns";
import { IWidget } from "@pages/Dashboard/types/dashboard.types";
import { ConfigModal } from "@components/config-modal";
import { useSectionStore } from "src/store/croModule.state";
import { useOutsideClick } from "src/utils/useOutsideClick";
import { WidgetClass } from "@services/widget.service";
import { useAdsAnaliticsStore } from "src/store/ads-analitics.store";

interface MetricBuilderProps {
  customTitle?: string;
}

const MetricBuilder = ({ customTitle }: MetricBuilderProps) => {
  const { isWhatIf } = useSectionStore((state) => ({
    isWhatIf: state.isWhatIf,
  }));
  const { setWidgets, selectedWidgets, dateRange, setSelectedWidgets } =
    useDashboardStore((state) => ({
      setWidgets: state.setWidgets,
      widgets: state.widgets,
      dateRange: state.dateRange,
      selectedWidgets: state.selectedWidgets,
      setSelectedWidgets: state.setSelectedWidgets,
    }));
  const { compareWith } = useAdsAnaliticsStore();
  const [widgetList, setWidgetList] = useState<IWidget[]>([]);
  const [isOpenTab, setIsOpenTab] = useState<boolean>(false);
  const [isTabOnTop] = useState<boolean>(false);
  const buttonRef = useOutsideClick(() => {
    setIsOpenTab(false);
  }, "mouseup");

  useEffect(() => {
    const fetch = async () => {
      const widgetService = new WidgetClass();
      const dateStartString = dateRange.startDate.toISOString().split("T")[0];
      const dateEndString = dateRange.endDate.toISOString().split("T")[0];
      const compareStartDate = startOfWeek(subWeeks(dateRange.startDate, 1))
        .toISOString()
        .split("T")[0];
      const compareEndDate = endOfWeek(subWeeks(dateRange.startDate, 1))
        .toISOString()
        .split("T")[0];

      const res = await widgetService.getWidgetsData(
        selectedWidgets,
        dateStartString,
        dateEndString,
        compareStartDate,
        compareEndDate,
      );

      setWidgetList(res);
    };
    fetch();
  }, [selectedWidgets, dateRange, compareWith]);

  return (
    <SectionContainer>
      <div className={styles.filters}>
        <h3 className={styles.title}>{customTitle || "Metrics"}</h3>
        <div className={styles.selects}>
          <div
            className={classNames(styles.selectBox, isTabOnTop && styles.onTop)}
          >
            <button
              onClick={() => {
                setIsOpenTab(!isOpenTab);
              }}
              className={classNames(
                styles.selectBoxLabel,
                isOpenTab && styles.active,
              )}
            >
              {widgetList.length || "12"} metrics selected{" "}
              <div className={styles.arrow}>
                <ChevronDown size={24} />
              </div>
            </button>
            {isOpenTab && (
              <div className={classNames(styles.config)} ref={buttonRef}>
                <ConfigModal
                  buttonWidth={100}
                  closeModal={() => setIsOpenTab(false)}
                  selectedWidgets={selectedWidgets}
                  setWidgets={setWidgets}
                  maxSelectedWidgets={12}
                  setSelectedWidgets={setSelectedWidgets}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <div className={styles.metricsGrid}>
        {widgetList.map((metric, i) => {
          const profit = metric.difference.startsWith("+");
          const color = profit ? "#80C67A" : "#FE5858";

          if (!isWhatIf) {
            return (
              <div className={styles.card}>
                <div className={styles.header}>
                  <span>{formatName(metric.name)}</span>
                  <span style={{ color }}>{metric.difference}%</span>
                </div>
                <span className={styles.value}>
                  {getValueWithLabel(metric.name, Number(metric.value))}
                </span>
              </div>
            );
          }
          return (
            <React.Fragment key={i}>
              <MetricCard metric={metric} />
              {i !== 0 && (i + 1) % 6 === 0 && (
                <div className={styles.metricsGridSeparator}></div>
              )}
            </React.Fragment>
          );
        })}
      </div>
    </SectionContainer>
  );
};

export default MetricBuilder;
