import React, { useEffect, useRef, useState } from "react";
import classNames from "classnames";
import styles from "./styles.module.scss";
import CheckIcon from "@assets/icons/CheckIcon.svg";
import ProductCode from "@components/ProductCode";
import { useProductStore } from "src/store/overviewProduct.state";
import { Product } from "@services/amazon/products/types";

interface CategoryPaginationProps {
  paginatedItems: Product[];
  activeTab: string;
  handleSelect: (item: any) => void;
}

const CategoryPagination: React.FC<CategoryPaginationProps> = ({
  paginatedItems,
  activeTab,
  handleSelect,
}) => {
  const { selectedProductToCompare } = useProductStore();

  return paginatedItems && paginatedItems.length > 0 ? (
    paginatedItems.map((item: any, itemIndex: number) => (
      <div
        key={`${activeTab}-${item.asin}-${itemIndex}`}
        className={styles.productCard}
      >
        <img
          src={item.image || item.picture_url || ""}
          alt={item.title || "Product"}
          className={styles.productImage}
          width={124}
          height={124}
        />
        <ProductName name={item.title} />
        <div className={styles.productDetails}>
          <ProductCode code={item.asin} />
        </div>
        <button
          className={classNames(styles.selectButton, {
            [styles.selected]: selectedProductToCompare.some(
              (p) => p.asin === item.asin,
            ),
          })}
          onClick={() => handleSelect(item)}
        >
          {selectedProductToCompare.some((p) => p.asin === item.asin) ? (
            <>
              <img
                src={CheckIcon}
                alt="Selected"
                className={styles.checkIcon}
              />
              SELECT
            </>
          ) : (
            "SELECT"
          )}
        </button>
      </div>
    ))
  ) : (
    <p></p>
  );
};
export const ProductName: React.FC<{ name: string }> = ({ name }) => {
  const [isTruncated, setIsTruncated] = useState(false);
  const productNameRef = useRef<HTMLParagraphElement>(null);

  useEffect(() => {
    const element = productNameRef.current;
    if (element) {
      setIsTruncated(element.scrollHeight > element.clientHeight);
    }
  }, [name]);

  return (
    <p
      ref={productNameRef}
      className={classNames(styles.productName, {
        [styles.truncated]: isTruncated,
      })}
    >
      {name}
    </p>
  );
};
export default CategoryPagination;
