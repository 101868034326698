import { Checkbox, Button } from "antd";
import InfoIcon from "@assets/icons/i.svg";

export const createDropdownContent = (columns, toggleColumn, handleApply) => {
  return (
    <div
      style={{
        width: "258px",
        height: "346px",
        background: "#1F1F20",
        padding: "16px",
        borderRadius: "8px",
        display: "flex",
        flexDirection: "column",
        gap: "8px",
      }}
    >
      <h4
        style={{
          color: "#7C7C78",
          margin: "0 0 8px",
          fontSize: "12px",
          fontWeight: "700",
          lineHeight: "14px",
          letterSpacing: "-0.2px",
        }}
      >
        Columns
      </h4>
      {columns.map((column) => (
        <div
          key={column.name}
          style={{ display: "flex", alignItems: "center" }}
        >
          <Checkbox
            style={{
              color: "#FFF",
              fontSize: "14px",
              fontWeight: "500",
              lineHeight: "20px",
              letterSpacing: "-0.2px",
              textTransform: "none",
            }}
            checked={column.checked}
            onChange={() => toggleColumn(column.name)}
          >
            <span style={{ display: "flex", alignItems: "center", gap: "8px" }}>
              {column.name.toLowerCase().replace(/^\w/, (c) => c.toUpperCase())}{" "}
              <img
                src={InfoIcon}
                alt="Info"
                style={{ width: "16px", height: "16px" }}
              />
            </span>
          </Checkbox>
        </div>
      ))}

      <Button
        type="primary"
        style={{
          marginTop: "auto",
          width: "100%",
          background: "#5295E033",
          borderColor: "transparent",
          boxShadow: "none",
          borderRadius: "8px",
          color: "#5295E0",
        }}
        onClick={handleApply}
      >
        Apply
      </Button>
    </div>
  );
};
