import { PieChart, Pie, Tooltip } from "recharts";
import styles from "./styles.module.scss";
import { calculateLabelPositions } from "./mock";
import CustomLegend from "../Month";
import { COLORS, data, legendOrder, renderInnerDots } from "./constant";

const SubscriptionCountChart = () => {
  const totalSubscriptions = data.reduce((acc, item) => acc + item.value, 0);

  let cumulativeAngle = -70;
  const overlapDegree = 20;

  const pieData = data.map((entry, index) => {
    const angle = (entry.value / totalSubscriptions) * 360;
    const startAngle = cumulativeAngle + overlapDegree;
    const endAngle = cumulativeAngle - angle - overlapDegree;
    cumulativeAngle = endAngle;
    return { ...entry, startAngle, endAngle, color: COLORS[index] };
  });

  const radius = 95;
  const offset1 = 30;
  const offsetHorizontal = 15;

  const labelPositions = calculateLabelPositions(
    pieData,
    radius,
    offset1,
    offsetHorizontal,
  );

  const legendPayload = data
    .map((item, index) => ({
      id: item.name,
      type: "circle",
      value: `${item.name} (${((item.value / totalSubscriptions) * 100).toFixed(
        0,
      )}%)`,
      color: COLORS[index % COLORS.length],
    }))
    .sort((a, b) => legendOrder.indexOf(a.id) - legendOrder.indexOf(b.id));

  return (
    <div className={styles.container}>
      <div className={styles.title}>Subscription Count</div>
      <PieChart width={372} height={230}>
        {renderInnerDots(185, 115, 75)}
        {pieData.map((entry, index) => (
          <Pie
            key={`slice-${index}`}
            data={[entry]}
            cx="50%"
            cy="50%"
            innerRadius={65}
            outerRadius={95}
            startAngle={entry.startAngle}
            endAngle={entry.endAngle}
            dataKey="value"
            stroke="none"
            fill={entry.color}
            cornerRadius={15}
          />
        ))}
        <Tooltip />
      </PieChart>
      <div className={styles.totalSubscriptions}>
        {totalSubscriptions.toLocaleString()}
      </div>
      <svg width="404" height="434" className={styles.svgContainer}>
        {labelPositions.map((pos, index) => (
          <g key={index}>
            <line
              x1={pos.startX}
              y1={pos.startY}
              x2={pos.midX}
              y2={pos.midY}
              stroke="white"
              strokeWidth="2"
            />
            <line
              x1={pos.midX}
              y1={pos.midY}
              x2={pos.endX}
              y2={pos.endY}
              stroke="white"
              strokeWidth="2"
            />
            <text
              x={pos.textX}
              y={pos.textY}
              fill="white"
              fontSize="14"
              textAnchor={pos.textAnchor}
            >
              {pos.value.toLocaleString()}
            </text>
          </g>
        ))}
      </svg>
      <CustomLegend payload={legendPayload} />
    </div>
  );
};

export default SubscriptionCountChart;
