import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { IWidget } from "@pages/Dashboard/types/dashboard.types.ts";
import { Widget } from "@pages/Dashboard/features/Widgets/Widget";
import { Spin } from "antd";
import classNames from "classnames";

interface WidgetsListProps {
  widgets?: IWidget[];
  containerClassName?: string;
  itemClassName?: string;
  isLoading?: boolean;
}

const WidgetsList: React.FC<WidgetsListProps> = ({
  widgets,
  isLoading,
  containerClassName,
  itemClassName,
}) => {
  const [widgetList, setWidgetList] = useState<IWidget[]>(widgets);
  const [draggingIndex, setDraggingIndex] = useState<number | null>(null);
  const [hoverIndex, setHoverIndex] = useState<number | null>(null);

  useEffect(() => {
    if (!widgets) return;
    setWidgetList(widgets.slice(0, 10));
  }, [widgets]);

  const handleDragStart = (index: number) => {
    setDraggingIndex(index);
  };

  const handleDrop = (dropIndex: number) => {
    if (draggingIndex !== null && draggingIndex !== dropIndex) {
      const updatedList = [...widgetList];
      const draggedWidget = updatedList[draggingIndex];

      updatedList.splice(draggingIndex, 1);
      updatedList.splice(dropIndex, 0, draggedWidget);

      setWidgetList(updatedList);
    }

    setDraggingIndex(null);
    setHoverIndex(null);
  };

  const handleDragOver = (
    e: React.DragEvent<HTMLDivElement>,
    index: number,
  ) => {
    e.preventDefault();
    setHoverIndex(index);
  };

  const handleDragEnd = () => {
    setDraggingIndex(null);
    setHoverIndex(null);
  };

  return (
    <div className={styles.widgetsList}>
      <div className={classNames(styles.flexBox, containerClassName)}>
        {isLoading ? (
          <div className={styles.loader}>
            <Spin size="large" />
          </div>
        ) : (
          widgetList &&
          widgetList.length &&
          widgetList.map((widget, index) => (
            <div
              key={widget.id}
              className={classNames(
                styles.widgetItem,
                itemClassName,
                draggingIndex === index ? styles.dragging : "",
                hoverIndex === index ? styles.placeholder : "",
              )}
              draggable
              onDragStart={() => handleDragStart(index)}
              onDrop={() => handleDrop(index)}
              onDragOver={(e) => handleDragOver(e, index)}
              onDragEnd={handleDragEnd}
            >
              <Widget widget={widget} />
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default React.memo(WidgetsList);
