import React, { useState, useEffect } from "react";
import styles from "./styles.module.scss";
import tickMark from "@assets/icons/tickMark.png";
import { optimizationContent } from "./mock";
import Pointer from "@assets/icons/pointer";
import Graph from "./components/Graph";
import SectionContainer from "../components/SectionContainer";
import CustomInput from "@components/СustomInput";
import { CROInfoType } from "@services/amazon/products/types";

interface TotalOptimizationProps {
  croInfo: CROInfoType;
  isWhatIf: boolean;
  totalPOS: string;
  brand: any;
}

function TotalOptimization({
  isWhatIf,
  croInfo,
  totalPOS,
  brand,
}: TotalOptimizationProps) {
  const [percentage, setPercentage] = useState(1);
  const [color, setColor] = useState("#FE5858");
  const [isProblemClosed, setIsProblemClosed] = useState(true);
  const [content, setContent] = useState(optimizationContent[0]);

  useEffect(() => {
    if (croInfo) setPercentage(Math.round(croInfo.total_pos));
  }, [croInfo]);

  useEffect(() => {
    const currentPercentage = totalPOS
      ? Math.round(Number(totalPOS))
      : percentage;

    if (currentPercentage < 33) {
      setColor("#FE5858");
      setContent(optimizationContent[0]);
    } else if (currentPercentage < 66) {
      setColor("#D89A3D");
      setContent(optimizationContent[1]);
    } else {
      setContent(optimizationContent[1]);
      setColor("#80C67A");
    }
  }, [totalPOS, percentage]);

  const angle =
    -60 + ((totalPOS ? Math.round(Number(totalPOS)) : percentage) / 100) * 260;

  return (
    <SectionContainer className={styles.container}>
      <div className={styles.gaugeContainer}>
        <div className={styles.gauge}>
          <img src={tickMark} alt="Tick Mark" className={styles.tickMark} />
          <div className={styles.pointerContainer}>
            <Pointer angle={angle} color={color} />
          </div>
          <div className={styles.graphContainer}>
            <Graph
              percentage={totalPOS ? Math.round(Number(totalPOS)) : percentage}
              color={color}
            />
          </div>
        </div>
        {isProblemClosed && (
          <div className={styles.content}>
            <h3 className={styles.title}>
              {totalPOS ? "Product optimization score" : "Score"}
            </h3>

            <p className={styles.description}>
              {content.description.split(" <br /> ").map((line, index) => (
                <React.Fragment key={index}>
                  {line}
                  <br />
                </React.Fragment>
              ))}
            </p>
          </div>
        )}
        <span className={styles.gaugeValue}>
          {totalPOS} {totalPOS ? "" : `${percentage}%`}
        </span>
      </div>
      {isWhatIf && (
        <div className={styles.card}>
          <CustomInput
            label={"POS %"}
            value={percentage}
            onChange={(e) => {
              setPercentage(parseInt(e.target.value));
            }}
            className={styles.input}
            blue
          />
        </div>
      )}
    </SectionContainer>
  );
}

export default TotalOptimization;
