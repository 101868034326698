export const slideUp = (target: HTMLElement, duration: number) => {
  const height = target.offsetHeight;
  target.style.height = height + "px";
  target.offsetHeight;
  target.style.height = "0";
  target.style.overflow = "hidden";
  target.style.paddingTop = "0";
  target.style.paddingBottom = "0";
  target.style.marginTop = "0";
  target.style.marginBottom = "0";
  target.style.transitionProperty = "height, margin, padding";
  target.style.transitionDuration = duration + "ms";
  setTimeout(() => {
    target.hidden = true;
    target.style.overflow = "";
    target.style.height = "";
    target.style.paddingTop = "";
    target.style.paddingBottom = "";
    target.style.marginTop = "";
    target.style.marginBottom = "";
    target.style.transitionProperty = "";
    target.style.transitionDuration = "";
  }, duration);
};

export const slideDown = (target: HTMLElement, duration: number) => {
  target.hidden = false;
  const height = target.offsetHeight;
  target.style.height = "0";
  target.style.overflow = "hidden";
  target.style.paddingTop = "0";
  target.style.paddingBottom = "0";
  target.style.marginTop = "0";
  target.style.marginBottom = "0";
  target.offsetHeight;
  target.style.height = height + "px";
  target.style.transitionProperty = "height, margin, padding";
  target.style.transitionDuration = duration + "ms";
  setTimeout(() => {
    target.style.overflow = "";
    target.style.height = "";
    target.style.paddingTop = "";
    target.style.paddingBottom = "";
    target.style.marginTop = "";
    target.style.marginBottom = "";
    target.style.transitionProperty = "";
    target.style.transitionDuration = "";
  }, duration);
};
