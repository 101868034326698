import React, { useEffect, useState } from "react";
import ReactEcharts from "echarts-for-react";
import { TreeNode, TooltipData } from "../chart.types";
import { mockTooltipData } from "./tooltip.const";
import { TreeChartClass } from "../tree-chart.class";
import { Tooltip } from "@components/Tooltip/tooltip";
import { SankeyApi } from "@services/sankey/sankey";
import { useDashboardStore } from "@pages/Dashboard/store/dashboard.state";
import { Spinner } from "@components/ui-kit/Spinner";

interface TreeChartProps {
  data: TreeNode;
  isAdType?: boolean;
  asin?: string;
}

const getOffsetMultiplier = (childrenCount: number) => {
  if (childrenCount <= 5) {
    return 150 / childrenCount;
  }
  if (childrenCount <= 10) {
    return 35;
  }
  if (childrenCount <= 20) {
    return 15;
  }
  if (childrenCount <= 30) {
    return 15;
  }
  return 13;
};

export const TreeChart: React.FC<TreeChartProps> = ({
  data,
  isAdType,
  asin,
}) => {
  const { dateRange } = useDashboardStore((state) => ({
    dateRange: state.dateRange,
  }));
  const [isLoading, setIsLoading] = useState(false);
  const [tooltipData, setTooltipData] = useState<TooltipData | null>(null);
  const [chartData, setChartData] = useState<TreeNode | null>(null);
  const [tooltipPositionY, setTooltipPositionY] = useState(null);
  const [tooltipWithButton, setTooltipWithButton] = useState(false);
  const [tooltipWithImg, setTooltipWithImg] = useState(false);
  const chartInstance = new TreeChartClass();

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      if (!isAdType) {
        setChartData(() => {
          chartInstance.applyGradientToNodes(data.children);
          return data;
        });
        setIsLoading(false);
        return;
      }
      const sankeyApi = new SankeyApi();
      const { startDate, endDate } = dateRange;
      const startDateString = startDate.toISOString().split("T")[0];
      const endDateString = endDate.toISOString().split("T")[0];

      await sankeyApi
        .getTreeData(asin, startDateString, endDateString)
        .then((data) => {
          setChartData(() => {
            chartInstance.applyGradientToNodes(data.children);
            return data;
          });
        });

      setIsLoading(false);
    };

    fetchData();
  }, [asin, data, dateRange]);

  const clearStyles = () => {
    setChartData((prevState) => {
      const updatedData = chartInstance.resetTreeNodeOpacity(
        prevState.children,
      );
      return {
        name: prevState.name,
        itemStyle: { ...chartInstance.firstLastLevelStyles, opacity: 1 },
        children: updatedData,
      };
    });
    setTooltipData(null);
    setTooltipPositionY(null);
  };

  const onChartClick = (params: any) => {
    if (tooltipWithButton) {
      setTooltipWithButton(false);
    }
    if (tooltipWithImg) {
      setTooltipWithImg(false);
    }
    if (params.event.target.culling) {
      return;
    }

    if (params.data) {
      const tooltipName = chartInstance
        .findNodePath(chartData, params.name)
        .join(" -- ");

      const updatedData = {
        ...mockTooltipData,
        name: tooltipName,
      };
      setTooltipData(updatedData);

      const topChildrenCount = chartInstance.countTopChildren(
        chartInstance.updateTreeNode(chartData.children, params.name),
      );

      const rootOffsetY = topChildrenCount
        ? params.event.offsetY -
          topChildrenCount * getOffsetMultiplier(topChildrenCount)
        : params.event.offsetY - 150;

      setTooltipPositionY(rootOffsetY);

      if (params.event.target.style.text.startsWith("Nyl") && isAdType) {
        setTooltipWithImg(true);
      }

      if (params.event.target.style.textAlign === "right") {
        setTooltipWithButton(true);
      }

      setChartData((prevState) => {
        if (params.name === prevState.name) {
          return prevState;
        }

        const updatedData = chartInstance.updateTreeNode(
          prevState.children,
          params.name,
        );

        return {
          name: prevState.name,
          itemStyle: { ...chartInstance.firstLastLevelStyles, opacity: 0.1 },
          children: updatedData,
        };
      });
    }
  };

  if (isLoading) {
    return (
      <div>
        <Spinner size="xxl" className="app__spinner" />
      </div>
    );
  }

  if (!chartData) {
    return null;
  }

  return (
    <div style={{ position: "relative", width: "100%" }}>
      <ReactEcharts
        option={chartInstance.getOption(chartData)}
        style={{ height: "6000px", width: "2000px" }}
        onEvents={{ click: onChartClick }}
        onChartReady={(chart) => {
          chart.getZr().on("mousedown", (params: any) => {
            onChartClick(params);
            params.event.stopPropagation();
          });
          chart.getZr().on("mousemove", (params: any) => {
            params.event.stopPropagation();
          });
          chart.getZr().on("mouseup", (params: any) => {
            params.event.stopPropagation();
          });
          chart.getZr().on("mousewheel", (params: any) => {
            params.event.stopPropagation();
          });
        }}
      />
      {tooltipData && tooltipPositionY && (
        <Tooltip
          data={tooltipData}
          clearStyles={clearStyles}
          position={tooltipPositionY}
          withImg={tooltipWithImg}
          withButton={tooltipWithButton}
        />
      )}
    </div>
  );
};
