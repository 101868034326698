export function convertValue(inputString: string, name: string) {
  const cleanedString = inputString.replace(/[^\d.,$%]/g, "");

  const lowerKey = name.toLowerCase();

  const isDollar = cleanedString.startsWith("$");
  const isPercentage = cleanedString.endsWith("%");

  const valueString = cleanedString.replace(/[$%]/g, "");

  const firstDelimiterIndex = valueString.search(/[.,]/);

  let integerPart = valueString;
  let decimalPart = "";

  if (firstDelimiterIndex !== -1) {
    integerPart = valueString.slice(0, firstDelimiterIndex);
    decimalPart = valueString.slice(firstDelimiterIndex + 1);
  }

  integerPart = integerPart.replace(/[.,]/g, "");

  const formattedIntegerPart = parseInt(integerPart, 10).toLocaleString(
    "en-US",
  );

  let result = formattedIntegerPart;

  if (isDollar) {
    result = "$" + result;
  }

  if (isPercentage) {
    result += "%";
  }

  if (result.endsWith(".") || result.endsWith(",")) {
    result = result.slice(0, -1);
  }

  const procentKeys = ["acos", "cvr", "ctr"];
  const dollarKeys = ["spend", "sales", "total"];
  const foundProcentKey = procentKeys.find((key) => lowerKey.includes(key));
  const foundDollarKey = dollarKeys.find((key) => lowerKey.includes(key));
  const formattedNumber = formatNumber(
    parseFloat(inputString),
    !!foundProcentKey,
  );

  let formattedValue: string = formattedNumber;

  if (foundProcentKey) {
    formattedValue = formattedValue + "%";
  } else if (foundDollarKey) {
    formattedValue = "$" + formattedValue;
  }

  return formattedValue;
}

export const formatNumber = (value: number, isPercentage: boolean): string => {
  const formattedValue = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: isPercentage ? 2 : 0,
    maximumFractionDigits: isPercentage ? 2 : 0,
  }).format(value);
  return formattedValue;
};

export const formattedKeys = (spiderDots) =>
  Object.keys(spiderDots).map((key) =>
    key
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" "),
  );
