import { useState } from "react";
import styles from "./styles.module.scss";
import Arrow from "@assets/icons/Arrow";
import { Slider } from "antd";
import "./range.scss";
import classNames from "classnames";
import { RangeCard } from "./RangeCard";
import { MetricCard } from "@pages/CROModule/components/CROPage/components/Sections/MetricBuilder/MetricCard";
import { IWidget } from "@pages/Dashboard/types/dashboard.types";
import { Goals, useAdsAnaliticsStore } from "src/store/ads-analitics.store";
import ModalBox from "@components/ModalBox";
import { useDashboardStore } from "@pages/Dashboard/store/dashboard.state";
import InfoBlue from "@assets/icons/InfoBlue";
import PencilIcon from "@assets/icons/PencilIcon";
import { getInputLabel, formatDate, metrics } from "./utils";
import Clock from "@assets/icons/clock";
import { HitsoryInteractionModal } from "./HistoryInteraction";

const isKeysExist = (keys: {
  performance: string[];
  campaign: string[];
  targeting: string[];
}) => {
  return (
    keys.performance.length > 0 ||
    keys.campaign.length > 0 ||
    keys.targeting.length > 0
  );
};

interface AdSpendProps {
  isSmall?: boolean;
}

export const AdSpend = ({ isSmall = false }: AdSpendProps) => {
  const { dateRange } = useDashboardStore((state) => ({
    dateRange: state.dateRange,
  }));
  const { keys, goals, setGoals, adSlides, setAdSlides, isWhatIfEnabled } =
    useAdsAnaliticsStore((state) => ({
      keys: state.keys,
      goals: state.goals,
      setGoals: state.setGoals,
      adSlides: state.adSlides,
      setAdSlides: state.setAdSlides,
      isWhatIfEnabled: state.isWhatIfEnabled,
    }));
  const [isAdvancedOpen, setIsAdvancedOpen] = useState<boolean>(false);
  const [adSpend, setAdSpend] = useState<number>(0);
  const [bids, setBids] = useState<number>(0);
  const [time, setTime] = useState<number>(0);
  const [top, setTop] = useState<number>(0);
  const [weeks, setWeeks] = useState<number>(0);
  const [openedMetric, setOpenedMetric] = useState<IWidget>();
  const [goalsInputs, setGoalsInputs] = useState<Goals>({
    acos: 0,
    dailySpend: 0,
    dailySales: 0,
  });
  const [historyIteration, setHistoryIteration] = useState(false);
  const [isOpenHistory, setIsOpenHistory] = useState(false);

  const handleAdSpendChange = (value: [number, number]) => {
    const currentValue = value[0] === 0 ? value[1] : value[0];
    setAdSpend(currentValue);
    handleAdSlidesChange("adSpend", currentValue);
  };

  const handleBidsChange = (value: [number, number]) => {
    const currentValue = value[0] === 0 ? value[1] : value[0];
    setBids(currentValue);
    handleAdSlidesChange("adBids", currentValue);
  };

  const handleTimeChange = (value: [number, number]) => {
    const currentValue = value[0] === 0 ? value[1] : value[0];
    setTime(currentValue);
    handleAdSlidesChange("adTime", currentValue);
  };

  const handleTopChange = (value: [number, number]) => {
    const currentValue = value[0] === 0 ? value[1] : value[0];
    setTop(currentValue);
    handleAdSlidesChange("adTop", currentValue);
  };

  const handleWeeksChange = (value: [number, number]) => {
    const currentValue = value[0] === 0 ? value[1] : value[0];
    setWeeks(currentValue);
    handleAdSlidesChange("week", currentValue);
  };

  const handleAdSlidesChange = (key: string, value: number) => {
    setAdSlides({
      ...adSlides,
      [key]: value,
    });
  };

  const handleChangeGoal = (key: string, value: number) => {
    setGoalsInputs({
      ...goals,
      [key]: value,
    });
  };

  const handleSaveGoals = () => {
    setGoals(goalsInputs);
    setOpenedMetric(undefined);
  };

  return (
    <div className={styles.container}>
      {keys && isKeysExist(keys) && !isSmall && (
        <div className={styles.keys}>
          <h2>Selected targets</h2>
          <div className={styles.keys__list}>
            {Object.values(keys)
              .flat()
              .map((key, index) => (
                <div key={index} className={styles.keys__item}>
                  {key}
                </div>
              ))}
          </div>
        </div>
      )}
      <div className={styles.wrapper}>
        <div className={styles.spend}>
          <h3>Ad Spend, %</h3>
          {!isAdvancedOpen && (
            <div className={styles.range}>
              <div className={styles.range__input}>
                <Slider
                  min={-100}
                  max={100}
                  step={1}
                  value={adSpend >= 0 ? [0, adSpend] : [adSpend, 0]}
                  defaultValue={[0, 0]}
                  onChange={handleAdSpendChange}
                  className={classNames(
                    "custom-range",
                    adSpend >= 0 ? "range__slider" : "range__sliderNegative",
                  )}
                  range
                />
              </div>
              <div className={styles.range__result}>
                <input
                  type="number"
                  value={adSpend}
                  onChange={(event) => setAdSpend(Number(event.target.value))}
                  className={styles.range__inputNumber}
                />
              </div>
            </div>
          )}
          <div className={styles.advanced}>
            <button
              className={classNames(
                styles.advanced__button,
                isAdvancedOpen && styles.active,
              )}
              onClick={() => {
                setIsAdvancedOpen(!isAdvancedOpen);
              }}
            >
              Advanced Options <Arrow />
            </button>
          </div>
          {isAdvancedOpen && (
            <>
              <div className={styles.cards}>
                <RangeCard
                  title="Ad Spend, %"
                  value={adSpend}
                  onChange={handleAdSpendChange}
                  isWhatIf={isWhatIfEnabled}
                />
                <RangeCard
                  title="Bids, %"
                  value={bids}
                  onChange={handleBidsChange}
                  isWhatIf={isWhatIfEnabled}
                />
                <RangeCard
                  title="Time in budget, %"
                  value={time}
                  onChange={handleTimeChange}
                  isWhatIf={isWhatIfEnabled}
                  min={0}
                />
                <RangeCard
                  title="Top of search, %"
                  value={top}
                  onChange={handleTopChange}
                  isWhatIf={isWhatIfEnabled}
                  min={0}
                />
              </div>
              <RangeCard
                title="Start Of Optimization, № Week"
                value={weeks}
                onChange={handleWeeksChange}
                isWhatIf={isWhatIfEnabled}
                min={0}
              />
              {!isSmall && !historyIteration && (
                <button
                  className={styles.apply}
                  onClick={() => {
                    setHistoryIteration(true);
                  }}
                >
                  APPLY changes
                </button>
              )}
            </>
          )}
        </div>
        {!isSmall && (
          <div className={styles.charts}>
            <div className={styles.metrics}>
              {metrics.map((metric) => (
                <div className={styles.metric}>
                  <MetricCard
                    key={metric.id}
                    metric={metric}
                    metricColor="#5295E0"
                    goalValue={goals[metric.key]}
                  />
                  {goals[metric.key] === 0 ? (
                    <button
                      className={styles.goals}
                      onClick={() => {
                        setOpenedMetric(metric);
                      }}
                    >
                      Set up goals
                    </button>
                  ) : (
                    <div className={styles.goals__container}>
                      <span>
                        Goal:{" "}
                        {Number(goals[metric.key]).toLocaleString("en-US")}{" "}
                        {metric.key === "acos" ? "%" : "$"}
                      </span>
                      <button
                        onClick={() => {
                          setOpenedMetric(metric);
                        }}
                      >
                        <PencilIcon />
                      </button>
                    </div>
                  )}
                </div>
              ))}
            </div>
            {historyIteration && (
              <button
                className={styles.view__iteration}
                onClick={() => {
                  setIsOpenHistory(!isOpenHistory);
                }}
              >
                <Clock /> View 3 previous iterations
              </button>
            )}
            <HitsoryInteractionModal
              isOpen={isOpenHistory}
              setIsOpen={setIsOpenHistory}
            />
          </div>
        )}
        <ModalBox
          isOpen={!!openedMetric}
          onClose={() => setOpenedMetric(undefined)}
          title={openedMetric?.name}
          className={styles.modal}
        >
          <div className={styles.input__container}>
            <span>{getInputLabel(openedMetric)}</span>
            <input
              type="number"
              value={goalsInputs[openedMetric?.key]}
              onChange={(event) =>
                handleChangeGoal(openedMetric?.key, Number(event.target.value))
              }
              className={styles.input}
            />
          </div>
          <div className={styles.date}>
            <div className={styles.date__item}>
              <span>Start date</span>
              <span>{formatDate(dateRange.startDate)}</span>
            </div>
            <div className={styles.date__item}>
              <span>End date</span>
              <span>{formatDate(dateRange.endDate)}</span>
            </div>
          </div>
          <div className={styles.info}>
            <InfoBlue />
            Affecting only selected targets
          </div>
          <button className={styles.save} onClick={handleSaveGoals}>
            Save Changes
          </button>
        </ModalBox>
      </div>
    </div>
  );
};
