import React, { useRef, useEffect, useState } from "react";
import ReactECharts from "echarts-for-react";
import { Dropdown } from "antd";
import Settings from "@assets/icons/Settings";
import "./styles.scss";
import { createDropdownContent } from "./utils";

const mockData = [
  { name: "Contribution Profit", value: 795300, color: "#255FA0" },
  { name: "COGS", value: -446700, color: "#FE5858" },
  { name: "Amazon Fees", value: 533100, color: "#FE5858" },
  { name: "Spend", value: -133100, color: "#FE5858" },
  { name: "Ad Sales", value: 1171100, color: "#80C67A" },
  { name: "Organic Sales", value: -269400, color: "#80C67A" },
  { name: "What if Contribution Profit", value: 1127300, color: "#255FA0" },
];

export const WhatIfChart: React.FC = () => {
  const chartRef = useRef(null);
  const [isSummarize, setIsSummarize] = useState(false);
  const [columns, setColumns] = useState(
    mockData.map((item) => ({ name: item.name, checked: true })),
  );
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);

  const toggleColumn = (name: string) => {
    setColumns((prevColumns) =>
      prevColumns.map((col) =>
        col.name === name ? { ...col, checked: !col.checked } : col,
      ),
    );
  };

  const getOption = () => {
    const filteredData = mockData.filter(
      (item) => columns.find((col) => col.name === item.name)?.checked,
    );

    const chartData = isSummarize
      ? [
          // Contribution Profit
          filteredData.find((item) => item.name === "Contribution Profit")
            ? {
                name: "Contribution Profit",
                value:
                  filteredData.find(
                    (item) => item.name === "Contribution Profit",
                  )?.value || 0,
                color: "#255FA0",
              }
            : null,

          // Total spend (COGS, Amazon Fees, Spend)
          filteredData.some((item) =>
            ["COGS", "Amazon Fees", "Spend"].includes(item.name),
          )
            ? {
                name: "Total spend",
                value: filteredData
                  .filter((item) =>
                    ["COGS", "Amazon Fees", "Spend"].includes(item.name),
                  )
                  .reduce((sum, item) => sum + item.value, 0),
                color: "#FE5858",
              }
            : null,

          // Total sales (Ad Sales, Organic Sales)
          filteredData.some((item) =>
            ["Ad Sales", "Organic Sales"].includes(item.name),
          )
            ? {
                name: "Total sales",
                value: filteredData
                  .filter((item) =>
                    ["Ad Sales", "Organic Sales"].includes(item.name),
                  )
                  .reduce((sum, item) => sum + item.value, 0),
                color: "#80C67A",
              }
            : null,

          // What if Contribution Profit
          filteredData.find(
            (item) => item.name === "What if Contribution Profit",
          )
            ? {
                name: "What if Contribution Profit",
                value:
                  filteredData.find(
                    (item) => item.name === "What if Contribution Profit",
                  )?.value || 0,
                color: "#255FA0",
              }
            : null,
        ].filter(Boolean)
      : filteredData;

    return {
      tooltip: {
        trigger: "axis",
        axisPointer: { type: "shadow" },
        formatter: (params: any) =>
          `${params[0].name}: $${(params[0].value / 1000).toFixed(1)}K`,
      },
      xAxis: {
        type: "category",
        data: chartData.map((item) => item.name),
        axisLabel: {
          color: "#FFF",
          fontSize: 10,
          interval: 0,
          rich: {
            align: "center",
          },
          formatter: (value: string) => {
            if (value === "What if Contribution Profit") {
              return "{align|What if}\n{align|Contribution}\n{align|Profit}";
            }
            return value.split(" ").join("\n");
          },
        },
      },
      yAxis: {
        type: "value",
        max: 1500000,
        min: -1500000,
        interval: 500000,
        axisLabel: {
          color: "#FFF",
          fontSize: 12,
          formatter: (value: number) => {
            const formattedValue = (value / 1000000)
              .toFixed(1)
              .replace(".", ",");
            return formattedValue.endsWith(",0")
              ? `$${formattedValue.slice(0, -2)}M`
              : `$${formattedValue}M`;
          },
        },
      },
      series: [
        {
          data: chartData.map((item) => ({
            value: item.value,
            itemStyle: { color: item.color },
            label: {
              show: true,
              position: item.value >= 0 ? "top" : "bottom",
              formatter: (params) => {
                const val = params.value;
                return `$${(val / 1000).toFixed(1)}K`;
              },
              color: "#FFF",
              fontSize: 10,
            },
          })),
          type: "bar",
          barWidth: "100%",
        },
      ],
      grid: { left: "10%", right: "10%", top: "5%", bottom: "15%" },
    };
  };

  useEffect(() => {
    const handleResize = () => {
      if (chartRef.current) {
        chartRef.current.getEchartsInstance().resize();
      }
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const dropdownContent = createDropdownContent(columns, toggleColumn, () =>
    setIsDropdownVisible(false),
  );

  return (
    <div className="chart__container">
      <div className="chart-header">
        <h3>What-If Analysis</h3>
        <div className="header-actions">
          <div className="toggle">
            <label className={`toggle-switch ${isSummarize ? "active" : ""}`}>
              <input
                type="checkbox"
                checked={isSummarize}
                onChange={() => setIsSummarize(!isSummarize)}
              />
              <span className="slider" />
            </label>
          </div>
          <p className="summarize-toggle">Summarize columns</p>
          <Dropdown
            overlay={dropdownContent}
            trigger={["click"]}
            placement="bottomRight"
            visible={isDropdownVisible}
            onVisibleChange={(visible) => setIsDropdownVisible(visible)}
          >
            <button className="settings-button">
              <Settings />
            </button>
          </Dropdown>
        </div>
      </div>
      <ReactECharts
        ref={chartRef}
        option={getOption()}
        style={{ height: "100%", width: "100%" }}
      />
    </div>
  );
};
