import React, { useState } from "react";
import styles from "./styles.module.scss";

interface ProductDetailsProps {
  name: string | null;
  points: string[] | null;
  isUpdated: boolean;
}

const ProductDetails: React.FC<ProductDetailsProps> = ({
  name,
  points,
  isUpdated,
}) => {
  const [showMore, setShowMore] = useState(false);

  return (
    <div className={styles.productDetails}>
      <h1 className={styles.title}>{isUpdated ? <mark>{name}</mark> : name}</h1>
      <div className={styles.itemDetails}>
        <p>About this item</p>
        <ul className={styles.detailsList}>
          {points && points.length > 0 ? (
            points.map((point, index) => (
              <li key={index}>{isUpdated ? <mark>{point}</mark> : point}</li>
            ))
          ) : (
            <li>No bullet points available.</li>
          )}
        </ul>
        {showMore && (
          <div className={styles.moreDetails}>
            <p>
              Gone are the days of dreading taking vitamins with our delicious
              and convenient gummies.
            </p>
          </div>
        )}
      </div>
      <button
        className={styles.showMoreButton}
        onClick={() => setShowMore(!showMore)}
      >
        {showMore ? "Show Less" : "Show More"}
      </button>
    </div>
  );
};

export default ProductDetails;
