import { IAnalyticsEvent } from "../types/analytics";

class AnalyticsEvent implements IAnalyticsEvent {
  event: string;
  parameters: Record<string, any>;

  constructor(event: string, parameters: Record<string, any> = {}) {
    this.event = event;
    this.parameters = parameters;
  }
}

const pushToDataLayer = (data: object) => {
  if (window.dataLayer) {
    window.dataLayer.push(data);
  }
};

const convertToDataLayer = (event: IAnalyticsEvent): Record<string, any> => {
  return {
    event: event.event,
    parameters: event.parameters,
  };
};

export const trackPageView = (path: string, title: string) => {
  const pageViewEvent = new AnalyticsEvent("pageview", {
    path,
    title,
  });
  trackEvent(pageViewEvent);
};

export const trackButtonClick = (
  buttonPagePath: string,
  buttonId: string,
  buttonText: string,
) => {
  const buttonEvent = new AnalyticsEvent("button_click", {
    button_page_path: buttonPagePath,
    button_id: buttonId,
    button_text: buttonText,
  });
  trackEvent(buttonEvent);
};

export const trackEvent = (event: IAnalyticsEvent) => {
  pushToDataLayer(convertToDataLayer(event));
};
