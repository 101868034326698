import style from "./style.module.scss";
import { Message } from "@components/ChatBot/types";

interface ChatPromptsProps {
  promptArray: string[];
  setShowPrompts: (value: boolean) => void;
  addMessage: (message: Message) => void;
  sendMessage: (value: string) => void;
}

export const ChatPrompts = ({
  promptArray,
  setShowPrompts,
  addMessage,
  sendMessage,
}: ChatPromptsProps) => {
  return (
    <div className={style.chat__prompts}>
      {promptArray.map((prompt) => (
        <button
          key={prompt}
          onClick={() => {
            addMessage({
              id: new Date().toISOString(),
              text: prompt,
              sender: "user",
              type: "text",
            });
            sendMessage(prompt);
            setShowPrompts(false);
          }}
        >
          {prompt}
        </button>
      ))}
    </div>
  );
};
