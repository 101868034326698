export const slides = [
  {
    id: 1,
    title: "Optimization recommendations",
    content:
      "In order to meet selected ACOS, please optimize Ad Spend, Bids and Reduced Ad Spend",
  },
  {
    id: 2,
    title: "Missing keywords",
    content:
      "You have 26 target recommendations for Sponsored Products campaigns with low clickthrough rate and low conversion rate, compared to similar campaigns. Applying these keyword recommendations can increase sales.",
  },
];

export const missingColumns = [
  {
    title: "Campaign Name",
    dataIndex: "campaignName",
    key: "campaignName",
    width: "381px",
  },
  {
    title: "Recommendation",
    dataIndex: "recommendation",
    key: "recommendation",
    width: "180px",
  },
  {
    title: "Bids Value",
    dataIndex: "bidsValue",
    key: "bidsValue",
    width: "120px",
  },
  {
    title: "Impressions",
    dataIndex: "impressions",
    key: "impressions",
    width: "120px",
  },
  {
    title: "CTR",
    dataIndex: "ctr",
    key: "ctr",
    width: "120px",
  },
  {
    title: "Clicks",
    dataIndex: "clicks",
    key: "clicks",
    width: "120px",
  },
  {
    title: "CVR",
    dataIndex: "cvr",
    key: "cvr",
    width: "120px",
  },
  {
    title: "CPC",
    dataIndex: "cpc",
    key: "cpc",
    width: "120px",
  },
  {
    title: "Spend",
    dataIndex: "spend",
    key: "spend",
    width: "120px",
  },
  {
    title: "Sales",
    dataIndex: "sales",
    key: "sales",
    width: "120px",
  },
  {
    title: "ACOS",
    dataIndex: "ACOS",
    key: "ACOS",
    width: "120px",
  },
];

export const missingData = [
  {
    key: "1",
    campaignName: "Nyle - SP - B0B5HN65QQ - CORP STRIPS 1",
    recommendation: "Missing Target",
    bidsValue: "$2.57",
    impressions: "699 743",
    ctr: "0.42%",
    clicks: "2 745",
    cvr: "27.5%",
    cpc: "$1.24",
    timeInBudget: "98%",
    spend: "$3 400",
    sales: "$21 850",
    ACOS: "16%",
    children: [
      {
        key: "1-1",
        campaignName: "product campaignName category",
        recommendation: "Missing Target",
        bidsValue: "$2.57",
        impressions: "298 832",
        ctr: "0.74%",
        clicks: "2 218",
        cvr: "29.1%",
        cpc: "$1.06",
        timeInBudget: "98%",
        spend: "$3 400",
        sales: "$18 776",
        ACOS: "12%",
      },
      {
        key: "1-2",
        campaignName: "Nyle - SP - B0B5HN65QQ-CAT STRIPS",
        recommendation: "Missing Target",
        bidsValue: "$2.57",
        impressions: "360 911",
        ctr: "0.15%",
        clicks: "527",
        cvr: "20.5%",
        cpc: "$2.03",
        timeInBudget: "98%",
        spend: "$3 400",
        sales: "$3 073",
        ACOS: "35%",
      },
    ],
  },
  {
    key: "2",
    campaignName: "Nyle - SP - B0B5HN65QQ - CORP STRIPS 2",
    recommendation: "Missing Target",
    bidsValue: "$2.57",
    impressions: "132 396",
    ctr: "1.00%",
    clicks: "1 330",
    cvr: "26.0%",
    cpc: "$1.86",
    timeInBudget: "98%",
    spend: "$3 400",
    sales: "$10 268",
    ACOS: "25%",
    children: [
      {
        key: "2-1",
        campaignName: "Nyle - SP - B0B5HN65QQ - CORP STRIPS NOT E...",
        recommendation: "Missing Target",
        bidsValue: "$2.57",
        impressions: "113 419",
        ctr: "0.77%",
        clicks: "870",
        cvr: "26.2%",
        cpc: "$2.14",
        timeInBudget: "98%",
        spend: "$3 400",
        sales: "$6 888",
        ACOS: "28%",
      },
      {
        key: "2-2",
        campaignName: "Nyle - SP - B0B5HN65QQ - CORP KIT NOT EXP",
        recommendation: "Missing Target",
        bidsValue: "$2.57",
        impressions: "18 977",
        ctr: "2.42%",
        clicks: "460",
        cvr: "25.7%",
        cpc: "$1.63",
        timeInBudget: "98%",
        spend: "$3 400",
        sales: "$3 680",
        ACOS: "20%",
      },
    ],
  },
  {
    key: "3",
    campaignName: "Nyle - SP - B0B5HN65QQ - CORP STRIPS 3",
    recommendation: "Missing Target",
    bidsValue: "$2.57",
    impressions: "57 912",
    ctr: "2.77%",
    clicks: "1 602",
    cvr: "28.9%",
    cpc: "$1.31",
    timeInBudget: "98%",
    spend: "$3 400",
    sales: "$13 472",
    ACOS: "16%",
    children: [
      {
        key: "3-1",
        campaignName: "Nyle - SP - B0B5HN65QQ - CORP STRIPS NOT E...",
        recommendation: "Missing Target",
        bidsValue: "$2.57",
        impressions: "57 905",
        ctr: "2.76%",
        clicks: "1 600",
        cvr: "28.9%",
        cpc: "$1.31",
        timeInBudget: "98%",
        spend: "$3 400",
        sales: "$13 472",
        ACOS: "16%",
      },
      {
        key: "3-2",
        campaignName: "Nyle - SP - B0BCXY5C2H - CORP STRIPS EXACT",
        recommendation: "Missing Target",
        bidsValue: "$2.57",
        impressions: "7",
        ctr: "28.57%",
        clicks: "2",
        cvr: "0%",
        cpc: "$0.78",
        timeInBudget: "98%",

        ACOS: "$0",
      },
    ],
  },
  {
    key: "4",
    campaignName: "Nyle - SP - B0B5HN65QQ - CORP STRIPS 4",
    recommendation: "Missing Target",
    bidsValue: "$2.57",
    impressions: "155 102",
    ctr: "0.21%",
    clicks: "333",
    cvr: "29.4%",
    cpc: "$2.73",
    timeInBudget: "98%",
    spend: "$3 400",
    sales: "$3 060",
    ACOS: "30%",
    children: [
      {
        key: "4-1",
        campaignName: "Nyle - SP - B0B5HN65QQ - GEKW HIGH EXACT",
        recommendation: "Missing Target",
        bidsValue: "$2.57",
        impressions: "155 102",
        ctr: "0.21%",
        clicks: "333",
        cvr: "29.4%",
        cpc: "$2.73",
        timeInBudget: "98%",
        spend: "$3 400",
        sales: "$3 060",
        ACOS: "30%",
      },
    ],
  },
  {
    key: "5",
    campaignName: "Nyle - SP - B0B5HN65QQ - CORP STRIPS 5",
    recommendation: "Missing Target",
    bidsValue: "$2.57",
    impressions: "68 454",
    ctr: "0.30%",
    clicks: "204",
    cvr: "26.6%",
    cpc: "$2.56",
    timeInBudget: "98%",
    spend: "$3 400",
    sales: "$1 610",
    ACOS: "32%",
    children: [
      {
        key: "5-1",
        campaignName: "Nyle - SP - B0B5HN65QQ - GEKW HIGH EXACT",
        recommendation: "Missing Target",
        bidsValue: "$2.57",
        impressions: "68 454",
        ctr: "0.30%",
        clicks: "204",
        cvr: "26.6%",
        cpc: "$2.56",
        timeInBudget: "98%",
        spend: "$3 400",
        sales: "$1 610",
        ACOS: "32%",
      },
    ],
  },
  {
    key: "6",
    campaignName: "Nyle - SP - B0B5HN65QQ - CORP STRIPS 6",
    recommendation: "Missing Target",
    bidsValue: "$2.57",
    impressions: "184 697",
    ctr: "0.09%",
    clicks: "175",
    cvr: "21.1%",
    cpc: "$2.65",
    timeInBudget: "98%",
    spend: "$3 400",
    sales: "$1 129",
    ACOS: "41%",
    children: [
      {
        key: "6-1",
        campaignName: "Nyle - SP - B0B5HN65QQ - GEKW HIGH EXACT",
        recommendation: "Missing Target",
        bidsValue: "$2.57",
        impressions: "184 697",
        ctr: "0.09%",
        clicks: "175",
        cvr: "21.1%",
        cpc: "$2.65",
        timeInBudget: "98%",
        spend: "$3 400",
        sales: "$1 129",
        ACOS: "41%",
      },
    ],
  },
  {
    key: "7",
    campaignName: "Nyle - SP - B0B5HN65QQ - CORP STRIPS 7",
    recommendation: "Missing Target",
    bidsValue: "$2.57",
    impressions: "90 283",
    ctr: "0.07%",
    clicks: "67",
    cvr: "23.9%",
    cpc: "$1.99",
    timeInBudget: "98%",
    spend: "$3 400",
    sales: "$370",
    ACOS: "36%",
    children: [
      {
        key: "7-1",
        campaignName: "Nyle - SP - B0B5HN65QQ - GEKW HIGH EXACT",
        recommendation: "Missing Target",
        bidsValue: "$2.57",
        impressions: "90 402",
        ctr: "0.06%",
        clicks: "56",
        cvr: "25.0%",
        cpc: "$2.02",
        timeInBudget: "98%",
        spend: "$3 400",
        sales: "$319",
        ACOS: "35%",
      },
      {
        key: "7-1",
        campaignName: "Nyle - SP - B0B5HN65QQ - GEKW HIGH EXACT",
        recommendation: "Missing Target",
        bidsValue: "$2.57",
        impressions: "2 881",
        ctr: "0,38%",
        clicks: "11",
        cvr: "19.2%",
        cpc: "$1.88",
        timeInBudget: "98%",
        spend: "$3 400",
        sales: "$51",
        ACOS: "41%",
      },
    ],
  },
  {
    key: "8",
    campaignName: "Nyle - SP - B0B5HN65QQ - CORP STRIPS 8",
    recommendation: "Missing Target",
    bidsValue: "$2.57",
    impressions: "11 257",
    ctr: "0.57%",
    clicks: "64",
    cvr: "34.4%",
    cpc: "$2.05",
    timeInBudget: "98%",
    spend: "$3 400",
    sales: "$598",
    ACOS: "22%",
    children: [
      {
        key: "8-1",
        campaignName: "Nyle - SP - B0B5HN65QQ - CORP KIT NOT EXP",
        recommendation: "Missing Target",
        bidsValue: "$2.57",
        impressions: "11 067",
        ctr: "0.73%",
        clicks: "45",
        cvr: "33.3%",
        cpc: "$1.67",
        timeInBudget: "98%",
        spend: "$3 400",
        sales: "$413",
        ACOS: "18%",
      },
      {
        key: "8-2",
        campaignName: "Nyle - SP - B0B5HN65QQ - CORP STRIPS NOT E...",
        recommendation: "Missing Target",
        bidsValue: "$2.57",
        impressions: "2 075",
        ctr: "0.66%",
        clicks: "19",
        cvr: "36.8%",
        cpc: "$2.95",
        timeInBudget: "98%",
        spend: "$3 400",
        sales: "$184",
        ACOS: "30%",
      },
      {
        key: "8-3",
        campaignName: "Nyle - SP - B0B5HN65QQ - CORP STRIPS",
        recommendation: "Missing Target",
        bidsValue: "$2.57",
        impressions: "0",
        ctr: "0.00%",
        clicks: "0",
        cvr: "0%",
        cpc: "$0",
        timeInBudget: "98%",

        ACOS: "Text",
      },
      {
        key: "8-4",
        campaignName: "Nyle - SP - B0BCXY5C2H - CORP STRIPS",
        recommendation: "Missing Target",
        bidsValue: "$2.57",
        impressions: "0",
        ctr: "0.00%",
        clicks: "0",
        cvr: "0%",
        cpc: "$0",
        timeInBudget: "98%",

        ACOS: "Text",
      },
    ],
  },
];

export const currentColumns = [
  {
    title: "Campaign Name",
    dataIndex: "campaignName",
    key: "campaignName",
    width: "381px",
  },
  {
    title: "Impressions",
    dataIndex: "impressions",
    key: "impressions",
    width: "110px",
  },
  {
    title: "CTR",
    dataIndex: "ctr",
    key: "ctr",
    width: "110px",
  },
  {
    title: "Clicks",
    dataIndex: "clicks",
    key: "clicks",
    width: "110px",
  },
  {
    title: "CVR",
    dataIndex: "cvr",
    key: "cvr",
    width: "110px",
  },
  {
    title: "CPC",
    dataIndex: "cpc",
    key: "cpc",
    width: "110px",
  },
  {
    title: "Time in budget, %",
    dataIndex: "timeInBudget",
    key: "timeInBudget",
    width: "150px",
  },
];

export const currentData = [
  {
    key: "1",
    campaignName: "Nyle - SP - B0B5HN65QQ - CORP STRIPS 1",
    impressions: "72 123",
    ctr: "0.15%",
    clicks: "111",
    cvr: "0%",
    cpc: "$0.81",
    timeInBudget: "98%",

    children: [
      {
        key: "1-1",
        campaignName: "Nyle - SP - B0B5HN65QQ - CORP STRIPS 2",
        impressions: "72 123",
        ctr: "0.15%",
        clicks: "111",
        cvr: "0%",
        cpc: "$0.81",
        timeInBudget: "98%",
      },
    ],
  },
  {
    key: "2",
    campaignName: "Nyle - SP - B0B5HN65QQ - CORP STRIPS 2",
    impressions: "94 921",
    ctr: "0.09%",
    clicks: "83",
    cvr: "0%",
    cpc: "$0.92",
    timeInBudget: "98%",

    children: [
      {
        key: "2-1",
        campaignName: "Nyle - SP - B0B5HN65QQ - CORP STRIPS 3",
        impressions: "94 921",
        ctr: "0.09%",
        clicks: "83",
        cvr: "0%",
        cpc: "$0.92",
        timeInBudget: "98%",
      },
    ],
  },
  {
    key: "3",
    campaignName: "Nyle - SP - B0B5HN65QQ - CORP STRIPS 3",
    impressions: "46 945",
    ctr: "0.04%",
    clicks: "18",
    cvr: "0%",
    cpc: "$2.79",
    timeInBudget: "98%",

    children: [
      {
        key: "3-1",
        campaignName: "Nyle - SP - B0B5HN65QQ - CORP STRIPS 4",
        impressions: "46 945",
        ctr: "0.04%",
        clicks: "18",
        cvr: "0%",
        cpc: "$2.79",
        timeInBudget: "98%",
      },
    ],
  },
  {
    key: "4",
    campaignName: "Nyle - SP - B0B5HN65QQ - CORP STRIPS 4",
    impressions: "26 216",
    ctr: "0.14%",
    clicks: "37",
    cvr: "0%",
    cpc: "$0.94",
    timeInBudget: "98%",

    children: [
      {
        key: "4-1",
        campaignName: "Nyle - SD - B0B5HN65QQ - REMARKETING",
        impressions: "26 216",
        ctr: "0.14%",
        clicks: "37",
        cvr: "0%",
        cpc: "$0.94",
        timeInBudget: "98%",
      },
    ],
  },
  {
    key: "5",
    campaignName: "Nyle - SP - B0B5HN65QQ - CORP STRIPS 5",
    impressions: "25 184",
    ctr: "0.17%",
    clicks: "43",
    cvr: "0%",
    cpc: "$0.77",
    timeInBudget: "98%",

    children: [
      {
        key: "5-1",
        campaignName: "Nyle - SD - B0B5HN65QQ - IM LS",
        impressions: "25 184",
        ctr: "0.17%",
        clicks: "43",
        cvr: "0%",
        cpc: "$0.77",
        timeInBudget: "98%",
      },
    ],
  },
  {
    key: "6",
    campaignName: "Nyle - SP - B0B5HN65QQ - CORP STRIPS 6",
    impressions: "22 917",
    ctr: "0.14%",
    clicks: "32",
    cvr: "0%",
    cpc: "$0.92",
    timeInBudget: "98%",

    children: [
      {
        key: "6-1",
        campaignName: "Nyle - SD - B0B5HN65QQ - REMARKETING",
        impressions: "22 917",
        ctr: "0.14%",
        clicks: "32",
        cvr: "0%",
        cpc: "$0.92",
        timeInBudget: "98%",
      },
    ],
  },
  {
    key: "7",
    campaignName: "Nyle - SP - B0B5HN65QQ - CORP STRIPS 7",
    impressions: "22 727",
    ctr: "0.11%",
    clicks: "26",
    cvr: "0%",
    cpc: "$0.94",
    timeInBudget: "98%",

    children: [
      {
        key: "7-1",
        campaignName: "Nyle - SD - B0B5HN65QQ - REMARKETING",
        impressions: "22 727",
        ctr: "0.11%",
        clicks: "26",
        cvr: "0%",
        cpc: "$0.94",
        timeInBudget: "98%",
      },
    ],
  },
  {
    key: "8",
    campaignName: "Nyle - SP - B0B5HN65QQ - CORP STRIPS 8",
    impressions: "22 230",
    ctr: "0.09%",
    clicks: "21",
    cvr: "0%",
    cpc: "$0.74",
    timeInBudget: "98%",

    children: [
      {
        key: "8-1",
        campaignName: "Nyle - SD - B0B5HN65QQ - REMARKETING",
        impressions: "22 230",
        ctr: "0.09%",
        clicks: "21",
        cvr: "0%",
        cpc: "$0.74",
        timeInBudget: "98%",
      },
    ],
  },
  {
    key: "9",
    campaignName: "Nyle - SP - B0B5HN65QQ - CORP STRIPS 9",
    impressions: "28 733",
    ctr: "0.03%",
    clicks: "10",
    cvr: "0%",
    cpc: "$0.79",
    timeInBudget: "98%",

    children: [
      {
        key: "9-1",
        campaignName: "Nyle - SD - B0B5HN65QQ - CONTEXTUAL",
        impressions: "28 733",
        ctr: "0.03%",
        clicks: "10",
        cvr: "0%",
        cpc: "$0.79",
        timeInBudget: "98%",
      },
    ],
  },
  {
    key: "10",
    campaignName: "Nyle - SP - B0B5HN65QQ - CORP STRIPS 10",
    impressions: "6 620",
    ctr: "0.30%",
    clicks: "20",
    cvr: "0%",
    cpc: "$0.28",
    timeInBudget: "98%",

    children: [
      {
        key: "10-1",
        campaignName: "Nyle - SD - B0B5HN65QQ - REMARKETING",
        impressions: "6 620",
        ctr: "0.30%",
        clicks: "20",
        cvr: "0%",
        cpc: "$0.28",
        timeInBudget: "98%",
      },
    ],
  },
  {
    key: "11",
    campaignName: "Nyle - SP - B0B5HN65QQ - CORP STRIPS 11",
    impressions: "14 921",
    ctr: "0.10%",
    clicks: "15",
    cvr: "0%",
    cpc: "$0.34",
    timeInBudget: "98%",

    children: [
      {
        key: "11-1",
        campaignName: "Nyle - SD - B0B5HN65QQ - REMARKETING",
        impressions: "14 921",
        ctr: "0.10%",
        clicks: "15",
        cvr: "0%",
        cpc: "$0.34",
        timeInBudget: "98%",
      },
    ],
  },
  {
    key: "12",
    campaignName: "Nyle - SP - B0B5HN65QQ - CORP STRIPS 12",
    impressions: "383 537",
    ctr: "0.11%",
    clicks: "416",
    cvr: "0%",
    cpc: "$0.90",
    timeInBudget: "98%",
  },
];

export const dataMock = [
  [
    {
      key: "1",
      keyword: "crest whitening strips",
      recommendation: "Missing Target",
      bidsValue: "$2.57",
      volume: "203 739",
      lostSales: "$11 276",
      lostSalesProcent: "60%",
    },
    {
      key: "2",
      keyword: "crest 3d whitestrips",
      recommendation: "Missing Target",
      bidsValue: "$2.57",
      volume: "44 737",
      lostSales: "$2 100",
      lostSalesProcent: "60%",
    },
    {
      key: "3",
      keyword: "teeth whitening pen",
      recommendation: "Missing Target",
      bidsValue: "$2.57",
      volume: "29 581",
      lostSales: "$1 248",
      lostSalesProcent: "10%",
    },
    {
      key: "4",
      keyword: "blanqueador de dientes",
      recommendation: "Missing Target",
      bidsValue: "$2.57",
      volume: "22 305",
      lostSales: "$262",
      lostSalesProcent: "50%",
    },
    {
      key: "5",
      keyword: "teeth whitening gel",
      recommendation: "Missing Target",
      bidsValue: "$2.57",
      volume: "18 172",
      lostSales: "$678",
      lostSalesProcent: "10%",
    },
    {
      key: "6",
      keyword: "crest white strips",
      recommendation: "Missing Target",
      bidsValue: "$2.57",
      volume: "12 946",
      lostSales: "$758",
      lostSalesProcent: "60%",
    },
    {
      key: "7",
      keyword: "purple toothpaste for teeth whitening",
      recommendation: "Missing Target",
      bidsValue: "$2.57",
      volume: "10 715",
      lostSales: "$496",
      lostSalesProcent: "10%",
    },
    {
      key: "8",
      keyword: "white teeth",
      recommendation: "Missing Target",
      bidsValue: "$2.57",
      volume: "6 978",
      lostSales: "$114",
      lostSalesProcent: "60%",
    },
    {
      key: "9",
      keyword: "teeth whitening toothpaste",
      recommendation: "Missing Target",
      bidsValue: "$2.57",
      volume: "5 842",
      lostSales: "$201",
      lostSalesProcent: "10%",
    },
    {
      key: "10",
      keyword: "crest white strips professional strength",
      recommendation: "Missing Target",
      bidsValue: "$2.57",
      volume: "4 479",
      lostSales: "$335",
      lostSalesProcent: "60%",
    },
    {
      key: "11",
      keyword: "whitestrips",
      recommendation: "Missing Target",
      bidsValue: "$2.57",
      volume: "3 819",
      lostSales: "$195",
      lostSalesProcent: "80%",
    },
    {
      key: "12",
      keyword: "best teeth whitening products 5 star",
      recommendation: "Missing Target",
      bidsValue: "$2.57",
      volume: "3 728",
      lostSales: "$127",
      lostSalesProcent: "50%",
    },
    {
      key: "13",
      keyword: "crest whitestrips",
      recommendation: "Missing Target",
      bidsValue: "$2.57",
      volume: "3 640",
      lostSales: "$195",
      lostSalesProcent: "60%",
    },
    {
      key: "14",
      keyword: "whitening mouthwash",
      recommendation: "Missing Target",
      bidsValue: "$2.57",
      volume: "3 619",
      lostSales: "$282",
      lostSalesProcent: "10%",
    },
    {
      key: "15",
      keyword: "teeth whiteners best sellers",
      recommendation: "Missing Target",
      bidsValue: "$2.57",
      volume: "3 584",
      lostSales: "$121",
      lostSalesProcent: "40%",
    },
    {
      key: "16",
      keyword: "crest teeth whitening strips",
      recommendation: "Missing Target",
      bidsValue: "$2.57",
      volume: "3 448",
      lostSales: "$188",
      lostSalesProcent: "60%",
    },
    {
      key: "17",
      keyword: "crest whitening strips sensitive teeth",
      recommendation: "Missing Target",
      bidsValue: "$2.57",
      volume: "3 433",
      lostSales: "$215",
      lostSalesProcent: "80%",
    },
    {
      key: "18",
      keyword: "crest whitening",
      recommendation: "Missing Target",
      bidsValue: "$2.57",
      volume: "3 417",
      lostSales: "$107",
      lostSalesProcent: "20%",
    },
    {
      key: "19",
      keyword: "3d white crest whitestrips",
      recommendation: "Missing Target",
      bidsValue: "$2.57",
      volume: "3 108",
      lostSales: "$114",
      lostSalesProcent: "60%",
    },
    {
      key: "20",
      keyword: "purple teeth whitening",
      recommendation: "Missing Target",
      bidsValue: "$2.57",
      volume: "3 054",
      lostSales: "$121",
      lostSalesProcent: "10%",
    },
    {
      key: "21",
      keyword: "inopro teeth whitening strips",
      recommendation: "Missing Target",
      bidsValue: "$2.57",
      volume: "2 851",
      lostSales: "$141",
      lostSalesProcent: "100%",
    },
    {
      key: "22",
      keyword: "whitening pen",
      recommendation: "Missing Target",
      bidsValue: "$2.57",
      volume: "2 771",
      lostSales: "$114",
      lostSalesProcent: "10%",
    },
    {
      key: "23",
      keyword: "teeth whitner",
      recommendation: "Missing Target",
      bidsValue: "$2.57",
      volume: "2 404",
      lostSales: "$80",
      lostSalesProcent: "40%",
    },
    {
      key: "24",
      keyword: "whitening strips crest",
      recommendation: "Missing Target",
      bidsValue: "$2.57",
      volume: "2 143",
      lostSales: "$87",
      lostSalesProcent: "60%",
    },
    {
      key: "25",
      keyword: "tooth whitener",
      recommendation: "Missing Target",
      bidsValue: "$2.57",
      volume: "1 920",
      lostSales: "$74",
      lostSalesProcent: "40%",
    },
    {
      key: "26",
      keyword: "crest sensitive whitening strips",
      recommendation: "Missing Target",
      bidsValue: "$2.57",
      volume: "1 859",
      lostSales: "$114",
      lostSalesProcent: "60%",
    },
  ],
  [
    {
      key: "1",
      campaignName: 'audience="IM - Oral Care Products"',
      impressions: "72 123",
      ctr: "0.15%",
      clicks: "111",
      cvr: "0%",
      cpc: "$0.81",
      timeInBudget: "98%",

      children: [
        {
          key: "1-1",
          campaignName: "Nyle - SD - B0B5HN65QQ - IM LS",
          impressions: "72 123",
          ctr: "0.15%",
          clicks: "111",
          cvr: "0%",
          cpc: "$0.81",
          timeInBudget: "98%",
        },
      ],
    },
    {
      key: "2",
      campaignName: "similar-product",
      impressions: "94 921",
      ctr: "0.09%",
      clicks: "83",
      cvr: "0%",
      cpc: "$0.92",
      timeInBudget: "98%",

      children: [
        {
          key: "2-1",
          campaignName: "Nyle - SD - B0B5HN65QQ - CONTEXTUAL",
          impressions: "94 921",
          ctr: "0.09%",
          clicks: "83",
          cvr: "0%",
          cpc: "$0.92",
          timeInBudget: "98%",
        },
      ],
    },
    {
      key: "3",
      campaignName: "teeth whitening kits",
      impressions: "46 945",
      ctr: "0.04%",
      clicks: "18",
      cvr: "0%",
      cpc: "$2.79",
      timeInBudget: "98%",

      children: [
        {
          key: "3-1",
          campaignName: "Nyle - SP - B0B5HN65QQ - GEKW MID EXACT",
          impressions: "46 945",
          ctr: "0.04%",
          clicks: "18",
          cvr: "0%",
          cpc: "$2.79",
          timeInBudget: "98%",
        },
      ],
    },
    {
      key: "4",
      campaignName: "views=(similar-product lookback=90)",
      impressions: "26 216",
      ctr: "0.14%",
      clicks: "37",
      cvr: "0%",
      cpc: "$0.94",
      timeInBudget: "98%",

      children: [
        {
          key: "4-1",
          campaignName: "Nyle - SD - B0B5HN65QQ - REMARKETING",
          impressions: "26 216",
          ctr: "0.14%",
          clicks: "37",
          cvr: "0%",
          cpc: "$0.94",
          timeInBudget: "98%",
        },
      ],
    },
    {
      key: "5",
      campaignName: 'audience="IM - Teeth Whitening Products"',
      impressions: "25 184",
      ctr: "0.17%",
      clicks: "43",
      cvr: "0%",
      cpc: "$0.77",
      timeInBudget: "98%",

      children: [
        {
          key: "5-1",
          campaignName: "Nyle - SD - B0B5HN65QQ - IM LS",
          impressions: "25 184",
          ctr: "0.17%",
          clicks: "43",
          cvr: "0%",
          cpc: "$0.77",
          timeInBudget: "98%",
        },
      ],
    },
    {
      key: "6",
      campaignName: "views=(similar-product lookback=60)",
      impressions: "22 917",
      ctr: "0.14%",
      clicks: "32",
      cvr: "0%",
      cpc: "$0.92",
      timeInBudget: "98%",

      children: [
        {
          key: "6-1",
          campaignName: "Nyle - SD - B0B5HN65QQ - REMARKETING",
          impressions: "22 917",
          ctr: "0.14%",
          clicks: "32",
          cvr: "0%",
          cpc: "$0.92",
          timeInBudget: "98%",
        },
      ],
    },
    {
      key: "7",
      campaignName: "views=(exact-product lookback=30)",
      impressions: "22 727",
      ctr: "0.11%",
      clicks: "26",
      cvr: "0%",
      cpc: "$0.94",
      timeInBudget: "98%",

      children: [
        {
          key: "7-1",
          campaignName: "Nyle - SD - B0B5HN65QQ - REMARKETING",
          impressions: "22 727",
          ctr: "0.11%",
          clicks: "26",
          cvr: "0%",
          cpc: "$0.94",
          timeInBudget: "98%",
        },
      ],
    },
    {
      key: "8",
      campaignName: "views=(exact-product lookback=60)",
      impressions: "22 230",
      ctr: "0.09%",
      clicks: "21",
      cvr: "0%",
      cpc: "$0.74",
      timeInBudget: "98%",

      children: [
        {
          key: "8-1",
          campaignName: "Nyle - SD - B0B5HN65QQ - REMARKETING",
          impressions: "22 230",
          ctr: "0.09%",
          clicks: "21",
          cvr: "0%",
          cpc: "$0.74",
          timeInBudget: "98%",
        },
      ],
    },
    {
      key: "9",
      campaignName: 'category="Teeth Whitening Strips"',
      impressions: "28 733",
      ctr: "0.03%",
      clicks: "10",
      cvr: "0%",
      cpc: "$0.79",
      timeInBudget: "98%",

      children: [
        {
          key: "9-1",
          campaignName: "Nyle - SD - B0B5HN65QQ - CONTEXTUAL",
          impressions: "28 733",
          ctr: "0.03%",
          clicks: "10",
          cvr: "0%",
          cpc: "$0.79",
          timeInBudget: "98%",
        },
      ],
    },
    {
      key: "10",
      campaignName: "purchases=(related-product lookback=90)",
      impressions: "6 620",
      ctr: "0.30%",
      clicks: "20",
      cvr: "0%",
      cpc: "$0.28",
      timeInBudget: "98%",

      children: [
        {
          key: "10-1",
          campaignName: "Nyle - SD - B0B5HN65QQ - REMARKETING",
          impressions: "6 620",
          ctr: "0.30%",
          clicks: "20",
          cvr: "0%",
          cpc: "$0.28",
          timeInBudget: "98%",
        },
      ],
    },
    {
      key: "11",
      campaignName: "views=(similar-product lookback=30)",
      impressions: "14 921",
      ctr: "0.10%",
      clicks: "15",
      cvr: "0%",
      cpc: "$0.34",
      timeInBudget: "98%",

      children: [
        {
          key: "11-1",
          campaignName: "Nyle - SD - B0B5HN65QQ - REMARKETING",
          impressions: "14 921",
          ctr: "0.10%",
          clicks: "15",
          cvr: "0%",
          cpc: "$0.34",
          timeInBudget: "98%",
        },
      ],
    },
    {
      key: "12",
      campaignName: <p style={{ paddingLeft: "38px" }}>Grand Total</p>,
      impressions: "383 537",
      ctr: "0.11%",
      clicks: "416",
      cvr: "0%",
      cpc: "$0.90",
      timeInBudget: "98%",
    },
  ],
  [
    {
      key: "1",
      campaignName: 'category="Teeth Whitening Strips"',
      recommendation: "Missing Target",
      bidsValue: "$2.57",
      impressions: "699 743",
      ctr: "0.42%",
      clicks: "2 745",
      cvr: "27.5%",
      cpc: "$1.24",
      timeInBudget: "98%",
      sales: "$21 850",
      ACOS: "16%",
      children: [
        {
          key: "1-1",
          campaignName: "product campaignName category",
          recommendation: "Missing Target",
          bidsValue: "$2.57",
          impressions: "298 832",
          ctr: "0.74%",
          clicks: "2 218",
          cvr: "29.1%",
          cpc: "$1.06",
          timeInBudget: "98%",
          sales: "$18 776",
          ACOS: "12%",
        },
        {
          key: "1-2",
          campaignName: "Nyle - SP - B0B5HN65QQ-CAT STRIPS",
          recommendation: "Missing Target",
          bidsValue: "$2.57",
          impressions: "360 911",
          ctr: "0.15%",
          clicks: "527",
          cvr: "20.5%",
          cpc: "$2.03",
          timeInBudget: "98%",
          sales: "$3 073",
          ACOS: "35%",
        },
      ],
    },
    {
      key: "2",
      campaignName: 'asin="B00AAHAW0O"',
      recommendation: "Missing Target",
      bidsValue: "$2.57",
      impressions: "132 396",
      ctr: "1.00%",
      clicks: "1 330",
      cvr: "26.0%",
      cpc: "$1.86",
      timeInBudget: "98%",
      sales: "$10 268",
      ACOS: "25%",
      children: [
        {
          key: "2-1",
          campaignName: "Nyle - SP - B0B5HN65QQ - CORP STRIPS NOT E...",
          recommendation: "Missing Target",
          bidsValue: "$2.57",
          impressions: "113 419",
          ctr: "0.77%",
          clicks: "870",
          cvr: "26.2%",
          cpc: "$2.14",
          timeInBudget: "98%",
          sales: "$6 888",
          ACOS: "28%",
        },
        {
          key: "2-2",
          campaignName: "Nyle - SP - B0B5HN65QQ - CORP KIT NOT EXP",
          recommendation: "Missing Target",
          bidsValue: "$2.57",
          impressions: "18 977",
          ctr: "2.42%",
          clicks: "460",
          cvr: "25.7%",
          cpc: "$1.63",
          timeInBudget: "98%",
          sales: "$3 680",
          ACOS: "20%",
        },
      ],
    },
    {
      key: "3",
      campaignName: 'asin="B077VCG84F"',
      recommendation: "Missing Target",
      bidsValue: "$2.57",
      impressions: "57 912",
      ctr: "2.77%",
      clicks: "1 602",
      cvr: "28.9%",
      cpc: "$1.31",
      timeInBudget: "98%",
      sales: "$13 472",
      ACOS: "16%",
      children: [
        {
          key: "3-1",
          campaignName: "Nyle - SP - B0B5HN65QQ - CORP STRIPS NOT E...",
          recommendation: "Missing Target",
          bidsValue: "$2.57",
          impressions: "57 905",
          ctr: "2.76%",
          clicks: "1 600",
          cvr: "28.9%",
          cpc: "$1.31",
          timeInBudget: "98%",
          sales: "$13 472",
          ACOS: "16%",
        },
        {
          key: "3-2",
          campaignName: "Nyle - SP - B0BCXY5C2H - CORP STRIPS EXACT",
          recommendation: "Missing Target",
          bidsValue: "$2.57",
          impressions: "7",
          ctr: "28.57%",
          clicks: "2",
          cvr: "0%",
          cpc: "$0.78",
          timeInBudget: "98%",

          ACOS: "$0",
        },
      ],
    },
    {
      key: "4",
      campaignName: "teeth whitening strips",
      recommendation: "Missing Target",
      bidsValue: "$2.57",
      impressions: "155 102",
      ctr: "0.21%",
      clicks: "333",
      cvr: "29.4%",
      cpc: "$2.73",
      timeInBudget: "98%",
      sales: "$3 060",
      ACOS: "30%",
      children: [
        {
          key: "4-1",
          campaignName: "Nyle - SP - B0B5HN65QQ - GEKW HIGH EXACT",
          recommendation: "Missing Target",
          bidsValue: "$2.57",
          impressions: "155 102",
          ctr: "0.21%",
          clicks: "333",
          cvr: "29.4%",
          cpc: "$2.73",
          timeInBudget: "98%",
          sales: "$3 060",
          ACOS: "30%",
        },
      ],
    },
    {
      key: "5",
      campaignName: "whitening strip",
      recommendation: "Missing Target",
      bidsValue: "$2.57",
      impressions: "68 454",
      ctr: "0.30%",
      clicks: "204",
      cvr: "26.6%",
      cpc: "$2.56",
      timeInBudget: "98%",
      sales: "$1 610",
      ACOS: "32%",
      children: [
        {
          key: "5-1",
          campaignName: "Nyle - SP - B0B5HN65QQ - GEKW HIGH EXACT",
          recommendation: "Missing Target",
          bidsValue: "$2.57",
          impressions: "68 454",
          ctr: "0.30%",
          clicks: "204",
          cvr: "26.6%",
          cpc: "$2.56",
          timeInBudget: "98%",
          sales: "$1 610",
          ACOS: "32%",
        },
      ],
    },
    {
      key: "6",
      campaignName: "teeth whitening strip",
      recommendation: "Missing Target",
      bidsValue: "$2.57",
      impressions: "184 697",
      ctr: "0.09%",
      clicks: "175",
      cvr: "21.1%",
      cpc: "$2.65",
      timeInBudget: "98%",
      sales: "$1 129",
      ACOS: "41%",
      children: [
        {
          key: "6-1",
          campaignName: "Nyle - SP - B0B5HN65QQ - GEKW HIGH EXACT",
          recommendation: "Missing Target",
          bidsValue: "$2.57",
          impressions: "184 697",
          ctr: "0.09%",
          clicks: "175",
          cvr: "21.1%",
          cpc: "$2.65",
          timeInBudget: "98%",
          sales: "$1 129",
          ACOS: "41%",
        },
      ],
    },
    {
      key: "7",
      campaignName: "tooth whitening strips",
      recommendation: "Missing Target",
      bidsValue: "$2.57",
      impressions: "90 283",
      ctr: "0.07%",
      clicks: "67",
      cvr: "23.9%",
      cpc: "$1.99",
      timeInBudget: "98%",
      sales: "$370",
      ACOS: "36%",
      children: [
        {
          key: "7-1",
          campaignName: "Nyle - SP - B0B5HN65QQ - GEKW HIGH EXACT",
          recommendation: "Missing Target",
          bidsValue: "$2.57",
          impressions: "90 402",
          ctr: "0.06%",
          clicks: "56",
          cvr: "25.0%",
          cpc: "$2.02",
          timeInBudget: "98%",
          sales: "$319",
          ACOS: "35%",
        },
        {
          key: "7-1",
          campaignName: "Nyle - SP - B0B5HN65QQ - GEKW HIGH EXACT",
          recommendation: "Missing Target",
          bidsValue: "$2.57",
          impressions: "2 881",
          ctr: "0,38%",
          clicks: "11",
          cvr: "19.2%",
          cpc: "$1.88",
          timeInBudget: "98%",
          sales: "$51",
          ACOS: "41%",
        },
      ],
    },
    {
      key: "8",
      campaignName: 'asin="B00V7F6MHR"',
      recommendation: "Missing Target",
      bidsValue: "$2.57",
      impressions: "11 257",
      ctr: "0.57%",
      clicks: "64",
      cvr: "34.4%",
      cpc: "$2.05",
      timeInBudget: "98%",
      sales: "$598",
      ACOS: "22%",
      children: [
        {
          key: "8-1",
          campaignName: "Nyle - SP - B0B5HN65QQ - CORP KIT NOT EXP",
          recommendation: "Missing Target",
          bidsValue: "$2.57",
          impressions: "11 067",
          ctr: "0.73%",
          clicks: "45",
          cvr: "33.3%",
          cpc: "$1.67",
          timeInBudget: "98%",
          sales: "$413",
          ACOS: "18%",
        },
        {
          key: "8-2",
          campaignName: "Nyle - SP - B0B5HN65QQ - CORP STRIPS NOT E...",
          recommendation: "Missing Target",
          bidsValue: "$2.57",
          impressions: "2 075",
          ctr: "0.66%",
          clicks: "19",
          cvr: "36.8%",
          cpc: "$2.95",
          timeInBudget: "98%",
          sales: "$184",
          ACOS: "30%",
        },
        {
          key: "8-3",
          campaignName: "Nyle - SP - B0B5HN65QQ - CORP STRIPS",
          recommendation: "Missing Target",
          bidsValue: "$2.57",
          impressions: "0",
          ctr: "0.00%",
          clicks: "0",
          cvr: "0%",
          cpc: "$0",
          timeInBudget: "98%",

          ACOS: "Text",
        },
        {
          key: "8-4",
          campaignName: "Nyle - SP - B0BCXY5C2H - CORP STRIPS",
          recommendation: "Missing Target",
          bidsValue: "$2.57",
          impressions: "0",
          ctr: "0.00%",
          clicks: "0",
          cvr: "0%",
          cpc: "$0",
          timeInBudget: "98%",

          ACOS: "Text",
        },
      ],
    },
  ],
];
