export const columns = [
  {
    title: "Search Query",
    dataIndex: "category",
    key: "category",
    fixed: "left" as const,
    width: 264,
  },
  {
    title: "Search Volume",
    dataIndex: "searchVolume",
    key: "searchVolume",
    width: 140,
  },
  {
    title: "Organic Rank",
    dataIndex: "organicRank",
    key: "organicRank",
    width: 140,
  },
  {
    title: "Organic Impressions",
    dataIndex: "organicImpressions",
    key: "organicImpressions",
    width: 160,
  },
  {
    title: "Impressions share",
    dataIndex: "impressionsShare",
    key: "impressionsShare",
    width: 160,
  },
  {
    title: "Organic Clicks",
    dataIndex: "organicClicks",
    key: "organicClicks",
    width: 140,
  },
  {
    title: "Organic Units Sold",
    dataIndex: "organicUnitsSold",
    key: "organicUnitsSold",
    width: 180,
  },
  {
    title: "Organic Sales",
    dataIndex: "organicSales",
    key: "organicSales",
    width: 140,
  },
  {
    title: "Organic CTR",
    dataIndex: "organicCTR",
    key: "organicCTR",
    width: 140,
  },
  {
    title: "Organic CVR",
    dataIndex: "organicCVR",
    key: "organicCVR",
    width: 140,
  },
  {
    title: "Organic CPR",
    dataIndex: "organicCPR",
    key: "organicCPR",
    width: 140,
  },
  {
    title: "Target Month Units Sold",
    dataIndex: "targetMonthUnitsSold",
    key: "targetMonthUnitsSold",
    width: 180,
  },
  {
    title: "Relevancy Score",
    dataIndex: "relevancyScore",
    key: "relevancyScore",
    width: 140,
  },
  {
    title: "Competing Products",
    dataIndex: "competingProducts",
    key: "competingProducts",
    width: 160,
  },
  {
    title: "Competition Index",
    dataIndex: "competingIndex",
    key: "competingIndex",
    width: 140,
  },
];

export const data = [
  {
    key: "1",
    category: "Hair growth gummies",
    searchVolume: "1,229,348",
    organicRank: "7",
    organicImpressions: "810,006",
    impressionsShare: "2.64%",
    organicClicks: "19,186",
    organicUnitsSold: "1,795",
    organicSales: "$53,863",
    organicCTR: "0.45%",
    organicCVR: "0.12%",
    organicCPR: "223",
    targetMonthUnitsSold: "836",
    relevancyScore: "12%",
    competingProducts: "10,000",
    competingIndex: "6.5%",
  },
  {
    key: "2",
    category: "Hair gummies",
    searchVolume: "608,941",
    organicRank: "9",
    organicImpressions: "401,225",
    impressionsShare: "2.78%",
    organicClicks: "9,503",
    organicUnitsSold: "889",
    organicSales: "$26,680",
    organicCTR: "1.65%",
    organicCVR: "0.18%",
    organicCPR: "221",
    targetMonthUnitsSold: "829",
    relevancyScore: "74%",
    competingProducts: "50,000",
    competingIndex: "153%",
  },
  {
    key: "3",
    category: "Hair growth for women",
    searchVolume: "1,531,873",
    organicRank: "11",
    organicImpressions: "1,009,337",
    impressionsShare: "3.10%",
    organicClicks: "29,907",
    organicUnitsSold: "2,237",
    organicSales: "$67,118",
    organicCTR: "1.07%",
    organicCVR: "0.35%",
    organicCPR: "187",
    targetMonthUnitsSold: "701",
    relevancyScore: "50%",
    competingProducts: "10,000",
    competingIndex: "8%",
  },
  {
    key: "4",
    category: "Hair growth vitamins",
    searchVolume: "5,043,633",
    organicRank: "9",
    organicImpressions: "3,323,202",
    impressionsShare: "3.18%",
    organicClicks: "78,713",
    organicUnitsSold: "7,366",
    organicSales: "$220,982",
    organicCTR: "0.22%",
    organicCVR: "0.32%",
    organicCPR: "167",
    targetMonthUnitsSold: "630",
    relevancyScore: "18%",
    competingProducts: "2,000",
    competingIndex: "85%",
  },
  {
    key: "5",
    category: "Hair supplements for...",
    searchVolume: "87,707",
    organicRank: "10",
    organicImpressions: "57,789",
    impressionsShare: "2.96%",
    organicClicks: "1,369",
    organicUnitsSold: "128",
    organicSales: "$9,433",
    organicCTR: "0.42%",
    organicCVR: "0.99%",
    organicCPR: "163",
    targetMonthUnitsSold: "611",
    relevancyScore: "32%",
    competingProducts: "10,000",
    competingIndex: "10%",
  },
  {
    key: "6",
    category: "Biotin chewables",
    searchVolume: "1,531,873",
    organicRank: "11",
    organicImpressions: "1,009,337",
    impressionsShare: "3.10%",
    organicClicks: "23,907",
    organicUnitsSold: "2,237",
    organicSales: "$67,118",
    organicCTR: "0.68%",
    organicCVR: "0.44%",
    organicCPR: "127",
    targetMonthUnitsSold: "476",
    relevancyScore: "16%",
    competingProducts: "3,000",
    competingIndex: "102%",
  },
  {
    key: "7",
    category: "Total",
    searchVolume: "5,043,633",
    organicRank: "9",
    organicImpressions: "3,323,202",
    impressionsShare: "2%",
    organicClicks: "110,526",
    organicUnitsSold: "17,910",
    organicSales: "$537,300",
    organicCTR: "0.98%",
    organicCVR: "0.4%",
    organicCPR: "1,082",
    targetMonthUnitsSold: "4,273",
    relevancyScore: "34%",
    competingProducts: "13,500",
    competingIndex: "27.5%",
  },
];
