import { useEffect, useState } from "react";
import classNames from "classnames";
import styles from "./styles.module.scss";
import ChevronDown from "@assets/icons/chevron-down";
import { useOutsideClick } from "src/utils/useOutsideClick";
import { DropdownItems } from "./renderDropdown";
import {
  COMPARING_TO,
  calculateCompareRange,
  getLabelFromCompareWith,
} from "./utils";

interface CustomSelectProps {
  dateRange: {
    startDate: Date;
    endDate: Date;
  };
  compareWith: {
    startDate: Date;
    endDate: Date;
  };
  setCompareWith: (range: { startDate: Date; endDate: Date }) => void;
  className?: string;
}

const CustomSelect = ({
  dateRange,
  compareWith,
  setCompareWith,
  className,
}: CustomSelectProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [label, setLabel] = useState(
    getLabelFromCompareWith(compareWith, dateRange),
  );
  const dropdownRef = useOutsideClick(() => setIsOpen(false));

  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleItemClick = (selectedLabel: string) => {
    setLabel(selectedLabel);
    setIsOpen(false);
    const newCompareRange = calculateCompareRange(selectedLabel, dateRange);
    setCompareWith(newCompareRange);
  };

  useEffect(() => {
    setCompareWith(calculateCompareRange(label, dateRange));
  }, [dateRange]);

  return (
    <div className={styles.dropdown} ref={dropdownRef}>
      <div
        className={classNames(styles.dropdownContainer, {
          [styles.dropdownActive]: isOpen,
        })}
      >
        <button
          className={classNames(styles.customSelect, className, {
            [styles.active]: isOpen,
          })}
          onClick={toggleDropdown}
        >
          {COMPARING_TO + label.toLowerCase()}
          <div className={styles.arrow}>
            <ChevronDown />
          </div>
        </button>
        {isOpen && (
          <div className={styles.dropdownMenu}>
            <DropdownItems
              handleItemClick={handleItemClick}
              currentDate={dateRange.startDate}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default CustomSelect;
