import WidgetIcon from "@assets/icons/widgetsDNDBtn";
import styles from "./styles.module.scss";
import Templates from "./Templates";
import classNames from "classnames";
import { FormEventHandler } from "react";
import { Template } from "./Template";
import { Spin } from "antd";

interface SelectTemplateWidgetsProps {
  loading: boolean;
  templates: Template[];
  onCancel: () => void;
  onGenereImage: () => void;
}

const SelectTemplateWidgets = ({
  loading,
  templates,
  onCancel,
  onGenereImage,
}: SelectTemplateWidgetsProps) => {
  const handleSubmit: FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();
    const target = event.nativeEvent.target;

    if (!(target instanceof HTMLFormElement)) {
      return;
    }

    const inputs = target.querySelectorAll(
      "input:checked",
    ) as NodeListOf<HTMLInputElement>;

    const checkedTemplates = Array.from(inputs).map((el) => el.value);

    if (checkedTemplates.length === 0) {
      return;
    }

    console.log(checkedTemplates);
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className={styles.block}>
        <WidgetIcon />
        <h2 className={styles.block__title}>Select Template</h2>
        {loading ? (
          <div className={styles.block__loading}>
            <Spin size="large" />
          </div>
        ) : (
          <>
            <Templates templates={templates} />
          </>
        )}
      </div>
      <div className={styles.buttons}>
        <button
          className={classNames(styles.button, styles["button--outline"])}
          onClick={onCancel}
        >
          Cancel
        </button>
        <button className={styles.button} onClick={onGenereImage}>
          Generate image
        </button>
      </div>
    </form>
  );
};

export default SelectTemplateWidgets;
