import styles from "./styles.module.scss";
import { cardData } from "../../Widgets/mock";
import classNames from "classnames";
import {
  NameType,
  Payload,
  ValueType,
} from "recharts/types/component/DefaultTooltipContent";
import { Coordinate } from "recharts/types/util/types";
import { getAdsLabel } from "@components/CheckWidget";
import TrendUp from "@assets/icons/trendUp";
import TrendDown from "@assets/icons/trendDown";

interface CustomTooltipProps {
  payload: Payload<ValueType, NameType>[];
  active: boolean;
  label: string;
  coordinate: Partial<Coordinate>;
  chartWidth: number;
}

const convertValue = (name: string, value: number) => {
  switch (name) {
    case "sales":
      return `$${Number(value).toFixed(2)}`;
    case "spend":
      return `$${Math.floor(Number(value)).toLocaleString("en-US")}`;
    case "ctr":
    case "cvr":
    case "acos":
      return `${Number(value).toFixed(2)}%`;
    case "cpr":
      return `$${Number(value).toFixed(2)}`;
    case "cpc":
      return `$${Number(value).toFixed(2)}`;
    case "roas":
      return Number(value).toFixed(2);
    default:
      return value.toLocaleString("en-US");
  }
};

const getDayOfWeek = (dateLabel: string): string => {
  const date = new Date(dateLabel);

  if (isNaN(date.getTime())) {
    return "";
  }

  return (
    date.toLocaleDateString("en-US", { weekday: "long" }).slice(0, 3) + ", "
  );
};

export const CustomTooltip = ({
  active,
  payload,
  label,
  coordinate,
  chartWidth,
}: CustomTooltipProps) => {
  if (active && payload && payload.length) {
    const isLeft = coordinate.x - 200 > chartWidth / 2;
    return (
      <div
        className={classNames(
          styles.tooltip,
          isLeft && styles.left,
          payload[0].payload.isPastData && styles.big,
        )}
      >
        <p className={styles.tooltip__label}>
          {getDayOfWeek(label)} {label}
        </p>{" "}
        <ul className={styles.tooltip__list}>
          {Object.keys(payload[0].payload).map((key, index) => {
            if (key === "date" || key === "isForecast" || key === "isPastData")
              return null;

            const displayKey = key.replace(/^(forecast|past)/i, "");

            // Skip 'past' keys; we'll handle them with their corresponding actual keys
            if (key.startsWith("past")) return null;

            const actualValue = payload[0].payload[key];
            const pastKey = `past${displayKey.charAt(0).toUpperCase()}${displayKey.slice(1)}`;
            const pastValue = payload[0].payload[pastKey];
            const changeImg =
              actualValue > pastValue ? <TrendUp /> : <TrendDown />;

            const displayValue =
              payload[0].payload.isPastData && pastValue !== undefined ? (
                <div className={styles.value}>
                  <div>{convertValue(displayKey, Number(actualValue))}</div>
                  <div>{changeImg}</div>
                  <div>{convertValue(displayKey, Number(pastValue))}</div>
                </div>
              ) : (
                convertValue(displayKey, Number(actualValue))
              );

            return (
              <li key={index} className={styles.tooltip__item}>
                <div className={styles.name}>
                  <span>
                    {!payload[0].payload.isForecast ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="8"
                        height="8"
                        viewBox="0 0 8 8"
                        fill="none"
                      >
                        <circle
                          cx="4"
                          cy="4"
                          r="4"
                          fill={cardData[index - 3]?.color}
                        />
                      </svg>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="10"
                        height="10"
                        viewBox="0 0 10 10"
                        fill="none"
                      >
                        <circle
                          cx="5"
                          cy="5"
                          r="4"
                          fill={cardData[index - 3]?.color}
                          fill-opacity="0.2"
                          stroke={cardData[index - 3]?.color}
                          stroke-width="2"
                          stroke-dasharray="2 2"
                        />
                      </svg>
                    )}
                  </span>
                  <span className={styles.tooltip__name}>
                    {getAdsLabel(displayKey as string)}
                    <span
                      className={
                        displayKey.toString().length <= 4 &&
                        styles.tooltip__item__small
                      }
                    >
                      {displayKey === "unitSolds" ? "Units Sold" : displayKey}:
                    </span>
                  </span>
                </div>
                <div className={styles.tooltip__value}>{displayValue}</div>
              </li>
            );
          })}
        </ul>
      </div>
    );
  }

  return null;
};
