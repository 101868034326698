export const horizontalCampaignsData = {
  name: "Campaigns",
  children: [
    {
      name: "Nyl - SP - B0B2HN33KQ1",
      children: [
        { name: "Some Keyword 1" },
        { name: "Another Keyword 1" },
        { name: "Keyword new 1" },
        { name: "Some Keyword 2" },
      ],
    },
    {
      name: "Nyl - SP - B0B2HN33KQ2",
      children: [
        { name: "Some Keyword 3" },
        { name: "Another Keyword 2" },
        { name: "Keyword new 2" },
        { name: "Some Keyword 4" },
        { name: "Another Keyword 3" },
        { name: "Keyword new 3" },
        { name: "Some Keyword 5" },
        { name: "Another Keyword 4" },
        { name: "Keyword new 4" },
        { name: "Some Keyword 6" },
        { name: "Another Keyword 5" },
        { name: "Keyword new 5" },
        { name: "Another Keyword 6" },
        { name: "Keyword new 6" },
      ],
    },
    {
      name: "Nyl - SP - B0B2HN33KQ3",
      children: [
        { name: "Some Keyword 7" },
        { name: "Another Keyword 7" },
        { name: "Keyword new 7" },
        { name: "Keyword new 8" },
        { name: "Another Keyword 8" },
        { name: "Keyword new 9" },
        { name: "Keyword new 10" },
        { name: "Another Keyword 9" },
        { name: "Keyword new 11" },
        { name: "Keyword new 12" },
        { name: "Another Keyword 10" },
        { name: "Keyword new 13" },
        { name: "Keyword new 14" },
        { name: "Another Keyword 11" },
        { name: "Keyword new 15" },
        { name: "Keyword new 16" },
        { name: "Another Keyword 12" },
        { name: "Keyword new 17" },
        { name: "Keyword new 18" },
        { name: "Another Keyword 13" },
        { name: "Keyword new 19" },
        { name: "Keyword new 20" },
      ],
    },
    {
      name: "Nyl - SP - B0B2HN33KQ4",
      children: [
        { name: "Some Keyword 8" },
        { name: "Another Keyword 14" },
        { name: "Keyword new 21" },
        { name: "Some Keyword 9" },
        { name: "Another Keyword 15" },
        { name: "Keyword new 22" },
        { name: "Some Keyword 10" },
        { name: "Another Keyword 16" },
      ],
    },
    {
      name: "Nyl - SP - B0B2HN33KQ5",
      children: [
        { name: "Some Keyword 11" },
        { name: "Another Keyword 17" },
        { name: "Keyword new 23" },
        { name: "Some Keyword 12" },
        { name: "Another Keyword 18" },
        { name: "Keyword new 24" },
        { name: "Some Keyword 13" },
        { name: "Another Keyword 19" },
      ],
    },
    {
      name: "Nyl - SP - B0B2HN33KQ6",
      children: [
        { name: "Some Keyword 14" },
        { name: "Another Keyword 20" },
        { name: "Key word looooong name 1" },
        { name: "Some Keyword 15" },
        { name: "Another Keyword 21" },
        { name: "Keyword new 25" },
      ],
    },
    {
      name: "Nyl - SP - B0B2HN33KQ7",
      children: [{ name: "Some Keyword 16" }, { name: "Another Keyword 22" }],
    },
  ],
};

export const horizontalAdTypeData = {
  name: "Ad Types",
  children: [
    {
      name: "Sponsored Brands",
      children: [
        {
          name: "Nyl - SP - B0B2HN33KQ1",
          children: [
            { name: "Some Keyword 1" },
            { name: "Another Keyword 2" },
            { name: "Keyword new 3" },
            { name: "Some Keyword 4" },
          ],
        },
        {
          name: "Nyl - SP - B0B2HN33KQ2",
          children: [
            { name: "Some Keyword 5" },
            { name: "Another Keyword 6" },
            { name: "Keyword new 7" },
            { name: "Some Keyword 8" },
            { name: "Some Keyword 7" },
            { name: "Another Keyword 10" },
            { name: "Keyword new 11" },
            { name: "Some Keyword 12" },
            { name: "Some Keyword 13" },
            { name: "Another Keyword 14" },
            { name: "Keyword new 15" },
            { name: "Some Keyword 16" },
            { name: "Some Keyword 17" },
            { name: "Another Keyword 18" },
          ],
        },
      ],
    },
    {
      name: "Sponsored Display",
      children: [
        {
          name: "Nyl - SP - B0B2HN33KQ3",
          children: [
            { name: "Some Keyword 33" },
            { name: "Another Keyword 34" },
            { name: "Keyword new 35" },
            { name: "Some Keyword 36" },
            { name: "Some Keyword 37" },
            { name: "Another Keyword 38" },
            { name: "Keyword new 39" },
            { name: "Some Keyword 40" },
            { name: "Some Keyword 41" },
            { name: "Another Keyword 42" },
            { name: "Keyword new 43" },
            { name: "Some Keyword 44" },
            { name: "Some Keyword 45" },
            { name: "Another Keyword 46" },
            { name: "Keyword new 47" },
            { name: "Some Keyword 48" },
            { name: "Some Keyword 49" },
            { name: "Another Keyword 50" },
            { name: "Keyword new 51" },
            { name: "Some Keyword 52" },
            { name: "Some Keyword 53" },
            { name: "Another Keyword 54" },
            { name: "Keyword new 55" },
          ],
        },
        {
          name: "Nyl - SP - B0B2HN33KQ4",
          children: [
            { name: "Some Keyword 19" },
            { name: "Another Keyword 20" },
            { name: "Keyword new 21" },
            { name: "Some Keyword 22" },
            { name: "Some Keyword 23" },
            { name: "Another Keyword 24" },
            { name: "Keyword new 25" },
            { name: "Some Keyword 26" },
          ],
        },
        {
          name: "Nyl - SP - B0B2HN33KQ5",
          children: [
            { name: "Some Keyword 533" },
            { name: "Another Keyword 534" },
            { name: "Keyword new 535" },
            { name: "Some Keyword 536" },
            { name: "Some Keyword 537" },
            { name: "Another Keyword 538" },
            { name: "Keyword new 539" },
            { name: "Some Keyword 540" },
          ],
        },
      ],
    },
    {
      name: "Sponsored Products",
      children: [
        {
          name: "Nyl - SP - B0B2HN33KQ6",
          children: [
            { name: "Some Keyword 56" },
            { name: "Another Keyword 57" },
            { name: "Keyword new 58" },
            { name: "Some Keyword 59" },
            { name: "Some Keyword 60" },
            { name: "Another Keyword 61" },
            { name: "Keyword new 62" },
            { name: "Some Keyword 63" },
          ],
        },
        {
          name: "Nyl - SP - B0B2HN33KQ6",
          children: [
            { name: "Some Keyword 64" },
            { name: "Another Keyword 65" },
          ],
        },
      ],
    },
  ],
};

export const horizontalFunnelData = {
  name: "Funnels",
  children: [
    {
      name: "BOF",
      children: [
        {
          name: "Nyl - SP - B0B2HN33KQ1",
          children: [
            { name: "Some Keyword 1" },
            { name: "Another Keyword 2" },
            { name: "Keyword new 3" },
            { name: "Some Keyword 4" },
          ],
        },
        {
          name: "Nyl - SP - B0B2HN33KQ2",
          children: [
            { name: "Some Keyword 5" },
            { name: "Another Keyword 6" },
            { name: "Keyword new 7" },
            { name: "Some Keyword 8" },
            { name: "Some Keyword 7" },
            { name: "Another Keyword 10" },
            { name: "Keyword new 11" },
            { name: "Some Keyword 12" },
            { name: "Some Keyword 13" },
            { name: "Another Keyword 14" },
            { name: "Keyword new 15" },
            { name: "Some Keyword 16" },
            { name: "Some Keyword 17" },
            { name: "Another Keyword 18" },
          ],
        },
      ],
    },
    {
      name: "TOF",
      children: [
        {
          name: "Nyl - SP - B0B2HN33KQ3",
          children: [
            { name: "Some Keyword 33" },
            { name: "Another Keyword 34" },
            { name: "Keyword new 35" },
            { name: "Some Keyword 36" },
            { name: "Some Keyword 37" },
            { name: "Another Keyword 38" },
            { name: "Keyword new 39" },
            { name: "Some Keyword 40" },
            { name: "Some Keyword 41" },
            { name: "Another Keyword 42" },
            { name: "Keyword new 43" },
            { name: "Some Keyword 44" },
            { name: "Some Keyword 45" },
            { name: "Another Keyword 46" },
            { name: "Keyword new 47" },
            { name: "Some Keyword 48" },
            { name: "Some Keyword 49" },
            { name: "Another Keyword 50" },
            { name: "Keyword new 51" },
            { name: "Some Keyword 52" },
            { name: "Some Keyword 53" },
            { name: "Another Keyword 54" },
            { name: "Keyword new 55" },
          ],
        },
        {
          name: "Nyl - SP - B0B2HN33KQ4",
          children: [
            { name: "Some Keyword 19" },
            { name: "Another Keyword 20" },
            { name: "Keyword new 21" },
            { name: "Some Keyword 22" },
            { name: "Some Keyword 23" },
            { name: "Another Keyword 24" },
            { name: "Keyword new 25" },
            { name: "Some Keyword 26" },
          ],
        },
        {
          name: "Nyl - SP - B0B2HN33KQ5",
          children: [
            { name: "Some Keyword 533" },
            { name: "Another Keyword 534" },
            { name: "Keyword new 535" },
            { name: "Some Keyword 536" },
            { name: "Some Keyword 537" },
            { name: "Another Keyword 538" },
            { name: "Keyword new 539" },
            { name: "Some Keyword 540" },
          ],
        },
      ],
    },
    {
      name: "MOF",
      children: [
        {
          name: "Nyl - SP - B0B2HN33KQ6",
          children: [
            { name: "Some Keyword 56" },
            { name: "Another Keyword 57" },
            { name: "Keyword new 58" },
            { name: "Some Keyword 59" },
            { name: "Some Keyword 60" },
            { name: "Another Keyword 61" },
            { name: "Keyword new 62" },
            { name: "Some Keyword 63" },
          ],
        },
        {
          name: "Nyl - SP - B0B2HN33KQ6",
          children: [
            { name: "Some Keyword 64" },
            { name: "Another Keyword 65" },
          ],
        },
      ],
    },
  ],
};

export const horizontalTargetData = {
  name: "Match Types",
  children: [
    {
      name: "BRAND",
      children: [
        {
          name: "Nyl - SP - B0B2HN33KQ1",
          children: [
            { name: "Some Keyword 1" },
            { name: "Another Keyword 2" },
            { name: "Keyword new 3" },
            { name: "Some Keyword 4" },
          ],
        },
        {
          name: "Nyl - SP - B0B2HN33KQ2",
          children: [
            { name: "Some Keyword 5" },
            { name: "Another Keyword 6" },
            { name: "Keyword new 7" },
            { name: "Some Keyword 8" },
            { name: "Some Keyword 7" },
            { name: "Another Keyword 10" },
            { name: "Keyword new 11" },
            { name: "Some Keyword 12" },
            { name: "Some Keyword 13" },
            { name: "Another Keyword 14" },
            { name: "Keyword new 15" },
            { name: "Some Keyword 16" },
            { name: "Some Keyword 17" },
            { name: "Another Keyword 18" },
          ],
        },
      ],
    },
    {
      name: "COMPETITOR",
      children: [
        {
          name: "Nyl - SP - B0B2HN33KQ3",
          children: [
            { name: "Some Keyword 33" },
            { name: "Another Keyword 34" },
            { name: "Keyword new 35" },
            { name: "Some Keyword 36" },
            { name: "Some Keyword 37" },
            { name: "Another Keyword 38" },
            { name: "Keyword new 39" },
            { name: "Some Keyword 40" },
            { name: "Some Keyword 41" },
            { name: "Another Keyword 42" },
            { name: "Keyword new 43" },
            { name: "Some Keyword 44" },
            { name: "Some Keyword 45" },
            { name: "Another Keyword 46" },
            { name: "Keyword new 47" },
            { name: "Some Keyword 48" },
            { name: "Some Keyword 49" },
            { name: "Another Keyword 50" },
            { name: "Keyword new 51" },
            { name: "Some Keyword 52" },
            { name: "Some Keyword 53" },
            { name: "Another Keyword 54" },
            { name: "Keyword new 55" },
          ],
        },
        {
          name: "Nyl - SP - B0B2HN33KQ4",
          children: [
            { name: "Some Keyword 19" },
            { name: "Another Keyword 20" },
            { name: "Keyword new 21" },
            { name: "Some Keyword 22" },
            { name: "Some Keyword 23" },
            { name: "Another Keyword 24" },
            { name: "Keyword new 25" },
            { name: "Some Keyword 26" },
          ],
        },
        {
          name: "Nyl - SP - B0B2HN33KQ5",
          children: [
            { name: "Some Keyword 533" },
            { name: "Another Keyword 534" },
            { name: "Keyword new 535" },
            { name: "Some Keyword 536" },
            { name: "Some Keyword 537" },
            { name: "Another Keyword 538" },
            { name: "Keyword new 539" },
            { name: "Some Keyword 540" },
          ],
        },
      ],
    },
    {
      name: "GENERIC",
      children: [
        {
          name: "Nyl - SP - B0B2HN33KQ6",
          children: [
            { name: "Some Keyword 56" },
            { name: "Another Keyword 57" },
            { name: "Keyword new 58" },
            { name: "Some Keyword 59" },
            { name: "Some Keyword 60" },
            { name: "Another Keyword 61" },
            { name: "Keyword new 62" },
            { name: "Some Keyword 63" },
          ],
        },
        {
          name: "Nyl - SP - B0B2HN33KQ6",
          children: [
            { name: "Some Keyword 64" },
            { name: "Another Keyword 65" },
          ],
        },
      ],
    },
  ],
};

export const horizontalTargetingData = {
  name: "Targeting",
  children: [
    {
      name: "asin = “B0B5HN65QQ”",
      children: [
        { name: "Nyl - SP - B0B2HN33KQ1" },
        { name: "Nyl - SP - B0B2HN33KQ2" },
        { name: "Nyl - SP - B0B2HN33KQ3" },
        { name: "Nyl - SP - B0B2HN33KQ4" },
      ],
    },
    {
      name: "category = ”Theeth whiting strips”",
      children: [
        { name: "Nyl - SP - B0B5HN65QQ" },
        { name: "Nyl - SP - B0B5HN65KO" },
        { name: "Nyl - SP - B0B5HN65KO2" },
        { name: "Nyl - SP - B0B5JK13OP" },
        { name: "Nyl - SP - B0B5II01OP" },
        { name: "Nyl - SP - B0B5ER88KP" },
        { name: "Nyl - SP - B0B2HN33KQ5" },
      ],
    },
    {
      name: "asin= “B0B2HN33KQ”",
      children: [
        { name: "Nyl - SP - B0B5HN65QQ2" },
        { name: "Nyl - SP - B0B5HN65KO3" },
        { name: "Nyl - SP - B0B5HN65KO4" },
        { name: "Nyl - SP - B0B5JK13OP2" },
        { name: "Nyl - SP - B0B5II01OP2" },
        { name: "Nyl - SP - B0B5ER88KP2" },
        { name: "Nyl - SP - B0B2HN33KQ6" },
      ],
    },
    {
      name: "theeth whiting strips",
      children: [
        { name: "Nyl - SP - B0B5ER88KP3" },
        { name: "Nyl - SP - B0B2HN33KQ7" },
      ],
    },
    {
      name: "Whiting Strip",
      children: [
        { name: "Nyl - SP - B0B5HN65QQ3" },
        { name: "Nyl - SP - B0B5HN65KO5" },
        { name: "Nyl - SP - B0B5HN65KO6" },
        { name: "Nyl - SP - B0B5JK13OP3" },
      ],
    },
    {
      name: "asin= “B0B5II01OP",
      children: [
        { name: "Nyl - SP - B0B5ER88KP4" },
        { name: "Nyl - SP - B0B2HN33KQ8" },
      ],
    },
    {
      name: "natural whiting strips",
      children: [
        { name: "Nyl - SP - B0B5HN65QQ4" },
        { name: "Nyl - SP - B0B5HN65KO6" },
        { name: "Nyl - SP - B0B5HN65KO7" },
        { name: "Nyl - SP - B0B5JK13OP4" },
      ],
    },
  ],
};

export const verticalCampaignsData = {
  nodes: [
    { name: "Nyl - SP - B0B5HN65QQ" },
    { name: "Nyl - SP - B0B2HN33KQ" },
    { name: "Nyl - SP - B0B5HN65KO" },
    { name: "Nyl - SP - B0B5IP83AA" },
    { name: "Nyl - SP - B0B5JK13OP" },
    { name: "Nyl - SP - B0B5II01OQ" },
    { name: "Nyl - SP - B0B5ER88KP" },
    { name: "Hair growth gummies" },
    { name: "Hair gummies" },
    { name: "Hair growth for women" },
    { name: "Hair growth vitamins" },
    { name: "Hair supplements for hair growth women" },
    { name: "Biotin chewables" },
  ],
  links: [
    {
      source: "Nyl - SP - B0B5HN65QQ",
      target: "Hair growth vitamins",
      value: 1,
    },
    {
      source: "Nyl - SP - B0B5HN65QQ",
      target: "Hair supplements for hair growth women",
      value: 1,
    },
    { source: "Nyl - SP - B0B2HN33KQ", target: "Hair gummies", value: 12 },
    {
      source: "Nyl - SP - B0B2HN33KQ",
      target: "Biotin chewables",
      value: 1,
    },
    {
      source: "Nyl - SP - B0B5HN65KO",
      target: "Hair growth for women",
      value: 1,
    },
    {
      source: "Nyl - SP - B0B5HN65KO",
      target: "Hair gummies",
      value: 1,
    },
    {
      source: "Nyl - SP - B0B5HN65KO",
      target: "Hair growth vitamins",
      value: 1,
    },
    {
      source: "Nyl - SP - B0B5IP83AA",
      target: "Hair growth vitamins",
      value: 1,
    },
    {
      source: "Nyl - SP - B0B5IP83AA",
      target: "Hair growth vitamins",
      value: 1,
    },
    { source: "Nyl - SP - B0B5IP83AA", target: "Biotin chewables", value: 6 },
    {
      source: "Nyl - SP - B0B5IP83AA",
      target: "Hair growth gummies",
      value: 1,
    },
    {
      source: "Nyl - SP - B0B5JK13OP",
      target: "Hair gummies",
      value: 10,
    },
    {
      source: "Nyl - SP - B0B5II01OQ",
      target: "Hair growth for women",
      value: 1,
    },
    {
      source: "Nyl - SP - B0B5II01OQ",
      target: "Hair gummies",
      value: 1,
    },
    {
      source: "Nyl - SP - B0B5ER88KP",
      target: "Hair growth vitamins",
      value: 1,
    },
  ],
};

export const verticalAdTypeData = {
  nodes: [
    { name: "Sponsored Products", color: "#fff" },
    { name: "Sponsored Display" },
    { name: "Sponsored Brands" },
    { name: "Nyl - SP - B0B5HN65QQ" },
    { name: "Nyl - SD - B0B2HN33KQ" },
    { name: "Nyl - SD - B0B5HN65KO" },
    { name: "Nyl - SB - B0B5IP83AA" },
    { name: "Nyl - SP - B0B5JK13OP" },
    { name: "Nyl - SP - B0B5II01OQ" },
    { name: "Nyl - SP - B0B5ER88KP" },
    { name: "Hair growth gummies" },
    { name: "Hair gummies" },
    { name: "Hair growth for women" },
    { name: "Hair growth vitamins" },
    { name: "Hair supplements for hair growth women" },
    { name: "Biotin chewables" },
  ],
  links: [
    {
      source: "Sponsored Products",
      target: "Nyl - SP - B0B5JK13OP",
      value: 20,
    },
    {
      source: "Sponsored Products",
      target: "Nyl - SP - B0B5II01OQ",
      value: 10,
    },
    {
      source: "Sponsored Products",
      target: "Nyl - SP - B0B5ER88KP",
      value: 16,
    },
    {
      source: "Sponsored Display",
      target: "Nyl - SD - B0B2HN33KQ",
      value: 25,
    },
    { source: "Sponsored Display", target: "Nyl - SD - B0B5HN65KO", value: 35 },
    { source: "Sponsored Brands", target: "Nyl - SP - B0B5HN65QQ", value: 40 },
    { source: "Sponsored Brands", target: "Nyl - SB - B0B5IP83AA", value: 40 },
    {
      source: "Nyl - SP - B0B5HN65QQ",
      target: "Hair growth vitamins",
      value: 20,
    },
    {
      source: "Nyl - SP - B0B5HN65QQ",
      target: "Hair supplements for hair growth women",
      value: 20,
    },
    { source: "Nyl - SD - B0B2HN33KQ", target: "Hair gummies", value: 16 },
    { source: "Nyl - SD - B0B2HN33KQ", target: "Biotin chewables", value: 9 },
    {
      source: "Nyl - SD - B0B5HN65KO",
      target: "Hair growth for women",
      value: 15,
    },
    { source: "Nyl - SD - B0B5HN65KO", target: "Hair gummies", value: 11 },
    {
      source: "Nyl - SD - B0B5HN65KO",
      target: "Hair growth vitamins",
      value: 9,
    },
    {
      source: "Nyl - SB - B0B5IP83AA",
      target: "Hair growth vitamins",
      value: 13,
    },
    { source: "Nyl - SB - B0B5IP83AA", target: "Biotin chewables", value: 8 },
    {
      source: "Nyl - SB - B0B5IP83AA",
      target: "Hair growth gummies",
      value: 19,
    },
    { source: "Nyl - SP - B0B5JK13OP", target: "Hair gummies", value: 20 },
    {
      source: "Nyl - SP - B0B5II01OQ",
      target: "Hair growth for women",
      value: 4,
    },
    { source: "Nyl - SP - B0B5II01OQ", target: "Hair gummies", value: 6 },
    {
      source: "Nyl - SP - B0B5ER88KP",
      target: "Hair growth vitamins",
      value: 16,
    },
  ],
};

export const verticalFunnelData = {
  nodes: [
    { name: "Amazon Organic" },
    { name: "TOF" },
    { name: "BOF" },
    { name: "MOF" },
    { name: "Nyl - SP - B0B5HN65QQ" },
    { name: "Nyl - SP - B0B2HN33KQ" },
    { name: "Nyl - SP - B0B5HN65KO" },
    { name: "Nyl - SP - B0B5IP83AA" },
    { name: "Nyl - SP - B0B5JK13OP" },
    { name: "Nyl - SP - B0B5II01OQ" },
    { name: "Nyl - SP - B0B5ER88KP" },
    { name: "Hair growth gummies" },
    { name: "Hair gummies" },
    { name: "Hair growth for women" },
    { name: "Hair growth vitamins" },
    { name: "Hair supplements for hair growth women" },
    { name: "Biotin chewables" },
  ],
  links: [
    { source: "Amazon Organic", target: "Hair growth gummies", value: 10 },
    { source: "Amazon Organic", target: "Hair gummies", value: 10 },
    { source: "Amazon Organic", target: "Hair growth for women", value: 10 },
    { source: "Amazon Organic", target: "Hair growth vitamins", value: 10 },
    {
      source: "Amazon Organic",
      target: "Hair supplements for hair growth women",
      value: 10,
    },
    { source: "Amazon Organic", target: "Biotin chewables", value: 10 },
    { source: "BOF", target: "Nyl - SP - B0B5HN65QQ", value: 8 },
    { source: "BOF", target: "Nyl - SP - B0B2HN33KQ", value: 10 },
    { source: "BOF", target: "Nyl - SP - B0B5HN65KO", value: 9 },
    { source: "BOF", target: "Nyl - SP - B0B5HN65QQ", value: 10 },
    { source: "TOF", target: "Nyl - SP - B0B5IP83AA", value: 12 },
    { source: "TOF", target: "Nyl - SP - B0B5HN65QQ", value: 20 },
    { source: "MOF", target: "Nyl - SP - B0B5JK13OP", value: 7 },
    { source: "MOF", target: "Nyl - SP - B0B5II01OQ", value: 14 },
    { source: "MOF", target: "Nyl - SP - B0B5ER88KP", value: 11 },
    { source: "MOF", target: "Nyl - SP - B0B5HN65KO", value: 9 },
    {
      source: "Nyl - SP - B0B5HN65QQ",
      target: "Hair growth gummies",
      value: 18,
    },
    {
      source: "Nyl - SP - B0B5HN65QQ",
      target: "Biotin chewables",
      value: 10,
    },
    { source: "Nyl - SP - B0B2HN33KQ", target: "Hair gummies", value: 8 },
    {
      source: "Nyl - SP - B0B5HN65KO",
      target: "Hair growth for women",
      value: 20,
    },
    {
      source: "Nyl - SP - B0B5IP83AA",
      target: "Hair growth vitamins",
      value: 9,
    },
    {
      source: "Nyl - SP - B0B5IP83AA",
      target: "Hair supplements for hair growth women",
      value: 6,
    },
    { source: "Nyl - SP - B0B5JK13OP", target: "Hair gummies", value: 7 },
    { source: "Nyl - SP - B0B5II01OQ", target: "Biotin chewables", value: 7 },
    { source: "Nyl - SP - B0B5II01OQ", target: "Hair gummies", value: 7 },
    {
      source: "Nyl - SP - B0B5ER88KP",
      target: "Hair growth vitamins",
      value: 11,
    },
  ],
};

export const verticalTargetData = {
  nodes: [
    { name: "BRAND" },
    { name: "COMPETITOR" },
    { name: "GENERIC" },
    { name: "Brand KW targeting (BRKW)" },
    { name: "Foreign language KW targeting (FLKW)" },
    { name: "Brand product targeting (COPR)" },
    { name: "Category targeting (CAT)" },
    { name: "Competitors KW targeting (COKW)" },
    { name: "Competitors product targeting (COPR)" },
    { name: "Video competitor product targeting (SBV COPR)" },
    { name: "SD competitor product targeting" },
    { name: "Generic HIGH relevancy KW targeting (GEKW HI)" },
    { name: "Generic MID relevancy KW targeting (GEKW MID)" },
    { name: "Generic LOW relevancy KW targeting (GEKW LOW)" },
    { name: "Video generic KW targeting (SBV GEKW)" },
    { name: "Nyl - SP - B0B5HN65QQ" },
    { name: "Nyl - SP - B0B2HN33KQ" },
    { name: "Nyl - SP - B0B5HN65KO" },
    { name: "Nyl - SP - B0B5IP83AA" },
    { name: "Nyl - SP - B0B5JK13OP" },
    { name: "Nyl - SP - B0B5II01OQ" },
    { name: "Nyl - SP - B0B5ER88KP" },
    { name: "Hair growth gummies" },
    { name: "Hair gummies" },
    { name: "Hair growth for women" },
    { name: "Hair growth vitamins" },
    { name: "Hair supplements for hair growth women" },
    { name: "Biotin chewables" },
  ],
  links: [
    {
      source: "BRAND",
      target: "Brand KW targeting (BRKW)",
      value: 35,
    },
    {
      source: "BRAND",
      target: "Foreign language KW targeting (FLKW)",
      value: 62,
    },
    {
      source: "BRAND",
      target: "Brand product targeting (COPR)",
      value: 32,
    },
    {
      source: "BRAND",
      target: "Category targeting (CAT)",
      value: 12,
    },
    {
      source: "COMPETITOR",
      target: "Competitors KW targeting (COKW)",
      value: 15,
    },
    {
      source: "COMPETITOR",
      target: "Competitors product targeting (COPR)",
      value: 12,
    },
    {
      source: "COMPETITOR",
      target: "Video competitor product targeting (SBV COPR)",
      value: 12,
    },
    {
      source: "COMPETITOR",
      target: "SD competitor product targeting",
      value: 27,
    },
    {
      source: "GENERIC",
      target: "Generic HIGH relevancy KW targeting (GEKW HI)",
      value: 30,
    },
    {
      source: "GENERIC",
      target: "Generic MID relevancy KW targeting (GEKW MID)",
      value: 28,
    },
    {
      source: "GENERIC",
      target: "Generic LOW relevancy KW targeting (GEKW LOW)",
      value: 12,
    },
    {
      source: "GENERIC",
      target: "Video generic KW targeting (SBV GEKW)",
      value: 15,
    },
    {
      source: "Brand KW targeting (BRKW)",
      target: "Nyl - SP - B0B2HN33KQ",
      value: 15,
    },
    {
      source: "Brand KW targeting (BRKW)",
      target: "Nyl - SP - B0B5HN65KO",
      value: 15,
    },
    {
      source: "Foreign language KW targeting (FLKW)",
      target: "Nyl - SP - B0B5IP83AA",
      value: 12,
    },
    {
      source: "Foreign language KW targeting (FLKW)",
      target: "Nyl - SP - B0B5IP83AA",
      value: 12,
    },
    {
      source: "Foreign language KW targeting (FLKW)",
      target: "Nyl - SP - B0B5JK13OP",
      value: 12,
    },
    {
      source: "Foreign language KW targeting (FLKW)",
      target: "Nyl - SP - B0B5II01OQ",
      value: 12,
    },
    {
      source: "Brand product targeting (COPR)",
      target: "Nyl - SP - B0B5HN65QQ",
      value: 12,
    },
    {
      source: "Brand product targeting (COPR)",
      target: "Nyl - SP - B0B2HN33KQ",
      value: 12,
    },
    {
      source: "Category targeting (CAT)",
      target: "Nyl - SP - B0B5HN65KO",
      value: 12,
    },
    {
      source: "Competitors KW targeting (COKW)",
      target: "Nyl - SP - B0B5HN65QQ",
      value: 12,
    },
    {
      source: "Competitors product targeting (COPR)",
      target: "Nyl - SP - B0B5IP83AA",
      value: 12,
    },
    {
      source: "Video competitor product targeting (SBV COPR)",
      target: "Nyl - SP - B0B5IP83AA",
      value: 12,
    },
    {
      source: "SD competitor product targeting",
      target: "Nyl - SP - B0B2HN33KQ",
      value: 12,
    },
    {
      source: "SD competitor product targeting",
      target: "Nyl - SP - B0B2HN33KQ",
      value: 12,
    },
    {
      source: "Generic HIGH relevancy KW targeting (GEKW HI)",
      target: "Nyl - SP - B0B5IP83AA",
      value: 12,
    },
    {
      source: "Generic HIGH relevancy KW targeting (GEKW HI)",
      target: "Nyl - SP - B0B5II01OQ",
      value: 12,
    },
    {
      source: "Generic MID relevancy KW targeting (GEKW MID)",
      target: "Nyl - SP - B0B5JK13OP",
      value: 12,
    },
    {
      source: "Generic MID relevancy KW targeting (GEKW MID)",
      target: "Nyl - SP - B0B5II01OQ",
      value: 12,
    },
    {
      source: "Generic LOW relevancy KW targeting (GEKW LOW)",
      target: "Nyl - SP - B0B5ER88KP",
      value: 12,
    },
    {
      source: "Video generic KW targeting (SBV GEKW)",
      target: "Nyl - SP - B0B5JK13OP",
      value: 12,
    },
    {
      source: "Nyl - SP - B0B5HN65QQ",
      target: "Hair growth gummies",
      value: 18,
    },
    {
      source: "Nyl - SP - B0B5HN65QQ",
      target: "Biotin chewables",
      value: 10,
    },
    { source: "Nyl - SP - B0B2HN33KQ", target: "Hair gummies", value: 8 },
    {
      source: "Nyl - SP - B0B5HN65KO",
      target: "Hair growth for women",
      value: 20,
    },
    {
      source: "Nyl - SP - B0B5IP83AA",
      target: "Hair growth vitamins",
      value: 9,
    },
    {
      source: "Nyl - SP - B0B5IP83AA",
      target: "Hair supplements for hair growth women",
      value: 6,
    },
    { source: "Nyl - SP - B0B5JK13OP", target: "Hair gummies", value: 7 },
    { source: "Nyl - SP - B0B5II01OQ", target: "Biotin chewables", value: 7 },
    { source: "Nyl - SP - B0B5II01OQ", target: "Hair gummies", value: 7 },
    {
      source: "Nyl - SP - B0B5ER88KP",
      target: "Hair growth vitamins",
      value: 11,
    },
  ],
};

export const verticalTargetingData = {
  nodes: [
    { name: "asin = 'B0B5HN65QQ'" },
    { name: "asin = 'B0B2HN33KQ'" },
    { name: "theeth whiting strips" },
    { name: "category = 'Theeth whiting strips'" },
    { name: "Whiting Strip" },
    { name: "asin = 'B0B5II01OP'" },
    { name: "natural whiting strips" },
    { name: "Nyl - SP - B0B5HN65QQ" },
    { name: "Nyl - SP - B0B5HN65KO" },
    { name: "Nyl - SP - B0B2HN33KQ" },
    { name: "Nyl - SP - B0B5JK13OP" },
    { name: "Nyl - SP - B0B5II01OQ" },
    { name: "Nyl - SP - B0B5ER88KP" },
  ],
  links: [
    {
      source: "asin = 'B0B5HN65QQ'",
      target: "Nyl - SP - B0B5HN65QQ",
      value: 15,
    },
    {
      source: "asin = 'B0B2HN33KQ'",
      target: "Nyl - SP - B0B5HN65KO",
      value: 12,
    },
    {
      source: "theeth whiting strips",
      target: "Nyl - SP - B0B5HN65KO",
      value: 8,
    },
    {
      source: "category = 'Theeth whiting strips'",
      target: "Nyl - SP - B0B2HN33KQ",
      value: 10,
    },
    { source: "Whiting Strip", target: "Nyl - SP - B0B2HN33KQ", value: 14 },
    {
      source: "asin = 'B0B5II01OP'",
      target: "Nyl - SP - B0B5JK13OP",
      value: 10,
    },
    {
      source: "natural whiting strips",
      target: "Nyl - SP - B0B5II01OQ",
      value: 8,
    },
    {
      target: "Nyl - SP - B0B5HN65QQ",
      source: "Whiting Strip",
      value: 12,
    },
    {
      target: "Nyl - SP - B0B2HN33KQ",
      source: "asin= “B0B5II01OP”",
      value: 6,
    },
  ],
};
