import { useCallback, useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { debounce } from "lodash";
import { Spin } from "antd";

interface PromptWidgetProps {
  loading: boolean;
  setData: (data: string) => void;
  data: string[];
  setReset?: (reset: boolean) => void;
  onGenerateImage: () => void;
}

const PromptWidget = ({
  loading,
  setData,
  data,
  setReset,
  onGenerateImage,
}: PromptWidgetProps) => {
  const [prompt, setPrompt] = useState("");

  const debouncedSetData = useCallback(
    debounce((value: string) => {
      setData(value);
    }, 500),
    [setData],
  );

  useEffect(() => {
    debouncedSetData(prompt);
  }, [prompt, debouncedSetData]);

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = event.target.value;

    if (value.length <= 150) {
      setPrompt(value);
    }
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setPrompt(event.target.value);
    } else {
      setPrompt("");
    }
  };

  return (
    <div className={styles.formSection}>
      <label>Select Prompt</label>
      {loading ? (
        <div className={styles.promptLoading}>
          <Spin size="large" />
        </div>
      ) : (
        <div className={styles.checkboxCol}>
          {data.map((item, index) => (
            <div key={index} className={styles.checkboxContainer}>
              <input
                type="checkbox"
                id={`checkbox-${index}`}
                value={item}
                checked={prompt === item}
                onChange={handleCheckboxChange}
                className={styles.checkbox}
              />
              <label
                htmlFor={`checkbox-${index}`}
                className={styles.checkboxLabel}
              ></label>
              <span>{item}</span>
            </div>
          ))}
        </div>
      )}
      {loading === false && (
        <>
          <form>
            <div className={styles.formGroup}>
              <label htmlFor="prompt">Custom</label>
              <textarea
                id="prompt"
                name="prompt"
                value={prompt}
                onChange={handleChange}
                placeholder="max 150 words"
              />
            </div>
          </form>
          <div className={styles.controls}>
            <button
              className={styles.resetChangesButton}
              onClick={() => setReset(true)}
            >
              Reset
            </button>
            <button
              className={styles.applyChangesButton}
              onClick={onGenerateImage}
            >
              Generate
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default PromptWidget;
