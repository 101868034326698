import {
  ProductWithImageLink,
  ProductWithPictureUrl,
  ProductDataByAsin,
  ProductMaterials,
  AnalyzeProductListing,
  CROInfoType,
  PhotoInfoType,
  ListingChangeType,
  NewPhotoType,
} from "@services/amazon/products/types";
import { create } from "zustand";

interface ProductsState {
  productsOwn: ProductWithImageLink[];
  productPublic: ProductWithPictureUrl | null;
  productsPublic: ProductWithPictureUrl[];
  productDataByAsin: ProductDataByAsin | null;
  productMaterials: ProductMaterials | null;
  analyzePhoto: string | null;
  analyzePhotoSuggestions: string | null;
  analyzeProductListing: AnalyzeProductListing | null;
  analyzeProductListingSuggestions: string | null;
  analyzeVideo: string | null;
  analyzeVideoSuggestions: string | null;
  CROInfo: CROInfoType | null;
  PhotoInfo: PhotoInfoType | null;
  ListingUpdate: ListingChangeType | null;
  newPhoto: NewPhotoType | null;
  setProductsOwn: (products: ProductWithImageLink[]) => void;
  setProductPublic: (product: ProductWithPictureUrl) => void;
  setProductsPublic: (products: ProductWithPictureUrl[]) => void;
  setProductDataByAsin: (product: ProductDataByAsin | null) => void;
  setProductMaterials: (materials: ProductMaterials) => void;
  setAnalyzePhoto: (msg: string | null) => void;
  setAnalyzePhotoSuggestions: (msg: string | null) => void;
  setAnalyzeProductListing: (msg: AnalyzeProductListing | null) => void;
  setAnalyzeProductListingSuggestions: (msg: string | null) => void;
  setAnalyzeVideo: (msg: string | null) => void;
  setAnalyzeVideoSuggestions: (msg: string | null) => void;
  setCROInfo: (info: CROInfoType | null) => void;
  setPhotoInfo: (info: PhotoInfoType | null) => void;
  setListingUpdate(res: ListingChangeType | null): void;
  setNewPhoto: (res: NewPhotoType | null) => void;
}

export const useProductsStore = create<ProductsState>((set) => ({
  productsOwn: [],
  productPublic: null,
  productsPublic: [],
  productDataByAsin: null,
  productMaterials: { images: [], videos: [], asin: "" },
  analyzePhoto: null,
  analyzePhotoSuggestions: null,
  analyzeProductListing: null,
  analyzeProductListingSuggestions: null,
  analyzeVideo: null,
  analyzeVideoSuggestions: null,
  CROInfo: null,
  PhotoInfo: null,
  ListingUpdate: null,
  newPhoto: null,

  setProductsOwn: (products) => set({ productsOwn: products }),
  setProductPublic: (product) => set({ productPublic: product }),
  setProductsPublic: (products) => set({ productsPublic: products }),
  setProductDataByAsin: (product) => set({ productDataByAsin: product }),
  setProductMaterials: (materials: ProductMaterials) =>
    set({ productMaterials: materials }),
  setAnalyzePhoto: (msg) => set({ analyzePhoto: msg }),
  setAnalyzePhotoSuggestions: (msg) => set({ analyzePhotoSuggestions: msg }),
  setAnalyzeProductListing: (msg) => set({ analyzeProductListing: msg }),
  setAnalyzeProductListingSuggestions: (msg) =>
    set({ analyzeProductListingSuggestions: msg }),
  setAnalyzeVideo: (msg) => set({ analyzeVideo: msg }),
  setAnalyzeVideoSuggestions: (msg) => set({ analyzeVideoSuggestions: msg }),
  setCROInfo: (info) => set({ CROInfo: info }),
  setPhotoInfo: (info) => set({ PhotoInfo: info }),
  setListingUpdate: (res) => set({ ListingUpdate: res }),
  setNewPhoto: (res) => set({ newPhoto: res }),
}));
