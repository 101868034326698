import React, { useState, useMemo } from "react";
import MetricItem from "@components/MetricItem";
import styles from "./styles.module.scss";
import { useDashboardStore } from "@pages/Dashboard/store/dashboard.state.ts";
import MetricsArrow from "@assets/icons/metricsArrow";
import classNames from "classnames";
import CustomPagination from "@components/CustomPagination";
import { WidgetMetricKey } from "@services/math/math.service";

interface MetricsListProps {
  searchQuery: string;
  onToggleMetric: (metric: WidgetMetricKey) => void;
  currentMetrics: WidgetMetricKey[];
  maxSelectedWidgets: number;
}

const ITEMS_PER_PAGE = 3;

const MetricsList: React.FC<MetricsListProps> = ({
  searchQuery,
  onToggleMetric,
  currentMetrics,
  maxSelectedWidgets,
}) => {
  const { availableWidgets } = useDashboardStore((state) => ({
    availableWidgets: state.availableWidgets,
  }));

  const [expandedGroups, setExpandedGroups] = useState<string[]>([]);
  const [page, setPage] = useState<number>(1);

  const onPageChange = (newPage: number) => setPage(newPage);

  const filteredItems = useMemo(() => {
    return availableWidgets.filter((metric) =>
      metric.toLowerCase().includes(searchQuery.toLowerCase()),
    );
  }, [availableWidgets, searchQuery]);

  const isMaxSelected = currentMetrics.length >= maxSelectedWidgets;

  const groups = useMemo(() => {
    const groupDefinitions = [
      {
        name: "Total",
        key: "totalWidgets",
        filter: (widget: WidgetMetricKey) => widget === "salesTotal",
      },
      {
        name: "Ad",
        key: "adWidgets",
        filter: (widget: WidgetMetricKey) =>
          widget.startsWith("ad") ||
          widget.includes("roas") ||
          widget.includes("cpc") ||
          widget.includes("acos"),
      },
      {
        name: "Organic",
        key: "organicWidgets",
        filter: (widget: WidgetMetricKey) => widget.startsWith("Organic"),
      },
      {
        name: "Other",
        key: "otherWidgets",
        filter: (widget: WidgetMetricKey) =>
          !widget.startsWith("Ad") &&
          !widget.startsWith("Organic") &&
          widget !== "salesTotal" &&
          widget.includes("roas") &&
          widget.includes("cpc") &&
          widget.includes("acos"),
      },
    ];

    return groupDefinitions.map((groupDef) => ({
      name: groupDef.name,
      key: groupDef.key,
      widgets: filteredItems.filter(groupDef.filter),
    }));
  }, [filteredItems]);

  const currentGroups = useMemo(() => {
    const startIndex = (page - 1) * ITEMS_PER_PAGE;
    return groups.slice(startIndex, startIndex + ITEMS_PER_PAGE);
  }, [groups, page]);

  const totalPages = Math.ceil(groups.length / ITEMS_PER_PAGE);

  const toggleGroupVisibility = (groupKey: string) => {
    setExpandedGroups((prevExpandedGroups) =>
      prevExpandedGroups.includes(groupKey)
        ? prevExpandedGroups.filter((key) => key !== groupKey)
        : [...prevExpandedGroups, groupKey],
    );
  };

  return (
    <div className={styles.metricsContent}>
      <h5>All metrics</h5>
      <div className={styles.metricList}>
        {currentGroups.map((group) => {
          const isExpanded = expandedGroups.includes(group.key);
          return (
            <div className={styles.metricGroup} key={group.key}>
              <button
                onClick={() => toggleGroupVisibility(group.key)}
                className={classNames(styles.metricButton, {
                  [styles.rotated]: isExpanded,
                })}
              >
                {group.name}
                <MetricsArrow />
              </button>

              {isExpanded && (
                <div className={styles.groupList}>
                  {group.widgets.map((metric) => {
                    const isChecked = currentMetrics.some(
                      (item) => item === metric,
                    );
                    return (
                      <div
                        key={metric}
                        className={classNames(styles.metricItem, {
                          [styles.dimmed]: isMaxSelected && !isChecked,
                        })}
                      >
                        <MetricItem
                          metric={metric}
                          checked={isChecked}
                          onChange={() => onToggleMetric(metric)}
                          disabled={isMaxSelected && !isChecked}
                        />
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          );
        })}
      </div>
      {totalPages > 1 && (
        <CustomPagination
          current={page}
          onChange={onPageChange}
          total={totalPages}
          pageSize={1}
        />
      )}
    </div>
  );
};

export default MetricsList;
