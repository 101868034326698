import React from "react";
import styles from "./styles.module.scss";
import { Spin } from "antd";
import classNames from "classnames";
import CroBackArrow from "@assets/icons/croBackArrow";

interface ImageSectionProps {
  loading: boolean;
  imageUrl: string;
  generatedImage: string;
  reset: boolean;
  masks: string[];
  changeMask: (index: number) => void;
  activeMask: number;
}

const ImageSection: React.FC<ImageSectionProps> = ({
  loading,
  imageUrl,
  generatedImage,
  reset,
  masks,
  changeMask,
  activeMask,
}) => {
  const hasMasks = masks.length;

  return (
    <div
      className={classNames(
        styles.imageSection,
        loading && styles.imageSectionLoading,
      )}
    >
      {loading && <Spin size="large" className={styles.imageLoading} />}
      {hasMasks && loading === false && reset === false ? (
        <>
          <div className={styles.label}>Select Mask</div>
          <div className={styles.carousel}>
            <button
              onClick={() => changeMask(activeMask - 1)}
              className={classNames(
                styles.carouselButton,
                styles.carouselButtonPrev,
              )}
            >
              <svg width="12" height="19" viewBox="0 0 12 19" fill="none">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M8.95452 0.430785C9.50361 -0.0908226 10.3651 -0.0908225 10.9142 0.430785C11.5046 0.991679 11.5046 1.93282 10.9142 2.49371L5.06515 8.04996C4.23509 8.83847 4.23509 10.1615 5.06515 10.95L10.9142 16.5063C11.5046 17.0672 11.5046 18.0083 10.9142 18.5692C10.3651 19.0908 9.50361 19.0908 8.95452 18.5692L0.933857 10.95C0.103798 10.1615 0.103797 8.83847 0.933856 8.04996L8.95452 0.430785Z"
                  fill="white"
                />
              </svg>
            </button>
            <button
              onClick={() => changeMask(activeMask + 1)}
              className={classNames(
                styles.carouselButton,
                styles.carouselButtonNext,
              )}
            >
              <svg width="12" height="19" viewBox="0 0 12 19" fill="none">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M8.95452 0.430785C9.50361 -0.0908226 10.3651 -0.0908225 10.9142 0.430785C11.5046 0.991679 11.5046 1.93282 10.9142 2.49371L5.06515 8.04996C4.23509 8.83847 4.23509 10.1615 5.06515 10.95L10.9142 16.5063C11.5046 17.0672 11.5046 18.0083 10.9142 18.5692C10.3651 19.0908 9.50361 19.0908 8.95452 18.5692L0.933857 10.95C0.103798 10.1615 0.103797 8.83847 0.933856 8.04996L8.95452 0.430785Z"
                  fill="white"
                />
              </svg>
            </button>
            <div className={styles.carouselInner}>
              <div
                className={styles.carouselItems}
                style={{ transform: `translate(-${100 * activeMask}%)` }}
              >
                {masks.map((el, i) => {
                  return (
                    <div key={i} className={styles.carouselItem}>
                      <img
                        src={"data:image/png;base64," + el}
                        className={styles.carouselImage}
                        alt=""
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          {reset ? (
            <img src={imageUrl} alt="Product" className={styles.productImage} />
          ) : (
            (imageUrl || generatedImage) && (
              <img
                src={
                  generatedImage
                    ? "data:image/png;base64," + generatedImage
                    : imageUrl
                }
                alt="Product"
                className={styles.productImage}
              />
            )
          )}
        </>
      )}
    </div>
  );
};

export default ImageSection;
