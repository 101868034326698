import { Table } from "antd";
import "./SubscriptionHeatmap.scss";
import { subscriptionData } from "./mock";

const getCellClass = (value) => {
  if (value === null || value === undefined) return "emptyCell";
  if (value >= 30000) return "veryHigh";
  if (value >= 10000) return "high";
  if (value >= 5000) return "medium";
  if (value > 0) return "low";
  return "veryLow";
};

const columns = [
  { title: "First subscription at", dataIndex: "month", key: "month" },
  ...Array.from({ length: 8 }, (_, index) => ({
    title: (index + 1).toString(),
    dataIndex: (index + 1).toString(),
    key: (index + 1).toString(),
    onCell: (record) => ({
      className: getCellClass(record[(index + 1).toString()]),
    }),
    render: (value) => (value ? `$${value.toLocaleString()}` : ""),
  })),
  {
    title: "Total",
    dataIndex: "total",
    key: "total",
    onCell: () => ({
      className: "totalCell",
    }),
    render: (value) => `$${value.toLocaleString()}`,
  },
];

const SubscriptionHeatmap = () => {
  return (
    <div className="tableContainer">
      <Table
        className="custom-table"
        columns={columns}
        dataSource={subscriptionData}
        pagination={false}
      />
    </div>
  );
};

export default SubscriptionHeatmap;
