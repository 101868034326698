import styles from "./styles.module.scss";

export const data = [
  { month: "January", "1 month": 1200, "3 month": 800, Other: 400 },
  { month: "February", "1 month": 1500, "3 month": 1200, Other: 600 },
  { month: "March", "1 month": 2000, "3 month": 1800, Other: 800 },
  { month: "April", "1 month": 1800, "3 month": 1600, Other: 700 },
  { month: "May", "1 month": 1300, "3 month": 1100, Other: 500 },
  { month: "June", "1 month": 1600, "3 month": 1300, Other: 600 },
  { month: "July", "1 month": 1400, "3 month": 1100, Other: 500 },
  { month: "August", "1 month": 900, "3 month": 700, Other: 300 },
];

export const COLORS = ["#1BB08D", "#50DAB9", "#A6FAE6"];

export const formatYAxis = (value) => `${value / 1000}K`;

export const CustomCursor = ({ x, width }: any) => {
  const centerX = x + width / 2;
  return (
    <line
      x1={centerX}
      x2={centerX}
      y1={0}
      y2="100%"
      stroke="white"
      strokeDasharray="4 4"
      strokeWidth={1}
      pointerEvents="none"
    />
  );
};
export const renderCustomizedBar = (props: any, activeLabel: string | null) => {
  const { x, y, width, height, fill, payload } = props;
  const isActive = payload.month === activeLabel;
  const fillOpacity = activeLabel === null ? 1 : isActive ? 1 : 0.3;
  return (
    <rect
      x={x}
      y={y}
      width={width}
      height={height}
      fill={fill}
      fillOpacity={fillOpacity}
    />
  );
};
