import React, { useState } from "react";
import { Card } from "antd";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  ResponsiveContainer,
  CartesianGrid,
  Tooltip,
} from "recharts";
import styles from "./styles.module.scss";
import {
  COLORS,
  CustomCursor,
  data,
  formatYAxis,
  renderCustomizedBar,
} from "./constant";
import CustomTooltip from "../Tooltip";

const SubscriptionReport = () => {
  const [activeLabel, setActiveLabel] = useState<string | null>(null);

  return (
    <Card
      title="Subscription Reporting"
      style={{
        backgroundColor: "#141414",
        borderRadius: "24px",
        border: "1px solid #2E2E2E",
        width: "100%",
        padding: "16px",
        height: "464px",
        color: "white",
      }}
      headStyle={{
        fontWeight: "bold",
        fontSize: "16px",
        color: "white",
        borderBottom: "none",
      }}
      bodyStyle={{
        padding: "5px",
      }}
    >
      <div className={styles.legendContainer}>
        <div className={styles.legendItem}>
          <span
            className={styles.colorDot}
            style={{ backgroundColor: COLORS[0] }}
          ></span>
          <span className={styles.legendText}>Subscription Count 1 month</span>
        </div>
        <div className={styles.legendItem}>
          <span
            className={styles.colorDot}
            style={{ backgroundColor: COLORS[1] }}
          ></span>
          <span className={styles.legendText}>Subscription Count 3 month</span>
        </div>
        <div className={styles.legendItem}>
          <span
            className={styles.colorDot}
            style={{ backgroundColor: COLORS[2] }}
          ></span>
          <span className={styles.legendText}>Subscription Count Other</span>
        </div>
      </div>

      <ResponsiveContainer width="100%" height={350}>
        <BarChart
          data={data}
          margin={{ top: 20, right: 30, left: 0, bottom: 5 }}
          barCategoryGap={0}
          barSize={64}
          onMouseMove={(state) => {
            if (state.isTooltipActive) {
              setActiveLabel(state.activeLabel);
            } else {
              setActiveLabel(null);
            }
          }}
          onMouseLeave={() => setActiveLabel(null)}
        >
          <CartesianGrid stroke="#1F1F20" vertical={false} />
          <XAxis
            dataKey="month"
            tick={{ fill: "white" }}
            axisLine={{ stroke: "#1F1F20" }}
          />
          <YAxis
            tick={{ fill: "white" }}
            axisLine={{ stroke: "#1F1F20" }}
            tickFormatter={formatYAxis}
            ticks={[0, 2000, 4000, 6000]}
          />

          <Bar
            dataKey="1 month"
            stackId="a"
            fill={COLORS[0]}
            name="Subscription Count 1 month"
            shape={(props) => renderCustomizedBar(props, activeLabel)}
          />
          <Bar
            dataKey="3 month"
            stackId="a"
            fill={COLORS[1]}
            name="Subscription Count 3 month"
            shape={(props) => renderCustomizedBar(props, activeLabel)}
          />
          <Bar
            dataKey="Other"
            stackId="a"
            fill={COLORS[2]}
            name="Subscription Count Other"
            shape={(props) => renderCustomizedBar(props, activeLabel)}
          />
          <Tooltip
            content={<CustomTooltip />}
            cursor={<CustomCursor height={350} />}
          />
        </BarChart>
      </ResponsiveContainer>
    </Card>
  );
};

export default SubscriptionReport;
