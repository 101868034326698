import { useState } from "react";
import styles from "./styles.module.scss";
import OptimisationArrow from "@assets/icons/optimisationArrow";
import classNames from "classnames";

interface OptimisationItemProps {
  title: string | React.ReactNode;
  children: React.ReactNode;
  defaultOpen?: boolean;
  disableHide?: boolean;
}

export const OptimisationItem = ({
  title,
  children,
  defaultOpen = false,
  disableHide = false,
}: OptimisationItemProps) => {
  const [isOpen, setIsOpen] = useState(defaultOpen);

  const toggleOpen = () => {
    if (disableHide) return;
    setIsOpen(!isOpen);
  };

  return (
    <div className={styles.optimisationContainer}>
      <button onClick={toggleOpen} className={styles.header}>
        <span className={classNames(styles.title, disableHide && styles.big)}>
          {title}
        </span>
        {!disableHide && (
          <div className={`${styles.arrow} ${isOpen ? styles.rotated : ""}`}>
            <OptimisationArrow />
          </div>
        )}
      </button>
      {isOpen && <div className={styles.content}>{children}</div>}
    </div>
  );
};
