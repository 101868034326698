export const calculateLabelPositions = (
  pieData,
  radius,
  offset1,
  offsetHorizontal,
) => {
  return pieData.map((slice, index) => {
    let midAngle = (slice.startAngle + slice.endAngle) / 2;
    midAngle = (midAngle + 360) % 360;

    const startX = 185 + radius * Math.cos((midAngle * Math.PI) / 180);
    const startY = 115 - radius * Math.sin((midAngle * Math.PI) / 180);

    const midX = startX + offset1 * Math.cos((midAngle * Math.PI) / 180);
    const midY = startY - offset1 * Math.sin((midAngle * Math.PI) / 180);

    let endX, endY, textX, textY, textAnchor;

    if (midAngle >= 70 && midAngle <= 110) {
      const verticalOffset = 20;
      endX = midX;
      endY = midY + verticalOffset;

      textX = endX;
      textY = endY + 15;
      textAnchor = "middle";
    } else if (midAngle >= 0 && midAngle < 180) {
      endX = midX + offsetHorizontal;
      endY = midY;

      textX = endX + 5;
      textY = endY + 5;
      textAnchor = "start";
    } else {
      endX = midX - offsetHorizontal;
      endY = midY;

      textX = endX - 5;
      textY = endY + 5;
      textAnchor = "end";
    }

    return {
      startX,
      startY,
      midX,
      midY,
      endX,
      endY,
      textX,
      textY,
      value: slice.value,
      textAnchor,
    };
  });
};
