import React, { useState, useMemo } from "react";
import styles from "./styles.module.scss";
import AuthButtonArrow from "@assets/icons/authButtonArrow";
import { ROUTES } from "src/router/routes";
import { useNavigate } from "react-router-dom";
import { renderAnalyzeData } from "src/utils/renderAnalyzeData";
import { updatedMaterials } from "src/utils/updateProductMaterials";
import { useProductsStore } from "src/store/product.state";
import { useProductStore } from "src/store/overviewProduct.state";
import { OptimizationService } from "@services/optimization/optimization.services";

interface RecommendationSectionProps {
  analyzeData?: any;
  recommendationData?: any;
  onToggleRecommendation?: () => void;
  id?: string;
  newPhoto?: string | null;
  setFetchPhoto?: (bool: boolean) => void;
}

const RecommendationSection: React.FC<RecommendationSectionProps> = ({
  analyzeData,
  recommendationData,
  onToggleRecommendation,
  id,
  setFetchPhoto,
  newPhoto,
}) => {
  const [showRecommendations, setShowRecommendations] = useState(false);
  const {
    productDataByAsin,
    setProductDataByAsin,
    productMaterials,
    setProductMaterials,
  } = useProductsStore();
  const { selectedProduct } = useProductStore();
  const optimizationService = new OptimizationService();

  const handleToggleRecommendation = () => {
    setShowRecommendations(!showRecommendations);
    if (!showRecommendations) {
      onToggleRecommendation();
      setFetchPhoto(true);
    }
  };

  const nav = useNavigate();
  const handleUpdate = async () => {
    if (!selectedProduct?.asin) return;

    try {
      const res = await optimizationService.getListingsPotentialChange(
        selectedProduct.asin,
      );
      const updatedData = {
        ...productDataByAsin,
        item_name: res.title,
        bullet_points: res.bullet_points,
      };

      setProductDataByAsin(updatedData);
      setProductMaterials(
        updatedMaterials(productMaterials, id, newPhoto?.image_link),
      );
      if (id === "MAIN" && newPhoto?.image_link) {
        const updatedProductData = {
          ...updatedData,
          image_link: newPhoto?.image_link,
        };

        setProductDataByAsin(updatedProductData);
      }
    } catch (error) {
      console.error("Failed to fetch listing changes:", error);
    } finally {
      nav(`/${ROUTES.CRO}`, { state: { isUpdated: true } });
    }
  };

  const analysis = useMemo(() => {
    if (analyzeData) {
      return typeof analyzeData.recommendations === "string"
        ? analyzeData.recommendations
        : renderAnalyzeData(analyzeData, id);
    }
    return null;
  }, [analyzeData, id]);

  const suggestions = useMemo(() => {
    if (recommendationData) {
      return typeof analyzeData.recommendations === "string"
        ? recommendationData.recommendations
        : renderAnalyzeData(recommendationData, id);
    }
    return null;
  }, [recommendationData, id]);

  return (
    <div className={styles.options}>
      <div className={styles.block}>
        <h2>Analysis</h2>
        {analyzeData ? (
          <p>{analysis ? analysis : "No analysis available."}</p>
        ) : (
          <p>Loading...</p>
        )}
        <div className={styles.row}>
          {analyzeData && (
            <button
              className={styles.button}
              onClick={handleToggleRecommendation}
            >
              {showRecommendations ? "Hide" : "See Recommendation"}
            </button>
          )}
        </div>
      </div>
      {showRecommendations && (
        <>
          <div className={styles.block}>
            <h2>Recommendations</h2>
            <div className={styles.loadingContent}>
              {recommendationData ? (
                recommendationData.recommendations ? (
                  <ul className={styles.recomendation}>
                    {suggestions.map((recommendation, index) => (
                      <li key={index}>
                        <strong>Issue:</strong> {recommendation.issue}
                        <br />
                        <strong>Suggestion:</strong> {recommendation.suggestion}
                      </li>
                    ))}
                  </ul>
                ) : (
                  <p>No recommendations available.</p>
                )
              ) : (
                <p>Loading...</p>
              )}
            </div>
          </div>
          {recommendationData && (
            <div className={styles.updateControls}>
              {location.pathname.includes(
                `/${ROUTES.CRO_PHOTOOPTIMISATION}`,
              ) && <button className={styles.applyButton}>regenerate</button>}
              <button className={styles.applyButton} onClick={handleUpdate}>
                Update <AuthButtonArrow />
              </button>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default RecommendationSection;
