import React from "react";
import styles from "./styles.module.scss";
import { ReviewChart } from "./ReviewScore";
import { useProductStore } from "src/store/overviewProduct.state";
import product1 from "@assets/img/noImage.jpg";
import { mockData } from "@pages/Compare/components/Products/mock";
import Star from "@assets/img/stars";
import { calculateRatingPercentages } from "./ReviewScore/ratingUtils";
import { calculateStars } from "./ReviewScore/ratingUtils";

interface ReviewScoreProps {
  score: number;
  stats?: {
    total: number;
    five_stars: number;
    four_stars: number;
    three_stars: number;
    two_stars: number;
    one_star: number;
  };
}

const ReviewScore: React.FC<ReviewScoreProps> = ({ score, stats }) => {
  const { selectedProduct } = useProductStore();

  const reviews = parseFloat((score || mockData[0]?.rating || 0).toFixed(1));
  const stars = calculateStars(reviews);

  const ratingPercentages = calculateRatingPercentages(stats);

  return (
    <div className={styles.reviewScoreContainer}>
      <div className={styles.productBox}>
        <div className={styles.imageContainer}>
          <img
            src={
              selectedProduct?.image_link ||
              selectedProduct?.picture_url ||
              product1
            }
            alt="choose your product"
            className={styles.productImage}
          />
        </div>
        <div className={styles.ratingContainer}>
          <div className={styles.ratingWrapper}>
            <div className={styles.startBox}>
              {stars.map((fill, index) => (
                <Star key={index} fill={fill} />
              ))}
              <div className={styles.ratingValue}>{reviews}</div>
            </div>
            <div className={styles.ratingBreakdown}>
              {ratingPercentages.length > 0 ? (
                ratingPercentages.map((rating, index) => (
                  <div key={index} className={styles.ratingRow}>
                    <div className={styles.star}>{rating.star}</div>
                    <div className={styles.progressBar}>
                      <div
                        className={styles.progressFill}
                        style={{ width: rating.width }}
                      ></div>
                      <span>{rating.percentage}%</span>
                    </div>
                  </div>
                ))
              ) : (
                <p>Loading ...</p>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className={styles.chartContainer}>
        <div className={styles.chartHeader}>
          <h3>Review score</h3>
        </div>
        <div>
          <ReviewChart />
        </div>
      </div>
    </div>
  );
};

export default ReviewScore;
