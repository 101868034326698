import React from "react";
import CustomCheckbox from "@components/CustomCheckbox";
import styles from "./styles.module.scss";
import InfoIcon from "@assets/icons/i.svg";
import { WidgetClass } from "@services/widget.service";

interface MetricItemProps {
  metric: string;
  checked: boolean;
  onChange: () => void;
  disabled?: boolean;
}

const MetricItem: React.FC<MetricItemProps> = ({
  metric,
  checked,
  onChange,
  disabled,
}) => {
  if (!metric) {
    return null;
  }

  const cleanedMetricName = metric
    .replace(/^ads\s+/i, "")
    .replace(/^organic\s+/i, "");

  return (
    <button
      className={`${styles.metricItem} ${disabled ? styles.disabled : ""}`}
      onClick={onChange}
    >
      <CustomCheckbox
        checked={checked}
        onChange={(e) => {
          e.stopPropagation();
        }}
      />
      <p className={styles.metricTitle}>
        {WidgetClass.convertKeyToName(cleanedMetricName)}
        <img
          src={InfoIcon}
          alt="Info"
          className={styles.infoIcon}
          width={14}
          height={14}
        />
      </p>
    </button>
  );
};

export default MetricItem;
