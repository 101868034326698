import InfoIcon from "@assets/icons/i.svg";

export const mockCategories = [
  { id: 1, name: "Total metrics" },
  { id: 2, name: "Ad metrics" },
  { id: 3, name: "Organic metrics" },
  { id: 4, name: "Other metrics" },
];
export const defaultMetrics = [
  { name: "Total sales", key: "sessionTotal" },

  { name: "Total impressions", key: "unitsOrdered" },
  { name: "Total Clicks", key: "unitsSessionPercentage" },
  { name: "Total CTR", key: "featuredOffer" },
  { name: "Total Units Sold", key: "pageViewsTotal" },
  { name: "Total CVR", key: "pageViewsTotalCVR" },
  {
    name: "PSL",
    key: "orderedProductSales",
    icon: InfoIcon,
    showButton: true,
    tooltip: "Potential Sales Lift",
  },
  {
    name: "POS",
    key: "posValue",
    icon: InfoIcon,
    showButton: true,
    tooltip: "Product Optimization Score",
  },
];

export const ITEMS_PER_PAGE = 5;

export const MOCK_CATEGORIES = [
  { id: 1, name: "Total metrics" },
  { id: 2, name: "Ad metrics" },
  { id: 3, name: "Organic metrics" },
  { id: 4, name: "Other metrics" },
];
export const categoryMetricsMap = {
  1: ["Total Sales"],
  2: [
    "Ad Impressions",
    "Ad Clicks",
    "Ad Orders",
    "Ad Units Sold",
    "Ad Sales",
    "Ad Spend",
    "ACOS",
    "ROAS",
    "CPC",
    "Ad CTR",
    "Ad CVR",
  ],
  3: [
    "Organic Impressions",
    "Organic Clicks",
    "Organic Cart Adds",
    "Organic Units Sold",
    "Organic CVR",
    "Organic CTR",
  ],
  4: ["Aov"],
};
