import React from "react";
import styles from "./styles.module.scss";
import Video from "@assets/video/templates/default.jobtemplate.mp4.480.mp4";
import noVideo from "@assets/img/no-video.webp";

interface Video {
  position: number;
  media_link: string;
}

interface VideosSectionProps {
  videos: Video[];
}

const VideoSection: React.FC<VideosSectionProps> = ({ videos }) => {
  const videoSource = videos?.length > 0 ? videos[0].media_link : Video;

  return (
    <div className={styles.videoSlider}>
      {videos && videos.length > 0 ? (
        <div className={styles.mainVideo}>
          <video controls className={styles.video} width="640" height="300">
            <source src={videoSource} type="video/mp4" />
          </video>
        </div>
      ) : (
        <img src={noVideo} alt="no video" className={styles.noVideo} />
      )}
      {videos && videos.length > 1 && (
        <div className={styles.sideVideos}>
          {videos.slice(1).map((video, index) => (
            <div className={styles.sideVideo} key={index}>
              <video controls className={styles.video} width="242" height="119">
                <source src={video.media_link} type="video/mp4" />
              </video>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default VideoSection;
