import { Api } from "src/utils/api";
import { CANVAS_API_BASE_URL } from "./canvas.constant";

export interface IPerformanceTableData {
  performance: string;
  sales: number;
  spend: number;
  acos: number;
  cpc: number;
}

export class CanvasApi extends Api {
  constructor() {
    super(CANVAS_API_BASE_URL);
  }

  public getPerformance = async (
    date_start: string,
    date_end: string,
  ): Promise<IPerformanceTableData[]> => {
    return await this.get("", {
      date_start,
      date_end,
    });
  };
}
