import { useEffect, useMemo, useState } from "react";
import { Checkbox } from "antd";
import { TabsTypeList } from "@pages/AdsAnalitics/components/SanKey/utils";
import { TypeTab } from "@pages/AdsAnalitics/components/SanKey/Chart";
import { Input } from "@components/Input";
import { Select, Option } from "@components/Select";
import { ExpandableTable } from "@components/ExpandebleTable";
import Search from "@assets/icons/search";
import StarKeyword from "@assets/icons/StarKeyword";
import { getStarColor } from "@components/ExpandebleTable/KeyTooltip";
import { useKeywordStore } from "src/store/keyword.state";
import { useDashboardStore } from "@pages/Dashboard/store/dashboard.state";
import { ProductsService } from "@services/amazon/products/products.service";
import { ProductWithImageLink } from "@services/amazon/products/types";
import { CanvasApi, IPerformanceTableData } from "@services/canvas/canvas.api";

import styles from "./styles.module.scss";

import {
  adTypeColumns,
  adtypeData,
  formatData,
  funnelColumns,
  funnelData,
  productsColumns,
  productsData,
  targetData,
  targetingColumns,
  targetingData,
  targetTypeColumns,
} from "../../mock";
import { campaignsColumns, campaignsData } from "../WhatIfTable/mock";
import { performanceColumns } from "./columns";

/**
 * Helper: Returns data according to the selected tab.
 */
const getTabData = (
  tab: TypeTab,
  performanceData?: IPerformanceTableData[],
) => {
  switch (tab) {
    case "Campaigns":
      return campaignsData;
    case "Ad Type":
      return adtypeData;
    case "Funnel":
      return funnelData;
    case "Target Type":
      return targetData;
    case "Targeting":
      return targetingData;
    case "Performance":
      return performanceData;
    default:
      return productsData;
  }
};

/**
 * Helper: Returns columns according to the selected tab.
 */
const getColumns = (tab: TypeTab) => {
  switch (tab) {
    case "Campaigns":
      return campaignsColumns;
    case "Ad Type":
      return adTypeColumns;
    case "Funnel":
      return funnelColumns;
    case "Target Type":
      return targetTypeColumns;
    case "Targeting":
      return targetingColumns;
    case "Performance":
      return performanceColumns;
    default:
      return productsColumns;
  }
};

/**
 * Helper: Formats keyword options for the Select component.
 */
const formattedKeys = (keys: string[]) => {
  return keys.map((key, index) => (
    <div className={styles.keys__container} key={key}>
      <Checkbox />
      <div className={styles.star}>
        <StarKeyword fill={getStarColor(index)} />
      </div>
      <span>{key}</span>
    </div>
  ));
};

interface CampaignsTableProps {
  hideAsin?: boolean;
}

export const CampaignsTable = ({ hideAsin }: CampaignsTableProps) => {
  const { keyword, setKeywords } = useKeywordStore();
  const { dateRange } = useDashboardStore();
  const canvasApi = useMemo(() => new CanvasApi(), []);

  // State for performance data and table display data
  const [performanceData, setPerformanceData] =
    useState<IPerformanceTableData[]>();
  const [data, setData] = useState<any[]>([]);

  // Tooltip titles
  const [tooltipTabsTitle, setTooltipTabsTitle] = useState("");
  const [tooltipKeysTitle, setTooltipKeysTitle] = useState("");

  // Selections and filters
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [selectedTab, setSelectedTab] = useState<TypeTab>("Campaigns");
  const [search, setSearch] = useState("");
  const [filter, setFilter] = useState("");
  const [choosedKeyword, setChoosedKeyword] = useState("");

  // ASIN management
  const [asin, setAsin] = useState<string>();
  const [asinOptions, setAsinOptions] = useState<Option[]>();

  /**
   * Fetches products for the ASIN dropdown.
   */
  useEffect(() => {
    const fetchAsinOptions = async () => {
      try {
        const productsService = new ProductsService();
        const productData = await productsService.getOwnProducts();
        const options = productData.map((product: ProductWithImageLink) => ({
          id: product.asin,
          text: product.item_name,
          img: product.image_link,
          asin: product.asin,
        }));
        setAsinOptions(options);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };
    fetchAsinOptions();
  }, [asin]);

  /**
   * Fetches performance data when the selected tab is "Performance".
   */
  useEffect(() => {
    const fetchPerformanceData = async () => {
      if (selectedTab !== "Performance") return;
      try {
        const { startDate, endDate } = dateRange;
        const formatDate = (date: Date) => date.toISOString().split("T")[0];
        const fetchedData = await canvasApi.getPerformance(
          formatDate(startDate),
          formatDate(endDate),
        );
        setPerformanceData(fetchedData);
      } catch (error) {
        console.error("Error fetching performance data:", error);
      }
    };
    fetchPerformanceData();
  }, [selectedTab, dateRange, canvasApi]);

  /**
   * Updates table data whenever:
   * - The selected tab changes
   * - Performance data updates
   * - Tooltip titles update
   * - Keywords update
   */
  useEffect(() => {
    const tabData = getTabData(selectedTab, performanceData);
    const formatted = formatData({
      data: tabData,
      openTooltip: setTooltipTabsTitle,
      openedTooltip: tooltipTabsTitle,
      keyTooltip: tooltipKeysTitle,
      setKeyTooltip: setTooltipKeysTitle,
      keyword,
      setKeywords,
      withKeywords: selectedTab === "Campaigns",
    });
    setData(formatted);
  }, [
    selectedTab,
    performanceData,
    tooltipTabsTitle,
    tooltipKeysTitle,
    keyword,
    setKeywords,
  ]);

  /**
   * Handles row selection changes.
   */
  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  return (
    <>
      {!hideAsin && (
        <div className={styles.inputBox}>
          <h2 className={styles.asinText}>ASIN</h2>
          <Select
            value={asin}
            placeholder="Select asin"
            options={asinOptions}
            onChange={(value: string) => setAsin(value)}
            className={styles.asin}
          />
        </div>
      )}

      <TabsTypeList
        isFunnelChart
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
      />

      <div className={styles.filters}>
        <Input
          value={search}
          placeholder="Search a campaign..."
          onChange={setSearch}
          className={styles.input}
          icon={<Search />}
        />
        <Select
          value={filter}
          options={["All", "Active", "Inactive"]}
          onChange={(value: string) => setFilter(value)}
          placeholder="Filters"
        />
        {selectedTab === "Campaigns" && (
          <Select
            value={choosedKeyword}
            options={formattedKeys(keyword.keywords)}
            onChange={(value: string) => setChoosedKeyword(value)}
            className={styles.select}
            placeholder={
              <span className={styles.placeholder}>
                <StarKeyword fill="#5C5C5A" />
                Custom Keywords
              </span>
            }
          />
        )}

        <button className={styles.export__button}>EXPORT</button>
      </div>

      <div className={styles.table}>
        <ExpandableTable
          columns={getColumns(selectedTab)}
          data={data}
          rowSelection={rowSelection}
          withCustomScroll
        />
      </div>
    </>
  );
};
