export const columns = [
  {
    title: "Type",
    dataIndex: "type",
    key: "type",
    fixed: "left" as const,
    width: 264,
  },
  {
    title: "Search Volume SQ",
    dataIndex: "searchVolume",
    key: "searchVolume",
    width: 128,
  },
  {
    title: "Search Query",
    dataIndex: "searchQuery",
    key: "searchQuery",
    width: 220,
  },
  {
    title: "Total Organic Impressions",
    dataIndex: "organicImpressions",
    key: "organicImpressions",
    width: 128,
  },
  {
    title: "Total Organic Clicks",
    dataIndex: "organicClicks",
    key: "organicClicks",
    width: 128,
  },
  {
    title: "Total Organic Add to Cart",
    dataIndex: "addToCart",
    key: "addToCart",
    width: 128,
  },
  {
    title: "Total Organic Orders",
    dataIndex: "organicOrders",
    key: "organicUnitsSold",
    width: 128,
  },
  {
    title: "Total Organic Sales",
    dataIndex: "organicSales",
    key: "organicSales",
    width: 128,
  },
  {
    title: "Brand Organic Sales",
    dataIndex: "brandOrganicSales",
    key: "brandOrganicSales",
    width: 128,
  },
  {
    title: "Brand Organic Sales What If",
    dataIndex: "brandOrganicSalesWhatIf",
    key: "brandOrganicSalesWhatIf",
    width: 128,
  },
  {
    title: "Brand Ad Sales",
    dataIndex: "brandAdSales",
    key: "brandAdSales",
    width: 128,
  },
  {
    title: "Lost Sales Missing Target",
    dataIndex: "lostSalesMissingTarget",
    key: "lostSalesMissingTarget",
    width: 128,
  },
  {
    title: "Organic rank",
    dataIndex: "organicRank",
    key: "organicRank",
    width: 128,
  },
  {
    title: "Relevance score",
    dataIndex: "relevanceScore",
    key: "relevanceScore",
    width: 128,
  },
];

export const data = [
  {
    key: 1,
    type: "Current target",
    searchVolume: "443880",
    searchQuery: "router",
    organicImpressions: "8755227",
    organicClicks: "123358",
    addToCart: "24737",
    organicOrders: "10455",
    organicSales: "$752504",
    brandOrganicSales: "",
    brandOrganicSalesWhatIf: "",
    brandAdSales: "$112",
    lostSalesMissingTarget: "",
    organicRank: "",
    relevanceScore: "16%",
  },
  {
    key: 2,
    type: "Current target",
    searchVolume: "397375",
    searchQuery: "starlink",
    organicImpressions: "7838891",
    organicClicks: "103780",
    addToCart: "12396",
    organicOrders: "1902",
    organicSales: "$315305",
    brandOrganicSales: "$120",
    brandOrganicSalesWhatIf: "$170",
    brandAdSales: "$159",
    lostSalesMissingTarget: "",
    organicRank: "",
    relevanceScore: "16%",
  },
  {
    key: 3,
    type: "Current target",
    searchVolume: "358343",
    searchQuery: "wifi router",
    organicImpressions: "7055502",
    organicClicks: "128755",
    addToCart: "25704",
    organicOrders: "10010",
    organicSales: "$613847",
    brandOrganicSales: "",
    brandOrganicSalesWhatIf: "",
    brandAdSales: "$440",
    lostSalesMissingTarget: "",
    organicRank: "",
    relevanceScore: "16%",
  },
  {
    key: 4,
    type: "Current target",
    searchVolume: "159427",
    searchQuery: "routers for wireless internet",
    organicImpressions: "3142026",
    organicClicks: "55405",
    addToCart: "11994",
    organicOrders: "4732",
    organicSales: "$351324",
    brandOrganicSales: "",
    brandOrganicSalesWhatIf: "",
    brandAdSales: "$0",
    lostSalesMissingTarget: "",
    organicRank: "",
    relevanceScore: "16%",
  },
  {
    key: 5,
    type: "Current target",
    searchVolume: "59418",
    searchQuery: "wifi",
    organicImpressions: "1160025",
    organicClicks: "15114",
    addToCart: "2237",
    organicOrders: "526",
    organicSales: "$23183",
    brandOrganicSales: "$832",
    brandOrganicSalesWhatIf: "$1177",
    brandAdSales: "$160",
    lostSalesMissingTarget: "",
    organicRank: "",
    relevanceScore: "60%",
  },
  {
    key: 6,
    type: "Current target",
    searchVolume: "55341",
    searchQuery: "netgear nighthawk router",
    organicImpressions: "1103977",
    organicClicks: "20805",
    addToCart: "3804",
    organicOrders: "1504",
    organicSales: "$222642",
    brandOrganicSales: "",
    brandOrganicSalesWhatIf: "",
    brandAdSales: "$0",
    lostSalesMissingTarget: "",
    organicRank: "",
    relevanceScore: "16%",
  },
  {
    key: 7,
    type: "Current target",
    searchVolume: "51361",
    searchQuery: "travel router",
    organicImpressions: "1019497",
    organicClicks: "27285",
    addToCart: "6007",
    organicOrders: "2213",
    organicSales: "$104431",
    brandOrganicSales: "$104431",
    brandOrganicSalesWhatIf: "$990",
    brandAdSales: "$0",
    lostSalesMissingTarget: "",
    organicRank: "",
    relevanceScore: "80%",
  },
  {
    key: 8,
    type: "Current target",
    searchVolume: "46158",
    searchQuery: "ryoko portable wifi",
    organicImpressions: "919089",
    organicClicks: "12070",
    addToCart: "1837",
    organicOrders: "164",
    organicSales: "$10194",
    brandOrganicSales: "$3048",
    brandOrganicSalesWhatIf: "$4313",
    brandAdSales: "$160",
    lostSalesMissingTarget: "",
    organicRank: "",
    relevanceScore: "100%",
  },
  {
    key: 9,
    type: "Current target",
    searchVolume: "45646",
    searchQuery: "hotspot device",
    organicImpressions: "892925",
    organicClicks: "18164",
    addToCart: "2278",
    organicOrders: "402",
    organicSales: "$29325",
    brandOrganicSales: "$13219",
    brandOrganicSalesWhatIf: "$18704",
    brandAdSales: "$4773",
    lostSalesMissingTarget: "",
    organicRank: "",
    relevanceScore: "100%",
  },
  {
    key: 10,
    type: "Current target",
    searchVolume: "44589",
    searchQuery: "mobile hotspot",
    organicImpressions: "876933",
    organicClicks: "18023",
    addToCart: "2031",
    organicOrders: "432",
    organicSales: "$44238",
    brandOrganicSales: "$14762",
    brandOrganicSalesWhatIf: "$20888",
    brandAdSales: "$14763",
    lostSalesMissingTarget: "",
    organicRank: "",
    relevanceScore: "100%",
  },
  {
    key: 11,
    type: "Current target",
    searchVolume: "42335",
    searchQuery: "portable wifi",
    organicImpressions: "827100",
    organicClicks: "18162",
    addToCart: "2578",
    organicOrders: "292",
    organicSales: "$16157",
    brandOrganicSales: "$7344",
    brandOrganicSalesWhatIf: "$10391",
    brandAdSales: "$20565",
    lostSalesMissingTarget: "",
    organicRank: "",
    relevanceScore: "100%",
  },
  {
    key: 12,
    type: "Current target",
    searchVolume: "39590",
    searchQuery: "hotspot",
    organicImpressions: "766289",
    organicClicks: "14370",
    addToCart: "1813",
    organicOrders: "381",
    organicSales: "$29031",
    brandOrganicSales: "$11071",
    brandOrganicSalesWhatIf: "$15666",
    brandAdSales: "$129410",
    lostSalesMissingTarget: "",
    organicRank: "",
    relevanceScore: "100%",
  },
  {
    key: 13,
    type: "Current target",
    searchVolume: "33494",
    searchQuery: "portable",
    organicImpressions: "767742",
    organicClicks: "3409",
    addToCart: "935",
    organicOrders: "89",
    organicSales: "$1913",
    brandOrganicSales: "",
    brandOrganicSalesWhatIf: "",
    brandAdSales: "$0",
    lostSalesMissingTarget: "",
    organicRank: "",
    relevanceScore: "",
  },
  {
    key: 14,
    type: "Current target",
    searchVolume: "32876",
    searchQuery: "portable wifi hotspot for travel",
    organicImpressions: "646462",
    organicClicks: "14895",
    addToCart: "2321",
    organicOrders: "316",
    organicSales: "$21095",
    brandOrganicSales: "$10639",
    brandOrganicSalesWhatIf: "$15055",
    brandAdSales: "$4775",
    lostSalesMissingTarget: "",
    organicRank: "",
    relevanceScore: "100%",
  },
  {
    key: 15,
    type: "Current target",
    searchVolume: "30902",
    searchQuery: "internet",
    organicImpressions: "527066",
    organicClicks: "4302",
    addToCart: "553",
    organicOrders: "128",
    organicSales: "$1023",
    brandOrganicSales: "$256",
    brandOrganicSalesWhatIf: "$362",
    brandAdSales: "$160",
    lostSalesMissingTarget: "",
    organicRank: "",
    relevanceScore: "80%",
  },
  {
    key: 16,
    type: "Current target",
    searchVolume: "23206",
    searchQuery: "starlink internet kit satellite",
    organicImpressions: "459665",
    organicClicks: "6366",
    addToCart: "703",
    organicOrders: "64",
    organicSales: "$21591",
    brandOrganicSales: "$380",
    brandOrganicSalesWhatIf: "$538",
    brandAdSales: "$0",
    lostSalesMissingTarget: "",
    organicRank: "",
    relevanceScore: "48%",
  },
  {
    key: 17,
    type: "Current target",
    searchVolume: "21310",
    searchQuery: "netgear nighthawk",
    organicImpressions: "433281",
    organicClicks: "7000",
    addToCart: "1014",
    organicOrders: "401",
    organicSales: "$58894",
    brandOrganicSales: "",
    brandOrganicSalesWhatIf: "",
    brandAdSales: "$120",
    lostSalesMissingTarget: "",
    organicRank: "",
    relevanceScore: "48%",
  },
  {
    key: 18,
    type: "Current target",
    searchVolume: "21050",
    searchQuery: "wifi hotspot",
    organicImpressions: "411457",
    organicClicks: "8500",
    addToCart: "1058",
    organicOrders: "220",
    organicSales: "$21161",
    brandOrganicSales: "$9343",
    brandOrganicSalesWhatIf: "$13221",
    brandAdSales: "$24097",
    lostSalesMissingTarget: "",
    organicRank: "",
    relevanceScore: "100%",
  },
  {
    key: 19,
    type: "Current target",
    searchVolume: "20935",
    searchQuery: "ryoko portable wifi hotspot",
    organicImpressions: "418826",
    organicClicks: "5611",
    addToCart: "784",
    organicOrders: "89",
    organicSales: "$6315",
    brandOrganicSales: "$3225",
    brandOrganicSalesWhatIf: "$4563",
    brandAdSales: "$0",
    lostSalesMissingTarget: "",
    organicRank: "",
    relevanceScore: "100%",
  },
  {
    key: 20,
    type: "Current target",
    searchVolume: "18302",
    searchQuery: "wireless router",
    organicImpressions: "371808",
    organicClicks: "6375",
    addToCart: "1341",
    organicOrders: "667",
    organicSales: "$41681",
    brandOrganicSales: "",
    brandOrganicSalesWhatIf: "",
    brandAdSales: "$0",
    lostSalesMissingTarget: "",
    organicRank: "",
    relevanceScore: "0%",
  },
  {
    key: 21,
    type: "Current target",
    searchVolume: "17156",
    searchQuery: "hot spot wifi portable",
    organicImpressions: "338582",
    organicClicks: "7526",
    addToCart: "935",
    organicOrders: "161",
    organicSales: "$10392",
    brandOrganicSales: "$5168",
    brandOrganicSalesWhatIf: "$7312",
    brandAdSales: "$848",
    lostSalesMissingTarget: "",
    organicRank: "",
    relevanceScore: "100%",
  },
  {
    key: 22,
    type: "Current target",
    searchVolume: "16198",
    searchQuery: "portable hotspot",
    organicImpressions: "315580",
    organicClicks: "7207",
    addToCart: "1103",
    organicOrders: "153",
    organicSales: "$5815",
    brandOrganicSales: "$2395",
    brandOrganicSalesWhatIf: "$3389",
    brandAdSales: "$2301",
    lostSalesMissingTarget: "",
    organicRank: "",
    relevanceScore: "100%",
  },
  {
    key: 23,
    type: "Current target",
    searchVolume: "15856",
    searchQuery: "hot spot",
    organicImpressions: "309452",
    organicClicks: "4394",
    addToCart: "648",
    organicOrders: "197",
    organicSales: "$10388",
    brandOrganicSales: "$6766",
    brandOrganicSalesWhatIf: "$9574",
    brandAdSales: "$1041",
    lostSalesMissingTarget: "",
    organicRank: "",
    relevanceScore: "100%",
  },
  {
    key: 24,
    type: "Current target",
    searchVolume: "15232",
    searchQuery: "technology",
    organicImpressions: "345307",
    organicClicks: "2215",
    addToCart: "381",
    organicOrders: "26",
    organicSales: "$469",
    brandOrganicSales: "",
    brandOrganicSalesWhatIf: "",
    brandAdSales: "$0",
    lostSalesMissingTarget: "",
    organicRank: "",
    relevanceScore: "",
  },
];
