// src/mocks/subscriptionHeatmapMock.js

export const subscriptionData = [
  {
    key: "1",
    month: "Jan 2024",
    "1": 685,
    "2": 685,
    "3": 685,
    "4": 1028,
    "5": 1028,
    "6": 2056,
    "7": 685,
    "8": 3427,
    total: 10282,
  },
  {
    key: "2",
    month: "Feb 2024",
    "1": 3703,
    "2": 3789,
    "3": 1765,
    "4": 1269,
    "5": 2385,
    "6": 3267,
    "7": 2335,
    "8": null,
    total: 18513,
  },
  {
    key: "3",
    month: "Mar 2024",
    "1": 4270,
    "2": 3487,
    "3": 2678,
    "4": 5478,
    "5": 1245,
    "6": 4193,
    "7": null,
    "8": null,
    total: 21351,
  },
  {
    key: "4",
    month: "Apr 2024",
    "1": 3808,
    "2": 3567,
    "3": 8743,
    "4": 11865,
    "5": null,
    "6": 10098,
    "7": null,
    "8": null,
    total: 38081,
  },
  {
    key: "5",
    month: "May 2024",
    "1": 2053,
    "2": 9865,
    "3": 5785,
    "4": null,
    "5": 13097,
    "6": null,
    "7": null,
    "8": null,
    total: 30800,
  },
  {
    key: "6",
    month: "Jun 2024",
    "1": 3181,
    "2": 14984,
    "3": 13641,
    "4": null,
    "5": null,
    "6": null,
    "7": null,
    "8": null,
    total: 31806,
  },
  {
    key: "7",
    month: "Jul 2024",
    "1": 32765,
    "2": 12840,
    "3": null,
    "4": null,
    "5": null,
    "6": null,
    "7": null,
    "8": null,
    total: 45594,
  },
  {
    key: "8",
    month: "Aug 2024",
    "1": 39014,
    "2": null,
    "3": null,
    "4": null,
    "5": null,
    "6": null,
    "7": null,
    "8": null,
    total: 39014,
  },
];
