const endpointIds = {
  mask: "jxi6y75pihtxqq",
  generate: "v9sa1k655huj40",
} as const;

const checkTaskStatus = async <T>(
  taskId: string,
  id: keyof typeof endpointIds,
): Promise<T> => {
  return new Promise((res, rej) => {
    const timeoutId = setInterval(async () => {
      try {
        const endpoint_id = endpointIds[id];

        const response = await fetch(
          `https://api.runpod.ai/v2/${endpoint_id}/status/${taskId}`,
          {
            method: "GET",
            headers: {
              Authorization: "Bearer QOC6OOEH08L9Y38EUFOP91BV3GKKWMU8XJTE9BCT",
            },
          },
        );
        const data = await response.json();

        if (data.status === "COMPLETED") {
          clearTimeout(timeoutId);
          res(data.output.result as T);
        } else if (
          data.status === "IN_QUEUE" ||
          data.status === "IN_PROGRESS"
        ) {
          // noop
        } else {
          console.error(`Task ${id} failed with status: ${data.status}`);
        }
      } catch (error) {
        clearTimeout(timeoutId);
        console.error(
          "An error occurred while checking the task status:",
          error,
        );
        rej(error);
      }
    }, 2000);
  });
};

export const getMaskAndPromptSuggestions = async (base64DataURI: string) => {
  const base64Image = base64DataURI.split(",")[1];

  try {
    const maskResponse = fetch(
      `https://api.runpod.ai/v2/${endpointIds.mask}/run`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer QOC6OOEH08L9Y38EUFOP91BV3GKKWMU8XJTE9BCT",
        },
        body: JSON.stringify({
          input: { img: base64Image },
        }),
      },
    );

    const promptResponse = fetch("https://api.openai.com/v1/chat/completions", {
      method: "POST",
      headers: {
        Authorization:
          "Bearer sk-proj-RJeZQynbcUvd1gzLcjKBT3BlbkFJEdz0mAFJlVTpWZYtTywI",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        model: "gpt-4o-2024-08-06",
        messages: [
          {
            role: "user",
            content: [
              {
                type: "text",
                text: "Provide 3 prompts for Stable Diffusion model that will generate background for advertising photo of main object of provided image. For example, if provided image has sport nutrition package on it, provided prompts could be backgrounds with kitchen environment with some healthy food on kitchen table, fitness club surroundings, nature surroundings, or just some minimalistic surroundings with just product pack.",
              },
              {
                type: "image_url",
                image_url: { url: base64DataURI },
              },
            ],
          },
        ],
        response_format: {
          type: "json_schema",
          json_schema: {
            name: "prompt_suggestions",
            schema: {
              type: "object",
              properties: {
                suggestions: {
                  type: "array",
                  items: { type: "string" },
                },
              },
              required: ["suggestions"],
              additionalProperties: false,
            },
            strict: true,
          },
        },
      }),
    });

    const [maskData, promptData] = await Promise.all([
      maskResponse,
      promptResponse,
    ]);
    const promptJson = await promptData.json();
    const maskJson = await maskData.json();

    const suggestions1 = JSON.parse(
      promptJson.choices[0].message.content,
    ).suggestions;

    const taskId = maskJson.id;
    const masks = await checkTaskStatus<string[]>(taskId, "mask");

    return [masks, suggestions1];
  } catch (error) {
    console.error("An error occurred while processing input image.", error);
  }
  return [];
};

export const generateImage = async (
  initImages: string[],
  mask: string,
): Promise<string> => {
  try {
    const response = await fetch(
      `https://api.runpod.ai/v2/${endpointIds.generate}/run`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer QOC6OOEH08L9Y38EUFOP91BV3GKKWMU8XJTE9BCT",
        },
        body: JSON.stringify({
          input: {
            payload: {
              mask: mask,
              init_images: initImages,
              prompt: prompt,
              negative_prompt: "",
              latent_mask: null,
              seed: -1,
              sampler_name: "Euler a",
              sampler_index: "Euler a",
              scheduler: "automatic",
              denoising_strength: "0.95",
              steps: "30",
              width: 512,
              height: 512,
              inpainting_mask_invert: 1,
              override_settings: {
                sd_model_checkpoint:
                  "sd_xl_base_1.0_inpainting_0.1.safetensors",
              },
              override_settings_restore_afterwards: true,
              restore_faces: false,
              n_iter: "1",
              cfg_scale: "7",
            },
          },
        }),
      },
    );

    const generateIMG = await response.json();

    if (generateIMG.id) {
      const result = await checkTaskStatus<string>(generateIMG.id, "generate");
      return result;
    } else {
      console.error("Failed to get task ID from response:", generateIMG);
    }
  } catch (error) {
    console.error("An error occurred while generating the image:", error);
  }
  return "";
};
