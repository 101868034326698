const CloseMenuIcon = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M25.5902 22.6484C26.1363 23.1864 26.1363 24.0586 25.5902 24.5965C25.044 25.1345 24.1585 25.1345 23.6124 24.5965L17.4888 18.5648C16.9426 18.0269 16.0572 18.0269 15.511 18.5648L9.3874 24.5965C8.84125 25.1345 7.95576 25.1345 7.40961 24.5965C6.86346 24.0586 6.86346 23.1864 7.40961 22.6484L15.511 14.6686C16.0572 14.1306 16.9426 14.1306 17.4888 14.6686L25.5902 22.6484Z"
      fill="#7C7C78"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.40982 8.35157C6.86367 7.81362 6.86367 6.94142 7.40982 6.40347C7.95597 5.86551 8.84146 5.86551 9.38761 6.40347L15.5112 12.4352C16.0574 12.9731 16.9428 12.9731 17.489 12.4352L23.6126 6.40347C24.1588 5.86551 25.0442 5.86551 25.5904 6.40347C26.1365 6.94142 26.1365 7.81362 25.5904 8.35158L17.489 16.3314C16.9428 16.8694 16.0574 16.8694 15.5112 16.3314L7.40982 8.35157Z"
      fill="#7C7C78"
    />
  </svg>
);
export default CloseMenuIcon;
