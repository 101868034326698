import styles from "src/pages/Auth/Auth.module.scss";
import { useTranslation } from "react-i18next";
import Banner from "@assets/img/banner.png";
import { Link } from "react-router-dom";
import BackAuthIcon from "@assets/icons/backAuthIcon";
import { useIsMobile } from "src/utils/isMobile";

export const AuthIntro = () => {
  const { t } = useTranslation();
  const isMobile = useIsMobile();
  return (
    <div className={styles.authPageIntro}>
      {location.pathname === "/auth/forgot" && isMobile && (
        <Link to="/login" className={styles.backButton}>
          <BackAuthIcon /> back to log in
        </Link>
      )}
      <h2 className={styles.authPageIntroHeading}>
        {t("message.welcome_title")}
      </h2>
      <p className={styles.authPageIntroText}>{t("message.welcome_message")}</p>
      <img
        src={Banner}
        alt="Auth Banner"
        className={styles.authPageIntroBanner}
      />
    </div>
  );
};
