import React, { useState, useEffect, useRef, useMemo } from "react";
import classNames from "classnames";
import ModalBox from "@components/ModalBox";
import { useProductsStore } from "src/store/product.state";
import { AmazonService } from "@services/amazon/amazon.service";
import styles from "./styles.module.scss";
import SearchIcon from "@assets/icons/SearchIcon.svg";
import CheckIcon from "@assets/icons/CheckIcon.svg";
import ProductCode from "@components/ProductCode";
import { Product } from "@services/amazon/products/types";
import { useProductStore } from "src/store/overviewProduct.state";
import { OverviewType } from "@pages/Overview";
import { useLocation } from "react-router-dom";
import { CategoriesService } from "@services/amazon/categories/categories.service";
import {
  getPaginatedItems,
  getPaginationRange,
  getRouteFlags,
  getTabs,
} from "./constant";
import { useCategoryStore } from "src/store/category";
import CategoryPagination from "../CategoryPagination";

export type ProductTab = "myProducts" | "allProducts";

interface ProductModalProps {
  isOpen: boolean;
  onClose?: () => void;
  onSelect: (product: Product, searchTerm: string) => void;
  productTwo?: boolean;
  type: OverviewType;
  handleApply?: (selectedProducts: Product[]) => void;
}

const ProductSelectionModal: React.FC<ProductModalProps> = ({
  isOpen,
  onClose,
  onSelect,
  productTwo = false,
  handleApply,
}) => {
  const {
    selectedProduct,
    selectedProductToCompare,
    setSelectedProduct,
    setSelectedProductToCompare,
  } = useProductStore();

  const location = useLocation();

  const tabs = getTabs(productTwo);
  const { isCompareRoute, isMyRoute } = getRouteFlags(location.pathname);
  const [activeTab, setActiveTab] = useState<ProductTab>(
    tabs[0].tab as ProductTab,
  );
  const [paginationStart, setPaginationStart] = useState(1);
  const [activeButton, setActiveButton] = useState(8);
  const [searchTerm, setSearchTerm] = useState("");
  const {
    productsOwn = [],
    productsPublic = [],
    setProductDataByAsin,
    setProductMaterials,
  } = useProductsStore();
  const [spiders, setSpiders] = useState<any>(null);
  const [selectedItems, setSelectedItems] = useState<any[]>([]);
  const [isCategorySelected, setIsCategorySelected] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchResults, setSearchResults] = useState<Product[]>([]);
  const [total, setTotal] = useState<Product[]>([]);
  const [selectedCategoryIndex, setSelectedCategoryIndex] = useState<
    number | null
  >(null);
  const [itemsPerPage, setItemsPerPage] = useState(8);
  const [totalItems, setTotalItems] = useState(0);

  const { selectedCategory, setSelectedCategory, setPaginatedItem } =
    useCategoryStore();

  const paginatedItems = useMemo(() => {
    if (!selectedCategory || !selectedCategory.items) return [];
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return selectedCategory.items.slice(startIndex, endIndex);
  }, [selectedCategory, currentPage, itemsPerPage]);

  useEffect(() => {
    if (selectedCategory && selectedCategory.items) {
      setTotalItems(selectedCategory.items.length);
    }
  }, [selectedCategory]);

  const handleCategoryClick = (index: number) => {
    const selectedCategoryData = spiders[index];
    setSelectedCategory({
      id: selectedCategoryData.id,
      name: selectedCategoryData.Sub_category,
      items: selectedCategoryData.items,
    });
    setIsCategorySelected(true);
    setSelectedCategoryIndex(index);
    setCurrentPage(1);
  };

  useEffect(() => {
    if (activeButton !== 16 && activeButton !== 24 && activeButton !== 32) {
      const paginated = getPaginatedItems(
        selectedItems,
        currentPage,
        activeButton,
      );
      setSelectedItems(paginated);
    }
  }, [currentPage, activeButton]);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const params = {
          asin: searchTerm,
          limit: activeButton,
        };
        if (searchTerm) {
          if (activeTab === "myProducts") {
            await AmazonService.products.searchOwnProducts(params);
          } else {
            const response =
              await AmazonService.products.searchPublicProducts(params);
            setSearchResults(response);
          }
        } else {
          setSearchResults([]);
          if (activeTab === "myProducts") {
            await AmazonService.products.getOwnProducts(params);
          }
        }
      } catch (err) {
        console.error("Error fetching products:", err);
      }
    };

    if (isOpen) {
      fetchProducts();
    }
  }, [isOpen, activeTab, activeButton, searchTerm]);

  const productsToDisplay =
    activeTab === "myProducts" ? productsOwn : productsPublic;

  const handleSelect = (product: Product) => {
    setProductDataByAsin(null);
    setProductMaterials(null);
    if (activeTab === "allProducts") {
      if (isCompareRoute) {
        if (selectedProductToCompare.some((p) => p.asin === product.asin)) {
          setSelectedProductToCompare(
            selectedProductToCompare.filter((p) => p.asin !== product.asin),
          );
        } else {
          setSelectedProductToCompare([...selectedProductToCompare, product]);
        }
      } else {
        setSelectedProductToCompare([product]);

        onSelect(product, searchTerm);

        if (onClose) {
          onClose();
        }
      }
    } else if (activeTab === "myProducts") {
      setSelectedProduct(product);

      onSelect(product, searchTerm);

      if (onClose) {
        onClose();
      }
    }
  };

  const applySelection = () => {
    if (handleApply) {
      handleApply(selectedProductToCompare);
      setSelectedProductToCompare(selectedProductToCompare);
    }
    onClose();
  };

  useEffect(() => {
    if (selectedCategory) {
      const startIndex = activeButton === 8 ? 0 : activeButton - 8;
      const endIndex = activeButton;
      const paginated = selectedCategory.items.slice(startIndex, endIndex);
      setPaginatedItem(paginated);
      setSelectedItems(paginated);
    }
  }, [selectedCategory, activeButton]);

  const renderTabButton = (tab: ProductTab, label: string) => (
    <button
      key={tab}
      className={classNames(styles.tabButton, {
        [styles.active]: activeTab === tab,
      })}
      onClick={() => setActiveTab(tab as ProductTab)}
    >
      {label.toUpperCase()}
    </button>
  );

  const renderSwitchButton = (value: number) => (
    <button
      key={value}
      className={classNames(styles.switchButton, {
        [styles.active]: itemsPerPage === value,
      })}
      onClick={() => handleSwitchItemsPerPage(value)}
    >
      {value}
    </button>
  );

  const handleSwitchItemsPerPage = (value: number) => {
    setItemsPerPage(value);
    setCurrentPage(1);
  };
  const handleBackClick = () => {
    setIsCategorySelected(false);
  };

  const fetchCategories = async () => {
    const spider = new CategoriesService();
    if (isMyRoute && activeTab === "allProducts") {
      const spidersData = await spider.getCategories(selectedProduct?.asin);
      const updatedTotals = spidersData.map((category: any) =>
        category.items ? category.items.length : 0,
      );
      setTotal(updatedTotals);
      setSpiders(spidersData);
    }
  };

  useEffect(() => {
    if (isOpen) {
      fetchCategories();
    }
  }, [isOpen, activeTab === "allProducts"]);

  const totalPages = Math.ceil(totalItems / itemsPerPage);

  useEffect(() => {
    if (selectedCategory) {
      setTotalItems(selectedCategory.items.length);
    }
  }, [selectedCategory]);

  const handleEllipsisClick = (type: "next" | "prev") => {
    const maxVisiblePages = 5;
    if (type === "next") {
      setPaginationStart((prev) =>
        Math.min(prev + maxVisiblePages, totalPages - maxVisiblePages + 1),
      );
    } else if (type === "prev") {
      setPaginationStart((prev) => Math.max(prev - maxVisiblePages, 1));
    }
  };

  return (
    <ModalBox
      isOpen={isOpen}
      onClose={onClose ? onClose : () => {}}
      title="Choose ASIN"
      hideCloseButton={!onClose}
    >
      <div className={styles.tabButtons}>
        <div className={styles.tabButtonsWrapper}>
          {tabs.map(({ tab, label }) =>
            renderTabButton(tab as ProductTab, label),
          )}
        </div>
      </div>

      <div className={styles.searchBox}>
        <input
          type="text"
          placeholder="Search by product name or ASIN"
          className={styles.searchInput}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <button className={styles.searchButton}>
          <img src={SearchIcon} alt="Search" />
        </button>
      </div>

      <div className={styles.sortSection}>
        {/* <div className={styles.sortSelect}>
          <span className={styles.sortText}>SORT ALPHABETICALLY</span>
          <img
            src={dropDownArrow}
            alt="Sort"
            className={styles.dropDownArrow}
          />
        </div> */}
        {isCategorySelected && (
          <button onClick={handleBackClick} className={styles.bcButton}>
            Back to Categories
          </button>
        )}
        <div className={styles.switchButtons}>
          {[8, 16, 32].map(renderSwitchButton)}
        </div>
      </div>

      {activeTab === "myProducts" ? (
        <div className={styles.productGridContainer}>
          <div className={styles.productGrid}>
            {Array.isArray(productsToDisplay) &&
              productsToDisplay.map((product, index) => (
                <div
                  key={`${activeTab}-${product.asin}-${index}`}
                  className={styles.productCard}
                >
                  <img
                    src={product.image_link || product.picture_url || ""}
                    alt="Product image"
                    className={styles.productImage}
                    width={124}
                    height={124}
                  />
                  <ProductName name={product.item_name || product.title} />
                  <div className={styles.productDetails}>
                    <ProductCode code={product.asin} />
                  </div>
                  <button
                    className={classNames(styles.selectButton, {
                      [styles.selected]: selectedProductToCompare.some(
                        (p) => p.asin === product.asin,
                      ),
                    })}
                    onClick={() => handleSelect(product)}
                  >
                    {selectedProductToCompare.some(
                      (p) => p.asin === product.asin,
                    ) ? (
                      <>
                        <img
                          src={CheckIcon}
                          alt="Selected"
                          className={styles.checkIcon}
                        />
                        SELECT
                      </>
                    ) : (
                      "SELECT"
                    )}
                  </button>
                </div>
              ))}
          </div>
        </div>
      ) : null}

      {activeTab === "allProducts" && (
        <div className={styles.productGridContainer}>
          <div className={styles.backButton}></div>
          <div className={styles.categoryListWrapper}>
            {isCategorySelected ? (
              <div className={styles.productGridContainer}>
                <div className={styles.productGrid}>
                  {searchResults.length > 0 ? (
                    searchResults.map((item: Product, itemIndex: number) => (
                      <div
                        key={`${activeTab}-${item.asin}-${itemIndex}`}
                        className={styles.productCard}
                      >
                        <img
                          src={item.picture_url || ""}
                          alt={item.title}
                          className={styles.productImage}
                          width={124}
                          height={124}
                        />
                        <ProductName name={item.title} />
                        <div className={styles.productDetails}>
                          <ProductCode code={item.asin} />
                        </div>
                        <button
                          className={classNames(styles.selectButton, {
                            [styles.selected]: selectedProductToCompare.some(
                              (p) => p.asin === item.asin,
                            ),
                          })}
                          onClick={() => handleSelect(item)}
                        >
                          {selectedProductToCompare.some(
                            (p) => p.asin === item.asin,
                          ) ? (
                            <>
                              <img
                                src={CheckIcon}
                                alt="Selected"
                                className={styles.checkIcon}
                              />
                              SELECT
                            </>
                          ) : (
                            "SELECT"
                          )}
                        </button>
                      </div>
                    ))
                  ) : (
                    <CategoryPagination
                      paginatedItems={paginatedItems}
                      activeTab={activeTab}
                      handleSelect={handleSelect}
                    />
                  )}
                </div>
              </div>
            ) : (
              <div className={styles.productGridContainer}>
                <div className={styles.categoryListWrapper}>
                  {spiders?.length
                    ? spiders.map((spider, index) => (
                        <div
                          className={styles.categoryItem}
                          key={index}
                          onClick={() => handleCategoryClick(index)}
                        >
                          <span className={styles.categoryText}>
                            {spider.Sub_category || "N/A"}
                          </span>
                          <span className={styles.categoryArrow}>›</span>
                        </div>
                      ))
                    : null}
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      {isCategorySelected && (
        <div className={styles.paginationWrapper}>
          <button
            className={classNames(styles.paginationArrow, {
              [styles.disabled]: currentPage === 1,
            })}
            onClick={() => {
              if (currentPage > 1) {
                const newPage = currentPage - 1;
                setCurrentPage(newPage);

                if (newPage < paginationStart) {
                  setPaginationStart((prev) => Math.max(prev - 5, 1));
                }
              }
            }}
            disabled={currentPage === 1}
          >
            &lt;
          </button>
          <div>
            {getPaginationRange(currentPage, totalPages, paginationStart).map(
              (page, index) =>
                typeof page === "number" ? (
                  <button
                    key={index}
                    className={classNames(styles.paginationButton, {
                      [styles.active]: currentPage === page,
                    })}
                    onClick={() => setCurrentPage(page)}
                  >
                    {page}
                  </button>
                ) : (
                  <span
                    key={index}
                    className={styles.paginationEllipsis}
                    onClick={() =>
                      page === "..."
                        ? paginationStart + 5 <= totalPages
                          ? handleEllipsisClick("next")
                          : handleEllipsisClick("prev")
                        : null
                    }
                  >
                    {page}
                  </span>
                ),
            )}
          </div>
          <button
            className={classNames(styles.paginationArrow, {
              [styles.disabled]: currentPage === totalPages,
            })}
            onClick={() => {
              if (currentPage < totalPages) {
                const newPage = currentPage + 1;
                setCurrentPage(newPage);

                if (newPage > paginationStart + 4) {
                  setPaginationStart((prev) =>
                    Math.min(prev + 5, totalPages - 4),
                  );
                }
              }
            }}
            disabled={currentPage === totalPages}
          >
            &gt;
          </button>
        </div>
      )}

      {activeTab === "allProducts" && isCompareRoute && (
        <button className={styles.applyButton} onClick={applySelection}>
          Apply
        </button>
      )}
    </ModalBox>
  );
};

export const ProductName: React.FC<{ name: string }> = ({ name }) => {
  const [isTruncated, setIsTruncated] = useState(false);
  const productNameRef = useRef<HTMLParagraphElement>(null);

  useEffect(() => {
    const element = productNameRef.current;
    if (element) {
      setIsTruncated(element.scrollHeight > element.clientHeight);
    }
  }, [name]);

  return (
    <p
      ref={productNameRef}
      className={classNames(styles.productName, {
        [styles.truncated]: isTruncated,
      })}
    >
      {name}
    </p>
  );
};

export default ProductSelectionModal;
