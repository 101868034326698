import React from "react";

interface StarProps {
  fill: "full" | "half" | "empty";
}

const Star: React.FC<StarProps> = ({ fill }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width="24"
      height="24"
      style={{ position: "relative" }}
    >
      <defs>
        <clipPath id="half">
          <rect x="0" y="0" width="50%" height="100%" />
        </clipPath>
      </defs>
      <path
        d="M12 2l3.09 6.26L22 9.27l-5 4.87L18.18 21 12 17.27 5.82 21 7 14.14l-5-4.87 6.91-1L12 2z"
        fill="#7C7C78"
      />
      {fill === "full" && (
        <path
          d="M12 2l3.09 6.26L22 9.27l-5 4.87L18.18 21 12 17.27 5.82 21 7 14.14l-5-4.87 6.91-1L12 2z"
          fill="#FFD700"
        />
      )}
      {fill === "half" && (
        <path
          d="M12 2l3.09 6.26L22 9.27l-5 4.87L18.18 21 12 17.27 5.82 21 7 14.14l-5-4.87 6.91-1L12 2z"
          fill="#FFD700"
          clipPath="url(#half)"
        />
      )}
    </svg>
  );
};

export default Star;
