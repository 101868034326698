import styles from "./styles.module.scss";
import { Header } from "./Header";
import { Elements } from "./Elements";
import { Canva } from "./Canva";

export const CanvasEditor: React.FC = () => {
  return (
    <div className={styles.container}>
      <Header />
      <div className={styles.wrapper}>
        <Elements />
        <Canva />
      </div>
    </div>
  );
};
