import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styles from "./styles.module.scss";
import { ROUTES } from "src/router/routes";
import ImageSection from "./сomponents/ImageSection";
import FormSection from "./сomponents/FormSection";
import { VisibleDescriptions } from "../../../components/OptimisationInfoSection/types";
import OptimisationPageWrapper from "../../../components/OptimisationPageWrapper";
import MinusIcon from "@assets/icons/minus-icon.svg";
import Plus from "@assets/icons/plus.tsx";
import Upload from "@assets/icons/upload.svg";
import Save from "@assets/icons/save.svg";
import CloseIcon from "@components/CloseIcon";
import OptimizationScore from "./сomponents/OptimizationScore";
import HistoryWidget from "./сomponents/HistoryWidget";
import EditorWidget from "./сomponents/EditorWidget";
import ReplaceBgWidget from "./сomponents/ReplaceBgWidget";
import AIWidget from "./сomponents/AIWidget";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from "react-beautiful-dnd";
import AIEraserWidget from "./сomponents/AIEraserWidget";

import AspectsIcon from "@assets/icons/Aspects-icon.svg";
import OptimizationScoreIcon from "@assets/icons/Optimization-rate-icon.svg";
import PhotoEditorIcon from "@assets/icons/Photo-editor-icon.svg";
import HistoryIcon from "@assets/icons/History-icon.svg";
import ReplaceBgIcon from "@assets/icons/Replace-bg-icon.svg";
import AIIcon from "@assets/icons/Napkin-AI-icon.svg";
import AIErase from "@assets/icons/AI-eraser-icon.svg";
import UpdatePhotoWidget from "./сomponents/UpdatePhotoWidget";
import PromptWidget from "./сomponents/PromptWidget";
import SelectMaskWidget from "./сomponents/SelectMaskWidget";
import { toDataURL } from "src/utils/toDataURL";
import { getMaskAndPromptSuggestions, generateImage } from "./api";
import classNames from "classnames";
import SearchWidget from "./сomponents/SearchWidget";
import SelectTemplateWidgets from "./сomponents/SelectTemplateWidget";
import { templatesMock } from "./mock";
import { Template } from "./сomponents/SelectTemplateWidget/Template";

const PhotoOptimisationDetails: React.FC = () => {
  const [isHypothesisVisible, setIsHypothesisVisible] = useState(false);
  const [visibleDescriptions, setVisibleDescriptions] =
    useState<VisibleDescriptions>({
      description: false,
      critique: false,
      hypothesis: false,
      howToTest: false,
      percentageImpact: false,
    });

  const [widgets, setWidgets] = useState(
    [
      { id: "1", name: "Aspects", icon: AspectsIcon },
      { id: "2", name: "Optimization Rate", icon: OptimizationScoreIcon },
      { id: "3", name: "Photo Editor", icon: PhotoEditorIcon },
      { id: "4", name: "Replace background", icon: ReplaceBgIcon },
      { id: "5", name: "Napkin AI", icon: AIIcon },
      { id: "6", name: "AI eraser", icon: AIErase },
      { id: "7", name: "History", icon: HistoryIcon },
    ].map((widget) => ({
      ...widget,
      show: false,
      setShow: () =>
        setWidgets((widgets) =>
          widgets.map((w) =>
            w.id === widget.id ? { ...w, show: !w.show } : w,
          ),
        ),
    })),
  );

  const [openedImages, setOpenedImages] = useState<
    { img: string; id: string; zoom: string }[]
  >([]);
  const [scale, setScale] = useState(1);
  const [promptData, setPromptData] = useState("");
  const [showPrompt, setShowPrompt] = useState(false);
  const [suggestions1, setSuggestions] = useState<string[]>([]);
  const [maskResult, setMaskResult] = useState<string[]>([]);
  const [templates, setTemplates] = useState<Template[]>([]);
  const [generatedImage, setGeneratedImage] = useState("");
  const [reset, setReset] = useState(false);
  const [imageName, setImageName] = useState("");
  const [uploadedImage, setUploadedImage] = useState("");
  const [activeMask, setActiveMask] = useState(0);
  const [search, setSearch] = useState("");
  const [activeImageId, setActiveImageId] = useState<string | null>(null);
  const changeActiveMask = (index: number) => {
    setActiveMask(Math.max(0, Math.min(maskResult.length - 1, index)));
  };

  const location = useLocation();
  const nav = useNavigate();

  const { imageUrl } = location.state || {};
  const currentImageUrl = uploadedImage || imageUrl;

  const id = location.pathname.split("/").pop() || "";

  useEffect(() => {
    const storedImages = localStorage.getItem("openedImages");
    if (storedImages) {
      const parsedImages = JSON.parse(storedImages);
      setOpenedImages(parsedImages);

      const existingImage = parsedImages.find(
        (imgObj) => imgObj.img === imageUrl,
      );
      if (existingImage) {
        setScale(parseFloat(existingImage.zoom));
      }
    }
  }, [imageUrl]);

  useEffect(() => {
    if (imageUrl && id) {
      setOpenedImages((prevImages) => {
        const existingImage = prevImages.find(
          (imgObj) => imgObj.img === imageUrl,
        );
        if (existingImage) {
          const updatedImages = prevImages.map((imgObj) =>
            imgObj.img === imageUrl
              ? { ...imgObj, zoom: scale.toFixed(2) }
              : imgObj,
          );
          localStorage.setItem("openedImages", JSON.stringify(updatedImages));
          return updatedImages;
        } else {
          const updatedImages = [
            ...prevImages,
            { img: imageUrl, id, zoom: scale.toFixed(2) },
          ];
          localStorage.setItem("openedImages", JSON.stringify(updatedImages));
          return updatedImages;
        }
      });
    }
  }, [imageUrl, id, scale]);

  useEffect(() => {
    const storedImages = localStorage.getItem("openedImages");
    if (storedImages) {
      const parsedImages = JSON.parse(storedImages);
      const existingImage = parsedImages.find(
        (imgObj) => imgObj.img === imageUrl,
      );
      if (existingImage) {
        setScale(parseFloat(existingImage.zoom));
      } else {
        setScale(1);
      }
    } else {
      setScale(1);
    }
  }, []);

  const toggleHypothesisVisibility = () => {
    setIsHypothesisVisible((prevState) => !prevState);
  };

  const toggleVisibility = (field: keyof VisibleDescriptions) => {
    setVisibleDescriptions((prevState) => ({
      ...prevState,
      [field]: !prevState[field],
    }));
  };

  const widgetHandler = (
    setWidget: React.Dispatch<React.SetStateAction<boolean>>,
  ) => {
    setWidget(true);
  };

  const widgetHandlerClose = (
    setWidget: React.Dispatch<React.SetStateAction<boolean>>,
  ) => {
    setWidget(false);
  };

  const handleRedirectToLayout = (imageId: string) => {
    const storedImages = localStorage.getItem("openedImages");
    if (storedImages) {
      const parsedImages = JSON.parse(storedImages);
      const nextImage = parsedImages.find((imgObj) => imgObj.id === imageId);
      const nextScale = nextImage ? parseFloat(nextImage.zoom) : 1;
      setScale(nextScale);
    } else {
      setScale(1);
    }
    setActiveImageId(imageId);
    nav(`/${ROUTES.CRO}/${ROUTES.CRO_PHOTOUPDATE}/${imageId}`);
  };

  const handleRemove = (imgToRemove: string) => {
    setOpenedImages((prevImages) => {
      const updatedImages = prevImages.filter(
        (imgObj) => imgObj.img !== imgToRemove,
      );
      localStorage.setItem("openedImages", JSON.stringify(updatedImages));

      if (updatedImages.length > 0) {
        const nextImageId = updatedImages[0].id;
        const nextImageZoom = updatedImages[0].zoom;

        setScale(Number(nextImageZoom));
        nav(`/${ROUTES.CRO}/${ROUTES.CRO_PHOTOUPDATE}/${nextImageId}`);
      } else {
        setScale(1);
        nav(`/${ROUTES.CRO}/${ROUTES.CRO_PHOTOUPDATE}/`);
      }

      return updatedImages;
    });
  };

  const zoomIn = () => {
    if (scale < 1) setScale((prevScale) => Math.min(prevScale + 0.05, 2));
  };

  const zoomOut = () => {
    if (scale > 0.5) setScale((prevScale) => Math.max(prevScale - 0.05, 0.05));
  };

  const handleOnDragEnd = (result: DropResult) => {
    if (!result.destination) return;
    const items = Array.from(widgets);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setWidgets(items);
  };

  const [loadingMaskAndPropmtSuggestions, setLoadingMaskAndPropmtSuggestions] =
    useState(false);
  const [loadingImageGeneration, setLoadingImageGeneration] = useState(false);
  const [base64data, setBase64data] = useState(null);

  const onStartGeneration = async () => {
    setMaskResult([]);
    setSuggestions([]);
    setLoadingMaskAndPropmtSuggestions(true);
    const imageDataUrl = await toDataURL(currentImageUrl);
    setBase64data(imageDataUrl);

    const [masks, suggestions] =
      await getMaskAndPromptSuggestions(imageDataUrl);

    setSuggestions(Array.isArray(suggestions) ? suggestions : []);
    setMaskResult(Array.isArray(masks) ? masks : []);
    setTemplates(templatesMock);
    setLoadingMaskAndPropmtSuggestions(false);
    setShowPrompt(true);
    setReset(false);
  };

  const onUploadImage = (event: React.ChangeEvent<HTMLInputElement>) => {
    const [file] = event.target.files;

    const hasImage = Boolean(file);

    if (!hasImage) {
      setImageName("");
      setUploadedImage("");
      return;
    }

    setImageName(file.name);
    setUploadedImage(URL.createObjectURL(file));

    setGeneratedImage("");

    if (showPrompt) {
      onStartGeneration();
    }
  };

  const onGenerateImage = async () => {
    const mask = maskResult[activeMask];
    const initImages = [base64data];

    if (!base64data || maskResult.length === 0 || !mask) {
      return;
    }

    setLoadingImageGeneration(true);
    setGeneratedImage("");
    setShowPrompt(false);

    const image = await generateImage(initImages, mask);

    setLoadingImageGeneration(false);
    setGeneratedImage(image);
    setMaskResult([]);
    setReset(false);
  };

  return (
    <OptimisationPageWrapper>
      <div className={styles.rowItems}>
        {openedImages.map((imgObj, index) => (
          <button
            key={index}
            // className={classNames(styles.card, {
            //   [styles.active]: activeImageId === imgObj.id || openedImages.length === 1,
            // })}
            className={classNames(styles.card, {
              [styles.active]:
                activeImageId === imgObj.id || openedImages.length === 1,
            })}
            onClick={() => handleRedirectToLayout(imgObj.id)}
          >
            {imgObj.img.split("/").pop()}
            <CloseIcon onClick={() => handleRemove(imgObj.img)} />
          </button>
        ))}
        <div className={styles.controls}>
          <label className={classNames(styles.button, styles.upload)}>
            <input
              type="file"
              onChange={onUploadImage}
              accept="image/png, image/gif, image/jpeg"
              hidden
            />
            {imageName ? (
              <span className={styles.buttonText}>{imageName}</span>
            ) : (
              <>
                <img src={Upload} alt="upload" />
                {"Upload"}
              </>
            )}
          </label>
          <button className={styles.button}>
            <img src={Save} alt="save" />
            Save changes
          </button>
        </div>
      </div>
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Droppable droppableId="widgets">
          {(provided) => (
            <div
              className={styles.canvaContainer}
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              <div className={styles.sidebar}>
                {/* SEARCH WIDGET */}
                <SearchWidget
                  label="Add elements"
                  initioalValue={search}
                  onChange={setSearch}
                />
                <div className={styles.widgets}>
                  {widgets.map((widget, index) => (
                    <Draggable
                      key={widget.id}
                      draggableId={widget.id}
                      index={index}
                    >
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          className={styles.widgetContainer}
                        >
                          <button
                            className={styles.widget}
                            onClick={(e) => {
                              e.preventDefault();
                              widget.setShow();
                            }}
                          >
                            <div
                              className={styles.row}
                              {...provided.dragHandleProps}
                            >
                              <img
                                src={widget.icon}
                                alt={`${widget.name} icon`}
                              />
                              <div className={styles.showIcon}>
                                {widget.show ? (
                                  <img src={MinusIcon} alt="hide" />
                                ) : (
                                  <Plus />
                                )}
                              </div>
                            </div>
                            <span className={styles.widgetTitle}>
                              {widget.name}
                            </span>
                          </button>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              </div>
              <div
                className={styles.canva}
                style={{
                  transform: `scale(${scale})`,
                  transformOrigin: "center",
                }}
              >
                <div className={styles.zoomBlock}>
                  <button
                    className={classNames(styles.card, styles.zoom)}
                    onClick={zoomIn}
                  >
                    +
                  </button>
                  <span>{Math.round(scale * 100)}%</span>
                  <button
                    className={classNames(styles.card, styles.zoom)}
                    onClick={zoomOut}
                  >
                    -
                  </button>
                </div>
                <div className={styles.imageRow}>
                  <ImageSection
                    loading={loadingImageGeneration}
                    generatedImage={generatedImage}
                    masks={maskResult}
                    reset={reset}
                    imageUrl={
                      currentImageUrl ||
                      openedImages.find((imgObj) => imgObj.id === id)?.img ||
                      ""
                    }
                    changeMask={changeActiveMask}
                    activeMask={activeMask}
                  />
                  {(showPrompt || loadingMaskAndPropmtSuggestions) && (
                    <PromptWidget
                      loading={loadingMaskAndPropmtSuggestions}
                      setData={setPromptData}
                      data={suggestions1}
                      setReset={setReset}
                      onGenerateImage={onGenerateImage}
                    />
                  )}
                </div>

                {(showPrompt || loadingMaskAndPropmtSuggestions) && (
                  <SelectTemplateWidgets
                    loading={loadingMaskAndPropmtSuggestions}
                    templates={templates}
                    onCancel={() => {}}
                    onGenereImage={() => {}}
                  />
                )}

                {/* {widgets.find(widget => widget.id === "1" && widget.show) && <FormSection />}
                {widgets.find(widget => widget.id === "2" && widget.show) && 
                  <OptimizationScore ctrChange={ctrChange} salesChange={salesChange} optimizationScore={optimizationScore} />}
                {widgets.find(widget => widget.id === "3" && widget.show) && <EditorWidget />}
                {widgets.find(widget => widget.id === "4" && widget.show) && 
                   <ReplaceBgWidget imageUrl={imageUrl || openedImages.find(imgObj => imgObj.id === id)?.img || ''} />}
                {widgets.find(widget => widget.id === "5" && widget.show) && <AIWidget />}
                {widgets.find(widget => widget.id === "6" && widget.show) && <AIEraserWidget />}
                {widgets.find(widget => widget.id === "7" && widget.show) && <HistoryWidget />} */}

                <UpdatePhotoWidget onStartGeneration={onStartGeneration} />
              </div>
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </OptimisationPageWrapper>
  );
};

export default PhotoOptimisationDetails;
