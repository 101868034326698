import { useState } from "react";
import { Link } from "react-router-dom";
import "./index.css";
import { ROUTES } from "src/router/routes.ts";
import Logo from "@assets/img/logo.tsx";
import { HeaderNav } from "@components/AppHeader/HeaderNav.tsx";
import { HeaderActions } from "@components/AppHeader/HeaderActions.tsx";
import { HeaderAvatar } from "@components/AppHeader/HeaderAvatar";
import MenuIcon from "@assets/icons/menu";
import CloseMenuIcon from "@assets/icons/closeMenu";
import { useIsMobile } from "src/utils/isMobile";

export const AppHeader = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const isMobile = useIsMobile();

  const toggleMenu = () => {
    setMenuOpen((prev) => !prev);
  };

  return (
    <header className="app-header">
      <div className="app-header__inner">
        {isMobile && (
          <button className="app-header__menu-button" onClick={toggleMenu}>
            {menuOpen ? <CloseMenuIcon /> : <MenuIcon />}
          </button>
        )}
        <Link to={ROUTES.ROOT} className="app-header__logo-link">
          <Logo />
        </Link>
        {isMobile ? (
          menuOpen && (
            <HeaderNav menuOpens={menuOpen} setMenuOpens={setMenuOpen} />
          )
        ) : (
          <HeaderNav menuOpens={true} setMenuOpens={() => {}} />
        )}
        <div className="app-header__right">
          <HeaderActions />
          <HeaderAvatar />
        </div>
      </div>
    </header>
  );
};
