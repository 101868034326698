import { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { TabButton, TabsTypeList, viewTabs } from "./utils";
import { TypeTab, ViewTab, Chart } from "./Chart";
import { Option, Select } from "@components/Select";
import { ProductsService } from "@services/amazon/products/products.service";
import { ProductWithImageLink } from "@services/amazon/products/types";

interface SanKeyProps {
  hideAsin?: boolean;
  canvaAsin?: string;
  hideTree?: boolean;
  hideTitle?: boolean;
  isFunnelChart?: boolean;
  defaultOpenTab?: TypeTab;
}

export const SanKey = ({
  hideAsin,
  canvaAsin,
  hideTree,
  hideTitle,
  isFunnelChart = false,
  defaultOpenTab,
}: SanKeyProps) => {
  const [selectedView, setSelectedView] = useState<ViewTab>("Sankey");
  const [selectedTab, setSelectedTab] = useState<TypeTab>(
    defaultOpenTab || "Campaigns",
  );
  const [asin, setAsin] = useState<string>(canvaAsin || "");
  const [asinOptions, setAsinOptions] = useState<Option[]>();

  useEffect(() => {
    if (canvaAsin) return;
    const fetchData = async () => {
      const productsService = new ProductsService();
      try {
        const data = await productsService.getOwnProducts();
        const options = data.map((product: ProductWithImageLink) => ({
          id: product.asin,
          text: product.item_name,
          img: product.image_link,
          asin: product.asin,
        }));

        setAsinOptions(options);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <div className={styles.container}>
      {!hideAsin && asinOptions && (
        <div className={styles.selectors}>
          <Select
            value={asin}
            placeholder="Select asin"
            options={asinOptions}
            onChange={(value: string) => {
              setAsin(value);
            }}
            className={styles.asin}
          />
        </div>
      )}
      {!hideTitle && (
        <div className={styles.header}>
          {!hideAsin ? <h2>Sankey View</h2> : <h3>Ads Sankey</h3>}
          {!hideTree && (
            <div className={styles.tabs}>
              {viewTabs.map((viewTab) => (
                <TabButton
                  key={viewTab.label}
                  label={viewTab.label}
                  isSelected={selectedView === viewTab.label}
                  onClick={() => setSelectedView(viewTab.label)}
                  icon={viewTab.icon}
                />
              ))}
            </div>
          )}
        </div>
      )}
      <div className={styles.tabs}>
        <TabsTypeList
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
          isFunnelChart={isFunnelChart}
        />
      </div>
      <Chart
        type={selectedTab}
        view={hideTree ? "Sankey" : selectedView}
        asin={canvaAsin}
      />
    </div>
  );
};
