export interface FormField {
  id: string;
  label: string;
  name: string;
}

export const formFields: FormField[] = [
  { id: "aspects", label: "Aspects", name: "aspects" },
  {
    id: "instructions",
    label: "Additional Instructions For AI Assistant",
    name: "instructions",
  },
  { id: "hypothesis", label: "Hypothesis Example", name: "hypothesis" },
  { id: "customerSay", label: "What Customers Say", name: "customerSay" },
];

export const widgets = [];

const list = [
  {
    label: "Width",
    value: "800",
  },
  {
    label: "Height",
    value: "800",
  },
  {
    label: "Denoising Strength",
    value: "0.95",
  },
  {
    label: "Sampler",
    value: "Euler a",
  },
  {
    label: "Scheduler",
    value: "Automatic",
  },
  {
    label: "Steps",
    value: "30",
  },
  {
    label: "CFG Scale",
    value: "7",
  },
  {
    label: "Negative Prompt",
    value: "",
  },
  {
    label: "Model",
    value: "scl_xl_base_1.0_impainting_0.1.safetensors",
  },
  {
    label: "Invert Mask",
    value: "Yes",
  },
];

export const templatesMock = [
  {
    label: "Template 1",
    list,
  },
  {
    label: "Template 2",
    list,
  },
  {
    label: "Template 3",
    list,
  },
  {
    label: "Template 4",
    list,
  },
  {
    label: "Template 5",
    list,
  },
  {
    label: "Template 6",
    list,
  },
  {
    label: "Template 7",
    list,
  },
];
