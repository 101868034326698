import React, { useEffect, useState, useRef } from "react";
import styles from "./styles.module.scss";
import inputArrow from "@assets/icons/inputArrow.svg";
import ProductSelectionModal from "@pages/Overview/components/ProductSelectionModal";
import { useProductStore } from "src/store/overviewProduct.state";
import { Product } from "@services/amazon/products/types";
import { useInsightsStore } from "src/store/insights/insights.state";
import { loadInsideProductData } from "src/utils/insides";
import { SpiderService } from "@services/spider/spider.services";
import { formattedKeys } from "src/utils/convertUtil";
import { OverviewType } from "@pages/Overview";
import MultiSelectModal from "@components/Multiselect";

interface ProductInputProps {
  productTwo?: boolean;
  label: string;
  isProductSelected?: boolean;
  selectType?: "your" | "competitor";
  type?: OverviewType;
  onProductSelect?: any;
}

const ProductInput: React.FC<ProductInputProps> = ({
  label,
  isProductSelected,
  selectType = "your",
  productTwo = false,
  type = "my-product",
}) => {
  const { selectedProduct, selectedProductToCompare } = useProductStore();

  const {
    setProductTwoAsin,
    setProductOneSpiderValues,
    setProductOneHypotheses,
    setProductOnePhrases,
    setProductOneProblems,
    setProductOneReviews,
    setProductTwoSpiderValues,
    setProductTwoHypotheses,
    setProductTwoPhrases,
    setProductTwoProblems,
    setProductTwoReviews,
    setProductTwoSpiderTitles,
    setProductOneSpiderTitles,
  } = useInsightsStore();

  const determineProduct = (
    selectType: "your" | "competitor",
    selectedProduct: Product | null,
    selectedProductToCompare: Product[] | null,
  ): Product | null => {
    if (selectType === "your") {
      return selectedProduct;
    }

    if (
      Array.isArray(selectedProductToCompare) &&
      selectedProductToCompare.length > 0
    ) {
      return selectedProductToCompare[0];
    }

    return null;
  };
  const [isModalOpen, setModalOpen] = useState(false);
  const [isSecondModalOpen, setSecondModalOpen] = useState(false);
  const [hasSelectedOnce, setHasSelectedOnce] = useState(false);
  const [asinQueue, setAsinQueue] = useState<string[]>([]);
  const prevProductRef = useRef<Product | null>(null);
  const prevAsinRef = useRef<string | null>(null);

  const openFirstModal = () => setModalOpen(true);
  const openSecondModal = () => setSecondModalOpen(true);

  const product = determineProduct(
    selectType,
    selectedProduct,
    selectedProductToCompare,
  );

  useEffect(() => {
    const fetchInsights = async () => {
      if (
        hasSelectedOnce &&
        prevProductRef.current !== product &&
        product?.asin
      ) {
        try {
          const { hypotheses, phrases, problems, reviews } =
            await loadInsideProductData(product);
          const spider = new SpiderService();
          const spiderDots = await spider.getSpiderInfo(product.asin, type);

          setProductTwoAsin([product.asin]);
          if (!productTwo) {
            setProductTwoSpiderTitles(formattedKeys(spiderDots));
            setProductOneSpiderValues(
              Object.values(spiderDots).map((value) =>
                parseFloat(value.toFixed(1)),
              ),
            );
            setProductOneHypotheses(hypotheses);
            setProductOnePhrases(phrases);
            setProductOneProblems(problems);
            setProductOneReviews(reviews);
          } else {
            setProductOneSpiderTitles(formattedKeys(spiderDots));
            setProductTwoSpiderValues(
              Object.values(spiderDots).map((value) =>
                parseFloat(value.toFixed(1)),
              ),
            );
            setProductTwoHypotheses(hypotheses);
            setProductTwoPhrases(phrases);
            setProductTwoProblems(problems);
            setProductTwoReviews(reviews);
          }
        } catch (error) {
          console.error(
            `Error fetching insights for product ${product.asin}:`,
            error,
          );
        }
      }
    };

    fetchInsights();
    const processNextAsin = async () => {
      if (asinQueue.length > 0) {
        const nextAsin = asinQueue[0];
        if (nextAsin !== prevAsinRef.current) {
          await fetchInsights();
          prevAsinRef.current = nextAsin;
          setAsinQueue((prevQueue) => prevQueue.slice(1));
        }
      }
    };

    processNextAsin();
  }, [product, hasSelectedOnce]);

  useEffect(() => {
    if (!product && isProductSelected === false) {
      setModalOpen(true);
    }
  }, [product, isProductSelected]);

  const closeModal = () => {
    setModalOpen(false);
    setSecondModalOpen(false);
  };

  const getInputValue = (product: Product | Product[] | null): string => {
    if (!product) return "";
    if (Array.isArray(product)) {
      return product.length > 0
        ? product[0].item_name || product[0].title || ""
        : "";
    } else {
      return product.item_name || product.title || "";
    }
  };

  const handleProductSelect = (selectedProduct: Product | Product[]) => {
    if (selectType === "competitor") {
      setHasSelectedOnce(true);
      if (Array.isArray(selectedProduct)) {
        selectedProduct.forEach((product) => {
          prevProductRef.current = product;
        });
      } else {
        prevProductRef.current = selectedProduct;
      }
      setModalOpen(false);
      closeModal();
    }
  };

  return (
    <div className={styles.inputBox}>
      <h4 className={styles.title}>{label}</h4>

      {!productTwo && (
        <>
          <button onClick={openFirstModal} className={styles.inputContainer}>
            <input
              className={styles.inputText}
              value={product ? product.item_name || product.title : ""}
              readOnly
            />
            <div className={styles.dropdownIcon}>
              <img
                src={inputArrow}
                width={12}
                height={12}
                alt="Dropdown Icon"
              />
            </div>
          </button>
          <ProductSelectionModal
            isOpen={isModalOpen}
            onSelect={handleProductSelect}
            onClose={closeModal}
            type={type}
          />
        </>
      )}
      {productTwo && (
        <div
          className={styles.inputContainerWrapper}
          style={{ position: "relative" }}
        >
          <button onClick={openSecondModal} className={styles.inputContainer}>
            <input
              className={styles.inputText}
              value={getInputValue(product)}
              readOnly
            />
            <div className={styles.dropdownIcon}>
              <img
                src={inputArrow}
                width={12}
                height={12}
                alt="Dropdown Icon"
              />
            </div>
          </button>
          <MultiSelectModal
            isOpen={isSecondModalOpen}
            onSelect={handleProductSelect}
            onClose={closeModal}
          />
        </div>
      )}
    </div>
  );
};

export default ProductInput;
