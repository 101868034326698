export default () => {
  return (
    <svg
      width="22"
      height="23"
      viewBox="0 0 22 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_7628_206002)">
        <path
          d="M21.4081 9.51674L19.1383 8.98091C18.9727 8.40854 18.7488 7.86213 18.4666 7.35115L19.6907 5.36994C19.8781 5.06663 19.8322 4.67493 19.5804 4.42313L18.2682 3.111C18.0169 2.85962 17.6255 2.81372 17.3219 3.00108L15.3414 4.22556C14.83 3.94333 14.2836 3.7191 13.7117 3.55389L13.1759 1.28293C13.0938 0.936762 12.7841 0.691406 12.4281 0.691406H10.5726C10.2166 0.691406 9.90732 0.936762 9.82529 1.28293L9.28946 3.55393C8.71709 3.71914 8.17072 3.94267 7.65971 4.2256L5.67849 3.00042C5.37555 2.81376 4.98348 2.85929 4.73168 3.11104L3.41959 4.4228C3.16821 4.67452 3.12231 5.06699 3.3093 5.36953L4.53415 7.35111C4.25192 7.86209 4.02765 8.4085 3.86285 8.98009L1.59153 9.51596C1.24536 9.59877 1 9.9077 1 10.264V12.1192C1 12.4752 1.24536 12.7853 1.59153 12.8669L3.86289 13.4028C4.0281 13.9747 4.25196 14.5207 4.53419 15.0314L3.30897 17.0133C3.12231 17.3166 3.16784 17.7087 3.41959 17.9608L4.73136 19.2722C4.98311 19.5243 5.37555 19.5698 5.67808 19.3825L7.66004 18.1573C8.17146 18.4399 8.71783 18.6638 9.28942 18.8286L9.82525 21.0995C9.90728 21.4468 10.2166 21.6914 10.5726 21.6914H12.4281C12.7841 21.6914 13.0938 21.4468 13.1758 21.0995L13.7116 18.8286C14.2832 18.6638 14.8296 18.4399 15.3403 18.1573L17.3219 19.3821C17.6255 19.5695 18.0169 19.5236 18.269 19.2719L19.5808 17.9597C19.8321 17.7083 19.8781 17.3162 19.6906 17.0129L18.4658 15.0317C18.7487 14.5211 18.9723 13.9747 19.1375 13.4027L21.4084 12.8669C21.7558 12.7848 22 12.4755 22 12.1192V10.2637C22 9.90807 21.7554 9.59877 21.4081 9.51674ZM11.4998 16.9877C8.30054 16.9877 5.70445 14.3917 5.70445 11.1912C5.70445 7.99046 8.30058 5.39475 11.4998 5.39475C14.7002 5.39475 17.2963 7.99046 17.2963 11.1912C17.2963 14.3917 14.7002 16.9877 11.4998 16.9877Z"
          fill="#7C7C78"
        />
        <path
          d="M11.4978 9.0918C10.2286 9.0918 9.19922 10.1215 9.19922 11.3909C9.19922 12.6604 10.2286 13.6901 11.4978 13.6901C12.7672 13.6901 13.7969 12.6604 13.7969 11.3909C13.7969 10.1215 12.7672 9.0918 11.4978 9.0918Z"
          fill="#7C7C78"
        />
      </g>
      <defs>
        <clipPath id="clip0_7628_206002">
          <rect
            width="22"
            height="22"
            fill="white"
            transform="translate(0 0.191406)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
