import { RangeCard } from "@pages/AdsAnalitics/components/Campaigns/components/AdSpend/RangeCard";
import styles from "./styles.module.scss";
import { useState } from "react";
import {
  Area,
  AreaChart,
  CartesianGrid,
  Label,
  ReferenceLine,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";
import { useCanvaStore } from "src/store/canva.state";

const data = [
  { rank: 1, sales: 50000.0, ctr: 25.0, cvr: 23.0 },
  { rank: 2, sales: 48511.36, ctr: 24.75, cvr: 22.72 },
  { rank: 3, sales: 47027.8, ctr: 24.5, cvr: 22.45 },
  { rank: 4, sales: 45549.14, ctr: 24.26, cvr: 22.17 },
  { rank: 5, sales: 44075.21, ctr: 24.02, cvr: 21.9 },
  { rank: 6, sales: 42605.86, ctr: 23.78, cvr: 21.63 },
  { rank: 7, sales: 41140.92, ctr: 23.54, cvr: 21.36 },
  { rank: 8, sales: 39680.21, ctr: 23.31, cvr: 21.1 },
  { rank: 9, sales: 38223.56, ctr: 23.07, cvr: 20.84 },
  { rank: 10, sales: 36770.82, ctr: 22.84, cvr: 20.57 },
  { rank: 11, sales: 35321.82, ctr: 22.61, cvr: 20.31 },
  { rank: 12, sales: 33876.41, ctr: 22.39, cvr: 20.06 },
  { rank: 13, sales: 32434.42, ctr: 22.16, cvr: 19.8 },
  { rank: 14, sales: 30995.7, ctr: 21.94, cvr: 19.55 },
  { rank: 15, sales: 29560.09, ctr: 21.72, cvr: 19.3 },
  { rank: 16, sales: 28127.43, ctr: 21.5, cvr: 19.05 },
  { rank: 17, sales: 26697.57, ctr: 21.28, cvr: 18.8 },
  { rank: 18, sales: 25270.36, ctr: 21.06, cvr: 18.55 },
  { rank: 19, sales: 23845.64, ctr: 20.85, cvr: 18.31 },
  { rank: 20, sales: 22423.25, ctr: 20.63, cvr: 18.06 },
  { rank: 21, sales: 21003.05, ctr: 20.42, cvr: 17.82 },
  { rank: 22, sales: 19584.87, ctr: 20.21, cvr: 17.58 },
  { rank: 23, sales: 18168.56, ctr: 20.0, cvr: 17.34 },
  { rank: 24, sales: 16753.96, ctr: 19.8, cvr: 17.11 },
  { rank: 25, sales: 15340.92, ctr: 19.59, cvr: 16.87 },
  { rank: 26, sales: 13929.28, ctr: 19.39, cvr: 16.64 },
  { rank: 27, sales: 12518.89, ctr: 19.18, cvr: 16.4 },
  { rank: 28, sales: 11109.6, ctr: 18.98, cvr: 16.17 },
  { rank: 29, sales: 9701.25, ctr: 18.78, cvr: 15.94 },
  { rank: 30, sales: 8293.7, ctr: 18.58, cvr: 15.71 },
  { rank: 31, sales: 6886.78, ctr: 18.39, cvr: 15.49 },
  { rank: 32, sales: 5480.35, ctr: 18.19, cvr: 15.26 },
  { rank: 33, sales: 4074.26, ctr: 18.0, cvr: 15.04 },
  { rank: 34, sales: 2668.36, ctr: 17.81, cvr: 14.81 },
  { rank: 35, sales: 1262.5, ctr: 17.62, cvr: 14.59 },
  { rank: 36, sales: 856.55, ctr: 17.43, cvr: 14.37 },
  { rank: 37, sales: 450.35, ctr: 17.24, cvr: 14.15 },
  { rank: 38, sales: 44.06, ctr: 17.05, cvr: 13.93 },
  { rank: 39, sales: 23.55, ctr: 16.87, cvr: 13.71 },
  { rank: 40, sales: 12.99, ctr: 16.68, cvr: 13.5 },
  { rank: 41, sales: 7.16, ctr: 16.5, cvr: 13.28 },
  { rank: 42, sales: 3.95, ctr: 16.32, cvr: 13.07 },
  { rank: 43, sales: 2.11, ctr: 16.14, cvr: 12.86 },
  { rank: 44, sales: 1.14, ctr: 15.96, cvr: 12.65 },
  { rank: 45, sales: 0.62, ctr: 15.78, cvr: 12.44 },
  { rank: 46, sales: 0.34, ctr: 15.6, cvr: 12.23 },
  { rank: 47, sales: 0.17, ctr: 15.43, cvr: 12.03 },
  { rank: 48, sales: 0.09, ctr: 15.25, cvr: 11.82 },
  { rank: 49, sales: 0.05, ctr: 15.08, cvr: 11.62 },
  { rank: 50, sales: 0.02, ctr: 14.9, cvr: 11.42 },
];

export const OrganicChart: React.FC = () => {
  const { isWhatIf } = useCanvaStore();
  const [estimate, setEstimate] = useState<number>(0);

  const handleEstimateChange = (value: [number, number]) => {
    setEstimate(value[0] === 0 ? value[1] : value[0]);
  };

  return (
    <div className={styles.container}>
      <RangeCard
        title="Estimated AVG Organic Rank"
        value={estimate}
        onChange={handleEstimateChange}
        min={0}
        max={50}
        isWhatIf={isWhatIf}
      />
      <div className={styles.chart}>
        <div className={styles.legend}>
          <div className={styles.legend__item}>
            <div
              className={styles.legend__color}
              style={{ background: "#5295E0" }}
            />
            <span>Organic CTR</span>
          </div>
          <div className={styles.legend__item}>
            <div
              className={styles.legend__color}
              style={{ background: "#F19867" }}
            />
            <span>Organic CVR</span>
          </div>
          <div className={styles.legend__item}>
            <div
              className={styles.legend__color}
              style={{ background: "#6B4EB9" }}
            />
            <span>Organic sales</span>
          </div>
        </div>
        <ResponsiveContainer width="100%" height={368}>
          <AreaChart data={data} margin={{ right: 40, bottom: 20, left: 20 }}>
            <CartesianGrid
              stroke="#1F1F20"
              vertical={false}
              horizontal={true}
            />
            <XAxis
              dataKey="rank"
              tickLine={false}
              axisLine={false}
              style={{ fill: "#fff" }}
            >
              <Label
                value="Organic Rank"
                offset={-14}
                position="insideBottom"
                style={{ textAnchor: "middle", fill: "#fff", fontSize: 12 }}
              />
            </XAxis>
            <YAxis
              yAxisId="left"
              orientation="left"
              stroke="#7C7C78"
              strokeWidth={2}
              tickLine={false}
              style={{ fill: "#fff" }}
              tickFormatter={(tick) => `${tick}%`}
            >
              <Label
                value="Organic CTR & Organic CVR"
                angle={-90}
                position="insideLeft"
                style={{ textAnchor: "middle", fill: "#fff", fontSize: 12 }}
              />
            </YAxis>
            <YAxis
              yAxisId="right"
              orientation="right"
              stroke="#7C7C78"
              strokeWidth={2}
              tickLine={false}
              tickFormatter={(tick) => `$${tick / 1000}K`}
              style={{ fill: "#fff", left: "-10px" }}
            >
              <Label
                value="Organic Sales"
                angle={90}
                offset={-10}
                position="insideRight"
                style={{ textAnchor: "middle", fill: "#fff", fontSize: 12 }}
              />
            </YAxis>
            <Area
              type="monotone"
              dataKey="sales"
              stroke="#6B4EB9"
              strokeWidth={2}
              fill="url(#colorSales)"
              yAxisId="right"
            />
            <Area
              type="monotone"
              dataKey="ctr"
              stroke="#5295E0"
              fill="url(#ctr)"
              yAxisId="left"
            />
            <Area
              type="monotone"
              dataKey="cvr"
              stroke="#F19867"
              fill="url(#cvr)"
              yAxisId="left"
            />
            <defs>
              <linearGradient
                id="colorSales"
                x1="546.5"
                y1="1"
                x2="546.5"
                y2="455"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#6B4EB9" />
                <stop offset="0.552554" stop-color="#6B4EB9" stop-opacity="0" />
              </linearGradient>
            </defs>
            <defs>
              <linearGradient
                id="cvr"
                x1="546.75"
                y1="1"
                x2="546.75"
                y2="435.525"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#F19867" />
                <stop offset="0.519933" stop-color="#F19867" stop-opacity="0" />
              </linearGradient>
            </defs>
            <defs>
              <linearGradient
                id="ctr"
                x1="546.5"
                y1="0.998047"
                x2="546.5"
                y2="341.998"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#5295E0" />
                <stop offset="0.443705" stop-color="#5295E0" stop-opacity="0" />
              </linearGradient>
            </defs>
            <ReferenceLine
              x={32}
              stroke="#B5B5B5"
              strokeDasharray="3 3"
              yAxisId="left"
              label={{
                value: "Current Value",
                position: "insideTop",
                offset: 16,
                style: { fill: "#B5B5B5", fontSize: 12, textAnchor: "start" },
              }}
            />
            {isWhatIf && (
              <ReferenceLine
                x={estimate}
                stroke="#80C67A"
                strokeDasharray="3 3"
                yAxisId="left"
                label={{
                  value: "Nyle Optimize",
                  position: "insideTop",
                  offset: 16,
                  style: {
                    fill: "#80C67A",
                    fontSize: 12,
                    textAnchor: "start",
                  },
                }}
              />
            )}
          </AreaChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};
