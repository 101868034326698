import React, { useEffect, useMemo, useState } from "react";
import { Checkbox } from "antd";
import styles from "./style.module.scss";
import { SpiderService } from "@services/spider/spider.services";
import { AmazonService } from "@services/amazon/amazon.service";
import { useProductStore } from "src/store/overviewProduct.state";
import { useInsightsStore } from "src/store/insights/insights.state";
import { CategoriesService } from "@services/amazon/categories/categories.service";
import ProductCode from "@components/ProductCode";
import ModalBox from "@components/ModalBox";
import classNames from "classnames";
import { ProductTab } from "@pages/Overview/components/ProductSelectionModal";
import { ProductName } from "@components/ProductName";
import SearchBar from "@components/Search";
import {
  Product,
  ProductWithSpiderData,
} from "@services/amazon/products/types";
import {
  capitalizeWords,
  extractAllAsins,
  fetchProducts,
  getItemsToDisplay,
} from "./utils";
import { useCategoryStore } from "src/store/category";
import {
  getPaginationRange,
  getTabs,
} from "@pages/Overview/components/ProductSelectionModal/constant";

interface ProductSelectionDropdownProps {
  isOpen: boolean;
  onSelect: (selectedProducts: ProductWithSpiderData[]) => void;
  onClose: () => void;
  productTwo?: boolean;
}

const MultiSelectModal: React.FC<ProductSelectionDropdownProps> = ({
  isOpen,
  onSelect,
  onClose,
}) => {
  const TABS = [{ tab: "allProducts", label: "OTHER PRODUCTS" }];

  const [selectedProducts, setSelectedProducts] = useState<string[]>([]);
  const [itemsPerPage, setItemsPerPage] = useState(8);
  const [activeTab, setActiveTab] = useState<ProductTab>(
    TABS[0].tab as ProductTab,
  );
  const [spiders, setSpiders] = useState<any>(null);
  const [searchTerm, setSearchTerm] = useState("");
  const {
    selectedProduct,
    selectedProductToCompare,
    setSelectedProductToCompare,
    selectedProductToCompareMultiselect,
  } = useProductStore();
  const { setProductTwoSpiderValues, setProductTwoSpiderTitles } =
    useInsightsStore();

  const [currentPage, setCurrentPage] = useState(1);
  const [isCategorySelected, setIsCategorySelected] = useState(false);
  const [searchResults, setSearchResults] = useState<Product[]>([]);
  const [totalItems, setTotalItems] = useState(0);
  const [paginationStart, setPaginationStart] = useState(1);
  const [selectedItems, setSelectedItems] = useState<any[]>([]);

  const { selectedCategory, setSelectedCategory } = useCategoryStore();
  const tabs = getTabs(selectedProductToCompare);

  const paginatedItems = useMemo(() => {
    if (!selectedCategory || !selectedCategory.items) return [];
    const startIndex = (currentPage - 1) * itemsPerPage;
    return selectedCategory.items.slice(startIndex, startIndex + itemsPerPage);
  }, [selectedCategory, currentPage, itemsPerPage]);

  useEffect(() => {
    const params = {
      asin: searchTerm,
      limit: itemsPerPage,
    };

    if (isOpen) {
      fetchProducts(activeTab, params, setSearchResults);
    }
  }, [isOpen, activeTab, itemsPerPage, searchTerm]);

  useEffect(() => {
    const fetchCategories = async () => {
      const categoryService = new CategoriesService();
      if (activeTab === "allProducts") {
        const categories = await categoryService.getCategories(
          selectedProduct?.asin,
        );
        setSpiders(categories);
      }
    };

    if (isOpen) {
      fetchCategories();
    }
  }, [isOpen, activeTab, selectedProduct]);

  const handleProductSelection = (asin: string) => {
    setSelectedProducts((prevSelected) =>
      prevSelected.includes(asin)
        ? prevSelected.filter((item) => item !== asin)
        : [...prevSelected, asin].slice(0, 6),
    );
  };

  const handleApply = async () => {
    if (selectedProducts.length > 0) {
      const apiResponses = [];
      const spider = new SpiderService();
      const additionalAsins = selectedProductToCompare
        .slice(0, 1)
        .map((product) => product.asin)
        .filter(Boolean);

      const allAsinsSet = new Set([...selectedProducts]);
      const allAsinSet = new Set([
        additionalAsins,
        ...selectedProducts,
        selectedProduct?.asin,
      ]);
      const allAsins = Array.from(allAsinsSet);
      const allAsin = Array.from(allAsinSet)
        .flat()
        .filter((asin): asin is string => typeof asin === "string");

      let spiderDotsCached = null;
      let spiderDataCached = null;

      try {
        setSelectedProductToCompare([]);
        if (allAsins.length > 0) {
          spiderDotsCached = await AmazonService.products.postPublicProduct(
            ...allAsins,
          );
        }

        if (allAsins.length > 0) {
          spiderDataCached = await spider.getSpiderDataList(...allAsin);
        }
        const keys = Object.keys(spiderDataCached);

        await handleOverallScore(keys);
        const spiderValues = Object.values(spiderDataCached).map((value) =>
          typeof value === "number" ? parseFloat(value.toFixed(1)) : 0,
        );

        const spiderTitles = Object.keys(spiderDataCached).map((key) =>
          capitalizeWords(key.replace(/_/g, " ")),
        );
        apiResponses.push(
          ...apiResponses,
          ...selectedProducts.map((asin) => ({
            asin,
            spiderDots: spiderDotsCached,
            spiderData: spiderDataCached,
            spiderValues,
            spiderTitles,
          })),
        );
      } catch (error) {
        console.error("Error fetching data for ASINs:", error);
      }

      if (apiResponses.length > 0) {
        setProductTwoSpiderTitles(apiResponses[0].spiderTitles);
        setProductTwoSpiderValues(apiResponses[0].spiderValues);
        selectedProductToCompareMultiselect(apiResponses);
        onSelect(apiResponses);
        onClose();
      }
    } else {
      console.log("No products selected");
    }
  };

  const fetchCategories = async () => {
    const spider = new CategoriesService();
    const spidersData = await spider.getCategories(selectedProduct?.asin);
    setSpiders(spidersData);
  };

  useEffect(() => {
    if (isOpen) {
      fetchCategories();
    }
  }, [isOpen]);
  const handleOverallScore = async (keys: string[]) => {
    const spider = new SpiderService();

    try {
      let allAsin: string[] = [];

      if (Array.isArray(selectedProductToCompare)) {
        allAsin = extractAllAsins(selectedProductToCompare);
      }
      if (allAsin.length > 0 && selectedProduct?.asin) {
        const overall = await spider.getDifferenceOverallScore(
          allAsin,
          selectedProduct.asin,
          keys,
        );
      }
    } catch (error) {
      console.error("Error in handleOverallScore:", error);
    }
  };

  const handleCategoryClick = (index: number) => {
    const category = spiders[index];
    setSelectedItems(spiders[index].items);
    setSelectedCategory({
      id: category.id,
      name: category.Sub_category,
      items: category.items,
    });
    setIsCategorySelected(true);
    setCurrentPage(1);
    setTotalItems(category.items.length);
  };

  const handleBackClick = () => {
    setIsCategorySelected(false);
  };

  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const itemsToDisplay = getItemsToDisplay(
    searchTerm,
    searchResults,
    paginatedItems,
  );
  const handleEllipsisClick = (type: "next" | "prev") => {
    const maxVisiblePages = 5;
    if (type === "next") {
      setPaginationStart((prev) =>
        Math.min(prev + maxVisiblePages, totalPages - maxVisiblePages + 1),
      );
    } else if (type === "prev") {
      setPaginationStart((prev) => Math.max(prev - maxVisiblePages, 1));
    }
  };
  const renderTabButton = (tab: ProductTab, label: string) => (
    <button
      key={tab}
      className={classNames(styles.tabButton, {
        [styles.active]: activeTab === tab,
      })}
      onClick={() => setActiveTab(tab as ProductTab)}
    >
      {label.toUpperCase()}
    </button>
  );
  const renderSwitchButton = (value: number) => (
    <button
      key={value}
      className={classNames(styles.switchButton, {
        [styles.active]: itemsPerPage === value,
      })}
      onClick={() => handleSwitchItemsPerPage(value)}
    >
      {value}
    </button>
  );
  const handleSwitchItemsPerPage = (value: number) => {
    setItemsPerPage(value);
    setCurrentPage(1);
  };
  return (
    <ModalBox isOpen={isOpen} onClose={onClose} title="Choose ASIN">
      <div className={styles.tabButtons}>
        <div className={styles.tabButtonsWrapper}>
          <button className={classNames(styles.tabButton, styles.active)}>
            OTHER PRODUCTS
          </button>
        </div>
      </div>

      <SearchBar searchTerm={searchTerm} setSearchTerm={setSearchTerm} />

      <div className={styles.productGridContainer}>
        <div className={styles.backButton}>
          {isCategorySelected && (
            <button onClick={handleBackClick} className={styles.bcButton}>
              Back to Categories
            </button>
          )}
          <div className={styles.switchButtons}>
            {[8, 16, 32].map(renderSwitchButton)}
          </div>
        </div>
        {isCategorySelected ? (
          <div className={styles.productGridContainer}>
            <div className={styles.productGrid}>
              {itemsToDisplay && itemsToDisplay.length > 0 ? (
                itemsToDisplay.map((item: Product, itemIndex: number) => (
                  <div
                    key={`${activeTab}-${item.asin}-${itemIndex}`}
                    className={styles.productCard}
                  >
                    <img
                      src={item.picture_url || item.image || ""}
                      alt={item.title}
                      className={styles.productImage}
                      width={124}
                      height={124}
                    />
                    <ProductName name={item.title} />
                    <div className={styles.productDetails}>
                      <ProductCode code={item.asin} />
                    </div>
                    <div className={styles.productDetails}>
                      <span>⭐ {item.score}</span>
                    </div>
                    <Checkbox
                      checked={selectedProducts.includes(item.asin)}
                      disabled={
                        selectedProducts.length >= 5 &&
                        !selectedProducts.includes(item.asin)
                      }
                      onChange={() => handleProductSelection(item.asin)}
                    >
                      SELECT
                    </Checkbox>
                  </div>
                ))
              ) : (
                <p>Loading...</p>
              )}
            </div>
          </div>
        ) : (
          <div className={styles.categoryListWrapper}>
            {spiders?.map((category, index) => (
              <div
                key={index}
                className={styles.categoryItem}
                onClick={() => handleCategoryClick(index)}
              >
                <span>{category.Sub_category}</span>
              </div>
            ))}
          </div>
        )}
      </div>

      {isCategorySelected && (
        <div className={styles.paginationWrapper}>
          <button
            className={classNames(styles.paginationArrow, {
              [styles.disabled]: currentPage === 1,
            })}
            onClick={() => {
              if (currentPage > 1) {
                const newPage = currentPage - 1;
                setCurrentPage(newPage);

                if (newPage < paginationStart) {
                  setPaginationStart((prev) => Math.max(prev - 5, 1));
                }
              }
            }}
            disabled={currentPage === 1}
          >
            &lt;
          </button>
          <div>
            {getPaginationRange(currentPage, totalPages, paginationStart).map(
              (page, index) =>
                typeof page === "number" ? (
                  <button
                    key={index}
                    className={classNames(styles.paginationButton, {
                      [styles.active]: currentPage === page,
                    })}
                    onClick={() => setCurrentPage(page)}
                  >
                    {page}
                  </button>
                ) : (
                  <span
                    key={index}
                    className={styles.paginationEllipsis}
                    onClick={() =>
                      page === "..."
                        ? paginationStart + 5 <= totalPages
                          ? handleEllipsisClick("next")
                          : handleEllipsisClick("prev")
                        : null
                    }
                  >
                    {page}
                  </span>
                ),
            )}
          </div>
          <button
            className={classNames(styles.paginationArrow, {
              [styles.disabled]: currentPage === totalPages,
            })}
            onClick={() => {
              if (currentPage < totalPages) {
                const newPage = currentPage + 1;
                setCurrentPage(newPage);

                if (newPage > paginationStart + 4) {
                  setPaginationStart((prev) =>
                    Math.min(prev + 5, totalPages - 4),
                  );
                }
              }
            }}
            disabled={currentPage === totalPages}
          >
            &gt;
          </button>
        </div>
      )}
      <button className={styles.applyButton} onClick={handleApply}>
        Apply
      </button>
    </ModalBox>
  );
};

export default MultiSelectModal;
