export const optimizationContent = [
  {
    title: "Product Optimization Score",
    description:
      "Overall quality score for assessing product performance based on sentiment analysis insights, content score and pricing, which together define Product Market Fit",
  },
  {
    title: "Score",
    description:
      "Our Full Product Optimization Score indicates good quality with the potential for further improvements",
  },
];
