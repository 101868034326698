import { Carousel } from "antd";
import CampaignSliderLeft from "@assets/icons/campaignSliderLeft";
import CampaignSliderRight from "@assets/icons/campaignSliderRight";
import {
  currentColumns,
  currentData,
  missingColumns,
  missingData,
  slides as slidesMock,
} from "./mock";
import { useState } from "react";
import classNames from "classnames";
import styles from "./styles.module.scss";
import "./slider.scss";
import ModalBox from "@components/ModalBox";
import { ExpandableTable } from "@components/ExpandebleTable";
import { Key } from "antd/lib/table/interface";
import bg from "@assets/img/modal-bg.png";
import CalendarStar from "@assets/icons/calendarStar";
import Circle from "@assets/icons/circle";
import { useAdsAnaliticsStore } from "src/store/ads-analitics.store";
import Calendar from "@assets/icons/calendar";
import assigneeUser from "@assets/img/Photo.png";
import { CollapseableItem } from "@components/CollapseableItem";
import { TabsTypeList, TypeTab } from "./Tabs";

export const SliderKey = () => {
  const { keys } = useAdsAnaliticsStore();
  const [activeSlideId, setActiveSlideId] = useState(slidesMock[0]?.id || null);
  const [isOpen, setIsOpen] = useState(false);
  const [slides, setSlides] = useState(slidesMock);
  const [currentTab, setCurrentTab] = useState<TypeTab>("Missing targets");

  const [selectedCampKeys, setSelectedCampKeys] = useState([]);
  const [selectedTargetingKeys, setSelectedTargetingKeys] = useState([]);
  const [selectedTargetKeys, setSelectedTargetKeys] = useState([]);

  const getRowSelectionFunction = () => {
    if (activeSlideId === 0) {
      return {
        selectedRowKeys: selectedCampKeys,
        onChange: (selectedRowKeys: Key[]) =>
          setSelectedCampKeys(selectedRowKeys),
      };
    }
    if (activeSlideId === 1) {
      return {
        selectedRowKeys: selectedTargetingKeys,
        onChange: (selectedRowKeys: Key[]) =>
          setSelectedTargetingKeys(selectedRowKeys),
      };
    }

    return {
      selectedRowKeys: selectedTargetKeys,
      onChange: (selectedRowKeys: Key[]) =>
        setSelectedTargetKeys(selectedRowKeys),
    };
  };

  const handleBeforeChange = (_, newIndex: number) => {
    setActiveSlideId(slides[newIndex]?.id || null);
  };

  const handleViewDetails = () => {
    setIsOpen(true);
  };

  const onApply = () => {
    setSlides((prev) => {
      const newSlides = prev.filter((slide) => slide.id !== activeSlideId);
      setActiveSlideId(newSlides[0]?.id || null);
      return newSlides;
    });
  };

  if (!slides.length) {
    return null;
  }

  const activeSlideData = slides.find((slide) => slide.id === activeSlideId);

  return (
    <div className={styles.container}>
      <Carousel
        arrows
        dots={{
          className: classNames(styles.dots, "slick-dots-custom"),
        }}
        className="campaign-slider-custom"
        beforeChange={handleBeforeChange}
        prevArrow={
          <button>
            <CampaignSliderLeft />
          </button>
        }
        nextArrow={
          <button>
            <CampaignSliderRight />
          </button>
        }
      >
        {slides.map((slide) => (
          <div key={slide.id} className={styles.slide}>
            <div className={styles.slide__wrapper}>
              <div className={styles.slide__content}>
                <h2 className={styles.slide__title}>{slide.title}</h2>
                <p className={styles.slide__text}>{slide.content}</p>
              </div>
              <div className={styles.slide__action}>
                <button
                  className={styles.details}
                  onClick={() => {
                    handleViewDetails();
                  }}
                >
                  View Details
                </button>
                <button className={styles.apply} onClick={onApply}>
                  Apply
                </button>
              </div>
            </div>
          </div>
        ))}
      </Carousel>
      <ModalBox
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        title={
          activeSlideId === 1
            ? "Optimization recommendations"
            : "Recommendations"
        }
        className={styles.modal}
      >
        {activeSlideId === 1 && (
          <div className={styles.modal__container}>
            <div className={styles.bg}>
              <img src={bg} alt="" />
            </div>
            <div className={styles.subtitle}>
              <span>
                <CalendarStar /> Todo
              </span>
              <Circle />
              <span>≈ 5 min to complete</span>
              <Circle />
              <span>3 steps</span>
            </div>
            <div className={styles.form}>
              <p>
                Lorem ipsum dolor sit amet consectetur adipiscing elit Ut et
                massa mi. Aliquam in hendrerit urna. Pellentesque sit amet
                sapien fringilla, mattis ligula consectetur, ultrices mauris.
              </p>
              <div className={classNames(styles.card, styles.targets)}>
                <p className={styles.card__title}>Selected targets</p>
                <p>
                  {Object.values(keys).flat().length > 0 &&
                    Object.values(keys)
                      .flat()
                      .map((key, id, arr) => (
                        <span className={styles.keys__item}>
                          {key}
                          {id !== arr.length - 1 && ", "}
                        </span>
                      ))}
                </p>
              </div>
              <div className={styles.info}>
                <div className={styles.card}>
                  <p className={styles.card__title}>Due Date</p>
                  <p className={styles.info__text}>
                    <Calendar /> Today
                  </p>
                </div>
                <div className={styles.card}>
                  <p className={styles.card__title}>Assignee</p>
                  <p className={styles.info__text}>
                    <img src={assigneeUser} alt="user" /> You
                  </p>
                </div>
              </div>
              <CollapseableItem
                header="Step 1"
                content="Lorem ipsum dolor sit amet consectetur adipiscing elit Ut et massa mi. Aliquam in hendrerit urna."
              />
              <CollapseableItem
                header="Step 2"
                content="Lorem ipsum dolor sit amet consectetur adipiscing elit Ut et massa mi. Aliquam in hendrerit urna."
              />
              <CollapseableItem
                header="Step 3"
                content="Lorem ipsum dolor sit amet consectetur adipiscing elit Ut et massa mi. Aliquam in hendrerit urna."
              />
            </div>
            <div className={classNames(styles.card, styles.outcome)}>
              <p className={styles.card__title}>Predicted outcome</p>
              <p>
                Lorem ipsum dolor sit amet consectetur adipiscing elit Ut et
                massa mi. Aliquam in hendrerit urna. Pellentesque sit amet
                sapien fringilla, mattis ligula consectetur, ultrices mauris.
                Maecenas vitae mattis tellus..
              </p>
            </div>
            <button className={styles.apply}>Apply</button>
          </div>
        )}
        {activeSlideId === 2 && (
          <>
            <p>{activeSlideData?.content}</p>
            <TabsTypeList
              selectedTab={currentTab}
              setSelectedTab={setCurrentTab}
            />
            <div className={styles.table__container}>
              <div className={styles.table}>
                <ExpandableTable
                  columns={
                    currentTab === "Missing targets"
                      ? missingColumns
                      : currentColumns
                  }
                  data={
                    currentTab === "Missing targets" ? missingData : currentData
                  }
                  rowSelection={getRowSelectionFunction()}
                  scroll={{ x: 1000, y: 55 * 9 }}
                />
              </div>
              <button className={styles.apply}>Apply 26 recommendations</button>
            </div>
          </>
        )}
      </ModalBox>
    </div>
  );
};
