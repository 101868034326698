import { Api } from "src/utils/api";

import { ApiResponse } from "src/types/apiResponce";
import { OPTIMIZATION_BASE_URL } from "./OptimizationUrls";
import {
  AnalyzeProductListing,
  AnalyzeProductPhoto,
  AnalyzeProductVideo,
  CROInfoType,
  ListingChangeType,
  MaskIDStatus,
  MaskIDType,
  NewPhotoType,
  PhotoInfoType,
} from "@services/amazon/products/types";
import { useProductsStore } from "src/store/product.state";

export class OptimizationService extends Api {
  constructor() {
    super(OPTIMIZATION_BASE_URL);
  }

  public async getAnalyzePhotoByAsin(
    asin: string,
  ): Promise<ApiResponse<AnalyzeProductPhoto>> {
    const resp = await this.get(`/spider/analyze_product_photo_asin/`, {
      asin,
    });
    useProductsStore.getState().setAnalyzePhoto(resp as any);
    return resp as any;
  }
  public async getAnalyzePhotoSuggestionsByAsin(
    asin: string,
    desiredScore: number,
  ): Promise<string | null> {
    const resp = await this.get(`/spider/analyze_photo_suggestions_asin/`, {
      asin,
      desiredScore,
    });

    useProductsStore.getState().setAnalyzePhotoSuggestions(resp as any);
    return resp as any;
  }

  public async getAnalyzeProductListingByAsin(
    asin: string,
  ): Promise<ApiResponse<AnalyzeProductListing>> {
    const resp = await this.get(`/spider/analyze_product_listings_asin/`, {
      asin,
    });
    useProductsStore
      .getState()
      .setAnalyzeProductListing(resp as AnalyzeProductListing);
    return resp as any;
  }
  public async getAnalyzeProductListingSuggestionsByAsin(
    asin: string,
    desiredScore: number,
  ): Promise<string | null> {
    const resp = await this.get(
      `/spider/analyze_product_listings_suggestions_asin/`,
      {
        asin,
        desiredScore,
      },
    );

    useProductsStore
      .getState()
      .setAnalyzeProductListingSuggestions(resp as any);
    return resp as any;
  }

  public async getAnalyzeVideoByAsin(
    asin: string,
  ): Promise<ApiResponse<AnalyzeProductVideo>> {
    const resp = await this.get(`/spider/analyze_product_video_asin/`, {
      asin,
    });
    useProductsStore.getState().setAnalyzeVideo(resp as any);
    return resp as any;
  }
  public async getAnalyzeVideoSuggestionsByAsin(
    asin: string,
    desiredScore: number,
  ): Promise<string | null> {
    const resp = await this.get(
      `/spider/analyze_product_video_suggestions_asin/`,
      {
        asin,
        desiredScore,
      },
    );

    useProductsStore.getState().setAnalyzeVideoSuggestions(resp as any);
    return resp as any;
  }

  public async getCROInfo(asin: string): Promise<ApiResponse<CROInfoType>> {
    const resp = await this.get(`/spider/get_asin_cro_info/`, {
      asin,
    });
    useProductsStore.getState().setCROInfo(resp as CROInfoType);
    return resp as any;
  }

  public async getPhotoInfo(
    asin: string,
    photo_id: string,
  ): Promise<ApiResponse<PhotoInfoType>> {
    const resp = await this.get(`/get_photo_info/`, { asin, photo_id });
    useProductsStore.getState().setPhotoInfo(resp as PhotoInfoType);
    return resp as any;
  }

  public async getListingsPotentialChange(
    asin: string,
  ): Promise<ApiResponse<ListingChangeType>> {
    const resp = await this.get(`/spider/get_listings_potential_change/`, {
      asin,
    });
    useProductsStore.getState().setListingUpdate(resp as ListingChangeType);
    return resp as any;
  }

  public async getNewPhoto(
    asin: string,
    photo_id: string,
  ): Promise<ApiResponse<NewPhotoType>> {
    const resp = await this.get(`/spider/showroom_photo_new/`, {
      asin,
      photo_id,
    });
    useProductsStore.getState().setNewPhoto(resp as NewPhotoType);
    return resp as any;
  }

  public async getMaskID(img: string): Promise<ApiResponse<MaskIDType>> {
    const resp = await this.post(`spider/get_mask`, {
      input: {
        img,
      },
    });
    return resp as any;
  }

  public async getMaskStatus(id: string): Promise<ApiResponse<MaskIDStatus>> {
    const resp = await this.get(`spider/status/${id}`);
    return resp as any;
  }

  public async getChatCompletions(base64data: string): Promise<any> {
    const resp = await this.post("https://api.openai.com/v1/chat/completions", {
      method: "POST",
      body: JSON.stringify({
        model: "gpt-4o-2024-08-06",
        messages: [
          {
            role: "user",
            content: [
              {
                type: "text",
                text: "Provide 3 prompts for Stable Diffusion model that will generate background for advertising photo of main object of provided image. For example, if provided image has sport nutrition package on it, provided prompts could be backgrounds with kitchen environment with some healthy food on kitchen table, fitness club surroundings, nature surroundings, or just some minimalistic surroundings with just product pack.",
              },
              {
                type: "image_url",
                image_url: {
                  url: base64data,
                },
              },
            ],
          },
        ],
        response_format: {
          type: "json_schema",
          json_schema: {
            name: "prompt_suggestions",
            schema: {
              type: "object",
              properties: {
                suggestions: {
                  type: "array",
                  items: {
                    type: "string",
                  },
                },
              },
              required: ["suggestions"],
              additionalProperties: false,
            },
            strict: true,
          },
        },
      }),
    });
    return resp as any;
  }
}
