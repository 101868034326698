import React, { useState } from "react";
import { Card, Typography, Segmented, Slider } from "antd";
import "./Discount.scss";

const { Text } = Typography;

interface DiscountCardsProps {
  isWhatIfEnabled: boolean;
}

const DiscountCards: React.FC<DiscountCardsProps> = ({ isWhatIfEnabled }) => {
  const [selectedDiscount, setSelectedDiscount] = useState(10);
  const [couponValue, setCouponValue] = useState<number>(10);
  const [couponType, setCouponType] = useState("percentage");

  return (
    <div className="cardContainer">
      <Card className="card" bordered={false} bodyStyle={{ padding: 0 }}>
        <div className="cardContent">
          <h1 className="cardTitle">Subscription Discount</h1>
          {isWhatIfEnabled ? (
            <div className="segmentedControl">
              <Segmented
                options={[
                  { label: "0%", value: 0 },
                  { label: "5%", value: 5 },
                  { label: "10%", value: 10 },
                ]}
                size="large"
                block
                value={selectedDiscount}
                onChange={(value) => setSelectedDiscount(value as number)}
              />
            </div>
          ) : (
            <h1 className="cardValue">0%</h1>
          )}
        </div>
      </Card>

      <Card className="card" bordered={false} bodyStyle={{ padding: 0 }}>
        <div className="cardContent">
          {isWhatIfEnabled ? (
            <div className="couponSlider">
              <div className="couponBetween">
                <h1 className="cardTitle">Coupon</h1>
                <Segmented
                  options={[
                    { label: couponValue.toString(), value: couponValue },
                    { label: "$", value: "dollar" },
                    { label: "%", value: "percentage" },
                  ]}
                  size="small"
                  value={couponType}
                  onChange={(value) => setCouponType(value.toString())}
                  className="segmentation"
                />
              </div>
              <Slider
                min={-100}
                max={100}
                className="slider"
                trackStyle={{
                  backgroundColor: "#1890ff",
                  height: "14px",
                  borderRadius: "8px",
                }}
                handleStyle={{
                  backgroundColor: "#fff",
                  borderColor: "#1890ff",
                  height: "20px",
                  width: "20px",
                  marginTop: "-3px",
                }}
                railStyle={{
                  backgroundColor: "#444",
                  height: "14px",
                  borderRadius: "8px",
                }}
              />
              <div className="segmentedContainer"></div>
            </div>
          ) : (
            <div className="valueWithToggle">
              <div className="couponDown">
                <h1 className="cardTitle">Coupon</h1>
                <h1 className="cardValue">0%</h1>
              </div>
              <Segmented
                options={[
                  { label: "$", value: "dollar" },
                  { label: "%", value: "percentage" },
                ]}
                size="small"
                defaultValue="percentage"
                className="segmentation"
              />
            </div>
          )}
        </div>
      </Card>
    </div>
  );
};

export default DiscountCards;
