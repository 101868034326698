import { RadarChart } from "@components/Spider/RadarChart";
import { RadarData, Message } from "@components/ChatBot/types";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import style from "./style.module.scss";
import {
  CardChartData,
  CardWithChart,
  CardWithChartProps,
} from "@components/CardWithChart";

const getRadarDataset = (radarValues: number[]) => [
  {
    label: "Your Product",
    data: radarValues,
    backgroundColor: "#FFFFFF",
    borderColor: "#5295E0",
    pointBackgroundColor: "transparent",
    pointHoverBackgroundColor: "#5295E0",
    pointBorderColor: "transparent",
    pointHoverBorderColor: "#5295E0",
    pointRadius: 10,
    pointHoverRadius: 6,
    borderWidth: 1,
  },
];

const addStarToRating = (text: string): string => {
  return text.replace(
    /(\*\*Rating:\*\* )(\d+)/g,
    (_, prefix, rating) => `${prefix}${rating}⭐`,
  );
};

const renderTextMessage = (text: string) => {
  return (
    <div className={style.message__text}>
      <ReactMarkdown remarkPlugins={[remarkGfm]}>
        {addStarToRating(text)}
      </ReactMarkdown>
    </div>
  );
};

const renderPerformanceChart = (data: CardChartData[]) => {
  const chartProps: CardWithChartProps = {
    title: "Performance Chart",
    chartData: data,
  };

  return (
    <div className={style.card}>
      <CardWithChart {...chartProps} />
    </div>
  );
};

const renderSpiderChart = (data: RadarData) => {
  const toolData = data as RadarData;
  const radarValues = [
    toolData.design,
    toolData.brand,
    toolData.usability,
    toolData.quality,
    toolData.price,
    toolData.noise,
  ];

  const radarDataset = getRadarDataset(radarValues);

  return (
    <div className={style.message__canva}>
      <RadarChart
        productOneTitles={chartLabels}
        titles={chartLabels}
        datasets={radarDataset}
        activeIndex={0}
        width={280}
        height={280}
        padding={0}
        productOneValues={radarValues}
        productTwoValues={[]}
        labelFontSize={12}
      />
    </div>
  );
};

const renderMetricValue = (data: number) => {
  const value = `${(data * 100).toFixed(2)}%`;
  const chartProps: CardWithChartProps = {
    title: "Metric",
    value,
  };

  return (
    <div className={style.card}>
      <CardWithChart {...chartProps} />
    </div>
  );
};

export const renderMessage = (message: Message) => {
  const { type, text, data } = message;

  try {
    switch (type) {
      case "text":
        return renderTextMessage(text);

      case "performance_chart":
        return renderPerformanceChart(data as CardChartData[]);

      case "metric_value":
        return renderMetricValue(Number(data));

      case "get_product_spider":
        return renderSpiderChart(data as RadarData);

      default:
        console.warn(`Unsupported message type: ${type}`);
        return <div className={style.message__text}>Type not supported</div>;
    }
  } catch (error) {
    console.error("Error rendering message:", error);
    return <div className={style.message__text}>Error rendering message</div>;
  }
};

const chartLabels = [
  "Design",
  "Brand",
  "Usability",
  "Quality",
  "Price",
  "Noise",
];
