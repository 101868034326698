import { calculateIncrease } from "@pages/AdsAnalitics/components/Campaigns/utils";
import { IWidget } from "@pages/Dashboard/types/dashboard.types.ts";

const safeNumber = (value: any) => (typeof value === "number" ? value : 0);

export const createWidgets = async (
  data: any,
  compareData: any,
): Promise<IWidget[]> => {
  const { metrics } = data;

  return [
    {
      id: "2",
      name: metrics.salesTotalData.name,
      value:
        metrics.salesTotalData.value !== null
          ? `$${Math.floor(metrics.salesTotalData.value)}`
          : "N/A",
      originalValue: safeNumber(metrics.aovData.value),
      difference: metrics.salesTotalData.difference,
    },
    {
      id: "3",
      name: metrics.adsImpressionsData.name,
      value:
        metrics.adsImpressionsData.value !== null
          ? metrics.adsImpressionsData.value.toString()
          : "N/A",
      originalValue: safeNumber(metrics.adsImpressionsData.value),
      difference: calculateIncrease(
        metrics.salesTotalData.value,
        compareData.salesCompare,
      ).increase,
    },
    {
      id: "4",
      name: metrics.adsClicksData.name,
      value:
        metrics.adsClicksData.value !== null
          ? metrics.adsClicksData.value.toString()
          : "N/A",
      originalValue: safeNumber(metrics.adsClicksData.value),
      difference: calculateIncrease(
        metrics.adsClicksData.value,
        compareData.clicksCompare,
      ).increase,
    },
    {
      id: "5",
      name: metrics.adsOrdersData.name,
      value:
        metrics.adsOrdersData.value !== null
          ? metrics.adsOrdersData.value.toString()
          : "N/A",
      originalValue: safeNumber(metrics.adsOrdersData.value),
      difference: calculateIncrease(
        metrics.adsOrdersData.value,
        compareData.ordersCompare,
      ).increase,
    },
    {
      id: "6",
      name: metrics.adsUnitsSoldData.name,
      value:
        metrics.adsUnitsSoldData.value !== null
          ? metrics.adsUnitsSoldData.value.toString()
          : "N/A",
      originalValue: safeNumber(metrics.adsUnitsSoldData.value),
      difference: calculateIncrease(
        metrics.adsUnitsSoldData.value,
        compareData.unitSoldsCompare,
      ).increase,
    },
    {
      id: "7",
      name: metrics.adsSalesData.name,
      value:
        metrics.adsSalesData.value !== null
          ? `$${Math.floor(metrics.adsSalesData.value)}`
          : "N/A",
      originalValue: safeNumber(metrics.adsSalesData.value),
      difference: calculateIncrease(
        metrics.adsSalesData.value,
        compareData.salesCompare,
      ).increase,
    },
    {
      id: "8",
      name: metrics.adsSpendData.name,
      value:
        metrics.adsSpendData.value !== null
          ? `$${Math.floor(metrics.adsSpendData.value)}`
          : "N/A",
      originalValue: safeNumber(metrics.adsSpendData.value),
      difference: calculateIncrease(
        metrics.adsSpendData.value,
        compareData.spendCompare,
      ).increase,
    },
    {
      id: "9",
      name: metrics.adsAcosData.name,
      value:
        metrics.adsAcosData.value !== null
          ? `${metrics.adsAcosData.value}%`
          : "N/A",
      originalValue: safeNumber(metrics.adsAcosData.value),
      difference: calculateIncrease(
        metrics.adsAcosData.value,
        compareData.acosCompare,
      ).increase,
    },
    {
      id: "10",
      name: metrics.adsRoasData.name,
      value:
        metrics.adsRoasData.value !== null
          ? metrics.adsRoasData.value.toString()
          : "N/A",
      originalValue: safeNumber(metrics.adsRoasData.value),
      difference: calculateIncrease(
        metrics.adsRoasData.value,
        compareData.roasCompare,
      ).increase,
    },
    {
      id: "11",
      name: metrics.adsCpcData.name,
      value:
        metrics.adsCpcData.value !== null
          ? `$${metrics.adsCpcData.value}`
          : "N/A",
      originalValue: safeNumber(metrics.adsCpcData.value),
      difference: calculateIncrease(
        metrics.adsCpcData.value,
        compareData.cpcCompare,
      ).increase,
    },
    {
      id: "12",
      name: metrics.adsCvrData.name,
      value:
        metrics.adsCvrData.value !== null
          ? `${metrics.adsCvrData.value}%`
          : "N/A",
      originalValue: safeNumber(metrics.adsCvrData.value),
      difference: calculateIncrease(
        metrics.adsCvrData.value,
        compareData.cvrCompare,
      ).increase,
    },
    {
      id: "13",
      name: metrics.adsCtrData.name,
      value:
        metrics.adsCtrData.value !== null
          ? `${metrics.adsCtrData.value}%`
          : "N/A",
      originalValue: safeNumber(metrics.adsCtrData.value),
      difference: calculateIncrease(
        metrics.adsCtrData.value,
        compareData.ctrCompare,
      ).increase,
    },
    {
      id: "14",
      name: metrics.organicImpressionsData.name,
      value:
        metrics.organicImpressionsData.value !== null
          ? metrics.organicImpressionsData.value.toString()
          : "N/A",
      originalValue: safeNumber(metrics.organicImpressionsData.value),
      difference: metrics.organicImpressionsData.difference,
    },
    {
      id: "15",
      name: metrics.organicClicksData.name,
      value:
        metrics.organicClicksData.value !== null
          ? metrics.organicClicksData.value.toString()
          : "N/A",
      originalValue: safeNumber(metrics.organicClicksData.value),
      difference: metrics.organicClicksData.difference,
    },
    {
      id: "16",
      name: metrics.organicCartAddsData.name,
      value:
        metrics.organicCartAddsData.value !== null
          ? metrics.organicCartAddsData.value.toString()
          : "N/A",
      originalValue: safeNumber(metrics.organicCartAddsData.value),
      difference: metrics.organicCartAddsData.difference,
    },
    {
      id: "17",
      name: metrics.organicUnitsSoldData.name,
      value:
        metrics.organicUnitsSoldData.value !== null
          ? metrics.organicUnitsSoldData.value.toString()
          : "N/A",
      originalValue: safeNumber(metrics.organicUnitsSoldData.value),
      difference: metrics.organicUnitsSoldData.difference,
    },
    {
      id: "18",
      name: metrics.organicCvrData.name,
      value:
        metrics.organicCvrData.value !== null
          ? `${metrics.organicCvrData.value}%`
          : "N/A",
      originalValue: safeNumber(metrics.organicCvrData.value),
      difference: metrics.organicCvrData.difference,
    },
    {
      id: "19",
      name: metrics.organicCtrData.name,
      value:
        metrics.organicCtrData.value !== null
          ? `${metrics.organicCtrData.value}%`
          : "N/A",
      originalValue: safeNumber(metrics.organicCtrData.value),
      difference: metrics.organicCtrData.difference,
    },
  ];
};
