import { Table } from "antd";
import "./table.scss";
import classNames from "classnames";

interface ExpandableTableProps {
  columns: any[];
  data: any[];
  rowSelection?: any;
  className?: string;
  isLoading?: boolean;
  withCustomScroll?: boolean;
  scroll?: { x?: number; y?: number };
  withoutBottomPadding?: boolean;
}

const isCheckboxDisabled = (record) => {
  if (typeof record.campaign === "string") {
    return (
      record.campaign?.startsWith("Total") ||
      record.campaign?.startsWith("Grand")
    );
  }
  if (typeof record.performance === "object") {
    return record.performance?.props.children.startsWith("Grand");
  }
  if (typeof record.targeting === "string") {
    return record.targeting?.startsWith("Total");
  }
  if (typeof record.typeAd === "object") {
    return record.typeAd?.props.children.startsWith("Grand");

    // return (
    //   record.typeAd?.startsWith("Grand") || record.typeAd?.startsWith("Total")
    // );
  }
  if (typeof record.category === "string") {
    return record.category?.startsWith("Total");
  }
  return false;
};

export const ExpandableTable = ({
  columns,
  data,
  rowSelection,
  className,
  isLoading,
  withCustomScroll,
  withoutBottomPadding,
  scroll = {
    x: 1000,
  },
}: ExpandableTableProps) => {
  return (
    <Table
      columns={columns}
      dataSource={data}
      loading={isLoading}
      expandable={{
        expandRowByClick: true,
      }}
      rowSelection={
        rowSelection && {
          ...rowSelection,
          checkStrictly: false,
          getCheckboxProps: (record) => ({
            disabled: isCheckboxDisabled(record),
          }),
        }
      }
      scroll={scroll}
      pagination={false}
      className={classNames(
        "expandable-table",
        withCustomScroll && "custom-scroll",
        withoutBottomPadding && "without-bottom-padding",
        className,
      )}
      key={"key"}
    />
  );
};
