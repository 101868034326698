export const normalizeMetricName = (name: string): string => {
  let cleanName = name.trim().replace(/\.$/, "").toLowerCase();

  cleanName = cleanName.replace(/\b(cvr|ctr|roi|acos|pos|psl)\b/gi, (abbr) => {
    return abbr.toUpperCase();
  });

  if (/\bunits\b/i.test(cleanName)) {
    cleanName = cleanName.replace(/\b\w/g, (char) => char.toUpperCase());
  } else {
    cleanName = cleanName.charAt(0).toUpperCase() + cleanName.slice(1);
  }

  return cleanName;
};
