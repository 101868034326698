export const data = [
  {
    x: 1,
    y: 10,
    size: 24,
    color: "#80C67A",
    name: "theeth whiting strips",
    adSpend: 100,
    searchVolume: 5000,
  },
  {
    x: 4,
    y: 4,
    size: 12,
    color: "#80C67A",
    name: "product 2",
    adSpend: 200,
    searchVolume: 4000,
  },
  {
    x: 4,
    y: 40,
    size: 10,
    color: "#80C67A",
    name: "product 3",
    adSpend: 300,
    searchVolume: 3000,
  },
  {
    x: 4,
    y: 80,
    size: 24,
    color: "#5C5C5A",
    name: "product 4",
    adSpend: 400,
    searchVolume: 2000,
  },
  {
    x: 4,
    y: 110,
    size: 24,
    color: "#80C67A",
    name: "product 5",
    adSpend: 500,
    searchVolume: 1000,
  },
  {
    x: 4,
    y: 290,
    size: 24,
    color: "#5C5C5A",
    name: "product 6",
    adSpend: 600,
    searchVolume: 500,
  },
  {
    x: 4,
    y: 430,
    size: 24,
    color: "#80C67A",
    name: "product 7",
    adSpend: 700,
    searchVolume: 250,
  },
  {
    x: 7,
    y: 10,
    size: 24,
    color: "#80C67A",
    name: "product 8",
    adSpend: 800,
    searchVolume: 125,
  },
  {
    x: 7,
    y: 45,
    size: 24,
    color: "#5C5C5A",
    name: "product 9",
    adSpend: 900,
    searchVolume: 60,
  },
  {
    x: 7,
    y: 70,
    size: 12,
    color: "#5C5C5A",
    name: "product 10",
    adSpend: 1000,
    searchVolume: 30,
  },
  {
    x: 7,
    y: 100,
    size: 24,
    color: "#5C5C5A",
    name: "product 11",
    adSpend: 1100,
    searchVolume: 15,
  },
  {
    x: 7,
    y: 150,
    size: 24,
    color: "#80C67A",
    name: "product 12",
    adSpend: 1200,
    searchVolume: 8,
  },
  {
    x: 7,
    y: 290,
    size: 24,
    color: "#5C5C5A",
    name: "product 13",
    adSpend: 1300,
    searchVolume: 4,
  },
  {
    x: 7,
    y: 430,
    size: 24,
    color: "#80C67A",
    name: "product 14",
    adSpend: 1400,
    searchVolume: 2,
  },
  // Продолжаем обновлять оставшиеся данные
  // x = 12
  {
    x: 12,
    y: 10,
    size: 24,
    color: "#80C67A",
    name: "product 15",
    adSpend: 1500,
    searchVolume: 1000,
  },
  {
    x: 12,
    y: 45,
    size: 24,
    color: "#5C5C5A",
    name: "product 16",
    adSpend: 1600,
    searchVolume: 900,
  },
  {
    x: 12,
    y: 70,
    size: 12,
    color: "#5C5C5A",
    name: "product 17",
    adSpend: 1700,
    searchVolume: 800,
  },
  {
    x: 12,
    y: 100,
    size: 24,
    color: "#5C5C5A",
    name: "product 18",
    adSpend: 1800,
    searchVolume: 700,
  },
  {
    x: 12,
    y: 150,
    size: 24,
    color: "#80C67A",
    name: "product 19",
    adSpend: 1900,
    searchVolume: 600,
  },
  {
    x: 12,
    y: 290,
    size: 24,
    color: "#5C5C5A",
    name: "product 20",
    adSpend: 2000,
    searchVolume: 500,
  },
  {
    x: 12,
    y: 430,
    size: 24,
    color: "#80C67A",
    name: "product 21",
    adSpend: 2100,
    searchVolume: 400,
  },
  // x = 15
  {
    x: 15,
    y: 10,
    size: 24,
    color: "#80C67A",
    name: "product 22",
    adSpend: 2200,
    searchVolume: 350,
  },
  {
    x: 15,
    y: 45,
    size: 24,
    color: "#5C5C5A",
    name: "product 23",
    adSpend: 2300,
    searchVolume: 300,
  },
  {
    x: 15,
    y: 70,
    size: 12,
    color: "#5C5C5A",
    name: "product 24",
    adSpend: 2400,
    searchVolume: 250,
  },
  {
    x: 15,
    y: 240,
    size: 24,
    color: "#5C5C5A",
    name: "product 25",
    adSpend: 2500,
    searchVolume: 200,
  },
  {
    x: 15,
    y: 150,
    size: 24,
    color: "#80C67A",
    name: "product 26",
    adSpend: 2600,
    searchVolume: 150,
  },
  {
    x: 15,
    y: 110,
    size: 24,
    color: "#80C67A",
    name: "product 27",
    adSpend: 2700,
    searchVolume: 100,
  },
  {
    x: 15,
    y: 290,
    size: 24,
    color: "#5C5C5A",
    name: "product 28",
    adSpend: 2800,
    searchVolume: 90,
  },
  {
    x: 15,
    y: 430,
    size: 24,
    color: "#80C67A",
    name: "product 29",
    adSpend: 2900,
    searchVolume: 80,
  },
  // x = 20
  {
    x: 20,
    y: 10,
    size: 24,
    color: "#80C67A",
    name: "product 30",
    adSpend: 3000,
    searchVolume: 70,
  },
  {
    x: 20,
    y: 45,
    size: 24,
    color: "#5C5C5A",
    name: "product 31",
    adSpend: 3100,
    searchVolume: 65,
  },
  {
    x: 20,
    y: 70,
    size: 12,
    color: "#5C5C5A",
    name: "product 32",
    adSpend: 3200,
    searchVolume: 60,
  },
  {
    x: 20,
    y: 100,
    size: 24,
    color: "#5C5C5A",
    name: "product 33",
    adSpend: 3300,
    searchVolume: 55,
  },
  {
    x: 20,
    y: 150,
    size: 24,
    color: "#80C67A",
    name: "product 34",
    adSpend: 3400,
    searchVolume: 50,
  },
  {
    x: 20,
    y: 250,
    size: 24,
    color: "#80C67A",
    name: "product 35",
    adSpend: 3500,
    searchVolume: 45,
  },
  {
    x: 20,
    y: 300,
    size: 24,
    color: "#80C67A",
    name: "product 36",
    adSpend: 3600,
    searchVolume: 40,
  },
  {
    x: 20,
    y: 350,
    size: 24,
    color: "#80C67A",
    name: "product 37",
    adSpend: 3700,
    searchVolume: 35,
  },
  {
    x: 20,
    y: 400,
    size: 24,
    color: "#80C67A",
    name: "product 38",
    adSpend: 3800,
    searchVolume: 30,
  },
  {
    x: 20,
    y: 450,
    size: 24,
    color: "#80C67A",
    name: "product 39",
    adSpend: 3900,
    searchVolume: 25,
  },
  // x = 25
  {
    x: 25,
    y: 10,
    size: 24,
    color: "#80C67A",
    name: "product 40",
    adSpend: 4000,
    searchVolume: 5000,
  },
  {
    x: 25,
    y: 39,
    size: 24,
    color: "#5C5C5A",
    name: "product 41",
    adSpend: 4100,
    searchVolume: 4800,
  },
  {
    x: 25,
    y: 68,
    size: 12,
    color: "#5C5C5A",
    name: "product 42",
    adSpend: 4200,
    searchVolume: 4600,
  },
  {
    x: 25,
    y: 88,
    size: 24,
    color: "#FE5858",
    name: "product 43",
    adSpend: 4300,
    searchVolume: 4400,
  },
  {
    x: 25,
    y: 114,
    size: 24,
    color: "#80C67A",
    name: "product 44",
    adSpend: 4400,
    searchVolume: 4200,
  },
  {
    x: 25,
    y: 143,
    size: 12,
    color: "#5C5C5A",
    name: "product 45",
    adSpend: 4500,
    searchVolume: 4000,
  },
  // x = 32
  {
    x: 32,
    y: 10,
    size: 24,
    color: "#5C5C5A",
    name: "product 46",
    adSpend: 4600,
    relevanceScore: 42,
    searchVolume: 3800,
  },
  {
    x: 32,
    y: 39,
    size: 12,
    color: "#FE5858",
    name: "product 47",
    adSpend: 4700,
    relevanceScore: 42,

    searchVolume: 3600,
  },
  {
    x: 32,
    y: 62,
    size: 24,
    color: "#C8A857",
    name: "product 48",
    adSpend: 4800,
    relevanceScore: 42,

    searchVolume: 3400,
  },
  {
    x: 32,
    y: 91,
    size: 12,
    color: "#C8A857",
    name: "product 49",
    adSpend: 4900,
    relevanceScore: 42,

    searchVolume: 3200,
  },
  {
    x: 32,
    y: 114,
    size: 24,
    color: "#5C5C5A",
    name: "product 50",
    adSpend: 5000,
    relevanceScore: 42,

    searchVolume: 3000,
  },
  {
    x: 32,
    y: 143,
    size: 12,
    color: "#FE5858",
    name: "product 51",
    adSpend: 5100,
    searchVolume: 2800,
  },
  {
    x: 32,
    y: 166,
    size: 24,
    color: "#C8A857",
    name: "product 52",
    adSpend: 5200,
    relevanceScore: 42,

    searchVolume: 2600,
  },
  {
    x: 32,
    y: 195,
    size: 24,
    color: "#C8A857",
    name: "product 53",
    adSpend: 5300,
    relevanceScore: 42,

    searchVolume: 2400,
  },
  // x = 41
  {
    x: 41,
    y: 10,
    size: 24,
    color: "#C8A857",
    name: "product 54",
    adSpend: 5400,
    relevanceScore: 42,
    searchVolume: 2200,
  },
  {
    x: 41,
    y: 39,
    size: 12,
    color: "#C8A857",
    name: "product 55",
    adSpend: 5500,
    relevanceScore: 42,
    searchVolume: 2000,
  },
  {
    x: 41,
    y: 62,
    size: 24,
    color: "#5C5C5A",
    name: "product 56",
    adSpend: 5600,
    relevanceScore: 42,
    searchVolume: 1800,
  },
  {
    x: 41,
    y: 91,
    size: 24,
    color: "#FE5858",
    name: "product 57",
    adSpend: 5700,
    relevanceScore: 42,
    searchVolume: 1600,
  },
  {
    x: 41,
    y: 120,
    size: 12,
    color: "#C8A857",
    name: "product 58",
    adSpend: 5800,
    relevanceScore: 42,
    searchVolume: 1400,
  },
  // x = 45
  {
    x: 45,
    y: 10,
    size: 12,
    color: "#1F1F20",
    name: "product 59",
    adSpend: 5900,
    relevanceScore: 42,
    searchVolume: 1200,
  },
  {
    x: 45,
    y: 33,
    size: 24,
    color: "#FE5858",
    name: "product 60",
    adSpend: 6000,
    relevanceScore: 42,
    searchVolume: 1100,
  },
  {
    x: 45,
    y: 62,
    size: 12,
    color: "#1F1F20",
    name: "product 61",
    adSpend: 6100,
    relevanceScore: 42,
    searchVolume: 1000,
  },
  {
    x: 45,
    y: 79,
    size: 12,
    color: "#1F1F20",
    name: "product 62",
    adSpend: 6200,
    relevanceScore: 42,
    searchVolume: 900,
  },
  {
    x: 45,
    y: 102,
    size: 24,
    color: "#1F1F20",
    name: "product 63",
    adSpend: 6300,
    relevanceScore: 42,
    searchVolume: 800,
  },
  {
    x: 45,
    y: 131,
    size: 12,
    color: "#FE5858",
    name: "product 64",
    adSpend: 6400,
    relevanceScore: 42,
    searchVolume: 700,
  },
  {
    x: 45,
    y: 154,
    size: 24,
    color: "#1F1F20",
    name: "product 65",
    adSpend: 6500,
    relevanceScore: 42,
    searchVolume: 600,
  },
  // x = 51
  {
    x: 51,
    y: 10,
    size: 24,
    color: "#FE5858",
    name: "product 66",
    adSpend: 6600,
    searchVolume: 500,
  },
  {
    x: 51,
    y: 39,
    size: 24,
    color: "#1F1F20",
    name: "product 67",
    adSpend: 6700,
    searchVolume: 450,
  },
  {
    x: 51,
    y: 68,
    size: 12,
    color: "#FE5858",
    name: "product 68",
    adSpend: 6800,
    searchVolume: 400,
  },
  {
    x: 51,
    y: 88,
    size: 24,
    color: "#1F1F20",
    name: "product 69",
    adSpend: 6900,
    searchVolume: 350,
  },
  {
    x: 51,
    y: 114,
    size: 12,
    color: "#FE5858",
    name: "product 70",
    adSpend: 7000,
    searchVolume: 300,
  },

  // x = 59
  {
    x: 59,
    y: 10,
    size: 12,
    color: "#FE5858",
    name: "product 71",
    adSpend: 7100,
    searchVolume: 250,
  },
  {
    x: 59,
    y: 33,
    size: 24,
    color: "#1F1F20",
    name: "product 72",
    adSpend: 7200,
    searchVolume: 225,
  },
  {
    x: 59,
    y: 62,
    size: 24,
    color: "#1F1F20",
    name: "product 73",
    adSpend: 7300,
    searchVolume: 200,
  },
  {
    x: 59,
    y: 91,
    size: 12,
    color: "#FE5858",
    name: "product 74",
    adSpend: 7400,
    searchVolume: 175,
  },
  {
    x: 59,
    y: 114,
    size: 24,
    color: "#FE5858",
    name: "product 75",
    adSpend: 7500,
    searchVolume: 150,
  },
  {
    x: 59,
    y: 143,
    size: 12,
    color: "#1F1F20",
    name: "product 76",
    adSpend: 7600,
    searchVolume: 125,
  },
  // x = 67
  {
    x: 67,
    y: 10,
    size: 24,
    color: "#1F1F20",
    name: "product 77",
    adSpend: 7700,
    searchVolume: 100,
  },
  {
    x: 67,
    y: 39,
    size: 12,
    color: "#FE5858",
    name: "product 78",
    adSpend: 7800,
    searchVolume: 90,
  },
  {
    x: 67,
    y: 62,
    size: 24,
    color: "#FE5858",
    name: "product 79",
    adSpend: 7900,
    searchVolume: 80,
  },
  {
    x: 67,
    y: 91,
    size: 12,
    color: "#1F1F20",
    name: "product 80",
    adSpend: 8000,
    searchVolume: 70,
  },
  {
    x: 67,
    y: 114,
    size: 24,
    color: "#1F1F20",
    name: "product 81",
    adSpend: 8100,
    searchVolume: 60,
  },
  {
    x: 67,
    y: 143,
    size: 12,
    color: "#FE5858",
    name: "product 82",
    adSpend: 8200,
    searchVolume: 50,
  },
  {
    x: 67,
    y: 166,
    size: 24,
    color: "#FE5858",
    name: "product 83",
    adSpend: 8300,
    searchVolume: 40,
  },
  {
    x: 67,
    y: 195,
    size: 12,
    color: "#1F1F20",
    name: "product 84",
    adSpend: 8400,
    searchVolume: 30,
  },
  // x = 75
  {
    x: 75,
    y: 10,
    size: 24,
    color: "#FE5858",
    name: "product 85",
    adSpend: 8500,
    searchVolume: 25,
  },
  {
    x: 75,
    y: 39,
    size: 24,
    color: "#1F1F20",
    name: "product 86",
    adSpend: 8600,
    searchVolume: 20,
  },
  {
    x: 75,
    y: 68,
    size: 12,
    color: "#1F1F20",
    name: "product 87",
    adSpend: 8700,
    searchVolume: 18,
  },
  {
    x: 75,
    y: 88,
    size: 24,
    color: "#FE5858",
    name: "product 88",
    adSpend: 8800,
    searchVolume: 16,
  },
  {
    x: 75,
    y: 114,
    size: 12,
    color: "#FE5858",
    name: "product 89",
    adSpend: 8900,
    searchVolume: 14,
  },
  {
    x: 75,
    y: 137,
    size: 24,
    color: "#1F1F20",
    name: "product 90",
    adSpend: 9000,
    searchVolume: 12,
  },
  {
    x: 75,
    y: 166,
    size: 12,
    color: "#FE5858",
    name: "product 91",
    adSpend: 9100,
    searchVolume: 10,
  },
  {
    x: 75,
    y: 189,
    size: 24,
    color: "#1F1F20",
    name: "product 92",
    adSpend: 9200,
    searchVolume: 8,
  },
  {
    x: 75,
    y: 218,
    size: 12,
    color: "#FE5858",
    name: "product 93",
    adSpend: 9300,
    searchVolume: 6,
  },
  // x = 83
  {
    x: 83,
    y: 10,
    size: 24,
    color: "#FE5858",
    name: "product 94",
    adSpend: 9400,
    searchVolume: 5,
  },
  {
    x: 83,
    y: 39,
    size: 12,
    color: "#1F1F20",
    name: "product 95",
    adSpend: 9500,
    searchVolume: 4.5,
  },
  {
    x: 83,
    y: 62,
    size: 24,
    color: "#1F1F20",
    name: "product 96",
    adSpend: 9600,
    searchVolume: 4,
  },
  {
    x: 83,
    y: 91,
    size: 12,
    color: "#FE5858",
    name: "product 97",
    adSpend: 9700,
    searchVolume: 3.5,
  },
  {
    x: 83,
    y: 114,
    size: 24,
    color: "#FE5858",
    name: "product 98",
    adSpend: 9800,
    searchVolume: 3,
  },
  {
    x: 83,
    y: 143,
    size: 12,
    color: "#1F1F20",
    name: "product 99",
    adSpend: 9900,
    searchVolume: 2.5,
  },
  {
    x: 83,
    y: 166,
    size: 24,
    color: "#1F1F20",
    name: "product 100",
    adSpend: 10000,
    searchVolume: 2,
  },
  {
    x: 83,
    y: 195,
    size: 12,
    color: "#FE5858",
    name: "product 101",
    adSpend: 10100,
    searchVolume: 1.8,
  },
  {
    x: 83,
    y: 218,
    size: 24,
    color: "#FE5858",
    name: "product 102",
    adSpend: 10200,
    searchVolume: 1.6,
  },
  {
    x: 83,
    y: 247,
    size: 12,
    color: "#1F1F20",
    name: "product 103",
    adSpend: 10300,
    searchVolume: 1.4,
  },
  // x = 90
  {
    x: 90,
    y: 10,
    size: 24,
    color: "#FE5858",
    name: "product 104",
    adSpend: 10400,
    searchVolume: 1.2,
  },
  {
    x: 90,
    y: 39,
    size: 24,
    color: "#1F1F20",
    name: "product 105",
    adSpend: 10500,
    searchVolume: 1.1,
  },
  {
    x: 90,
    y: 68,
    size: 12,
    color: "#FE5858",
    name: "product 106",
    adSpend: 10600,
    searchVolume: 1.0,
  },
  {
    x: 90,
    y: 88,
    size: 24,
    color: "#1F1F20",
    name: "product 107",
    adSpend: 10700,
    searchVolume: 0.9,
  },
  {
    x: 90,
    y: 114,
    size: 24,
    color: "#FE5858",
    name: "product 108",
    adSpend: 10800,
    searchVolume: 0.8,
  },
  {
    x: 90,
    y: 143,
    size: 12,
    color: "#1F1F20",
    name: "product 109",
    adSpend: 10900,
    searchVolume: 0.7,
  },
  {
    x: 90,
    y: 166,
    size: 24,
    color: "#FE5858",
    name: "product 110",
    adSpend: 11000,
    searchVolume: 0.6,
  },
  {
    x: 90,
    y: 195,
    size: 12,
    color: "#1F1F20",
    name: "product 111",
    adSpend: 11100,
    searchVolume: 0.5,
  },
  // x = 97
  {
    x: 97,
    y: 10,
    size: 24,
    color: "#1F1F20",
    name: "product 112",
    adSpend: 11200,
    searchVolume: 0.4,
  },
  {
    x: 97,
    y: 39,
    size: 12,
    color: "#FE5858",
    name: "product 113",
    adSpend: 11300,
    searchVolume: 0.35,
  },
  {
    x: 97,
    y: 62,
    size: 24,
    color: "#FE5858",
    name: "product 114",
    adSpend: 11400,
    searchVolume: 0.3,
  },
  {
    x: 97,
    y: 91,
    size: 24,
    color: "#1F1F20",
    name: "product 115",
    adSpend: 11500,
    searchVolume: 0.25,
  },
  {
    x: 97,
    y: 120,
    size: 12,
    color: "#1F1F20",
    name: "product 116",
    adSpend: 11600,
    searchVolume: 0.2,
  },
  {
    x: 97,
    y: 143,
    size: 24,
    color: "#FE5858",
    name: "product 117",
    adSpend: 11700,
    searchVolume: 0.15,
  },
  {
    x: 97,
    y: 172,
    size: 12,
    color: "#FE5858",
    name: "product 118",
    adSpend: 11800,
    searchVolume: 0.1,
  },
  {
    x: 97,
    y: 195,
    size: 24,
    color: "#1F1F20",
    name: "product 119",
    adSpend: 11900,
    searchVolume: 0.08,
  },
  {
    x: 97,
    y: 224,
    size: 12,
    color: "#1F1F20",
    name: "product 120",
    adSpend: 12000,
    searchVolume: 0.06,
  },
  // x = 100
  {
    x: 100,
    y: 10,
    size: 24,
    color: "#FE5858",
    name: "product 121",
    adSpend: 12100,
    searchVolume: 0.05,
  },
  {
    x: 100,
    y: 39,
    size: 24,
    color: "#1F1F20",
    name: "product 122",
    adSpend: 12200,
    searchVolume: 0.04,
  },
  {
    x: 100,
    y: 68,
    size: 12,
    color: "#FE5858",
    name: "product 123",
    adSpend: 12300,
    searchVolume: 0.03,
  },
  {
    x: 100,
    y: 88,
    size: 24,
    color: "#1F1F20",
    name: "product 124",
    adSpend: 12400,
    searchVolume: 0.025,
  },
  {
    x: 100,
    y: 114,
    size: 24,
    color: "#FE5858",
    name: "product 125",
    adSpend: 12500,
    searchVolume: 0.02,
  },
  {
    x: 100,
    y: 143,
    size: 12,
    color: "#1F1F20",
    name: "product 126",
    adSpend: 12600,
    searchVolume: 0.015,
  },
  {
    x: 100,
    y: 166,
    size: 24,
    color: "#FE5858",
    name: "product 127",
    adSpend: 12700,
    searchVolume: 0.01,
  },
  {
    x: 100,
    y: 195,
    size: 12,
    color: "#1F1F20",
    name: "product 128",
    adSpend: 12800,
    searchVolume: 0.008,
  },
  {
    x: 100,
    y: 218,
    size: 24,
    color: "#FE5858",
    name: "product 129",
    adSpend: 12900,
    searchVolume: 0.006,
  },
  {
    x: 100,
    y: 247,
    size: 12,
    color: "#1F1F20",
    name: "product 130",
    adSpend: 13000,
    searchVolume: 0.005,
  },
];
