import styles from "./syles.module.scss";
import TilesIcon from "@assets/icons/tiles.tsx";
import Table from "@assets/icons/table.tsx";
import clsx from "clsx";
import { useEffect, useState } from "react";
import { useProductStore } from "src/store/overviewProduct.state";
import { ProductsService } from "@services/amazon/products/products.service";

interface Tab {
  code: string;
  icon: React.ReactNode;
}

const tabList: Tab[] = [
  {
    code: "tiles",
    icon: <TilesIcon color="red" />,
  },
  {
    code: "table",
    icon: <Table />,
  },
];

interface Props {
  setSelectedTab: (code: string) => void;
}

export const ProductTab: React.FC<Props> = ({ setSelectedTab }) => {
  const [activeTab, setActiveTab] = useState<string>("tiles");
  const { setSelectedProduct } = useProductStore();
  const productsService = new ProductsService();

  const toggleTab = (code: string) => {
    setActiveTab(code);
    setSelectedTab(code);
  };
  useEffect(() => {
    let timeoutId;

    if (activeTab === "tiles") {
      const fetchProducts = async () => {
        try {
          const params = { limit: 8, page: 1 };
          const response = await productsService.getOwnProducts(params);

          timeoutId = setTimeout(() => {
            if (response.length > 0) {
              setSelectedProduct(response[0].asin);
            } else {
              setSelectedProduct(null);
            }
          }, 5000);
        } catch (err) {
          setSelectedProduct(null);
        }
      };

      fetchProducts();
    } else {
      setSelectedProduct(null);
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [activeTab]);

  return (
    <div className={styles.tabContainer}>
      <h1 className={styles.tabContainer__title}>Products</h1>
      <div className={clsx(styles["tabContainer__tab"])}>
        {tabList.map((item) => (
          <button
            key={item.code}
            onClick={() => toggleTab(item.code)}
            className={clsx(styles.element, {
              [styles["element__active"]]: activeTab === item.code,
            })}
          >
            {item.icon}
          </button>
        ))}
      </div>
    </div>
  );
};
