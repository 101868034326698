import { Widget } from ".";

export const mockWidgets: Widget[] = [
  {
    id: "1",
    items: [
      {
        id: "1-1",
        title: "Subscription Cart Revenue",
        value: "$235,441",
        isPositive: true,
      },
      {
        id: "1-2",
        title: "Subscription Cart Revenue Forecasted",
        value: "$269,496",
        change: "+$34K",
        isPositive: true,
      },
    ],
  },
  {
    id: "3",
    items: [
      {
        id: "3-1",
        title: "Subscription Units Sold",
        value: "8,269",
        isPositive: true,
      },
      {
        id: "3-2",
        title: "Subscription Units Sold Forecasted",
        value: "10,517",
        change: "+34K",
        isPositive: true,
      },
    ],
  },
  {
    id: "2",
    items: [
      {
        id: "2-1",
        title: "Subscription Price",
        value: "$28,47",
        isPositive: true,
      },
      {
        id: "2-2",
        title: "Subscription Price Forecasted",
        value: "$25.63",
        change: "+$34K",
        isPositive: true,
      },
    ],
  },
  {
    id: "4",
    items: [
      {
        id: "4-1",
        title: "Subscriptions Count",
        value: "5,672",
        isPositive: false,
      },
      {
        id: "4-2",
        title: "Subscriptions Count Forecasted",
        value: "5,600",
        change: "-72",
        isPositive: false,
      },
    ],
  },
];
